export const userMenu = (orgName) => [
  { name: orgName, href: '#' },
  // { name: 'Billing', route: 'billing' },
  // { name: 'Users', route: 'users' },
  { name: 'Log out' },
]

export const engineerMenu = () => [{ name: 'Log out' }]

export const navigation = [
  // { name: 'Team', route: 'team' },
  { name: 'Settings', route: 'settings' },
  { name: 'Merge Requests', route: 'open' },
  // { name: 'Analytics', route: 'analytics' },
]

export const subNavigation = {
  // team: [{ name: 'Team management', route: 'team-management' }],
  settings: [
    { name: 'General', route: 'general' },
    { name: 'Team channel', route: 'team-channel' },
    { name: 'Stand-up', route: 'standups' },
    // { name: 'Integrations', route: 'integration' },
  ],
  open: [{ name: 'Open Slack channels', route: 'channels' }],
  // analytics: [{ name: 'Dashboard', route: 'dashboard' }],
  // admin: [
  //   { name: 'Billing', route: 'billing' },
  //   { name: 'Users', route: 'users' },
  // ],
}

export const onboardingSubNavigation = {
  settings: [{ name: 'General', route: 'general' }],
}
