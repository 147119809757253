import { RefreshIcon } from '@heroicons/react/outline'
import { Dropdown, Menu, Select } from 'antd'
import { classNames, useAxiosWithHeader } from '../../utils'
import { useState } from 'react'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { useDispatch, useSelector } from 'react-redux'
const { Option } = Select

function RefreshPrSelectingRepo({
  repositories,
  organization,
  axios,
  handleUpdateAllPRs,
  handleUpdateRepo,
}) {
  const dispatch = useDispatch()

  async function onSelectRepository(repoId, option) {
    const hide = messageInteraction({
      content: 'Updating pull requests...',
      type: 'loading',
    })
    try {
      const { data: newPrs } = await axios.get(
        `${URLBACK}pull-requests/refresh-all-pr-from-one-repo?orgId=${organization.id}&repoId=${repoId}`,
      )
      dispatch(
        Organization.actions.setData({
          pullRequests: newPrs,
          // we want a copy of all PRs before the fetch from search bar reset the current state
          // fetchPullRequestsAndUpdateReducer
          initialPullRequests: newPrs,
        }),
      )
      hide()
      messageInteraction({
        content: 'Pull requests updated.',
        type: 'success',
      })
    } catch (e) {
      console.log(e)
      hide()
      messageInteraction({
        content: 'Error while updating your pull requests, please try again.',
        type: 'error',
      })
    }
  }
  return (
    <Select
      showSearch
      style={{ width: 280 }}
      placeholder="Select a repository to refresh"
      optionFilterProp="children"
      allowClear
      onSelect={(value, option) => onSelectRepository(value, option)} // Handle repository change
      notFoundContent={
        <button
          className={classNames(
            ' block w-full rounded-md border border-primary bg-primary py-2 text-center text-sm font-semibold text-white hover:bg-blue-700 hover:text-white',
          )}
          onClick={() => handleUpdateRepo()}
        >
          Can't find your repository? <br />
          Update your repository list.
        </button>
      }
    >
      {repositories.map((repo, i) => (
        <Option key={repo._id} value={repo._id}>
          {repo.name}
        </Option>
      ))}
    </Select>
  )
}

export default function RepositorySelectorDropdown({
  repositories,
  handleUpdateAllPRs,
  handleUpdateRepo,
}) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const axios = useAxiosWithHeader()
  const menu = (
    <Menu>
      <Menu.Item>
        <RefreshPrSelectingRepo
          repositories={repositories}
          axios={axios}
          organization={organization}
          handleUpdateAllPRs={handleUpdateAllPRs}
          handleUpdateRepo={handleUpdateRepo}
        />
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown
        overlay={menu}
        onOpenChange={(flag) => setDropdownVisible(flag)}
        open={dropdownVisible}
        trigger={['click']}
        placement="bottom"
      >
        <RefreshIcon
          className="ml-2 h-4 w-4 cursor-pointer text-gray-800"
          onClick={(e) => {
            // Prevent menu from closing when the icon is clicked
            e.preventDefault()
            setDropdownVisible(!dropdownVisible)
          }}
        />
      </Dropdown>
    </>
  )
}
