import { useState } from 'react'
import { ReminderInputLine } from './input-line-reminders'
import AlreadyCreatedMessageTable from './message-already-created'
import { PersonalizeReminderTableColumn } from './personalize-reminder-columns'

export default function ReminderMessagePersonalization({ isAdmin }) {
  const [newMessage, setNewMessage] = useState('')

  return (
    <div className="">
      <h1 className="text-xl font-semibold text-gray-900">Message personalization</h1>
      <div className="flex flex-col overflow-visible pt-4">
        <div className="-my-2 -mx-4 overflow-visible  sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full overflow-visible pb-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300 overflow-visible">
                <PersonalizeReminderTableColumn />
                <tbody className="divide-y divide-gray-200 bg-white">
                  <ReminderInputLine
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                  />
                  <AlreadyCreatedMessageTable isAdmin={isAdmin} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
