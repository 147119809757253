import React from 'react'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { createBrowserHistory } from 'history'
import { User } from '../../reducers/userReducer'
import { Organization } from '../../reducers/organizationReducer'
import { AppContent } from '../../components/utils/utils'
import { OtherIntegrations } from '../../components/OtherIntegrations'

export function IntegrationPage() {
  const history = createBrowserHistory()
  let prevPath = null

  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname
      window.analytics.page()
    }
  })

  const user = useSelector(User.selectors.selectUser, isEqual)
  const organization = useSelector(Organization.selectors.getOrganization, isEqual)

  return (
    <div className="rounded-lg  bg-white">
      <div className="mb-4 block items-end lg:flex">
        <h2 className="!mb-0  text-xl font-semibold text-gray-900 ">Integrations</h2>
        <h3 className="!mb-0 font-normal text-gray-400 lg:ml-8">
          Connect Axolo to your favorite applications
        </h3>
      </div>
      <AppContent className="mt-4 flex">
        <OtherIntegrations user={user} organization={organization} />
      </AppContent>
    </div>
  )
}

export default IntegrationPage
