import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { useAxiosWithHeader } from '../../utils'
import { useEffectOnce } from 'react-use'

// Register the necessary components
Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const Graph = ({ event, color, title, label, startDate, endDate }) => {
  const [eventData, setEventData] = useState([]) // State for event data
  const [grouping, setGrouping] = useState('day') // State for grouping
  const [loading, setLoading] = useState(true) // State for loading
  const axios = useAxiosWithHeader()

  useEffectOnce(() => {
    // Fetch data for the specified event
    const type = 'track'
    const queryString = `type=${type}&event=${encodeURIComponent(event)}`

    axios
      .get(`/events?${queryString}`)
      .then((response) => {
        const eventData = response.data.filter((eventItem) => eventItem.event === event)
        setEventData(eventData)
        setLoading(false) // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching event data:', error)
        setLoading(false) // Set loading to false even if there's an error
      })
  }, [axios, event])

  const generateDateRange = (start, end, key) => {
    const dates = []
    let currentDate = new Date(start)
    const today = new Date() // Get today's date

    while (currentDate <= end && currentDate <= today) {
      // Ensure dates do not go beyond today
      let formattedDate
      if (key === 'day') {
        formattedDate = currentDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        }) // Use month + day format
        currentDate.setDate(currentDate.getDate() + 1)
      } else if (key === 'week') {
        const startOfWeek = new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay()),
        )
        formattedDate = startOfWeek.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })
        currentDate.setDate(currentDate.getDate() + 7)
      } else if (key === 'month') {
        formattedDate = `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1,
        ).padStart(2, '0')}`
        currentDate.setMonth(currentDate.getMonth() + 1)
      }
      dates.push(formattedDate)
    }

    return dates
  }

  const groupBy = (data, key) => {
    const allDates = generateDateRange(startDate, endDate, key)
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.createdAt)
      if (date < startDate || date > endDate) return acc // Filter by date range
      let formattedDate
      if (key === 'day') {
        formattedDate = date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })
      } else if (key === 'week') {
        const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()))
        formattedDate = startOfWeek.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })
      } else if (key === 'month') {
        formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          '0',
        )}`
      }
      if (!acc[formattedDate]) acc[formattedDate] = 0
      acc[formattedDate] += 1
      return acc
    }, {})

    // Ensure all dates are represented
    allDates.forEach((date) => {
      if (!groupedData[date]) groupedData[date] = 0
    })

    // Sort the dates
    const sortedGroupedData = Object.keys(groupedData)
      .sort((a, b) => new Date(a) - new Date(b))
      .reduce((acc, key) => {
        acc[key] = groupedData[key]
        return acc
      }, {})

    return sortedGroupedData
  }

  const groupedEventData = groupBy(eventData, grouping)

  const labels = Object.keys(groupedEventData)
  const eventDataPoints = Object.values(groupedEventData)

  const totalDataPoints = eventDataPoints.reduce((acc, point) => acc + point, 0) // Calculate total

  const data = {
    labels,
    datasets: [
      {
        label: label,
        data: eventDataPoints,
        borderColor: color,
        backgroundColor: color.replace('rgb', 'rgba').replace(')', ', 0.2)'), // Add background color for fill with transparency
        fill: true,
      },
    ],
  }

  const maxDataPoint = Math.max(...eventDataPoints) + 1

  const options = {
    scales: {
      y: {
        stacked: true,
        min: 0,
        max: maxDataPoint, // Set dynamic max value
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        stacked: true, // Enable stacking
      },
    },
    interaction: {
      mode: 'nearest', // Show tooltip when hovering near the data points
      intersect: false, // Do not require an exact intersection with the data point
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
  }

  return (
    <div className="p-6">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
      </div>
      <div className="mb-4 flex justify-end">
        <select
          onChange={(e) => setGrouping(e.target.value)}
          value={grouping}
          className="rounded border border-gray-300 p-2"
        >
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
        </select>
      </div>
      <div className="text-gray-700">
        <span className="font-medium">Total {label}:</span> {totalDataPoints}
      </div>{' '}
      {loading ? (
        <div>Loading...</div> // Simple loading indicator
      ) : eventDataPoints.every((point) => point === 0) ? ( // Check if all data points are zero
        <div className="text-center text-gray-500">No data available</div> // No data message
      ) : (
        <Line data={data} options={options} />
      )}
    </div>
  )
}

export default Graph
