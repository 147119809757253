import React from 'react'
import { URLBACK } from '../../assets/urls'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useEffectOnce, useLocation } from 'react-use'
import { useDispatch } from 'react-redux'
import { updateUser, User } from '../../reducers/userReducer'
import LoadingPage from '../../pages/Loading'

export default function GithubAuth() {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const loggingUser = async () => {
    try {
      const { data, status } = await Axios.get(
        `${URLBACK}auth/github/callback${location.search}`,
        {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
          },
        },
      )
      const { user, jwt } = data
      const isAdminUser = user?.role?.name === 'Authenticated'

      if (status !== 200) {
        console.log('Something wrong just happen. Error:', status, data)
        return
      }
      if (user.target_type === 'user') {
        window.$crisp.push(['do', 'chat:open'])
        window.$crisp.push([
          'do',
          'message:show',
          [
            'text',
            "Hello there!\n\n You've installed Axolo on your personal GitHub account but unfortunately, Axolo is an organization-only collaboration app. If you'd like to try our service, please install GitHub with an organization. If you're interested in knowing more before continuing, please contact our team here to organize a demo.",
          ],
        ])
        window.$crisp.push(['set', 'session:event', ['github_personal']])
      }
      dispatch(updateUser({ ...user, jwt, isAdminUser }))
      window.$crisp.push(['set', 'user:email', user.email])
      Object.keys(user).forEach((key) => {
        window.$crisp.push(['set', `user:${key}`, user[key]])
      })

      if (isAdminUser) {
        history.push('/home?github=true')
      } else if (user?.role?.type === 'engineer') {
        history.push(`/engineer/settings/general`)
      } else {
        history.push(`/onboarding/selfonboard`)
      }
    } catch (e) {
      console.error({ e })
      dispatch(User.actions.setError(e.message))
    }
  }

  useEffectOnce(() => {
    loggingUser().then()
  })

  return <LoadingPage />
}
