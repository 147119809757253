import messageInteraction from '../messageInteraction'
import { Analytics } from '../../../../reducers/analyticsReducer'
import { Setting } from '../../../../reducers/settingReducer'
import { URLBACK_VELOFRAME } from '../../../../assets/urls'

export default async function initialQuery({ dispatch, axios, organization }) {
  try {
    if (organization.id) {
      const res = await axios.get(
        `${URLBACK_VELOFRAME}api/organizations/getOrganizationInfo?orgId=${organization.id}&updateOrganization=false`,
      )
      const { providerLogin, plan, avatarUrl, repositories, teams } = res.data

      let loadingInitialQuery = !organization.onboardingCompleted
      dispatch(
        Setting.actions.setData({
          selectedRepositories: repositories,
          selectedTeams: teams,
          loadingInitialQuery,
        }),
      )
      dispatch(
        Analytics.actions.setData({
          providerLogin,
          plan,
          avatarUrl,
          repositories,
          teams,
          loading: false,
        }),
      )
    }
  } catch (e) {
    messageInteraction({
      content: 'Error fetching initial info. Please refresh or contact support.',
      type: 'error',
    })
    console.log('Error fetching initial info: ', e)
  }
}
