export default function NoDataGraph() {
  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <div className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-sm font-semibold text-indigo-600 tracking-wide">Oups...</p>
            <p className="mt-2 text-2xl font-bold text-gray-900 tracking-tight sm:text-3xl">
              No PR found
            </p>
            <p className="mt-2 text-base text-gray-500">
              Try changing the date range or{' '}
              <span
                className="underline text-indigo-500 cursor-pointer"
                onClick={() => {
                  window.$crisp.push(['do', 'chat:open'])
                }}
              >
                contact us
              </span>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
