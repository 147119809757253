import React, { useState } from 'react'
import { Select, Space } from 'antd'
import { UpdateStatusActionAndDeployment } from './utils'
import { useDispatch } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { cloneDeep } from 'lodash'

const delay = 2
let timeout

export const DeploymentStatusMultiSelect = ({ setting, statusSelected, axios }) => {
  const dispatch = useDispatch()
  const parsedStatus = statusSelected?.split(',')

  const options = [
    { value: 'success' },
    { value: 'error' },
    { value: 'inactive' },
    { value: 'in_progress' },
    { value: 'queued' },
    { value: 'pending' },
    { value: 'failure' },
  ]

  const [value, setValue] = useState(parsedStatus)
  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value,
    options,
    onChange: (status) => {
      setValue(status)
      UpdateStatusActionAndDeployment(setting, status, 'deployment', axios)

      let newSettings = cloneDeep(setting)
      newSettings.githubDeploymentStatusNotifications = status.join(',')
      // Previous timeout is cleared so it doesn't run every time this is called
      if (timeout !== null) {
        clearTimeout(timeout)
      }
      // Create a timeout of 2 seconds so it keeps element selectable and doesn't close the window
      timeout = setTimeout(() => {
        dispatch(Organization.actions.setData({ setting: newSettings }))
      }, delay * 1000)
    },
    placeholder: 'Select status',
    maxTagCount: 'responsive',
  }
  return (
    <Space
      direction="vertical"
      style={{
        width: '50%',
        maxWidth: '250px',
      }}
    >
      <Select {...selectProps} disabled={!setting.deploymentChannel} />
    </Space>
  )
}
