import { Redirect, Route, useHistory } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import {
  useIsLogged,
  useIsAdminUser,
  useIsEngineer,
  useAxiosWithHeader,
} from '../../utils'
import AppLayout from '../Layout'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { User } from '../../reducers/userReducer'
import { handleLogout } from '../logoutButton/handleLogout'

// the app will log out the user after 10 hours of inactivity
const SESSION_TIMEOUT_DURATION = 1000 * 60 * 60 * 10

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />

const LoadingSpinner = ({ children }) => {
  const user = useSelector(User.selectors.selectUser)
  const { loadingSwitchOrg = false } = user

  return (
    <>
      {loadingSwitchOrg && (
        <div className="fixed top-0 left-0 z-50 h-screen w-screen bg-gradient-to-b from-transparent via-gray-300 to-gray-500">
          <div className="flex h-full justify-center">
            <div className="flex flex-col justify-center p-12 ">
              <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
                Loading
              </h2>
              <div className="mt-8 flex items-center justify-center sm:mx-auto sm:w-full sm:max-w-md">
                <Spin className="mt-4 mb-14" indicator={antIcon} />
              </div>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  )
}

export const PrivateRoute = ({ children, noLayout = false, noMenu = false, ...rest }) => {
  const isLogged = useIsLogged()
  const isAdminUser = useIsAdminUser()
  const isEngineer = useIsEngineer()
  const isNotAdminNeitherEngineer = isEngineer || isAdminUser
  const dispatch = useDispatch()
  const history = useHistory()
  const sessionTimeoutRef = useRef(null)
  const jwt = useSelector(User.selectors.selectJWT)
  const axios = useAxiosWithHeader()

  const resetSessionTimeout = () => {
    if (sessionTimeoutRef.current) clearTimeout(sessionTimeoutRef.current)

    sessionTimeoutRef.current = setTimeout(() => {
      // Logout the user and redirect to the homepage
      handleLogout(jwt, dispatch, axios)
      history.push('/')
    }, SESSION_TIMEOUT_DURATION)
  }

  useEffect(() => {
    const handleActivity = () => {
      resetSessionTimeout()
    }

    // Set initial timeout when App is mounted
    resetSessionTimeout()

    // Setup event listeners
    window.addEventListener('mousemove', handleActivity)
    window.addEventListener('keydown', handleActivity)
    window.addEventListener('click', handleActivity)

    return () => {
      // Cleanup event listeners
      window.removeEventListener('mousemove', handleActivity)
      window.removeEventListener('keydown', handleActivity)
      window.removeEventListener('click', handleActivity)
    }
  }, [dispatch, history])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogged ? (
          !isNotAdminNeitherEngineer || noLayout ? (
            <>{children}</>
          ) : (
            <LoadingSpinner>
              <AppLayout>{children}</AppLayout>
            </LoadingSpinner>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
