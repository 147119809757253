import ReminderMessagePersonalization from '../../components/ReminderMessagePersonalization'
import ReminderSetup from '../../components/ReminderSetup'

export default function EngineerDashboardReminder() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ReminderSetup isAdmin={false} />

      <ReminderMessagePersonalization isAdmin={false} />
    </div>
  )
}
