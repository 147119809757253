import moment from 'moment'
import { useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
} from 'recharts'
import {
  areaColor,
  areaColorRed,
  referenceLineColor1,
  referenceLineColor2,
  referenceLineStroke,
} from '../utils/graph-classes'
import NoDataGraph from '../NoDataGraph/NoDataGraph'
import GraphLoading from '../GraphLoading/GraphLoading'
import ChartError from '../ChartError/chartError'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white rounded-lg shadow-lg px-4 py-2">
        <p className="">{moment(label, 'YYYY-MM-DD').format('MMMM Do YYYY')}</p>
        <p className="">Additions: {Math.floor(payload[0].payload.additions)} lines</p>
        <p className="">Deletions: {Math.floor(payload[0].payload.deletions)} lines</p>
      </div>
    )
  }
  return null
}

export default function PullRequestLengthGraph({ error, loading }) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  if (error) return <ChartError />
  if (loading) return <GraphLoading />
  const { pullRequestLength } = analytics
  const stats = pullRequestLength?.map((item) => ({
    additions: item.stat.additions,
    deletions: -item.stat.deletions,
    date: item.date,
  }))
  if (stats?.length === 0) return <NoDataGraph />
  return (
    <div className="flex p-2 justify-center ">
      <ResponsiveContainer height={300} width={'100%'}>
        <AreaChart data={stats}>
          <Area
            type="monotone"
            dataKey="additions"
            stroke={areaColor}
            fillOpacity={1}
            fill="url(#colorPullRequestLengthGraph1)"
          />
          <Area
            type="monotone"
            dataKey="deletions"
            stroke={areaColorRed}
            fillOpacity={1}
            fill="url(#colorPullRequestLengthGraph2)"
          />
          <defs>
            <linearGradient id="colorPullRequestLengthGraph1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={areaColor} stopOpacity={0.8} />
              <stop offset="95%" stopColor={areaColor} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPullRequestLengthGraph2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={areaColorRed} stopOpacity={0} />
              <stop offset="95%" stopColor={areaColorRed} stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            tickFormatter={(t) => {
              return moment(t, 'YYYY-MM-DD').format('MMM Do')
            }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine
            y={400}
            label=""
            fontFamily="Poppins"
            stroke={referenceLineColor1}
            strokeDasharray={referenceLineStroke}
          />
          <ReferenceLine
            y={-400}
            label=""
            fontFamily="Poppins"
            stroke={referenceLineColor2}
            strokeDasharray={referenceLineStroke}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
