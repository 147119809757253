import { useState } from 'react'
import OnboardingSteps from '../../components/OnboardingSteps'
import { useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { isEqual } from 'lodash'
import { Button, Typography } from 'antd'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'
import { goGithubApp } from '../../components/Helpers'
import { sendAxoloSlack, useAxiosWithHeader } from '../../utils'
import { isErrorInGithubEnterpriseParameters } from './helperGithubOnboarding'
import { useEffectOnce } from 'react-use'
import { useHistory, useLocation } from 'react-router'

export default function SelfHostedgithubOnboarding() {
  const location = useLocation()
  const history = useHistory()
  const [proxy, setProxy] = useState(false)
  const user = useSelector(User.selectors.selectUser, isEqual)
  const [githubDomain, setGithubDomain] = useState(
    user?.githubCredential?.githubHost || '',
  )
  const [githubAppName, setGithubAppName] = useState(
    user?.githubCredential?.appName || '',
  )
  const [githubAppId, setGithubAppId] = useState(
    user?.githubCredential?.githubAppId || '',
  )
  const [githubClientId, setGithubClientId] = useState(
    user?.githubCredential?.githubClientId || '',
  )
  const [githubAppSecret, setGithubAppSecret] = useState('')
  const [githubPrivateKey, setGithubPrivateKey] = useState('')
  const [githubProxy, setgithubProxy] = useState('')
  const [messageAdviceVisible, setMessageAdviceVisible] = useState(false)
  const axios = useAxiosWithHeader()

  async function installAxoloAppForGithubSelfHosted() {
    if (
      isErrorInGithubEnterpriseParameters({
        githubDomain,
        githubAppName,
        githubAppId,
        githubAppSecret,
        githubPrivateKey,
        githubClientId,
      })
    )
      return

    /* check here that it begins with the right line and end with the right line for private key */

    let domain = githubDomain
    if (domain[domain.length - 1] === '/') {
      domain = domain.slice(0, -1)
    }

    const regexWhiteSpace = new RegExp('\\s+', 'g')
    let appName = githubAppName.replace(regexWhiteSpace, '-')
    if (appName[domain.length - 1] === '/') {
      domain = domain.slice(0, -1)
    }
    appName = appName.toLowerCase()

    const selfHostedParams = {
      githubDomain: domain.trim(),
      githubAppName: appName.trim(),
      githubAppId: githubAppId.trim(),
      githubAppSecret: githubAppSecret.trim(),
      githubPrivateKey: githubPrivateKey.trim(),
      githubClientId: githubClientId.trim(),
      githubProxy: githubProxy.trim(),
      proxy,
    }
    try {
      setMessageAdviceVisible(true)
      await axios.post(`/github/save-github-self-hosted-credential`, selfHostedParams)
      goGithubApp(user.id, user.providerAuth, selfHostedParams)
    } catch (e) {
      console.log(e)
      messageInteraction({
        type: 'error',
        content: 'Error while saving credentials. Please try again or contact support.',
      })
    }
  }

  useEffectOnce(() => {
    sendAxoloSlack({
      userId: user?.id,
      type: 'github-self-hosted-onboarding',
      message: 'Clicked on GitHub Enterprise Server',
      axios,
    })
  })

  return (
    <MainDivOnboarding pathname={location.pathname}>
      <OnboardingSteps />
      <br></br>
      <div className="justify-center">
        <Typography.Title level={4} className="text-center">
          GitHub Enterprise Server onboarding
        </Typography.Title>
        <div className="pt-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Axolo app creation
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Please{' '}
              <a
                href="https://axolo.co/docs/gettingstarted/installation-github-enterprise-server"
                target="_blank"
                rel="nofollow noreferrer"
                className="text-primary"
              >
                follow the instructions
              </a>{' '}
              and fill the required information to create the Axolo app on your GitHub
              instance.
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium text-gray-700"
              >
                GitHub Domain Address
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="github-domain-address"
                  id="github-domain-address"
                  autoComplete="github-domain-address"
                  className="block h-6 w-full rounded-md border border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder='e.g. "https://github.apple.com"'
                  onChange={(event) => setGithubDomain(event.target.value)}
                  value={githubDomain}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="github-app-id"
                className="block text-sm font-medium text-gray-700"
              >
                GitHub App Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="github-app-name"
                  id="github-app-name"
                  placeholder="e.g. Axolo"
                  autoComplete="given-name"
                  className="block h-6 w-full rounded-md border border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(event) => setGithubAppName(event.target.value)}
                  value={githubAppName}
                />
              </div>
            </div>
            <div className="sm:col-span-1">
              <label
                htmlFor="github-app-id"
                className="block text-sm font-medium text-gray-700"
              >
                GitHub App ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="github-app-id"
                  id="github-app-id"
                  className="block h-6 w-full rounded-md border border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="e.g. an integer"
                  onChange={(event) => setGithubAppId(event.target.value)}
                  value={githubAppId}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="github-app-id"
                className="block text-sm font-medium text-gray-700"
              >
                GitHub Client ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="github-client-id"
                  id="github-client-id"
                  className="block h-6 w-full rounded-md border border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="e.g. Iv1.f811cc0c67b69d7i"
                  onChange={(event) => setGithubClientId(event.target.value)}
                  value={githubClientId}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                GitHub App Client Secret
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="github-app-secret"
                  id="github-app-secret"
                  placeholder="e.g. 7620fe0ad8e35dd6d9d5c18e6777dab6a7a2e409"
                  className="block h-6 w-full rounded-md border  border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(event) => setGithubAppSecret(event.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                GitHub Private Key
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="github-private-key"
                  id="github-private-key"
                  placeholder="e.g. -----BEGIN RSA PRIVATE KEY-----"
                  className="block h-6 w-full rounded-md border  border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(event) => setGithubPrivateKey(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 sm:col-span-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-indigo-500"
                // We now have DO for fixed ip addresses so no need for proxy
                // onChange={(event) => setProxy(event.target.checked)}
              />
              <span className="ml-2 text-sm text-gray-700">
                Check this box if your GitHub instance is behind a VPN and needs to
                whitelist Axolo server IP addresses, these are the two addresses you need
                to whitelist: <strong>64.23.230.50</strong> and{' '}
                <strong>64.23.230.204</strong>.
              </span>
            </label>
          </div>
          <br></br>
          <div className="sm:col-span-3">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700"
            >
              GitHub domain proxy (optional)
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="github-domain-proxy"
                id="github-domain-proxy"
                placeholder="e.g. https://github-proxy.acme-corp.com "
                className="block h-6 w-full rounded-md border  border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(event) => setgithubProxy(event.target.value)}
              />
            </div>
          </div>
          <div className="w-50 flex justify-center pt-10">
            <Button
              type="primary"
              className="w-40 !rounded-lg"
              onClick={() => installAxoloAppForGithubSelfHosted()}
            >
              Install Axolo
            </Button>
          </div>
          {messageAdviceVisible && (
            <div className="flex  justify-center pt-2">
              <p className="max-w-xl text-center text-xs text-gray-500">
                If you are redirected to a 404 right away, there might be an error with
                your GitHub Domain Address or GitHub App Name. Contact us if you need
                help.
              </p>
            </div>
          )}
        </div>
        <div
          className="flex justify-center pt-4 text-center"
          onClick={() => history.push('/home/onboarding-host/github')}
        >
          <button className="pb-6 text-center italic underline">Go back</button>
        </div>
      </div>
    </MainDivOnboarding>
  )
}
