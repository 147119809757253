import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { AskEveryoneToCompleteOnboardingButton } from './askTeamOnboarding'

export default function TeamManagementTableColumn({
  selectedTeamIds,
  setSelectedTeamIds,
  visibleActiveEngineers,
  axios,
  org,
  dispatch,
  pathName,
}) {
  const organization = useSelector(Organization.selectors.getOrganization)

  function onChangeTeamSelect(value) {
    if (selectedTeamIds.includes(value)) {
      setSelectedTeamIds(selectedTeamIds.filter((id) => id !== value))
    } else {
      setSelectedTeamIds([...selectedTeamIds, value])
    }
  }

  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
        >
          {organization.provider === 'github' ? 'GitHub' : 'GitLab'}
        </th>
        <th
          scope="col"
          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          Slack
        </th>
        <th
          scope="col"
          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          Status
          <AskEveryoneToCompleteOnboardingButton
            visibleActiveEngineers={visibleActiveEngineers}
            axios={axios}
            organization={org}
            dispatch={dispatch}
            pathName={pathName}
          />{' '}
        </th>
        <th
          scope="col"
          className="hidden items-center  justify-between px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 xl:flex"
        >
          Team
          {/* This has been commented since pagination */}
          {/* <TeamSelect selectedTeamIds={selectedTeamIds} onChange={onChangeTeamSelect} /> */}
        </th>
        <th
          scope="col"
          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          <span className="">Action</span>
        </th>
      </tr>
    </thead>
  )
}
