import { RefreshIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { PlayerState } from '../../reducers/playersReducer'
import { useAxiosWithHeader } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import AxoloTooltip from './axoloTooltip'

export default function RefreshConversationsButton() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const players = useSelector(PlayerState.selectors.getPlayers)
  const axios = useAxiosWithHeader()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  async function handleUpdateConversations() {
    if (loading) {
      messageInteraction({
        content: 'Please wait...',
        type: 'warning',
      })
      return
    }
    const hide = messageInteraction({
      content: 'Updating channels...',
      type: 'loading',
      duration: 0,
    })
    if (players?.length > 500) {
      messageInteraction({
        content:
          'Slack imposes a rate limit of 2000 channels per minute. Given the size of your organization, the synchronization process could take some time. To ensure smooth operation, please avoid initiating a second sync while the first is still in progress.',
        type: 'info',
        duration: 10,
      })
    }
    setLoading(true)
    try {
      const res = await axios.get(
        `${URLBACK}conversations/update-conversations/${organization.id}`,
      )
      hide()
      const { data: slackChannels } = res
      dispatch(Organization.actions.setData({ ...organization, slackChannels }))
      messageInteraction({
        content: 'Channels updated.',
        type: 'success',
      })
      setLoading(false)
    } catch (e) {
      hide()
      console.log('Error updating channels: ', e)
      messageInteraction({
        content: 'Error updating channels.',
        type: 'error',
      })
      setLoading(false)
    }
  }
  return (
    <AxoloTooltip title={`Click to sync your Slack channels`}>
      <div className="flex items-center hover:cursor-pointer">
        <RefreshIcon
          className="ml-2 h-4 w-4 text-gray-800"
          onClick={() => handleUpdateConversations()}
        />
      </div>
    </AxoloTooltip>
  )
}
