import { ExternalLinkIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TableGraphButton = ({ tableGraphLink }) => {
  if (tableGraphLink?.length > 0) {
    return (
      <Link
        to={tableGraphLink}
        onClick={() => {
          if (process.env.NODE_ENV === 'production') {
            window.analytics.track(tableGraphLink)
          }
        }}
        className={classNames(
          'align-normal rounded-md text-sm font-medium text-gray-500 hover:text-hoverPrimary',
        )}
      >
        <div className="ml-2 flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
            />
          </svg>
        </div>
      </Link>
    )
  }
}

const DocumentationButton = ({ documentationLink }) => {
  if (documentationLink?.length > 0) {
    return (
      <a
        href={documentationLink}
        target="_blank"
        rel="noreferrer"
        className={classNames(
          'rounded-md text-sm font-medium text-gray-500 hover:text-hoverPrimary',
        )}
      >
        <div className="ml-2 flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </a>
    )
  }
}

export default function GraphBanner({ children }) {
  return (
    <>
      <div className="">
        <div className=" grid grid-cols-1 gap-4 overflow-hidden rounded-lg lg:grid-cols-2 ">
          {children?.[0] ? (
            children?.map((child) => (
              <div
                key={child.props.title}
                className={
                  'group relative rounded-lg border bg-white px-6 pt-6 pb-2 shadow focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                }
              >
                <div className="h-84">
                  <div className="pb-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center pb-2">
                        <h3 className="mb-0 text-lg font-medium text-gray-500">
                          {child.props.title || 'Title'}
                        </h3>
                        <DocumentationButton
                          documentationLink={child.props.documentationLink}
                        />
                      </div>
                      <TableGraphButton tableGraphLink={child.props.tableGraphLink} />
                    </div>
                    <h4 className="text-sm font-normal text-gray-900">
                      {child.props.subTitle || 'Subtitle'}
                    </h4>
                  </div>
                  {child}
                </div>
              </div>
            ))
          ) : (
            <div
              key={children.props.title}
              className={
                'group relative rounded-lg border bg-white px-6 pt-6 pb-2 shadow focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
              }
            >
              <div className="h-84">
                <h3 className="pb-4">
                  <p className="text-lg font-medium text-gray-500">
                    {children.props.title || 'Title'}
                  </p>
                  <p className="text-sm font-normal text-gray-900">
                    {children.props.subTitle || 'Subtitle'}
                  </p>
                </h3>
                {children}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
