import { IntegrationButton } from '../../components/IntegrationButton'
import OnboardingSteps from '../../components/OnboardingSteps'
import gitlab from '../../assets/images/gitlab-logo-500.png'
import { goGitlabApp } from '../../components/Helpers'
import { useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { isEqual } from 'lodash'
import { Typography } from 'antd'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import { CloudIcon, ServerIcon } from '@heroicons/react/solid'
import { useHistory } from 'react-router-dom'

export default function ChooseWhichGithubInstance() {
  const user = useSelector(User.selectors.selectUser, isEqual)
  const history = useHistory()

  return (
    <MainDivOnboarding>
      <OnboardingSteps />
      <br></br>
      <div className="flex justify-center">
        <Typography.Title level={4}>
          Are you using GitLab.com or a self-hosted instance?
        </Typography.Title>
      </div>
      <div className="mb-6 grid grid-cols-2 gap-2 pt-12">
        <div
          className="relative block h-20 justify-center justify-self-center text-center "
          onClick={() =>
            goGitlabApp({
              userId: user.id,
              authorized: user.providerAuth,
            })
          }
        >
          <CloudIcon
            className="absolute right-0 h-10 w-10 text-primary"
            aria-hidden="true"
          />
          <IntegrationButton imageSrc={gitlab} text="GitLab.com" />
        </div>
        <div
          className="relative block justify-center justify-self-center text-center first-letter:h-20 "
          onClick={() => history.push('/home/onboarding-self-hosted/gitlab')}
        >
          {' '}
          <ServerIcon
            className="absolute right-0 h-10 w-10 text-primary"
            aria-hidden="true"
          />
          <IntegrationButton imageSrc={gitlab} text="GitLab Self-hosted" />
        </div>
      </div>
      <div
        className="flex justify-center text-center"
        onClick={() => history.push('/home/onboarding')}
      >
        <button className="pb-6 text-center italic underline">Go back</button>
      </div>
    </MainDivOnboarding>
  )
}
