import { IntegrationButton } from '../../components/IntegrationButton'
import OnboardingSteps from '../../components/OnboardingSteps'
import gitlab from '../../assets/images/gitlab-logo-500.png'
import { Typography } from 'antd'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import { useHistory } from 'react-router-dom'

export default function ChooseGithubOrGitlab() {
  const history = useHistory()

  return (
    <MainDivOnboarding>
      <OnboardingSteps />
      <br></br>
      <div className="flex justify-center">
        <Typography.Title level={4} className="sm:!mb-0">
          What platform is your team working on?
        </Typography.Title>
      </div>
      <div className="mb-12 grid grid-cols-2 gap-6 pt-12">
        <div
          className="flex items-center justify-center"
          onClick={() => history.push('/home/onboarding-host/github')}
        >
          <IntegrationButton
            imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/github_logo.png"
            text="Install on GitHub"
          />
        </div>
        <div
          className="flex items-center justify-center"
          onClick={() => history.push('/home/onboarding-host/gitlab')}
        >
          <IntegrationButton imageSrc={gitlab} text="Install on GitLab" />
        </div>
      </div>

      {/* <div
          className="col-span-2 block justify-center justify-self-center text-center sm:col-span-1 sm:justify-self-start "
          onClick={() => {
            history.push('/home/onboarding-host/bitbucket')
          }}
        >
          <IntegrationButton imageSrc={bitbucketLogo} text="Install on Bitbucket" />
        </div> */}
    </MainDivOnboarding>
  )
}
