import { URLBACK } from '../../assets/urls'
import { Analytics } from '../../reducers/analyticsReducer'
import { updateUser } from '../../reducers/userReducer'

export async function loggingUser({ dispatch, axios, history, user }) {
  if (!user.hasGithubAccessToken || user?.veloframeId?.length > 0) return
  try {
    const resp = await axios.get(`${URLBACK}veloframe/connect`)
    if (
      typeof resp.data === 'string' &&
      resp?.data?.includes('Error: No GitHub Access token associated with this user')
    ) {
      dispatch(
        updateUser({
          hasGithubAccessToken: false,
          initialLoading: false,
        }),
      )
      return
    }
    const { user, jwt } = resp.data

    // this it to prevent the veloframe properties to replace Axolo's
    dispatch(
      updateUser({
        veloframeJwt: jwt,
        githubAvatarUrl: user.avatarUrl,
        veloframeBlocked: user.blocked,
        veloframeConfirmed: user.confirmed,
        githubEmail: user.email,
        veloframeId: user.id,
        veloframeOrganizations: user.organizations,
        githubUsername: user.username,
        hasGithubAccessToken: true,
        initialLoading: false,
      }),
    )
    window.analytics.identify(user.email, {
      username: user.username,
      email: user.email,
      avatar: user.avatarUrl,
    })
    if (user?.organizations?.length > 0) {
      // dispatch(Organizations.actions.setData({ ...user.organizations }))
      dispatch(Analytics.actions.setData({ ...user.organizations[0] }))
      if (user?.organizations?.[0]?.onboardingCompleted) {
        // the user is logged in and have a working organization
        // notify segment of logged in user
        if (process.env.NODE_ENV === 'production') {
          window.analytics.track('Login user', {
            user: user.email,
            organization: user?.organizations?.[0]?.providerLogin,
          })
        }
      } else {
        // the user is logged in and have an organization but we dont have yet the data we need, installation unfinished
        if (process.env.NODE_ENV === 'production') {
          window.analytics.track('Redirection to processing installation', {
            user: user.email,
            organization: user?.organizations?.[0]?.providerLogin,
          })
        }
        console.log("Sorry, we're still working over here. We'll let you know!")
      }
    } else {
      if (process.env.NODE_ENV === 'production') {
        window.analytics.track('Redirection to no organizaton found', {
          user: user.email,
        })
      }
      console.log('Code red, we did not find any organization.')
      // history.push('/no-organization-found')
    }
  } catch (e) {
    console.log(e)
  }
}
