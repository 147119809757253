import _ from 'lodash'
import moment from 'moment'
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { useSelector } from 'react-redux'
import { Setting } from '../../../reducers/settingReducer'
import { Tooltip } from 'antd'
import { delayToolTip } from './const'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Tag = ({ changeType, change, objective }) => {
  return (
    <div
      className={classNames(
        changeType === objective
          ? 'bg-green-100 text-green-800'
          : 'bg-red-100 text-red-800',
        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
      )}
    >
      {changeType === 'increase' ? (
        <ArrowSmUpIcon
          className={classNames(
            '-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5',
            changeType === objective ? 'text-green-500' : 'text-red-500',
          )}
          aria-hidden="true"
        />
      ) : (
        <ArrowSmDownIcon
          className={classNames(
            '-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 ',
            changeType === objective ? 'text-green-500' : 'text-red-500',
          )}
          aria-hidden="true"
        />
      )}
      <span className="sr-only">
        {changeType === 'increase' ? 'Increased' : 'Decreased'} by
      </span>
      {(change * 100).toFixed()}%
    </div>
  )
}

export default function TagGrowth({ changeType, change, objective = 'decrease' }) {
  const setting = useSelector(Setting.selectors.getSetting)
  const startDate = moment(setting.startDate, 'DD-MM-YYYY')
  const endDate = moment(setting.endDate, 'DD-MM-YYYY')
  const diff = endDate.diff(startDate, 'days')
  const previousStartDate = _.cloneDeep(startDate).subtract(diff, 'days')
  if (!change || change === 0 || change > 10 || change < -10) return null
  return (
    <Tooltip
      title={`Compared with ${previousStartDate.format(
        'MMMM Do YYYY',
      )}-${startDate.format('MMMM Do YYYY')}`}
      mouseEnterDelay={delayToolTip}
    >
      <div>
        <Tag changeType={changeType} change={change} objective={objective} />
      </div>
    </Tooltip>
  )
}
