import { useDispatch, useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import { Setting } from '../../../reducers/settingReducer'
import MergedPRGraph from '.'
import messageInteraction from '../utils/messageInteraction'
import { useState, useEffect } from 'react'
import { useAxiosWithHeader } from '../../../utils'
import { URLBACK_VELOFRAME } from '../../../assets/urls'
import { createStandardQuery } from '../utils/query'

export default function MergedPRGraphContainer({fields = "providerCreatedAt,providerId", pullRequestRelations}) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const axios = useAxiosWithHeader('analytics')
  const setting = useSelector(Setting.selectors.getSetting)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const organizationId = analytics.id
    setLoading(true)
    async function getMergedPR({ setting, organizationId, dispatch }) {
      try {
        const res = await axios.get(
          `${URLBACK_VELOFRAME}api/pull-requests/findSpecificPullRequests?status=merged&fields=${fields}&pullRequestRelations=${pullRequestRelations}&${createStandardQuery(
            { setting, organizationId },
          )}`,
        )
        dispatch(Analytics.actions.setData({ mergedPullRequests: res.data }))
      } catch (e) {
        messageInteraction({
          content:
            'Error in get merged pull requests. Please refresh or contact support.',
          type: 'error',
        })
        setError(true)
        console.log('Error get merged pull requests: ', e)
      }
      setLoading(false)
    }
    if (!setting.loadingInitialQuery) {
      getMergedPR({ setting, organizationId, dispatch })
    }
  }, [
    setting.startDate,
    setting.endDate,
    setting.selectedRepositories,
    setting.selectedTeams,
  ])

  return <MergedPRGraph loading={loading} error={error} />
}
