import React, { useState } from 'react'
import { Modal, Button, Radio } from 'antd'
import { useHistory } from 'react-router-dom' // for navigation
import messageInteraction from '../../components/Analytics/utils/messageInteraction'
import { URLBACK } from '../../assets/urls'

const FinishOnboardingModal = ({ isVisible, onConfirm }) => {
  const [selectedOption, setSelectedOption] = useState('')
  const [otherText, setOtherText] = useState('')

  const options = ['Google', 'Friend', 'GitHub', 'Reddit', 'Event', 'Other']

  const handleSubmit = () => {
    onConfirm(selectedOption, selectedOption === 'Other' ? otherText : '')
  }

  const handleOtherSpecifyChange = (e) => {
    setOtherText(e.target.value)
  }

  return (
    <Modal
      title={
        <span className="text-xl font-semibold">Welcome to the Axolo Community!</span>
      }
      visible={isVisible}
      onOk={handleSubmit}
      closable={false}
      footer={[
        <Button
          disabled={
            selectedOption === '' || (selectedOption === 'Other' && otherText === '')
          }
          key="submit"
          type={
            (selectedOption !== '' && selectedOption !== 'Other') ||
            (selectedOption === 'Other' && otherText !== '' && 'primary')
          }
          onClick={handleSubmit}
        >
          Submit Feedback
        </Button>,
      ]}
    >
      <div className="space-y-4">
        <p>
          We're thrilled to have you on board. Help us improve by telling us how you
          discovered us.
        </p>
        <Radio.Group
          onChange={(e) => setSelectedOption(e.target.value)}
          value={selectedOption}
          className="flex flex-col space-y-2"
        >
          {options.map((option) => (
            <>
              <Radio value={option} key={option} className="leading-6">
                {option}
              </Radio>
              <br></br>
            </>
          ))}
        </Radio.Group>
        {selectedOption === 'Other' && (
          <div className="mt-4">
            <label htmlFor="otherSpecify" className="mb-2 block font-medium">
              If other, please specify:
            </label>
            <input
              type="text"
              id="otherSpecify"
              value={otherText}
              onChange={handleOtherSpecifyChange}
              className="w-full rounded border border-gray-300 p-2 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
              placeholder="Your answer"
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

const FinishOnboardingProcess = ({
  hasCreatedChannel,
  dispatch,
  organization,
  setIsOnboarded,
  axios,
}) => {
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()

  const handleFinishClick = () => {
    // Add logic if needed before showing the modal
    setShowModal(true)
  }

  const handleModalConfirm = async (response, otherText = '') => {
    if (response === '' || response === undefined) {
      return
    }

    setShowModal(false)

    if (response !== 'Skipped') {
      axios.post(`${URLBACK}organizations/discovered`, {
        response: response,
        otherText,
      })
    }

    dispatch(setIsOnboarded(true))
    if (organization?.pullRequests?.length > 0) {
      messageInteraction({
        content: '🎉 You are all set! Create your first PR Slack channel here.',
        type: 'success',
      })
      history.push('/open/channels')
    } else {
      messageInteraction({
        content: '🎉 You are all set!',
        type: 'success',
      })
      history.push('/settings/pull-request-channel')
    }
  }

  return (
    <>
      <button
        className="rounded bg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-hoverPrimary disabled:cursor-not-allowed disabled:opacity-50"
        disabled={!hasCreatedChannel}
        onClick={handleFinishClick}
      >
        Finish & Access my Settings
      </button>
      <FinishOnboardingModal isVisible={showModal} onConfirm={handleModalConfirm} />
    </>
  )
}

export default FinishOnboardingProcess
