import React from 'react'
import { message, Popover, Select } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { PlayerState } from '../../reducers/playersReducer'
import { useAxiosWithHeader } from '../../utils'
import { UserAvatar } from '../../pages/OpenChannels/userAvatar'

const { Option } = Select

export default function WhiteListBot({ organization, setting, updateCompanySetting }) {
  const pull = organization.provider === 'gitlab' ? 'merge' : 'pull'
  const axios = useAxiosWithHeader()
  const bots = useSelector(PlayerState.selectors.getBots)
  const dispatch = useDispatch()

  async function updateBot(value) {
    const hide = message.loading('Loading...', 0)
    try {
      const resp = await axios.put(`/bot`, {
        botWhiteList: value,
      })
      const newBots = resp.data
      dispatch(PlayerState.actions.updateBots(newBots))
      hide()
      return 200
    } catch (error) {
      message.warning('Error updating your setting. Try again or contact support.')
      hide()
      return error
    }
  }

  const handleChange = (value) => {
    updateBot(value)
  }

  const defaultValue = bots?.filter((bot) => bot.playing).map((bot) => bot.providerLogin)

  const children = bots.map((bot) => {
    if (bot.providerLogin !== 'axolo-co[bot]') {
      let botname = bot.providerLogin.split('[bot]')[0]
      if (bot?.data?.name) {
        botname = bot?.data?.name
      }
      if (bot.provider === 'gitlab') {
        botname = `${bot.providerLogin.split('[bot]')[0]}-${bot?.data?.name}`
      }
      return (
        <Option key={bot.id} value={bot.providerLogin}>
          <UserAvatar record={bot} size={20} css="!mr-2" />
          {botname}
        </Option>
      )
    }
  })

  return (
    <div className="mt-8">
      <div className="flex flex-col justify-between">
        <div className="flex flex-row justify-between">
          <p className="mb-0 flex">
            Enable bots to comment, review or create {pull} requests.
            <span className="flex pl-3 pt-[4px]">
              <Popover
                content={
                  organization.provider === 'github'
                    ? `This list is updated every time a bot comments, reviews, or creates a ${pull} request. Log in again to see the updated list.`
                    : `Give us the name of your bot in the chat below to add it to the list.`
                }
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </span>
          </p>
          <div style={{ minWidth: 550, maxWidth: 650 }}>
            <div className="flex">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={defaultValue || []}
                onChange={handleChange}
              >
                {children}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
