import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { Button, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router'

export const SettingsNotOnboarded = ({ organization }) => {
  const history = useHistory()
  const [redirect, setRedirect] = useState(false)

  useEffectOnce(() => {
    setRedirect(true)
  })

  if (redirect) {
    return <Redirect to={'/home'} />
  }

  return (
    <div>
      <h1 className="mt-4 mb-4 text-xl font-semibold text-gray-900">
        New organization successfully installed
      </h1>
      <div className="mb-8 flex justify-between">
        <Typography className="">
          You've recently successfully added a new organization:{' '}
          <strong>{organization.providerLogin}</strong>. Please complete your onboarding
          to start merging pull requests faster.
        </Typography>
        <div onClick={() => history.push('/home')}>
          <Button className="rounded-sm bg-primary text-textWhite">
            Complete my onboarding
          </Button>
        </div>
      </div>
    </div>
  )
}
