import {
  classActionColumn,
  classCreatorColumn,
  classMessageColumn,
  classStatusColumn,
} from './helper-reminder-personalization'

export const PersonalizeReminderTableColumn = () => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th scope="col" className={classMessageColumn}>
          Message
        </th>
        <th scope="col" className={classStatusColumn}>
          PR Status
        </th>
        <th scope="col" className={classCreatorColumn}>
          Creator
        </th>

        <th scope="col" className={classActionColumn}>
          <span className="sr-only">Action</span>
        </th>
      </tr>
    </thead>
  )
}
