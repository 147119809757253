import { DatePicker, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useState } from 'react'
import { Setting } from '../../../reducers/settingReducer'
import RepositoryMultiSelect from '../RepositoryMultiSelect/repositoryMultiSelect'
import TeamMultiSelect from '../TeamMultiSelect/teamMultiSelect'
import { Analytics } from '../../../reducers/analyticsReducer'

const { RangePicker } = DatePicker

export default function FilterMenu() {
  const dispatch = useDispatch()
  const setting = useSelector(Setting.selectors.getSetting)
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const [rangeButton, setRangeButton] = useState('Custom')
  const [dateRange, setDateRange] = useState()
  const [openDateRange, setOpenDateRange] = useState(false)

  function onChange(value) {
    setDateRange(value)
  }

  function handleRangeButton(value, subtract) {
    setRangeButton(value)
    if (value === 'Custom') {
      setOpenDateRange(true)
      return
    }
    const { number, period } = subtract
    const startDate = moment().subtract(number, period)
    const endDate = moment()
    setDateRange([startDate, endDate])
    dispatch(
      Setting.actions.setData({
        ...setting,
        startDate: startDate.format('DD-MM-YYYY'),
        endDate: endDate.format('DD-MM-YYYY'),
      }),
    )
  }

  function handleDateChange(dates, datesString, info) {
    if (info.range === 'end') {
      setRangeButton('Custom')
      const startDate = moment(datesString[0], 'MMMM Do YYYY').format('DD-MM-YYYY')
      const endDate = moment(datesString[1], 'MMMM Do YYYY').format('DD-MM-YYYY')
      dispatch(Setting.actions.setData({ ...setting, startDate, endDate }))
    }
  }

  const disabled12M = moment(analytics?.lastPrSaved).isAfter(
    moment().subtract(12, 'months'),
  )
  const disabledYest = moment(analytics?.lastPrSaved).isAfter(moment().subtract(1, 'day'))
  const disable7D = moment(analytics?.lastPrSaved).isAfter(moment().subtract(7, 'days'))
  const disable30D = moment(analytics?.lastPrSaved).isAfter(moment().subtract(30, 'days'))
  const disable3M = moment(analytics?.lastPrSaved).isAfter(moment().subtract(3, 'months'))
  const disable6M = moment(analytics?.lastPrSaved).isAfter(moment().subtract(6, 'months'))
  const spanClassName =
    'overflow-hidden border-slate-300 border-2 border-r-0 hover:bg-gray-300 text-gray-800 py-2 px-4 disabled:border-slate-500 disabled:bg-gray-100 disabled:opacity-25 disabled:transition-none flex justify-center cursor-pointer flex-1'
  return (
    <div className="">
      <div className="">
        <p className="mb-1 text-sm font-medium text-gray-500">Range</p>
        <div className="mb-2 flex flex-row">
          <button
            style={{ fontFamily: 'Poppins' }}
            onClick={() => handleRangeButton('Custom')}
            className={`flex items-center rounded-l border-2 border-r-0 border-slate-300 py-2 px-4 text-gray-800 hover:bg-gray-300 ${
              rangeButton === 'Custom' ? 'bg-gray-200' : 'bg-gray-100'
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1 h-3 w-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>{' '}
            Custom
          </button>
          <Tooltip
            placement="top"
            title={
              disabledYest
                ? `First PR saved on ${moment(analytics.lastPrSaved).format(
                    'MMM d, YYYY',
                  )}`
                : ''
            }
            className={
              disabledYest
                ? 'flex justify-center border-slate-500 bg-gray-100 opacity-25 transition-none'
                : ''
            }
          >
            <span
              className={`${spanClassName} ${
                rangeButton === 'Yesterday' ? 'bg-gray-200' : 'bg-gray-100'
              }`}
              style={{ fontFamily: 'Poppins' }}
              onClick={() => handleRangeButton('Yesterday', { number: 1, period: 'day' })}
            >
              <button disabled={disabledYest}>24H</button>
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              disable7D
                ? `First PR Saved on ${moment(analytics.lastPrSaved).format(
                    'MMM d, YYYY',
                  )}`
                : ''
            }
            className={
              disable7D
                ? 'flex justify-center border-slate-500 bg-gray-100 opacity-25 transition-none'
                : ''
            }
          >
            <span
              className={`${spanClassName} ${
                rangeButton === '7D' ? 'bg-gray-200' : 'bg-gray-100'
              }`}
              style={{ fontFamily: 'Poppins' }}
              onClick={() => handleRangeButton('7D', { number: 7, period: 'days' })}
            >
              <button disabled={disable7D}>7D</button>
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              disable30D
                ? `First PR Saved on ${moment(analytics.lastPrSaved).format(
                    'MMM d, YYYY',
                  )}`
                : ''
            }
            className={
              disable30D
                ? 'flex justify-center border-slate-500 bg-gray-100 opacity-25 transition-none'
                : ''
            }
          >
            <span
              className={`${spanClassName} ${
                rangeButton === '30D' ? 'bg-gray-200' : 'bg-gray-100'
              }`}
              style={{ fontFamily: 'Poppins' }}
              onClick={() => handleRangeButton('30D', { number: 30, period: 'days' })}
            >
              <button disabled={disable30D}>30D</button>
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              disable3M
                ? `First PR Saved on ${moment(analytics.lastPrSaved).format(
                    'MMM d, YYYY',
                  )}`
                : ''
            }
            className={
              disable3M
                ? 'flex justify-center border-slate-500 bg-gray-100 opacity-25 transition-none'
                : ''
            }
          >
            <span
              className={`${spanClassName} ${
                rangeButton === '3M' ? 'bg-gray-200' : 'bg-gray-100'
              }`}
              style={{ fontFamily: 'Poppins' }}
              onClick={() => handleRangeButton('3M', { number: 3, period: 'months' })}
            >
              <button disabled={disable3M}>3M</button>
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              disable6M
                ? `First PR Saved on ${moment(analytics.lastPrSaved).format(
                    'MMM d, YYYY',
                  )}`
                : ''
            }
            className={
              disable6M
                ? 'flex justify-center border-slate-500 bg-gray-100 opacity-25 transition-none'
                : ''
            }
          >
            <span
              className={`${spanClassName} ${
                rangeButton === '6M' ? 'bg-gray-200' : 'bg-gray-100'
              }`}
              style={{ fontFamily: 'Poppins' }}
              onClick={() => handleRangeButton('6M', { number: 6, period: 'months' })}
            >
              <button disabled={disable6M}>6M</button>
            </span>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              disabled12M
                ? `First PR Saved on ${moment(analytics.lastPrSaved).format(
                    'MMM d, YYYY',
                  )}`
                : ''
            }
            className={
              disabled12M
                ? 'flex justify-center border-slate-500 bg-gray-100 opacity-25 transition-none'
                : ''
            }
          >
            <span
              className={`${spanClassName} rounded-r border-r-2 ${
                rangeButton === '12M' ? 'bg-gray-200' : 'bg-gray-100'
              }`}
              style={{ fontFamily: 'Poppins' }}
              onClick={() => handleRangeButton('12M', { number: 12, period: 'months' })}
            >
              <button disabled={disabled12M}>12M</button>
            </span>
          </Tooltip>
        </div>
        <RangePicker
          open={openDateRange}
          onOpenChange={() => setOpenDateRange(!openDateRange)}
          className="w-full rounded-lg"
          format="MMMM Do YYYY"
          onChange={onChange}
          value={dateRange}
          defaultValue={[
            moment(setting.startDate, 'DD-MM-YYYY'),
            moment(setting.endDate, 'DD-MM-YYYY'),
          ]}
          onCalendarChange={(dates, datesString, info) =>
            handleDateChange(dates, datesString, info)
          }
          disabledDate={(current) =>
            current &&
            (current.isBefore(moment(analytics?.lastPrSaved, 'YYYY-MM-DD')) ||
              current.isAfter(moment()))
          }
        />
      </div>

      <div className="">
        <RepositoryMultiSelect />
      </div>
      <div className="">
        <TeamMultiSelect />
      </div>
    </div>
  )
}
