import React, { useState } from 'react'
import { ArrowNarrowDownIcon, CheckIcon } from '@heroicons/react/solid'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { User } from '../../reducers/userReducer'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { PlayerState } from '../../reducers/playersReducer'
import { ButtonBilling, EndButton } from '../../components/Pricing/components'
import ModalRedirectionPostPayment from './modalRedirectionOpenChannels'
import { tiers } from './data'
import MyCurrentBilling from '../../components/MyCurrentBilling'
import ModalAskedAdminAccess from './modalAskedAdminAccess'
import EnterpriseBox from './EnterpriseBox'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Billing() {
  let { status } = useParams()
  const user = useSelector(User.selectors.selectUser)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const [monthlySelected, setMonthlySelected] = useState(true)
  const organization = useSelector(Organization.selectors.getOrganization)
  let players = useSelector(PlayerState.selectors.getPlayers)

  const hasPayments = organization?.payments?.length > 0
  const [seeMore, setSeeMore] = useState(!hasPayments)
  const [isRedirectionModalOpen, setIsRedirectionModalOpen] = useState(false)
  const location = useLocation()
  const [isModalAdminAccessOpen, setIsModalAdminAccessOpen] = useState(
    user?.role?.type === 'engineer',
  )
  const { pullRequests } = organization
  const openPrWithoutChannels = pullRequests?.filter(
    (pr) => !(pr.slackChannelId?.length > 0),
  )

  const isPayingOrg =
    organization.plan !== 'starter' &&
    organization.plan !== 'trial' &&
    organization.plan !== 'blocked'

  const hadPayments = organization?.payments?.length > 0

  function handleSeeMoreClick() {
    setSeeMore(true)
  }
  const SeeMoreButton = () => {
    return (
      <div className="mt-4 flex items-center justify-center">
        <span className="flex w-40 cursor-pointer" onClick={() => handleSeeMoreClick()}>
          See other plans <ArrowNarrowDownIcon className="ml-2 w-5 animate-bounce pt-1" />
        </span>
      </div>
    )
  }

  useEffectOnce(() => {
    if (user.organizations.length > 0) {
      ;(async () => {
        const org = await axios.get(`/organizations/${organization.id}`)
        dispatch(Organization.actions.setData(org.data))
      })()
    }

    if (
      location?.pathname?.includes('/billing/success') &&
      openPrWithoutChannels?.length > 0 &&
      isRedirectionModalOpen === false
    ) {
      setIsRedirectionModalOpen(true)
    }

    if (status === 'success') {
      message.destroy()
    } else if (status === 'error') {
      message.error('There has been an error with your payment')
    } else if (status === 'canceled') {
      message.warning("You've canceled your payment")
    }
  })

  useEffectOnce(() => {
    if (user.organizations.length > 0) {
      ;(async () => {
        const org = await axios.get(`/organizations/${organization.id}`)
        dispatch(Organization.actions.setData(org.data))
      })()
    }
  })

  const clickOnMontly = () => {
    setMonthlySelected(true)
  }

  const clickOnYearly = () => {
    setMonthlySelected(false)
  }

  const notSelected = ' border border-transparent text-gray-700 focus:ring-secondaryBlack'
  const selected =
    'bg-white border-gray-200  shadow-sm  text-gray-900  focus:ring-secondaryBlack'

  return (
    <div className="relative">
      <ModalAskedAdminAccess
        isOpen={isModalAdminAccessOpen}
        setIsOpen={setIsModalAdminAccessOpen}
        user={user}
        axios={axios}
      />

      <ModalRedirectionPostPayment
        isOpen={isRedirectionModalOpen}
        setIsOpen={setIsRedirectionModalOpen}
        openPrWithoutChannels={openPrWithoutChannels}
      />
      <div className="relative mx-auto max-w-[1500px] ">
        {organization.plan === 'enterprise' && <EnterpriseBox />}
        {(isPayingOrg || hadPayments) && organization.plan !== 'enterprise' ? (
          <MyCurrentBilling />
        ) : null}
        <h1 className=" text-left text-xl font-semibold text-gray-900">Our plans</h1>
        <div className="sm:flex sm:flex-col sm:items-center">
          <h2 className="mt-5 text-xl text-gray-500 sm:text-center ">
            Whether your engineering team is large or small, we’re here to help you grow.
          </h2>

          <div className="flex justify-center">
            <div className="cursor mt-6 inline-flex cursor-help self-center rounded-lg bg-gray-100 p-0.5 font-sans text-base sm:mt-8 ">
              <button
                onClick={() => clickOnMontly()}
                className={classNames(
                  'relative w-auto whitespace-nowrap rounded-md py-2 font-sans text-sm font-medium focus:z-10 focus:outline-none focus:ring-2 ',
                  monthlySelected && selected,
                  !monthlySelected && notSelected,
                  'px-2',
                )}
              >
                Monthly billing
              </button>
              <button
                onClick={() => clickOnYearly()}
                className={classNames(
                  'relative w-auto whitespace-nowrap rounded-md py-2 font-sans text-sm font-medium focus:z-10 focus:outline-none focus:ring-2',
                  !monthlySelected && selected,
                  monthlySelected && notSelected,
                  'px-2',
                )}
              >
                Yearly billing
              </button>
            </div>
          </div>
          {/* we only show the 'manage my subscription' button if it's a paying organization */}
          {/* {hasPayments && <EndButton hasPayments={hasPayments} axios={axios} />} */}
        </div>
        <div
          className={classNames(
            'overflow-hidden transition-all',
            seeMore ? 'max-h-full' : 'max-h-[350px]',
          )}
        >
          <div className=" mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6  sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:grid  xl:max-w-none xl:grid-cols-4 ">
            {tiers.map((tier) => (
              <div
                key={tier.name}
                className="col-span-1 divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-md"
              >
                <div className="p-6">
                  <h2 className="font-sans text-lg font-bold  leading-6 text-gray-900">
                    {tier.name}
                  </h2>
                  <p className="mt-4 text-base text-gray-500">{tier.description}</p>
                  <p className="mt-8">
                    <span className="text-4xl font-bold text-primary">
                      {tier.priceMonthly !== 'Contact us' && tier.priceMonthly !== 'Free'
                        ? '$'
                        : ''}{' '}
                      {monthlySelected ? tier.priceMonthly : tier.priceYearly}
                    </span>{' '}
                    <span className="text-base font-medium text-gray-500">
                      {tier.priceMonthly !== 'Contact us' && tier.priceMonthly !== 'Free'
                        ? monthlySelected
                          ? '/seat/mo'
                          : '/seat/mo'
                        : ''}
                    </span>
                  </p>
                  <ButtonBilling
                    tier={tier}
                    organization={organization}
                    players={players}
                    monthlySelected={monthlySelected}
                  />
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className="text-xs font-medium uppercase tracking-wide text-gray-900">
                    What's included
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="h-5 w-5 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="font-sans  text-sm text-gray-500">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className="sm:flex sm:flex-col sm:items-center">
            <EndButton hasPayments={hasPayments} axios={axios} />
          </div>
        </div>
      </div>

      {seeMore ? null : (
        <div className="absolute inset-x-0 bottom-8 h-64 bg-gradient-to-t from-white to-transparent" />
      )}
      {seeMore ? null : <SeeMoreButton />}
    </div>
  )
}
