import { useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList,
} from 'recharts'
import TagGrowth from '../utils/tagGrowth'
import {
  radiusBarLeft,
  radiusBarRight,
  stackedColor1,
  stackedColor2,
  stackedColor3,
  toolTipProps,
} from '../utils/stackedBar-classes'
import NoDataGraph from '../NoDataGraph/NoDataGraph'
import SkeletonLoading from '../utils/skeletonLoader'
import StackedBarLoading from '../StackedBarLoading.js/stackedBarLoading'
import ChartError from '../ChartError/chartError'
import { classNames } from '../../../utils'

/*
 ** Variable constitution:
 **  stat = {
 **    totalReviewTime : int,
 **    firstResponseTime : int,
 **    reworkTime: int,
 **  }
 */

export default function ReviewTimeStackedChart({ loading, error, marginTime }) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  if (error) return <ChartError />
  const { reviewTime } = analytics
  let stat, pastStat
  if (reviewTime) {
    stat = reviewTime.stat
    pastStat = reviewTime.pastStat
  }
  if (!reviewTime || !stat) return <NoDataGraph />
  if (stat?.totalReviewTime < 0 || stat?.firstResponseTime < 0 || stat?.reworkTime < 0) {
    return <NoDataGraph />
  }

  const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props
    const value = props.value / stat?.totalReviewTime
    return (
      <Label
        {...rest}
        value={`${(value * 100).toFixed()}%`}
        fontSize="12"
        fill="#FFFFFF"
        fontWeight="Bold"
        fontFamily="Poppins"
      />
    )
  }

  const totalReviewTime =
    (stat?.totalReviewTime / 60).toFixed() +
    'h' +
    (stat?.totalReviewTime % 60).toFixed() +
    'm'
  const firstResponseTime =
    (stat?.firstResponseTime / 60).toFixed() +
    'h' +
    (stat?.firstResponseTime % 60).toFixed() +
    'm'
  const reworkTime =
    (stat?.reworkTime / 60).toFixed() + 'h' + (stat?.reworkTime % 60).toFixed() + 'm'
  const idleCompletionTime =
    (stat?.idleCompletionTime / 60).toFixed() +
    'h' +
    (stat?.idleCompletionTime % 60).toFixed() +
    'm'

  const ReviewTimeMainData = () => {
    if (loading) return <SkeletonLoading specificCss="!w-20" />
    return (
      <>
        <p
          className={classNames(
            'text-lg text-gray-500 mr-4',
            marginTime && 'px-4 sm:px-6 lg:px-8 sm:flex-auto',
          )}
        >
          <span className="font-bold text-xl text-primary">{totalReviewTime}</span>
        </p>
        <TagGrowth
          change={
            (stat.totalReviewTime - pastStat.totalReviewTime) /
              pastStat.totalReviewTime || 0
          }
          changeType={
            stat.totalReviewTime - pastStat.totalReviewTime > 0 ? 'increase' : 'decrease' // < because we want to reduce it
          }
        />
      </>
    )
  }

  const ReviewTimeDescription = () => {
    return (
      <div className="grid grid-cols-3 gap-4 self-center ml-4 text-lg font-medium ">
        <div className="text-center">
          <h4 className=" text-gray-500">Pickup Time</h4>
          <div className="flex justify-center">
            {loading ? (
              <SkeletonLoading specificCss="!w-20" />
            ) : (
              <>
                <p className={`text-[${stackedColor1}] font-semibold mr-2`}>
                  {firstResponseTime}
                </p>
                <TagGrowth
                  change={
                    (stat.firstResponseTime - pastStat.firstResponseTime) /
                      pastStat.firstResponseTime || 0
                  }
                  // < because we want to reduce it
                  changeType={
                    stat.firstResponseTime - pastStat.firstResponseTime > 0
                      ? 'increase'
                      : 'decrease'
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className="text-center">
          <h4 className=" text-gray-500">Rework Time</h4>
          <div className="flex justify-center">
            {loading ? (
              <SkeletonLoading specificCss="!w-20" />
            ) : (
              <>
                <p className={`text-[${stackedColor2}] font-semibold mr-2`}>
                  {reworkTime}
                </p>
                <TagGrowth
                  change={
                    (stat.reworkTime - pastStat.reworkTime) / pastStat.reworkTime || 0
                  }
                  changeType={
                    // < because we want to reduce it
                    stat.reworkTime - pastStat.reworkTime > 0 ? 'increase' : 'decrease'
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className="text-center">
          <h4 className=" text-gray-500">Completion Time</h4>
          <div className="flex justify-center">
            {loading ? (
              <SkeletonLoading specificCss="!w-20" />
            ) : (
              <>
                {' '}
                <p className={`text-[${stackedColor3}] font-semibold mr-2`}>
                  {idleCompletionTime}
                </p>
                <TagGrowth
                  change={
                    (stat.idleCompletionTime - pastStat.idleCompletionTime) /
                      pastStat.idleCompletionTime || 0
                  }
                  changeType={
                    stat.idleCompletionTime - pastStat.idleCompletionTime > 0
                      ? 'increase'
                      : 'decrease'
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  const ReviewTimeBar = () => {
    if (loading) return <StackedBarLoading />

    return (
      <ResponsiveContainer height={150} width={'100%'}>
        <BarChart
          layout="vertical"
          data={[stat]}
          stackOffset="expand"
          maxBarSize={75}
          margin={{ top: 0, left: -30, right: 10, bottom: 0 }}
        >
          <XAxis hide type="number" />
          <YAxis type="category" dataKey="name" stroke="#FFFFFF" fontSize="12" />
          <Bar
            dataKey="firstResponseTime"
            fill={stackedColor1}
            stackId="a"
            radius={radiusBarLeft}
          >
            <LabelList
              dataKey="firstResponseTime"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
          <Bar dataKey="reworkTime" fill={stackedColor2} stackId="a">
            <LabelList
              dataKey="reworkTime"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
          <Bar
            dataKey="idleCompletionTime"
            fill={stackedColor3}
            stackId="a"
            radius={radiusBarRight}
          >
            <LabelList
              dataKey="idleCompletionTime"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return (
    <div className="">
      <div className="flex">
        <ReviewTimeMainData marginTime={marginTime} />
      </div>
      <div className="py-2 pr-2 justify-center ">
        <ReviewTimeBar />
        <ReviewTimeDescription />
      </div>
    </div>
  )
}
