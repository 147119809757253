import { useSelector } from 'react-redux'
import { Setting } from '../../../reducers/settingReducer'
import moment from 'moment'
import { Organization } from '../../../reducers/organizationReducer'
import GitHubUsers from '../../GithubUsers'
import { tableClass, thClass, tdClass } from './tablesClasses'
import { classNames } from '../../../utils'

export default function TableDeployments({ deployments, title }) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const setting = useSelector(Setting.selectors.getSetting)
  const { startDate, endDate } = setting
  return (
    <div className="mt-5 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h3 className="font-medium text-lg text-gray-500 mb-0">{title}</h3>
          <h4 className="text-sm font-normal text-gray-900">
            A list of all the deployments from{' '}
            {moment(startDate, 'DD-MM-YYYY').format('MMMM Do YYYY')} to{' '}
            {moment(endDate, 'DD-MM-YYYY').format('MMMM Do YYYY')}.
          </h4>
        </div>
      </div>
      <div className="mt-3 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className={tableClass}>
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className={classNames(thClass, 'w-[120px]')}>
                      id
                    </th>
                    <th scope="col" className={thClass}>
                      Environment
                    </th>
                    {/* <th
                      scope='col'
                      className={thClass}
                    >
                      Production
                    </th> */}
                    <th scope="col" className={thClass}>
                      Creator
                    </th>
                    <th scope="col" className={thClass}>
                      Repository
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {deployments.map((deployment) => (
                    <tr key={deployment.id}>
                      <td className={tdClass}>{deployment.id || 'no data'}</td>
                      <td className={tdClass}>{deployment.environment || 'no data'}</td>
                      {/* <td className={tdClass}>
                        {!deployment.productionEnvironment ? 'false' : 'true'}
                      </td> */}
                      <td className={tdClass}>
                        <a
                          href={deployment?.creator?.profileUrl}
                          rel="noreferrer"
                          target={'_blank'}
                        >
                          <GitHubUsers githubUsers={[deployment?.creator]} />
                        </a>
                      </td>
                      <td className={tdClass}>
                        <a
                          target={'_blank'}
                          rel="noreferrer"
                          href={`https://github.com/${organization?.providerLogin}/${deployment?.repository?.name}`}
                        >
                          {deployment?.repository?.name}
                        </a>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(deployment.providerCreatedAt).format('DD MMMM YYYY') ||
                          'no data'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
