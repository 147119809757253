import React from 'react'

import { Link } from 'react-router-dom'
import { RandomAxoloAvatar } from '../../components/RandomAxoloAvatar'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { Button } from 'antd'
import { handleLogout } from '../../components/logoutButton/handleLogout'
import { useAxiosWithHeader } from '../../utils'

export const Error = () => {
  const dispatch = useDispatch()
  const jwt = useSelector(User.selectors.selectJWT)
  const axios = useAxiosWithHeader()

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <h1 className="text-3xl text-textWhite">404</h1>
      <RandomAxoloAvatar></RandomAxoloAvatar>
      <h1 className="pb-10 text-xl text-textWhite">
        This is not the web page you are looking for.
      </h1>
      <Link onClick={() => handleLogout(jwt, dispatch, axios)} to={`/`}>
        <Button
          type="primary"
          className="border-none bg-primary text-textWhite hover:bg-indigo-700 hover:text-textWhite"
        >
          Go to the Home page to login again
        </Button>
      </Link>
    </div>
  )
}
