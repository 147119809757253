import { H2Settings } from '../../components/Settings'
import { Organization } from '../../reducers/organizationReducer'
import { useDispatch, useSelector } from 'react-redux'
import { AutoAssignReviewerSetting } from '../../components/Settings/utils'
import { globalUpdateCompanySetting, useAxiosWithHeader } from '../../utils'

export default function AutoAssignReviewerSettings() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()

  return (
    <>
      <H2Settings content="Auto Assign Reviewer" />

      <AutoAssignReviewerSetting
        orgIsPremium={true}
        setting={setting}
        updateCompanySetting={globalUpdateCompanySetting}
        axios={axios}
        dispatch={dispatch}
      />
    </>
  )
}
