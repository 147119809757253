import './App.less'
import 'antd/dist/antd.min.css'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Login } from './pages/Login/Login'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { Integrations } from '@sentry/tracing'
import { User } from './reducers/userReducer'
import { useHistory } from 'react-router-dom'

window.$crisp = []
window.CRISP_WEBSITE_ID = 'e55d5c33-8163-469b-8799-071e4bb34d9f'
;(function () {
  var d = document
  var s = d.createElement('script')
  s.src = 'https://client.crisp.chat/l.js'
  s.async = 1
  d.getElementsByTagName('head')[0].appendChild(s)
})()
window.$crisp.push(['safe', true])

if (process.env.NODE_ENV !== 'development') {
  try {
    Sentry.init({
      dsn:
        'https://991cb66c5d1f4ea6b7455c3472f057eb@o4504762492125184.ingest.sentry.io/4504762555105280',
      integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
      replaysOnErrorSampleRate: 1.0,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    })
    posthog.init('phc_L9f6Uj1bRNHNEBe4QDQkLwzq8iAtzszkwzrvXw90wjV', {
      api_host: 'https://app.posthog.com',
      enable_recording_console_log: true,
    })
  } catch (error) {
    console.error('Error initializing Sentry or PostHog', error)
  }
}

const App = () => {
  const user = useSelector(User.selectors.selectUser)
  const history = useHistory()

  if (user.jwt) {
    history.push('/home/refresh')
  }
  return (
    <div className="h-[100vh] bg-white">
      <Login />
    </div>
  )
}

export default App
