import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useAxiosWithHeader } from '../../utils'
import LoadingPage from '../Loading'
import HomeFooter from '../FooterHomepage/footerHomePage'
import ButtonRequest from './buttonRequest'

const URLBACK =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:1337/'
    : 'https://api.axolo.co/'

export default function PlayerNotAdmin() {
  const [loading, setLoading] = useState(true)
  const [adminUsername, setAdminUsername] = useState('')
  const [adminId, setAdminId] = useState('')
  const [engineerId, setEngineerId] = useState('')
  const axios = useAxiosWithHeader()

  const ButtonDoc = ({ URL, text }) => {
    return (
      <div className="flex justify-center">
        <a className="" href={URL}>
          <button
            type="button"
            className="my-2 block items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm
          font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500
          
          focus:ring-offset-2"
          >
            {text}
          </button>
        </a>
      </div>
    )
  }

  const setDataForRedirection = async () => {
    const res = await axios.get(`${URLBACK}organizations/queryDataForRedirection`)
    setAdminUsername(res.data.admin.username)
    setAdminId(res.data.admin.id)
    setEngineerId(res.data.user.id)
    setLoading(false)
  }

  useEffectOnce(() => {
    setDataForRedirection().then()
  })

  if (loading) return <LoadingPage />

  return (
    <div className=" h-screen bg-white">
      <div className=" flex h-[88vh] flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
          <img className="mx-auto h-12 w-auto" src="/logo_axolo.png" alt="Logo Axolo" />
          <h2 className="text-ourBlack mt-6 text-center text-xl font-bold">
            An admin already installed Axolo on your workspace
          </h2>
          <p className="mt-2 text-center text-sm text-ourGrey ">
            What do you want to do?
          </p>

          <div className="grid grid-cols-2 gap-4 pt-12 font-medium text-axoloBlack">
            <div className="text-center">
              Use Axolo within my team{' '}
              <ButtonDoc
                URL="https://axolo.co/docs/guides/howto#onboard-yourself-on-axolo"
                text="Onboard myself on Axolo"
              />
            </div>
            <div className="text-center">
              Become an admin on Axolo{' '}
              <ButtonRequest
                axios={axios}
                URL={`${URLBACK}organizations/request-admin-right?admin=${adminId}&engineer=${engineerId}`}
                text={`Request ${adminUsername?.split('_')[0]} admin access`}
                adminName={adminUsername?.split('_')[0]}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center ">
          <p className="absolute bottom-24 text-center text-sm text-ourGrey lg:bottom-28">
            Looking for something else?{' '}
            <span
              onClick={() => {
                window.$crisp.push(['do', 'chat:open'])
              }}
              className="cursor-pointer underline	"
            >
              Chat with us
            </span>
            . We'll see you soon !
          </p>
        </div>
      </div>
      <HomeFooter />
    </div>
  )
}
