import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { URLBACK } from '../../assets/urls'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import { Organization } from '../../reducers/organizationReducer'
import { User } from '../../reducers/userReducer'
import { classNames, useAxiosWithHeader } from '../../utils'

async function sendAxoloNews({
  test,
  markdownText,
  imageUrl,
  user,
  all,
  axios,
  setDisabled,
  optOutVisible,
}) {
  setDisabled(true)
  try {
    if (test) {
      await axios.get(
        `${URLBACK}slack/sendAxoloNews?message=${markdownText}&imgUrl=${imageUrl}&orgId=${user.organizations[0].id}&optOutMessage=${optOutVisible}`,
      )
    }
    if (all) {
      await axios.get(
        `${URLBACK}slack/sendAxoloNews?message=${markdownText}&imgUrl=${imageUrl}&all=true&optOutMessage=${optOutVisible}`,
      )
    }
    messageInteraction({
      type: 'success',
      content: 'Notification sent!',
    })
  } catch (e) {
    messageInteraction({
      type: 'error',
      content: `An error occured while sending the notification:  ${e?.response?.data}`,
    })
    console.log('An error occured while sending the notification.', e?.response?.data, e)
  }
  setDisabled(false)
}

function translateTextToSlack(text) {
  let newText = text
  newText = newText.replace(/\n/g, '')
  newText = newText.replace(/\\n/g, '\n')
  return newText
}

const DisplayerNotif = ({ markdownText, imageUrl }) => {
  return (
    <div className="space-x-4 p-4">
      <div className="">
        <p className="whitespace-pre-line">
          {translateTextToSlack(markdownText) || 'So wow! Much empty...'}
        </p>
      </div>
      <div className="">
        <img
          src={
            imageUrl ||
            'https://i1.wp.com/thetempest.co/wp-content/uploads/2017/08/The-wise-words-of-Michael-Scott-Imgur-2.jpg?w=1024&ssl=1'
          }
          alt="Slack Notification"
          className="h-auto max-w-full"
        />
      </div>
    </div>
  )
}

const NotifCreator = ({
  markdownText,
  setMarkdownText,
  imageUrl,
  setImageUrl,
  user,
  axios,
}) => {
  const [disabled, setDisabled] = useState(false)
  const [optOutVisible, setOptOutVisible] = useState(false)
  return (
    <div className="flex flex-col space-y-4 p-4">
      <div className="flex flex-col">
        <label htmlFor="markdown-text" className="mb-2 text-xl">
          Notification text in Slack markdown and with \n
        </label>
        <textarea
          id="markdown-text"
          className="rounded-md border-2 border-gray-200 p-2"
          value={markdownText}
          onChange={(e) => setMarkdownText(e.target.value)}
          placeholder="Enter your text here..."
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="image-url" className="mb-2 text-xl">
          Image URL
        </label>
        <input
          type="url"
          id="image-url"
          className="rounded-md border-2 border-gray-200 p-2"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          placeholder="Enter your image URL here..."
        />
      </div>
      <div className="flex">
        <button
          className={classNames(
            'inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-hoverPrimary ',
            disabled && 'cursor-not-allowed opacity-50',
          )}
          onClick={() =>
            sendAxoloNews({
              test: true,
              markdownText,
              imageUrl,
              user,
              axios,
              setDisabled,
              optOutVisible,
            })
          }
          disabled={disabled}
        >
          Send test in MP
        </button>
        <button
          className={classNames(
            'ml-4 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 ',
            disabled && 'cursor-not-allowed opacity-50',
          )}
          onClick={() =>
            sendAxoloNews({
              all: true,
              markdownText,
              imageUrl,
              user,
              setDisabled,
              optOutVisible,
              axios,
            })
          }
          disabled={disabled}
        >
          ⚠️ Send to all ⚠️
        </button>
      </div>
      <div className="flex items-center space-x-4">
        <input
          type="checkbox"
          value={optOutVisible}
          onClick={() => setOptOutVisible(!optOutVisible)}
          className="rounded border p-2" // Tailwind classes
        />
        <span className="text-sm">OptOut message visible</span>
      </div>
    </div>
  )
}
export default function NotifBuilder() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const user = useSelector(User.selectors.selectUser)
  const [markdownText, setMarkdownText] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const axios = useAxiosWithHeader()
  const history = useHistory()

  if (
    organization?.providerLogin !== 'axolo-co' &&
    organization?.providerLogin !== 'Wave-Corp'
  ) {
    history.push('/settings/pull-request-channel')
  }

  return (
    <MainDivOnboarding>
      <div className="mx-10 grid grid-cols-2 gap-8">
        <DisplayerNotif imageUrl={imageUrl} markdownText={markdownText} />
        <NotifCreator
          markdownText={markdownText}
          setMarkdownText={setMarkdownText}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          user={user}
          axios={axios}
        />
      </div>
      <div className="mx-20 mt-20 flex justify-center">
        ⚠️ Even in test mode, this function only sends DM during general working hours
        (9am-5pm). If you want to test it outside, find workingHourDelay === 0 in the
        backend and in debug put it at 0.
      </div>
    </MainDivOnboarding>
  )
}
