import React from 'react'
import './index.css'
import { render } from 'react-dom'
import store from './app/store'
import Root from './Root'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { Provider } from 'react-redux'

const persistor = persistStore(store)
// persistor.purge()

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
