import { Tooltip } from 'antd'

export default function AxoloTooltip({
  placement = 'top',
  title = 'To do',
  tw,
  children,
}) {
  return (
    <Tooltip placement={placement} title={title} className={tw}>
      {children}
    </Tooltip>
  )
}
