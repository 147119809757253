import moment from 'moment'

export async function updateTimeZoneOffset(organization, axios, timeZone) {
  const timeZoneDiff = moment().tz(timeZone).format().slice(-6)
  const negativeOrPositiveOffset = timeZoneDiff[0]
  const hourDifferenceTz = parseInt(timeZoneDiff.slice(2, 3))
  const minuteDifferenceTz = parseInt(timeZoneDiff.slice(-2))
  let totalOffset = hourDifferenceTz * 60 * 60 + minuteDifferenceTz * 60
  totalOffset = negativeOrPositiveOffset === '+' ? totalOffset : -totalOffset

  axios.put('/organizations/' + organization.id, {
    timeZoneOffset: totalOffset,
    timeZone,
  })
}
