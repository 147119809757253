import { useState } from 'react'
import { classNames } from '../../utils'

const testimonials = [
  {
    name: 'Richard M.',
    company: 'Irwin',
    role: 'Director of Engineering',
    image:
      'https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/human/richard.jpg',
    text: 'We no longer need to cut and paste MR URLs into Slack manually. Axolo also reduces button-clicking in the GitLab UI, because Slack can be used to assign a Reviewer and also Approve an MR. We no longer need to watch for email notifications and context-switch to the GitLab UI to have the discussion -- Axolo automatically opens (and archives) a channel dedicated to the MR discussion.',
  },
  {
    company: 'Sproutfi',
    name: 'Tyler',
    role: 'CTO',
    image:
      'https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/human/tyler.jfif',
    text: "Code quality has improved, engineers have a better understanding of what their colleagues are working on, and code is getting shipped much faster. Time from pull request open to review to merge is WAY faster, and engagement with reviews is much higher for our team. Axolo's team is also unbelievably fast in resolving issues when we run into them. Pleasure to work with them.",
  },
  {
    company: 'HeraldAPI',
    name: 'Jacob',
    role: 'CTO',
    image:
      'https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/human/jacob.jfif',
    text: "Axolo is an incredible tool for connecting PRs with our everyday engineering discussion on Slack. It integrates seamlessly and makes it so as a manager, I never lose track of my engineers' work. For my engineers, it allows them to collaborate without losing a thread in different contexts. Overall it keeps us moving swiftly throughout the sprint and continuously reviewing and merging code!",
  },
  {
    company: 'House Rx',
    name: 'Dan',
    role: 'Director of Infrastructure',
    image: 'https://axolo.co//images/testimonials/dan.jpg',
    text: 'Axolo has made it easier to hold developers and QA engineers accountable for reviewing and merging pull requests on a timely basis. Our average PR time-to-merge was 2.40 days before Axolo, this has been reduced to 1.51 days after only using it for 2 weeks.',
  },
  {
    company: 'Weekday',
    name: 'Anubhav Malik',
    role: 'CTO',
    image:
      'https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/human/a_malik.jpg',
    text: "We used to send each other our GitHub PR links on Slack to ask for a review. Having Axolo made it a zero-effort thing. Having discussions on Slack is much more natural and Axolo does a great job doing it. The support is amazing as well! Arthur and Sydney proactively reach out to ask if we're facing any issues and even released one feature request within hours!",
  },
  {
    company: 'Juo.io',
    name: 'Pawel',
    role: 'CTO',
    image:
      'https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/human/pawel.png',
    text: 'Axolo is a must-have for teams using GitHub. My team can discuss pull requests without leaving Slack now. Axolo helped us merge pull requests faster in a more seamless way. I was waiting for this product for so long!',
  },
  {
    company: 'Staqfi',
    name: 'Dee Evans',
    image: 'https://axolo.co//images/testimonials/dee.jpg',
    role: 'CTO',
    text: "I've been a CTO at various companies for a decade now. One of my biggest pain points has always been PR review responsiveness. I've tried so many home baked solutions. Axolo is the app that solves the problem perfectly.",
  },
]

export default function AppSignInTestimonial() {
  const randomInt = Math.floor(Math.random() * testimonials.length)

  const [activeTestimonial, setActiveTestimonial] = useState(randomInt)

  return (
    <div className="hidden items-center justify-center bg-axoloBlack lg:col-span-3 lg:flex ">
      <div className="flex flex-col items-center">
        <div className="flex h-[500px] w-[350px] flex-col  rounded-lg bg-white px-8 py-4 shadow-lg">
          <div className="-mt-[60px] flex w-max items-center justify-center self-center rounded-full border-4 border-white shadow-xl">
            <img
              className="h-20 w-20 rounded-full"
              src={testimonials[activeTestimonial]?.image}
              alt={testimonials[activeTestimonial]?.name}
            />
          </div>
          <div className="mt-4">
            <p className="mb-0 font-bold">{testimonials[activeTestimonial]?.name}</p>
            <p className="mb-0">
              {testimonials[activeTestimonial]?.role} @{' '}
              {testimonials[activeTestimonial]?.company}
            </p>
          </div>
          <div className=" mt-4 border-t-[1px] border-gray-300 pt-4">
            <p className="">{testimonials[activeTestimonial]?.text}</p>
          </div>
        </div>
        <div className="mt-8 flex justify-center gap-4 ">
          {testimonials.map((t, i) => {
            // this will return a line of clickable avatars
            return (
              <button
                className="flex items-center justify-center"
                onClick={() => setActiveTestimonial(i)}
                key={i}
              >
                <img
                  className={classNames(
                    'h-10 w-10 rounded-full',
                    i !== activeTestimonial && 'opacity-50',
                    i === activeTestimonial && 'ring-2 ring-white',
                  )}
                  src={t.image}
                  alt="Logo Axolo"
                />
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}
