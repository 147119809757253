import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, message, Typography } from 'antd'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'

const { Title, Paragraph, Link } = Typography

const APIKey = () => {
  const dispatch = useDispatch()
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const { apikey, apiaccess } = setting
  const [loading, setLoading] = useState(false)

  const createApiKey = async () => {
    setLoading(true)
    try {
      const response = await axios.post(`/organization/${organization.id}/apikey`)
      const newApiKey = response.data.apikey
      dispatch(
        Organization.actions.setData({ setting: { ...setting, apikey: newApiKey } }),
      )
      message.success('API Key created successfully')
    } catch (error) {
      message.error('Error creating API Key')
    } finally {
      setLoading(false)
    }
  }

  const recreateApiKey = async () => {
    setLoading(true)
    try {
      const response = await axios.post(`/organization/${organization.id}/apikey`)
      const newApiKey = response.data.apikey
      dispatch(
        Organization.actions.setData({ setting: { ...setting, apikey: newApiKey } }),
      )
      message.success(
        'API Key re-created successfully. The old API Key is no longer available.',
      )
    } catch (error) {
      message.error('Error re-creating API Key')
    } finally {
      setLoading(false)
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apikey)
    message.success('API Key copied to clipboard')
  }

  if (!apiaccess) {
    return (
      <Paragraph>
        You do not have access to manage API keys. Please contact support.
      </Paragraph>
    )
  }

  return (
    <div>
      <Title level={2}>API Key Management</Title>
      <Paragraph>
        Use this API key for provisioning and deprovisioning users automatically. For more
        details, refer to our{' '}
        <Link href="https://axolo.co/docs" target="_blank">
          documentation
        </Link>
        .
      </Paragraph>
      {apikey ? (
        <div>
          <div className="flex items-center">
            <Input.Password value={apikey} readOnly className="flex-1" />
            <Button onClick={copyToClipboard} className="ml-2">
              Copy API Key
            </Button>
          </div>
          <Button
            type="primary"
            className="mt-5"
            onClick={recreateApiKey}
            loading={loading}
          >
            Re-create API Key
          </Button>
          <Paragraph className="mt-2 text-gray-500">
            Creating a new API key will invalidate the old one.
          </Paragraph>
        </div>
      ) : (
        <Button type="primary" onClick={createApiKey} loading={loading}>
          Create API Key
        </Button>
      )}
    </div>
  )
}

export default APIKey
