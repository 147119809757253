import React from 'react'
import { useEffectOnce } from 'react-use'
import { useState } from 'react'
import { URLBACK } from '../../assets/urls'
import { useAxiosWithHeader } from '../../utils'
import momentTimezone from 'moment-timezone'
import StandupSetting from '../../components/StandupSetting'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { Engineer } from '../../reducers/engineerReducer'
import { useHistory } from 'react-router'

export default function EngineerDashboardStandup() {
  const axios = useAxiosWithHeader()
  const [engineer, setEngineer] = useState({})
  const [loading, setLoading] = useState(true)
  const organization = useSelector(Organization.selectors.getOrganization)
  const dispatch = useDispatch()
  const history = useHistory()

  if (!engineer || engineer?.providerLogin?.length === 0) {
    history.push(`/onboarding/selfonboard`)
  }

  useEffectOnce(() => {
    axios
      .get(`${URLBACK}engineer/me/${organization.id}`)
      .then((engineerResp) => {
        setEngineer(engineerResp.data)
        setLoading(false)
        dispatch(
          Organization.actions.setData({
            timeZone: engineerResp.timeZone,
            timeZoneOffset: engineerResp.timeZoneOffset,
          }),
        )
        dispatch(Engineer.actions.setData(engineerResp.data))
        // This means an engineer signed in but is not onboarded
        if (!engineerResp?.data || engineerResp?.data?.providerLogin?.length === 0) {
          history.push(`/onboarding/selfonboard`)
        }
      })
      .catch((error) => {
        console.log('error getting engineer info', error)
        setEngineer(error)
      })
  })

  return (
    <>
      <br></br>
      <StandupSetting engineer={engineer} isAdmin={false} />
    </>
  )
}
