const allsteps = [
  { id: 'Step 1', name: 'Install Slack', href: '#', status: 'complete' },
  { id: 'Step 2', name: 'Install on Git platform', href: '#', status: 'current' },
  { id: 'Step 3', name: 'Onboard your team', href: '#', status: 'upcoming' },
  { id: 'Step 4', name: 'Create your team channel', href: '#', status: 'upcoming' },
]

export default function OnboardingSteps({ steps = allsteps, step = 1 }) {
  if (step > 2) {
    steps[1].status = 'complete'
    steps[2].status = 'current'
  }
  if (step > 3) {
    steps[1].status = 'complete'
    steps[2].status = 'complete'
    steps[3].status = 'current'
  }
  return (
    <nav aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === 'complete' ? (
              <span
                href={step.href}
                className="group flex flex-col border-l-4 border-primary py-2 pl-4 hover:border-primary md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
              >
                <span className="text-xs font-semibold uppercase tracking-wide text-primary group-hover:text-primary">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-slate-500">{step.name}</span>
              </span>
            ) : step.status === 'current' ? (
              <span
                href={step.href}
                className="flex flex-col border-l-4 border-blue-500 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0 "
                aria-current="step"
              >
                <span className="opacity:50 text-xs font-bold uppercase tracking-wide text-blue-600">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-black">{step.name}</span>
              </span>
            ) : (
              <span
                href={step.href}
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
              >
                <span className="text-xs font-semibold uppercase tracking-wide text-gray-500 group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-sm font-medium text-slate-600">{step.name}</span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
