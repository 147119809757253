import { Avatar } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { User } from '../../reducers/userReducer'
import messageInteraction from '../Analytics/utils/messageInteraction'
import {
  SelectTeamStandup,
  StandupSelectChannels,
  StandupSelectTime,
} from './standup-selectors'
import { StandupTableColumn } from './table-columns'
import {
  AddStandupButton,
  DeletedStandupButton,
  LoadingStandupData,
  PersonalizationStatusStandup,
  StandupPersonalizationTitle,
  StandupTimeInformation,
  StandupTitle,
} from './table-element'
import { editStandup, Standups } from '../../reducers/standupReducer'
import { ChannelInformation } from '../ChannelLink'
import { UserAvatar } from '../../pages/OpenChannels/userAvatar'

export const StandupTable = ({
  personalStandup,
  engineer,
  axios,
  loading,
  standups,
  isAdmin,
}) => {
  const user = useSelector(User.selectors.selectUser)
  const organization = useSelector(Organization.selectors.getOrganization)
  const { teams, slackChannels, setting } = organization
  const [teamStandupSelect, setTeamStandupSelect] = useState()
  const [channelSelected, setChannelSelected] = useState()
  const [timeSelected, setTimeSelected] = useState()
  const [daySelected, setDaySelected] = useState({
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
  })
  const [disableAdd, setDisabledAdd] = useState(false)
  const dispatch = useDispatch()

  function isMissingInfo() {
    const missingInformation = []
    if (!teamStandupSelect || !teamStandupSelect.label) {
      missingInformation.push('team')
    }
    if (!channelSelected && teamStandupSelect?.key !== 'personalReminder') {
      missingInformation.push('channel')
    }
    if (!timeSelected) {
      missingInformation.push('time')
    }

    if (missingInformation.length > 0) {
      const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })
      const missingText = formatter.format(missingInformation)
      messageInteraction({
        content: `Missing ${missingText} information.`,
        type: 'error',
      })
      return true
    }
    return false
  }

  function emptyAllSelection() {
    setTeamStandupSelect(null)
    setChannelSelected(null)
    setTimeSelected(null)
  }

  async function addNewStandup() {
    setDisabledAdd(true)
    const missingInfo = isMissingInfo()
    if (!missingInfo) {
      const hide = messageInteraction({
        content: 'Adding a new stand-up...',
        type: 'loading',
        duration: 0,
      })
      let scope
      if (teamStandupSelect.label === 'organization-wide') scope = 'all'
      else if (teamStandupSelect.key === 'personalReminder') scope = 'personal'
      else scope = 'team'
      const channelId = scope === 'personal' ? user.authed_user : channelSelected.key
      const channelName = scope === 'personal' ? 'Direct message' : channelSelected.label
      const standupObject = {
        scope,
        organization: organization.id,
        team: scope === 'team' ? teamStandupSelect.key : null,
        channelId,
        channelName,
        timeUtc: timeSelected
          .subtract(organization.timeZoneOffset, 's')
          .format('HH:mm:ss'),
        sunday: daySelected.sunday,
        monday: daySelected.monday,
        tuesday: daySelected.tuesday,
        wednesday: daySelected.wednesday,
        thursday: daySelected.thursday,
        friday: daySelected.friday,
        saturday: daySelected.saturday,
        creator: engineer.id,
        userId: user.id,
      }
      try {
        // this controller was overwritten in the backend, standup.create
        const res = await axios.post(`${URLBACK}standups`, standupObject)
        hide()
        dispatch(Standups.actions.addMyStandup(res.data))
        emptyAllSelection()
      } catch (e) {
        hide()
        messageInteraction({
          content: `Error creating your stand-up. Please try again or contact support.`,
          type: 'error',
        })
      }
    }
    setDisabledAdd(false)
  }
  async function handleClickOnDay({ daySelected, day, standup }) {
    if (personalStandup) {
      const hide = messageInteraction({
        content: `Loading...`,
        type: 'loading',
        duration: 0,
      })
      try {
        const updatedStandupRes = await axios.put(`${URLBACK}standups/${standup?.id}`, {
          [day]: !daySelected[day],
        })
        dispatch(editStandup(updatedStandupRes.data))
      } catch (e) {
        messageInteraction({
          content: `Error updating your stand-up. Please try again or contact support.`,
          type: 'error',
        })
      }
      hide()
    } else {
      messageInteraction({
        content: `You can't update stand-ups that are not yours.`,
        type: 'info',
      })
    }
  }

  const StandupData = () => {
    return (
      <tbody className="divide-y divide-gray-200 bg-white">
        {standups.map((standup) => {
          if (standup.scope === 'personal' && !personalStandup) return null
          return (
            <tr key={standup?.id}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {standup?.scope === 'all' && 'organization-wide'}
                {standup?.scope === 'personal' && 'only me (direct message)'}
                {standup?.scope === 'team' && standup?.team?.name}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                <div className="flex items-center">
                  <UserAvatar record={standup?.creator} />
                  <p className="mb-0 pl-2 text-gray-800">
                    {standup?.creator?.providerLogin || 'An admin'}
                  </p>
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <ChannelInformation
                  standup={true}
                  channel={standup}
                  slackCred={user.credential}
                />{' '}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <StandupTimeInformation
                  standup={standup}
                  handleClickOnDay={handleClickOnDay}
                  timeZoneOffset={user.organizations?.[0]?.timeZoneOffset}
                  timeZone={organization.timeZone}
                  personalStandup={personalStandup}
                />
              </td>
              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                <span href="#" className="text-indigo-600 hover:text-indigo-900">
                  <div className="flex justify-center ">
                    {personalStandup && (
                      <DeletedStandupButton
                        dispatch={dispatch}
                        standupId={standup?.id}
                        creatorId={standup?.creator?.id}
                        engineerId={engineer?.id}
                        axios={axios}
                        myStandups={standups}
                      />
                    )}
                    <span className="sr-only">, {standup?.id}</span>
                  </div>
                </span>
              </td>
            </tr>
          )
        })}
        {/* last line to add a new stand-up */}
        {personalStandup && (
          <tr key="newStandup" className="h-[125px]">
            <td className="whitespace-nowrap pb-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              <SelectTeamStandup
                teams={teams}
                setTeamStandupSelect={setTeamStandupSelect}
                teamStandupSelect={teamStandupSelect}
              />
            </td>
            <td className="whitespace-nowrap px-3 pb-4 text-sm text-gray-500 ">
              <div className="flex items-center">
                <UserAvatar record={engineer?.avatarUrl?.length > 0 ? engineer : user} />
                <p className="mb-0 pl-2 text-gray-800">
                  {engineer?.providerLogin || user?.email}
                </p>
              </div>
            </td>
            <td className="whitespace-nowrap px-3 pb-4 text-sm text-gray-500">
              <StandupSelectChannels
                slackChannels={slackChannels}
                setChannelSelected={setChannelSelected}
                channelSelected={channelSelected}
                teamStandupSelect={teamStandupSelect}
                user={user}
              />
            </td>
            <td className="whitespace-nowrap px-3 pb-4 text-sm text-gray-500">
              <StandupSelectTime
                setTimeSelected={setTimeSelected}
                setDaySelected={setDaySelected}
                daySelected={daySelected}
                axios={axios}
                timeSelected={timeSelected}
                isAdmin={isAdmin}
              />
            </td>
            <td className="relative whitespace-nowrap  pb-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <AddStandupButton addNewStandup={addNewStandup} disabled={disableAdd} />
            </td>
          </tr>
        )}
      </tbody>
    )
  }

  let noOtherStandupToShow = false
  if (
    !personalStandup &&
    standups.filter((standup) => standup.scope !== 'personal').length === 0
  ) {
    noOtherStandupToShow = true
  }
  if (noOtherStandupToShow) return null
  return (
    <div className="pb-6">
      <StandupTitle personalStandup={personalStandup} provider={organization.provider} />
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <StandupTableColumn />
                {loading ? <LoadingStandupData /> : <StandupData />}
              </table>
            </div>
          </div>
        </div>
      </div>
      {isAdmin && (
        <>
          <StandupPersonalizationTitle />
          <PersonalizationStatusStandup
            setting={setting}
            axios={axios}
            dispatch={dispatch}
          />
        </>
      )}
    </div>
  )
}
