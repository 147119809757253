import React from 'react'
import { useEffectOnce } from 'react-use'
import { AppContent } from '../../components/utils/utils'
import { Organization } from '../../reducers/organizationReducer'
import getOrgAndEngineers from '../../api/getOrgAndEngineers'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { useAxiosWithHeader, useQuery } from '../../utils'
import { setIsOnboarded } from '../../reducers/playersReducer'
import TeamManagementTable from '../../components/TeamManagementTable'
import { isSlackAuth } from '../../components/TeamChannels/helpers'

export default function TeamPage() {
  const user = useSelector(User.selectors.selectUser)
  const organization = useSelector(Organization.selectors.getOrganization)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  let query = useQuery()

  const slackAuth = isSlackAuth({ user, organization })

  // do we really need this useEffectOnce?
  useEffectOnce(() => {
    ;(async () => {
      if (user) {
        // why are there two getOrgAndEngineers here?
        // need a time out so we can first get the user in due time
        await setTimeout(() => {
          getOrgAndEngineers({
            organization,
            slackAuth,
            dispatch,
            user,
            axios,
            setIsOnboarded,
          })
        }, 1000)
      }
    })()
  }, [axios, dispatch, query, user, getOrgAndEngineers])

  return (
    <div>
      <div className="">
        <AppContent>
          <h1 className="mt-2 text-xl font-semibold text-gray-900">
            Manage active engineers
          </h1>
          <p>Decide which engineers will have access to Axolo.</p>
          <TeamManagementTable />
        </AppContent>
      </div>
    </div>
  )
}
