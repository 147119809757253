import React from 'react'
import { Popover, Switch } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'

export default function CodeSnippets({ organization, setting, updateCompanySetting }) {
  return (
    <div className="mt-8">
      <div className="flex flex-col justify-between">
        <div className="flex flex-row justify-between">
          <p className="mb-0 flex">
            Enable code snippets in code review comments in Slack
            <span className="flex pl-3 pt-[4px]">
              <Popover
                content={
                  <div className="max-w-[400px]">
                    <h1>Code Snippets</h1>
                    Allow Axolo to send a link to the code review comment so that the
                    GitHub app displays a code snippet.
                    <br></br>
                    <div className="mt-5 flex justify-center">
                      <img
                        src="/images/screenshot_codesnippets.png"
                        alt="Axolo general notification for pull request status"
                        className="max-w-[400px]"
                      />
                    </div>
                  </div>
                }
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </span>
          </p>
          <div className="flex">
            <Switch
              checked={setting?.permalink}
              onClick={() => {
                updateCompanySetting('permalink', !setting?.permalink)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
