export default function EnterpriseBox() {
  return (
    <div className="mt-10 mb-40 sm:flex sm:flex-col ">
      <h1 className=" text-xl font-semibold text-gray-900">Enterprise</h1>
      <h2 className="mt-5 text-xl text-gray-500 sm:text-center ">
        For detailed information about your enterprise plan and tailored solutions, please
        reach out to our sales team at hey@axolo.co. They're ready to assist you with
        personalized support.
      </h2>
    </div>
  )
}
