import React from 'react'
import { useEffectOnce } from 'react-use'
import { useState } from 'react'
import { URLBACK } from '../../assets/urls'
import { useAxiosWithHeader } from '../../utils'
import ButtonRequest from '../PlayerNotAdmin/buttonRequest'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { Engineer } from '../../reducers/engineerReducer'
import { PlayerState } from '../../reducers/playersReducer'
import MultiSelectEngineers from '../../components/MultiSelect'
import { useHistory } from 'react-router'
import { Button } from 'antd'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'
import { Organization } from '../../reducers/organizationReducer'

export default function SelfOnboard() {
  const players = useSelector(PlayerState.selectors.getPlayers)
  const organization = useSelector(Organization.selectors.getOrganization)

  const inactivePlayers = players.filter(
    (p) => p?.communicationToolId?.length === 0 || !p?.communicationToolId,
  )
  const dispatch = useDispatch()
  const axios = useAxiosWithHeader()
  const history = useHistory()

  const [adminUsername, setAdminUsername] = useState('')
  const [adminId, setAdminId] = useState('')
  const [engineerId, setEngineerId] = useState('')
  const [loading, setLoading] = useState(true)
  const [newUser, setNewUser] = useState({})

  const setDataForRedirection = async () => {
    const res = await axios.get(`${URLBACK}organizations/queryDataForRedirection`)
    setAdminUsername(res.data.admin.username)
    setAdminId(res.data.admin.id)
    setEngineerId(res.data.user.id)
    setLoading(false)
  }
  const getEngineers = async () => {
    const res = await axios.get(`${URLBACK}organizations/engineers/${organization.id}`)
    dispatch(PlayerState.actions.setInitialPlayers({ players: res.data }))
  }

  const checkEngineer = async () => {
    axios
      .get(`${URLBACK}engineer/me/${organization.id}`)
      .then((engineerResp) => {
        dispatch(Engineer.actions.setData(engineerResp.data))
        if (engineerResp?.data?.providerLogin?.length > 0) {
          history.push(`/engineer/settings/general`)
        }
      })
      .catch((error) => {
        console.log('error getting engineer info', error)
      })
  }

  useEffectOnce(() => {
    // TODO Prevent onboarded engineers to come on this page. Check engineers/me ?
    setDataForRedirection().then()
    getEngineers().then()
    checkEngineer()
  })

  const validateOnboarding = async () => {
    if (!newUser.id) {
      messageInteraction({
        type: 'error',
        content: 'Please select a user.',
      })
      return
    }
    if (loading) {
      return
    }
    setLoading(true)
    const res = await axios.post(`${URLBACK}engineer/selfOnboard`, {
      ghData: newUser,
    })
    setLoading(false)
    dispatch(User.actions.updateUser(res.data))
    if (res.status === 200) {
      history.push('/engineer/settings/general')
    }
  }

  return (
    <div className="">
      <h1 className="mb-5 text-xl font-semibold text-gray-900">
        Onboard myself on Axolo
      </h1>
      <div className="flex items-center justify-between">
        <div className="flex justify-center">
          <p className="mb-0">
            Select your {organization.provider === 'github' ? 'GitHub' : 'GitLab'} account
            to onboard yourself.
          </p>
        </div>
        <div className="align-center flex justify-center">
          <MultiSelectEngineers
            newUser={newUser}
            setNewUser={setNewUser}
            members={inactivePlayers}
          ></MultiSelectEngineers>
          <Button
            disabled={loading}
            type={'primary'}
            className="ml-4 flex !h-10 items-center rounded-md border border-transparent bg-primary px-4 py-2"
            size={'large'}
            style={{ borderRadius: 5, margin: 6, color: 'white' }}
            loading={loading}
            onClick={() => validateOnboarding()}
          >
            Validate
          </Button>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="flex justify-between">
        <h1 className=" text-xl font-semibold text-gray-900">
          Request admin access to Axolo
        </h1>
        <ButtonRequest
          axios={axios}
          URL={`${URLBACK}organizations/request-admin-right?admin=${adminId}&engineer=${engineerId}`}
          text={`Request ${adminUsername?.split('_')[0]} admin access`}
          adminName={adminUsername?.split('_')[0]}
        />
      </div>
    </div>
  )
}
