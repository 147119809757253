import { useSelector } from 'react-redux'
import { Setting } from '../../../reducers/settingReducer'
import moment from 'moment'
import { Organization } from '../../../reducers/organizationReducer'
import { Fragment } from 'react'
import GitHubUsers from '../../GithubUsers'
import { tableClass, tdClass, thClass } from './tablesClasses'
import { classNames } from '../../../utils'
import { Tooltip } from 'antd'
import { delayToolTip } from '../utils/const'

export default function TableCycleTimeEvolution({
  continuousCycleTime,
  title,
  description,
}) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const setting = useSelector(Setting.selectors.getSetting)
  const { startDate, endDate } = setting
  return (
    <div className="mt-5 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h3 className="font-medium text-lg text-gray-500 mb-0">{title}</h3>
          <h4 className="text-sm font-normal text-gray-900">
            {description}
            {moment(startDate, 'DD-MM-YYYY').format('MMMM Do YYYY')} to{' '}
            {moment(endDate, 'DD-MM-YYYY').format('MMMM Do YYYY')}.
          </h4>
        </div>
      </div>
      <div className="mt-3 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className={tableClass}>
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className={classNames(thClass, 'w-[350px]')}>
                      Pull request title
                    </th>
                    <th scope="col" className={thClass}>
                      Creator
                    </th>
                    <th scope="col" className={thClass}>
                      Reviewers
                    </th>
                    {/* <th
                      scope='col'
                      className={thClass}
                    >
                      # of review requests
                    </th>
                    <th
                      scope='col'
                      className={thClass}
                    >
                      # of PR reviews
                    </th> */}
                    <th scope="col" className={thClass}>
                      Repository
                    </th>
                    <th scope="col" className={thClass}>
                      Cycle time
                    </th>
                    <th scope="col" className={thClass}>
                      Created At / Merged At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {continuousCycleTime?.map((cycleTime, index) => (
                    <Fragment key={index}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          {moment(cycleTime.date, 'YYYY-MM-DD').format('MMMM Do YYYY')}
                        </th>
                      </tr>
                      {cycleTime?.pullRequests?.map((pr) => (
                        <tr key={pr.id}>
                          <td className={tdClass}>
                            <Tooltip title={pr.title} mouseEnterDelay={delayToolTip}>
                              <a
                                target={'_blank'}
                                rel="noreferrer"
                                href={`https://github.com/${organization.providerLogin}/${pr?.repository?.name}/pull/${pr?.number}`}
                              >
                                {pr.title || 'no data'}
                              </a>
                            </Tooltip>
                          </td>
                          <td className={tdClass}>
                            <GitHubUsers githubUsers={[pr?.creator]} />
                          </td>
                          <td className={tdClass}>
                            <GitHubUsers githubUsers={pr?.reviewers} />
                          </td>
                          {/* <td className={tdClass}>
                            {pr?.review_requests?.length}
                          </td>
                          <td className={tdClass}>
                            {pr?.pull_request_reviews?.length}
                          </td> */}
                          <td className={tdClass}>
                            <Tooltip
                              title={pr?.repository?.name}
                              mouseEnterDelay={delayToolTip}
                            >
                              <a
                                target={'_blank'}
                                rel="noreferrer"
                                href={`https://github.com/${organization.providerLogin}/${pr?.repository?.name}`}
                              >
                                {pr?.repository?.name}
                              </a>
                            </Tooltip>
                          </td>
                          <td className={tdClass}>
                            <span className={'text-orange-500'}>
                              {moment.duration(pr.cycleTime, 'minutes').humanize()}
                            </span>
                          </td>
                          <td className={tdClass}>
                            {moment(pr.providerCreatedAt).format('DD MMMM YYYY') ||
                              'no data'}
                            /<br></br>
                            {moment(pr.mergedAt).format('DD MMMM YYYY')}
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
