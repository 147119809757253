import React, { useState, useEffect } from 'react'
import { Avatar, Tooltip } from 'antd'
import { UserOutlined } from '@ant-design/icons'

const AvatarComponent = ({ record, size, css }) => {
  const [avatarContent, setAvatarContent] = useState({
    type: 'image', // 'image' or 'text'
    content: record?.avatarUrl,
  })

  useEffect(() => {
    setAvatarContent({ type: 'image', content: record?.avatarUrl })
  }, [record?.avatarUrl])

  const handleError = () => {
    setAvatarContent({ type: 'text', content: record?.providerLogin })
  }

  return (
    <Tooltip title={record?.providerLogin}>
      {avatarContent.type === 'image' ? (
        <Avatar
          size={size}
          src={avatarContent.content}
          className={css}
          onError={handleError}
          icon={<UserOutlined />}
        />
      ) : (
        <Avatar size={size} className={css}>
          {avatarContent.content?.slice(0, 2)?.toUpperCase()}
        </Avatar>
      )}
    </Tooltip>
  )
}

export const UserAvatar = ({ record, size, css = '' }) => {
  return record?.providerProfileUrl?.length > 0 ? (
    <a
      href={record?.providerProfileUrl}
      target="_blank"
      rel="noopener noreferrer"
      key={record?.id}
    >
      <AvatarComponent record={record} size={size} css={css} />
    </a>
  ) : (
    <AvatarComponent record={record} size={size} css={css} />
  )
}
