import { useState, useEffect } from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PlayerState, setIsOnboarded } from '../../reducers/playersReducer'
import { User, changesOrg, toggleLoadingOrg } from '../../reducers/userReducer'
import { Organization } from '../../reducers/organizationReducer'
import { useLocation } from 'react-router-dom'
import { userMenu, subNavigation } from './roots'
import UserDropdown from './userDropDownDesktop'
import MobileOpenedMenu from './mobileOpenedMenu'
import HeaderMenuDesktop from './headerMenuDesktop'
import SubHeaderMenuDesktop from './subHeaderMenuDesktop'
import { Engineer } from '../../reducers/engineerReducer'
import { goGithubApp } from '../Helpers'
import getOrgAndEngineers from '../../api/getOrgAndEngineers'
import { useAxiosWithHeader } from '../../utils'
import { isSlackAuth } from '../TeamChannels/helpers'
import { handleLogout } from '../logoutButton/handleLogout'

export default function Header() {
  const history = useHistory()
  let isOnboarded = useSelector(PlayerState.selectors.isOnboarded)
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const user = useSelector(User.selectors.selectUser)
  const axios = useAxiosWithHeader()
  const jwt = useSelector(User.selectors.selectJWT)
  const engineer = useSelector(Engineer.selectors.getEngineer)
  let defaultAvatar =
    'https://axolo.s3.eu-west-3.amazonaws.com/analytics/media-app/default-avatar.png'
  const [avatar, setAvatar] = useState(defaultAvatar)

  const testImage = (url) =>
    new Promise((resolve) => {
      const img = new Image()
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
      img.src = url
    })

  const setAvatarFuntion = async (url) => {
    const isValid = await testImage(url)
    if (isValid) {
      setAvatar(url)
    }
  }

  if (user.avatarUrl?.length > 0) {
    setAvatarFuntion(user.avatarUrl)
  } else if (engineer.avatarUrl?.length > 0) {
    setAvatarFuntion(engineer.avatarUrl)
  }
  const userNavigation = userMenu()

  if (
    (organization.provider === 'github' && user.superAdmin) ||
    organization.provider === 'gitlab'
  ) {
    const text = `+ Add new ${
      organization.provider === 'gitlab' ? 'group' : 'organization'
    }`
    userNavigation.unshift({
      name: text,
      route: '#',
    })
  }
  if (user?.organizations?.length > 0) {
    const organizationsMenu = user.organizations.map((org) => ({
      name: org.providerLogin,
      route: '#',
      isSelected: org.providerLogin === organization.providerLogin,
    }))
    userNavigation.unshift(...organizationsMenu)
  }

  // this set the right navigation when you reload or enter a url
  const location = useLocation()
  let headerLocation = location?.pathname?.split('/')?.[1]
  let subHeaderLocation = location?.pathname?.split('/')?.[2]
  // this prevent a bug from the redirect after authentication from Home
  if (headerLocation === 'home') {
    headerLocation = 'settings'
    subHeaderLocation = 'pull-request-channel'
  }
  const [activePage, setActivePage] = useState(headerLocation)
  const [activeSubPage, setActiveSubPage] = useState(subHeaderLocation)

  useEffect(() => {
    setActivePage(headerLocation)
    setActiveSubPage(subHeaderLocation)
    if (user.avatarUrl?.length > 0) {
      setAvatarFuntion(user.avatarUrl)
    } else if (engineer.avatarUrl?.length > 0) {
      setAvatarFuntion(engineer.avatarUrl)
    }
  }, [headerLocation, subHeaderLocation])

  async function handleClick({ item, dispatch, disabled }) {
    if (item.name === 'Log out') {
      if (process.env.NODE_ENV === 'production') {
        window.analytics.track('Sign out user')
      }
      handleLogout(jwt, dispatch, axios)
    }
    if (item.name === 'Refresh application data') {
      history.push('/home/refresh')
    }
    if (item.name.includes('+ Add new')) {
      if (organization.provider === 'github') {
        goGithubApp(user.id, false, user.githubCredential)
        return
      } else if (organization.provider === 'gitlab') {
        history.push('/home/onboarding/gitlab/')
      }
    } else {
      const organization = user.organizations.find(
        (org) => org.providerLogin === item.name,
      )

      const slackAuth = isSlackAuth({ user, organization })

      if (organization) {
        // Whenever a user switches organization, we reset the state
        // Then we get the other organization's data
        await dispatch(changesOrg())
        await getOrgAndEngineers({
          organization,
          slackAuth,
          dispatch,
          user,
          axios,
          setIsOnboarded,
          requestRefresh: true,
        })
        // IF we want to stay on the same page we can comment this line:
        history.push('/settings/pull-request-channel')
        await dispatch(toggleLoadingOrg(false))
      } else if (item.name !== 'Refresh application data') {
        console.log('error switching organization')
      }
    }
  }

  let newSubNavigation = { ...subNavigation }
  if (setting.apiaccess) {
    newSubNavigation.settings = [
      ...subNavigation.settings,
      { name: 'API Key', route: 'api' },
    ]
  }

  return (
    <>
      <div className="min-h-full">
        <div className="bg-primary pb-32">
          <Disclosure
            as="nav"
            className="border-b border-hoverPrimary border-opacity-25 bg-primary lg:border-none"
          >
            {({ open }) => (
              <>
                <div className="mx-auto max-w-[1500px] px-2 sm:px-4 lg:px-8">
                  <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-hoverPrimary lg:border-opacity-25">
                    <HeaderMenuDesktop
                      isOnboarded={isOnboarded}
                      activePage={activePage}
                      setActivePage={setActivePage}
                      setActiveSubPage={setActiveSubPage}
                      subNavigation={newSubNavigation}
                    />
                    <div className="flex lg:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-primary p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                    {/* Doc and User dropdow buttno on desktop */}
                    <UserDropdown
                      isOnboarded={isOnboarded}
                      avatar={avatar}
                      handleClick={handleClick}
                      activeSubPage={activeSubPage}
                      userNavigation={userNavigation}
                      provider={organization.provider}
                      user={user}
                    />
                  </div>
                </div>

                <MobileOpenedMenu
                  isOnboarded={isOnboarded}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  setActiveSubPage={setActiveSubPage}
                  handleClick={handleClick}
                  avatar={avatar}
                  userNavigation={userNavigation}
                />
              </>
            )}
          </Disclosure>
          <SubHeaderMenuDesktop
            isOnboarded={isOnboarded}
            activePage={activePage}
            activeSubPage={activeSubPage}
            setActiveSubPage={setActiveSubPage}
            subNavigation={newSubNavigation}
          />
        </div>
      </div>
    </>
  )
}
