import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />
export default function InitialLoadingModal() {
  return (
    <div className="">
      <div className="fixed top-28 left-0 h-[150vh] w-[100vw] z-20 bg-gradient-to-b from-transparent via-gray-300 to-gray-500"></div>
      <div className="absolute m-auto inset-0 max-h-[350px] max-w-[600px] text-center shadow-xl rounded-lg bg-white z-30">
        <div className="flex h-[-webkit-fill-available] justify-center">
          <div className="flex flex-col justify-center p-12 ">
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">Loading</h2>
            <p className="mt-2 text-center text-sm text-grey-600">
              Please wait while we query your information.
            </p>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex items-center justify-center">
              <Spin className="mt-4 mb-14" indicator={antIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
