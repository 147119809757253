import React, { useState, useRef, useEffect } from 'react'
import { message } from 'antd'
import { Select } from 'antd'
import { Switch, Popover } from 'antd'
import 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { User } from '../../reducers/userReducer'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { useAxiosWithHeader } from '../../utils'
import { cloneDeep } from 'lodash'
import momentTimezone from 'moment-timezone'
import PaywallModal from '../PaywallModal'
import { updateTimeZoneOffset } from './organization-timezone'
import ChannelTitle from './channelTitles'
import { LockClosedIcon } from '@heroicons/react/solid'
// import { LabelManager } from './labelManager'
import {
  MaxEngineersInTeamReviewToInviteSetting,
  MinimumReviewersToBeMergeable,
} from './max-team-reviewers'
import WhiteListBot from './whiteListBot'
import ReminderSetup from '../../components/ReminderSetup'
import CodeSnippets from './CodeSnippets'
import { AutoAssignReviewerSetting } from './utils'
// import { useEffectOnce } from 'react-use'
// import { fetchPullRequestsAndUpdateReducer } from '../../pages/OpenChannels/fetchPullRequest'

const { Option } = Select

const delay = 3
let timeout

export const H2Settings = ({ content }) => {
  return <h2 className="text-xl font-semibold text-gray-900 ">{content}</h2>
}

export const Settings = ({ isSelectMembersReady }) => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { plan, setting } = organization
  const orgIsPremium =
    plan === 'professional' ||
    plan === 'trial' ||
    plan === 'business' ||
    plan === 'enterprise'
  const user = useSelector(User.selectors.selectUser)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const [isModalPaywallVisible, setIsModalPaywallVisible] = useState(false)
  const isMountedRef = useRef(true)

  if (organization.timeZone === null || organization.timeZone === undefined) {
    const timeZone = momentTimezone.tz.guess()
    updateTimeZoneOffset(organization, axios, timeZone)
    dispatch(Organization.actions.setTimeZone(momentTimezone.tz.guess()))
  }

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  // TO DO we need this here ?
  // useEffectOnce(() => {
  //   if (!(organization.pullRequest?.length > 0)) {
  //     fetchPullRequestsAndUpdateReducer({
  //       organization,
  //       user,
  //       axios,
  //       dispatch,
  //       Organization,
  //       isMountedRef,
  //     })
  //   }
  // })

  const CiCdSettings = () => {
    return (
      <div className="flex justify-between">
        <p className="flex">
          Receive a CI/CD summary when{' '}
          {organization.provider === 'gitlab'
            ? 'merge request pipelines'
            : 'all  pull request checks'}{' '}
          are completed.
          <span className="flex self-center pl-3">
            <Popover
              content={
                organization.provider === 'gitlab' ? (
                  <img
                    className="w-64"
                    src="https://axolo.s3.eu-west-3.amazonaws.com/communication/docs/cicd_pipeline.PNG"
                    alt="Axolo GitLab Pipeline summary"
                  />
                ) : (
                  <img
                    className="h-48"
                    src="https://axolo.s3.eu-west-3.amazonaws.com/communication/docs/all_checks.png"
                    alt="Axolo checks summary"
                  />
                )
              }
              title={`${
                organization.provider === 'gitlab' ? 'Pipeline' : 'Checks'
              } summary`}
            >
              <InfoCircleTwoTone twoToneColor="#0049ff" />
            </Popover>
          </span>
        </p>
        <Switch
          checked={setting?.sendNotificationForCheckRuns}
          onClick={() => toggleNotificationForCheckRuns(organization)}
        />
      </div>
    )
  }

  async function toggleAutoArchiveSlackChannel() {
    if (orgIsPremium) {
      try {
        axios.get(
          `/organizations/updateSettings/${organization.id}?setting=autoArchiveSlackChannel`,
        )
      } catch (error) {
        if (error?.response?.status === 402) {
          setIsModalPaywallVisible(true)
          return
        }
      }
      let newSettings = cloneDeep(setting)
      newSettings.autoArchiveSlackChannel = !newSettings.autoArchiveSlackChannel
      dispatch(Organization.actions.setData({ setting: newSettings }))
    } else {
      setIsModalPaywallVisible(true)
    }
    window.analytics.track('toggleAutoArchiveSlackChannel')
  }

  async function toggleNotificationForCheckRuns() {
    try {
      axios.get(
        `/organizations/updateSettings/${organization.id}?setting=sendNotificationForCheckRuns`,
      )
    } catch (error) {
      if (error?.response?.status === 402) {
        setIsModalPaywallVisible(true)
        return
      }
    }
    let newSettings = cloneDeep(setting)
    newSettings.sendNotificationForCheckRuns = !newSettings.sendNotificationForCheckRuns
    dispatch(Organization.actions.setData({ setting: newSettings }))
  }

  async function updateCompanySetting(key, value, action = {}, HasTimeout = false) {
    const hide = message.loading('Loading...', 0)
    try {
      const resp = await axios.put(`/settings/${setting._id}`, {
        [key]: value,
        action,
      })
      const newSetting = resp.data
      if (HasTimeout) {
        if (timeout !== null) {
          clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
          dispatch(Organization.actions.setData({ setting: newSetting }))
          message.success(`Your setting has been updated.`)
        }, delay * 1000)
      } else {
        dispatch(Organization.actions.setData({ setting: newSetting }))
      }
      hide()
      return 200
    } catch (error) {
      message.warning('Error updating your setting. Try again or contact support.')
      hide()
      return error
    }
  }

  const SpecialWorkflow = () => {
    return (
      <>
        <H2Settings content="Special Workflows"></H2Settings>
        <div className="ml-10">
          <div className="relative" style={{ width: '100%' }}>
            <div className="mb-2 flex justify-between">
              <p className="">
                As a reviewer, leave a PR channel automatically when I approve a {pull}{' '}
                request.
              </p>
              <Switch
                disabled={!orgIsPremium}
                checked={setting?.leaveWhenApproved}
                onClick={() => {
                  if (orgIsPremium) {
                    updateCompanySetting('leaveWhenApproved', !setting?.leaveWhenApproved)
                  }
                }}
              />
            </div>
            {!orgIsPremium && (
              <div
                className="absolute bottom-0 left-0 right-0 top-0"
                onClick={() => {
                  setIsModalPaywallVisible(true)
                }}
                style={{ cursor: 'pointer', backgroundColor: 'transparent' }}
              />
            )}
          </div>
          <div className="mb-8 flex justify-between">
            <p className="flex">
              Automatically archive channels post-merge or closure at a predefined
              interval.
              <span className="flex self-center pl-3">
                <Popover
                  content={
                    <p>
                      Set a delay for archiving Slack channels following a merge or
                      closure.
                    </p>
                  }
                  title="Delayed Channel Archiver"
                >
                  <InfoCircleTwoTone twoToneColor="#0049ff" />
                </Popover>
              </span>
            </p>

            <Select
              // disabled={!orgIsPremium}
              defaultValue="0"
              value={setting?.archiveChannelDelay?.toString()}
              style={{ width: 120 }}
              onClick={() => {
                if (!orgIsPremium) {
                  setIsModalPaywallVisible(true)
                }
              }}
              onChange={(value) => {
                if (orgIsPremium) {
                  updateCompanySetting('archiveChannelDelay', value)
                }
              }}
            >
              <Option value="0">Immediately</Option>
              <Option value="60">1 hour</Option>
              <Option value="120">2 hours</Option>
              <Option value="300">5 hours</Option>
              <Option value="720">12 hours</Option>
              <Option value="1440">1 day</Option>
              <Option value="2880">2 days</Option>
              <Option value="10080">1 week</Option>
            </Select>
          </div>
        </div>
      </>
    )
  }

  const Beta = () => {
    return (
      <>
        <H2Settings content="Beta"></H2Settings>
        <AutoAssignReviewerSetting
          orgIsPremium={true}
          setting={setting}
          updateCompanySetting={updateCompanySetting}
          setIsModalPaywallVisible={setIsModalPaywallVisible}
          axios={axios}
          dispatch={dispatch}
        />
      </>
    )
  }
  const pull = organization.provider === 'github' ? 'pull' : 'merge'
  const AutoArchiveSlackChannel = ({ organization, orgIsPremium }) => (
    <>
      <H2Settings
        content={`${
          organization.provider === 'github' ? 'Pull' : 'Merge'
        } request channels`}
      ></H2Settings>
      <div className="ml-10">
        <div className="mb-8 flex justify-between">
          <p className="flex">
            Auto archive Slack channel if the associated {pull} request is merged or
            closed.
            <span className="flex self-center pl-3">
              <Popover
                content={
                  <p>
                    If 'Auto Channel Archiver' is on, every {pull} request that your team
                    will close or merge <br />
                    will automatically archive the corresponding channel in Slack.
                  </p>
                }
                title="Auto Channel Archiver"
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </span>
          </p>
          <div className="relative" style={{ width: 'fit-content' }}>
            <Switch disabled={!orgIsPremium} checked={setting?.autoArchiveSlackChannel} />
            <div
              className="absolute bottom-0 left-0 right-0 top-0"
              onClick={() => toggleAutoArchiveSlackChannel(organization)}
              style={{ cursor: 'pointer', backgroundColor: 'transparent' }}
            />
          </div>
        </div>
      </div>

      <div className="mb-8 ml-10">
        <CiCdSettings />
        {organization.provider === 'github' && (
          <MaxEngineersInTeamReviewToInviteSetting
            updateCompanySetting={updateCompanySetting}
          />
        )}
        <div className="relative mt-8 flex justify-between" style={{ width: '100%' }}>
          <p className="flex ">
            <span className="mb-0 flex">
              Create <LockClosedIcon className="ml-2 mr-1 w-4" />
              private {pull} requests channels instead of public.
            </span>
            <span className="flex self-center pl-3">
              <Popover
                content={
                  <ul className="w-[550px] list-inside list-disc">
                    <span className="font-medium">Pros of private channels:</span>{' '}
                    <li>No {pull} request channels cluttering your history.</li>
                    <li className="mb-2">
                      {pull === 'pull' ? 'Pull' : 'Merge'} requests stay private between
                      creator, assignees and reviewers.
                    </li>
                    <span className="font-medium ">Cons of private channels:</span>{' '}
                    <li>
                      You cannot access Slack channels once merged (but everything stays
                      in GitHub).
                    </li>
                    <li>
                      The rest of the team cannot jump into the conversation without being
                      invited.{' '}
                    </li>
                  </ul>
                }
                title={`Private ${pull} request channels`}
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </span>
          </p>

          <Switch
            checked={setting?.privateChannelForPr}
            disabled={!orgIsPremium}
            onClick={() => {
              if (orgIsPremium) {
                updateCompanySetting('privateChannelForPr', !setting?.privateChannelForPr)
              }
            }}
          />
          {!orgIsPremium && (
            <div
              className="absolute bottom-0 left-0 right-0 top-0"
              onClick={() => {
                setIsModalPaywallVisible(true)
              }}
              style={{ cursor: 'pointer', backgroundColor: 'transparent' }}
            />
          )}
        </div>

        <MinimumReviewersToBeMergeable updateCompanySetting={updateCompanySetting} />
        <ChannelTitle organization={organization} setting={setting} />
        <WhiteListBot
          organization={organization}
          setting={setting}
          updateCompanySetting={updateCompanySetting}
        />
        {organization.provider === 'github' && (
          <CodeSnippets
            organization={organization}
            setting={setting}
            updateCompanySetting={updateCompanySetting}
          />
        )}
      </div>
    </>
  )

  return (
    <div className="">
      <ReminderSetup isAdmin={true} />

      <AutoArchiveSlackChannel organization={organization} orgIsPremium={orgIsPremium} />
      <>
        <SpecialWorkflow />
        {organization.provider === 'gitlab' && <Beta />}

        {/* <LabelManager
            setIsModalPaywallVisible={setIsModalPaywallVisible}
            updateCompanySetting={updateCompanySetting}
          /> */}
      </>
      <br />
      {isModalPaywallVisible ? (
        <PaywallModal
          isModalPaywallVisible={isModalPaywallVisible}
          setIsModalPaywallVisible={setIsModalPaywallVisible}
        />
      ) : null}
    </div>
  )
}
