export const classCell = ' px-3 py-4 text-sm text-gray-500'

export const classMessageColumn =
  'max-w-[300px] w-[300px] py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6'

export const classStatusColumn =
  'max-w-[275px] w-[275px] px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500'

export const classCreatorColumn =
  'max-w-[250px] w-[250px] px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500'

export const classActionColumn =
  'max-w-[167px] w-[167px] whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6'

export const PRStatusBgColor = {
  draft: 'bg-gray-200',
  wip: 'bg-orange-200',
  reviewable: 'bg-indigo-200',
  mergeable: 'bg-lime-200',
}

export const PRStatusTextColor = {
  draft: 'text-gray-500',
  wip: 'text-orange-500',
  reviewable: 'text-indigo-500',
  mergeable: 'text-lime-500',
}

export const allPRStatus = ['draft', 'wip', 'mergeable', 'reviewable']
