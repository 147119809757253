import { Tooltip } from 'antd'
import { classNames } from '../../utils'
import { handleErrorReviewersTag } from './reminders-helper-PR-status'

const textVariablesForReminder = [
  { tooltipMessage: "'Hey author' will become 'Hey Alex'", text: '[author]' },
  {
    tooltipMessage: "'Hey reviewers' will become 'Hey Alex, Sarah and John'",
    text: '[reviewers]',
  },
  {
    tooltipMessage:
      "'has been opened for duration' will become 'has been opened for 38 hours'",
    text: '[duration]',
  },
]

function handleOnClickTagVariable({
  reminderVariable,
  newMessage,
  setNewMessage,
  newStatus,
}) {
  console.log('newStatus', newStatus, reminderVariable)

  // // we only accept the [reviewers] tag if we have no status or only the reviewable status
  // if (
  //   !(
  //     (newStatus.length === 1 && newStatus[0] === 'reviewable') ||
  //     newStatus.length === 0
  //   ) &&
  //   reminderVariable.text === '[reviewers]'
  // ) {
  //   handleErrorReviewersTag()
  //   return
  // }

  if (
    reminderVariable.text !== '[reviewers]' ||
    newStatus.length === 0 ||
    (newStatus.length === 1 && newStatus[0] === 'reviewable')
  ) {
    setNewMessage(`${newMessage}${reminderVariable.text}`)
  } else handleErrorReviewersTag()
}

const color = '#0049ff'

const TagVariable = ({ reminderVariable, newMessage, setNewMessage, newStatus }) => {
  const desactivated = newMessage?.includes(reminderVariable?.text)
  return (
    <Tooltip
      placement="bottom"
      title={`${desactivated ? `${reminderVariable?.text} is already inserted.` : ''}${
        reminderVariable?.tooltipMessage
      }`}
      color={color}
    >
      <span
        className={classNames(
          'inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-primary hover:cursor-pointer hover:opacity-90',
          desactivated && 'opacity-50 hover:cursor-not-allowed hover:opacity-50',
        )}
        onClick={() =>
          !desactivated &&
          handleOnClickTagVariable({
            reminderVariable,
            setNewMessage,
            newMessage,
            newStatus,
          })
        }
      >
        + {reminderVariable?.text?.replace('[', '')?.replace(']', '')?.toLowerCase()}
      </span>
    </Tooltip>
  )
}

export const ReminderVariableSelector = ({ newMessage, setNewMessage, newStatus }) => {
  return (
    <div className="absolute mt-2">
      Add variables to your personal reminders:
      <div className="flex flex-wrap gap-2 pt-2">
        {textVariablesForReminder.map((reminderVariable, index) => {
          return (
            <TagVariable
              key={index}
              reminderVariable={reminderVariable}
              setNewMessage={setNewMessage}
              newMessage={newMessage}
              newStatus={newStatus}
            />
          )
        })}
      </div>
    </div>
  )
}
