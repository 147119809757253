import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// error with Enterprise if we show too many Slack users
const MAX_ADMIN_TO_SHOW_IN_SEARCH = 20

export default function AdminSlackSelect({ newUser, setNewUser }) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const [searchTerm, setSearchTerm] = useState('')
  const { slackMembers, users } = organization

  const adminEmails = users
    .filter((item) => {
      return item?.role && item?.role?.name === 'Authenticated'
    })
    .map((admin) => admin?.email)

  const selectableUsers = (Array.isArray(slackMembers) ? slackMembers : []).filter(
    (member) => {
      return !adminEmails.includes(member?.profile?.email)
    },
  )

  const filteredOptions = searchTerm
    ? selectableUsers.filter((item) =>
        item.real_name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : selectableUsers

  return (
    <Listbox value={newUser} onChange={setNewUser}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="flex items-center">
                <img
                  src={newUser?.profile?.image_48}
                  alt=""
                  className="h-6 w-6 flex-shrink-0 rounded-full"
                />
                <span className="ml-3 block truncate">{newUser?.real_name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className="relative">
                  <div className="sticky top-0 z-20  px-1">
                    <div className="mt-1 block  items-center">
                      <input
                        type="text"
                        name="search"
                        id="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="block w-full rounded-md border border-gray-300 bg-gray-50 px-2 py-2 pr-12 font-bold shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5 ">
                        {searchTerm && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-1 h-6 w-6 font-bold text-blue-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            onClick={() => {
                              setSearchTerm('')
                            }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-1 h-6 w-6 text-gray-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                {filteredOptions.slice(0, MAX_ADMIN_TO_SHOW_IN_SEARCH).map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={person}
                  >
                    {({ newUser, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={person.profile.image_48}
                            alt=""
                            className="h-6 w-6 flex-shrink-0 rounded-full"
                          />
                          <span
                            className={classNames(
                              newUser ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate',
                            )}
                          >
                            {person.real_name}
                          </span>
                        </div>

                        {newUser ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
