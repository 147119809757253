export const tiers = [
  {
    name: 'Free',
    href: 'https://api.axolo.co/identify/slack',
    priceMonthly: 'Free',
    priceYearly: 'Free',
    description: 'Perfect to get you started and improve your code review workflow.',
    includedFeatures: [
      'Up to 50 pull request channels per month',
      'A channel for each pull request',
      'Daily pull request reminders',
      'Deployment & PR checks notifications',
      'Stand-up notifications',
      // 'Weekly analytics for last week pull requests',
    ],
    buttonText: 'Sign up for free',
  },
  {
    name: 'Standard',
    href: 'https://api.axolo.co/identify/slack',
    priceMonthly: 10,
    priceYearly: 8.3,
    description:
      'Perfect to take your team up to speed and improve your code review experience.',
    includedFeatures: [
      'Unlimited pull request channels',
      'A channel for each pull request',
      'Daily pull request reminders',
      'Deployment & PR checks notifications',
      'Stand-up notifications',
      'Code review timeslots',
      'Automatic channel archiver',
      'Private pull request channels',
    ],
    buttonText: 'Start 2-week trial',
  },
  {
    name: 'Business',
    href: 'https://api.axolo.co/identify/slack',
    priceMonthly: 16,
    priceYearly: 13.3,
    description: 'Perfect for multi-pillar organizations looking for custom workflows.',
    includedFeatures: [
      'Unlimited pull request channels',
      'A channel for each pull request',
      'Daily pull request reminders',
      'Deployment & PR checks notifications',
      'Stand-up notifications',
      'Code review timeslots',
      'Automatic channel archiver',
      'Private pull request channels',
      'Priority support',
      'Account manager for security & compliance',
      'Sign a data processing agreement (DPA)',
      'Multiple team-specific channels',
      'Multiple GitLab Groups',
      'Workflow & custom automations',
    ],
    buttonText: 'Start 2-week trial',
  },
  {
    name: 'Enterprise',
    href: 'https://cal.com/arthurc/30min',
    priceMonthly: 'Contact us',
    priceYearly: 'Contact us',
    description: 'Enterprise-level services, custom integrations, and dedicated support.',
    includedFeatures: [
      'Minimum billing for 50 seats after your trial ends',
      'A channel for each pull request',
      'Daily pull request reminders',
      'Deployment & PR checks notifications',
      'Stand-up notifications',
      'Code review timeslots',
      'Automatic channel archiver',
      'Private pull request channels',
      'Priority support',
      'Account manager for security & compliance',
      'Sign a data processing agreement (DPA)',
      'Multiple team-specific channels',
      'Multiple GitLab Groups',
      'Workflow & custom automations',
      'GitHub Enterprise Server support',
      'User provisioning & deprovisioning',
      'Dedicated IP address',
      'Dedicated proxy server',
      'Account consolidation & billing',
      'Custom onboarding',
      'Security questionnaires & Pen test report',
    ],
    buttonText: 'Book a demo',
  },
]
