export const IntegrationButton = ({ imageSrc, text }) => {
  return (
    <div className="cursor-pointer w-36">
      <button
        className="h-24 w-24 cursor-pointer shadow-md bg-white rounded-xl border-gray-100 hover:bg-gray-50
      focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
      >
        <div className="flex h-16 justify-center ">
          <img className="" alt={text} src={imageSrc} />
        </div>
      </button>
      <p className="pt-2 text-gray-900">{text}</p>
    </div>
  )
}
