import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Organization } from '../../reducers/organizationReducer'
import { useSelector } from 'react-redux'
import { Popover } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'

const values = [
  { id: 1, value: '1' },
  { id: 2, value: '2' },
  { id: 3, value: '3' },
  { id: 4, value: '4' },
  { id: 5, value: '5' },
  { id: 6, value: '6' },
  { id: 7, value: '7' },
  { id: 8, value: '8' },
  { id: 9, value: '9' },
  { id: 10, value: '10' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

async function onNumberSelect(element, setSelected, updateCompanySetting, settingKey) {
  await updateCompanySetting(settingKey, element.value)
  setSelected(element)
}
const SelectNumber = ({
  updateCompanySetting,
  settingKey = 'maxReviewersInTeamToInvite',
}) => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const initialElement = values.find(
    (val) => val.value === setting?.[settingKey]?.toString(),
  )
  const [selected, setSelected] = useState(initialElement)

  return (
    <Listbox
      value={selected}
      onChange={(value) =>
        onNumberSelect(value, setSelected, updateCompanySetting, settingKey)
      }
    >
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm">
              <span className="block truncate">{selected?.value}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="w-30 absolute right-0 z-10 mt-1 max-h-60 overflow-auto rounded-md
              bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {values.map((element) => (
                  <Listbox.Option
                    key={element.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-hoverPrimary text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-8 pr-4',
                      )
                    }
                    value={element}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {element.value}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-hoverPrimary',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export const MaxEngineersInTeamReviewToInviteSetting = ({ updateCompanySetting }) => {
  return (
    <div className="mt-6 flex justify-between">
      <p className="mb-0 flex self-center">
        Set the maximum number of reviewers to invite in PR channels when you request a
        team as reviewer.
        <span className="flex self-center pl-3">
          <Popover
            content={
              <p className="w-96">
                We recommend keeping this number low (~3). Inviting too many people in
                channels creates unnecessary notifications and has a negative impact on
                your team mental load and productivity.{' '}
              </p>
            }
          >
            <InfoCircleTwoTone twoToneColor="#0049ff" />
          </Popover>
        </span>
      </p>
      <SelectNumber
        updateCompanySetting={updateCompanySetting}
        settingKey="maxReviewersInTeamToInvite"
      />
    </div>
  )
}

export const MinimumReviewersToBeMergeable = ({ updateCompanySetting, provider }) => {
  return (
    <div className="mt-6 flex justify-between">
      <p className="mb-0 flex self-center">
        Set the required number of approvals before Axolo considers a{' '}
        {provider === 'github' ? 'pull' : 'merge'} request mergeable.
      </p>
      <SelectNumber
        updateCompanySetting={updateCompanySetting}
        settingKey="minimumNumberOfReviews"
      />
    </div>
  )
}
