import React, { useState } from 'react'
import { Modal, Button, Tooltip } from 'antd'

// NotifyModal Component
const NotifyModal = ({ isVisible, onConfirm, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleYesClick = async () => {
    setIsLoading(true) // Start loading
    await onConfirm(true) // Assuming onConfirm returns a promise
    setIsLoading(false) // End loading
  }

  return (
    <Modal
      title={
        <span className="text-lg font-semibold">
          Ask your developers to complete their onboarding
        </span>
      }
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="back"
          onClick={() => onConfirm(false)}
          className="bg-gray-300 hover:bg-gray-400"
        >
          No, I'll tell them personally
        </Button>,
        <Button key="submit" type="primary" onClick={handleYesClick} loading={isLoading}>
          Yes, notify them
        </Button>,
      ]}
      className="rounded-lg"
    >
      <div className="space-y-4">
        <p className="text-sm">
          Developers need to complete their onboarding to ensure the best Axolo experience
          possible.
        </p>
        <p className="text-sm">
          If you accept to ping them now, they will receive this invitation:
        </p>
        <img
          src={'/welcomeonboardingMSG.png'}
          alt="Welcome Onboarding Message"
          className="h-auto max-w-full rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
        />
        <br />
        Also, we recommend you to read and send them{' '}
        <a
          href="https://axolo.co/docs/makethebest/best-of-axolo"
          target="_blank"
          rel="noreferrer"
        >
          How to make the best of Axolo
        </a>
      </div>
    </Modal>
  )
}

// NextButtonWithModal Component
const NextButtonWithModal = ({ canContinue, onNotifyConfirm }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleButtonClick = () => {
    setIsModalVisible(true)
  }

  const handleModalConfirm = (notify) => {
    setIsModalVisible(false)
    onNotifyConfirm(notify)
  }

  return (
    <>
      <Tooltip
        title={
          canContinue
            ? null
            : "You need at least two developers to review each other's code!"
        }
      >
        <div className="float-right flex w-fit justify-end">
          <button
            className="m-1 rounded-sm bg-primary px-2 py-1 text-textWhite hover:bg-hoverPrimary hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50"
            disabled={!canContinue}
            onClick={handleButtonClick}
          >
            Next
          </button>
        </div>
      </Tooltip>
      <NotifyModal
        isVisible={isModalVisible}
        onConfirm={handleModalConfirm}
        onCancel={() => setIsModalVisible(false)}
      />
    </>
  )
}

export default NextButtonWithModal
