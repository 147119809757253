import { Row } from 'antd'

export function AppContent({ children, extraContent }) {
  return (
    <Row>
      <div style={{ flex: 1 }}>{children}</div>
      <div className="image">{extraContent}</div>
    </Row>
  )
}
