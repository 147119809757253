import { Button } from 'antd'
import HomeFooter from '../FooterHomepage/footerHomePage'
import { User } from '../../reducers/userReducer'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import LoaderIcon from '../../components/LoaderIcon'
import { handleLogout } from '../../components/logoutButton/handleLogout'
import { useAxiosWithHeader } from '../../utils'

export default function LoadingPage({ footer = true, title }) {
  const user = useSelector(User.selectors.selectUser)
  const dispatch = useDispatch()
  const axios = useAxiosWithHeader()

  return (
    <div className=" h-screen bg-white ">
      <div className=" flex h-[88vh] flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
          <img
            className="mb-30 mx-auto h-12 w-auto"
            src="/logo_axolo.png"
            alt="Logo Axolo"
          />
          {user.error ? (
            <div className="mt-30 flex flex-col content-center text-center">
              <br></br>
              <h1>There seems to be an error logging you in: "{user.error}"</h1>
              <br></br>
              <Link onClick={() => handleLogout(user.jwt, dispatch, axios)} to={`/`}>
                <Button
                  type="primary"
                  className="border-none bg-primary text-textWhite hover:bg-indigo-700 hover:text-textWhite"
                >
                  Go to the Home page to login again
                </Button>
              </Link>
            </div>
          ) : (
            <>
              <div className="">
                <h2 className="text-ourBlack mt-6 text-center text-3xl font-bold">
                  Loading
                </h2>
                <LoaderIcon title={title} />
              </div>
            </>
          )}
        </div>
      </div>
      {footer && <HomeFooter />}
    </div>
  )
}
