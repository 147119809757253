import { useSelector } from 'react-redux'
import { selectJWT, selectIsAdminUser, User } from './reducers/userReducer'
import { useLocation } from 'react-router'
import Axios from 'axios'
import { URLBACK } from './assets/urls'
import messageInteraction from './components/Analytics/utils/messageInteraction'
import { Organization } from './reducers/organizationReducer'
import updateUserFunction from './pages/GitLabGroups/updateUser'

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export function useIsLogged() {
  const jwt = useSelector(selectJWT)
  return jwt && jwt.length > 0
}

export function useIsAdminUser() {
  const isAdminUser = useSelector(selectIsAdminUser)
  return isAdminUser
}

export function useIsEngineer() {
  const user = useSelector(User.selectors.selectUser)
  return user?.role?.type === 'engineer'
}

export async function sendAxoloSlack({ userId, type, message, axios }) {
  await axios.post(`${URLBACK}organizations/sendToInternalSlack`, {
    userId,
    type,
    message,
  })
}

export function createAxios(jwt) {
  return Axios.create({
    baseURL: URLBACK,
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
    },
  })
}

export const useAxiosWithHeader = (app = 'axolo') => {
  const jwt = useSelector(User.selectors.selectJWT)
  const analyticsJwt = useSelector(User.selectors.selectAnalyticsJWT)
  if (app === 'analytics') return createAxios(analyticsJwt)
  else return createAxios(jwt)
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export async function globalUpdateCompanySetting({
  key,
  value,
  action = {},
  setting,
  axios,
  dispatch,
}) {
  const hide = messageInteraction({ content: 'Loading...', type: 'loading', duration: 0 })
  try {
    const resp = await axios.put(`/settings/${setting.id}`, {
      [key]: value,
      action,
    })
    hide()
    const newSetting = resp.data
    dispatch(Organization.actions.setData({ setting: newSetting }))
    return resp.data
  } catch (e) {
    hide()
    console.log('Error updating settings', e.message, e)
    messageInteraction({
      type: 'error',
      content: 'Error updating your setting. Try again or contact support.',
    })
    return e
  }
}

export async function refreshPlayersFromDatabase({
  organization,
  axios,
  getOrgAndEngineers,
  slackAuth,
  dispatch,
  user,
  setIsOnboarded,
  handleReset,
}) {
  // TODO should refresh engineers first
  await updateUserFunction({ axios, user, dispatch, User })
  const error = []
  if (organization.provider === 'gitlab') {
    const hide = messageInteraction({
      content: 'Refreshing engineers in database...',
      duration: 0,
      type: 'loading',
    })
    try {
      await axios.get(
        `${URLBACK}gitlab/updateGroupMembersTeamsAndProjects/${organization?.id}`,
      )
    } catch (e) {
      error.push(e)
    }
    hide()
  }
  try {
    // todo here only update playerState / players from backend not the whole organization
    await getOrgAndEngineers({
      organization,
      slackAuth,
      dispatch,
      user,
      axios,
      setIsOnboarded,
      requestRefresh: true,
    })
    handleReset()
  } catch (e) {
    error.push(e)
  }
  messageInteraction({
    content: 'Engineers should be up to date.',
    type: 'success',
  })
  if (error.length > 0) {
    console.log('Error refreshing players: ', error.join('\n'))
    messageInteraction({
      type: 'error',
      content: 'Error while refreshing engineers. See logs for more information.',
    })
  }
}

export const statusColors = {
  active: {
    bg: 'green-100',
    text: 'green-800',
  },
  inactive: {
    bg: 'red-100',
    text: 'red-800',
  },
}
