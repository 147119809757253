import messageInteraction from '../../components/Analytics/utils/messageInteraction'

export function isErrorInGithubEnterpriseParameters({
  githubDomain,
  githubAppName,
  githubAppId,
  githubAppSecret,
  githubPrivateKey,
  githubClientId,
}) {
  if (
    !(githubDomain.length > 0) ||
    !(githubAppName.length > 0) ||
    !(githubAppId.length > 0) ||
    !(githubClientId.length > 0) ||
    !(githubAppSecret.length > 0) ||
    !(githubPrivateKey.length > 0)
  ) {
    messageInteraction({
      type: 'error',
      content: 'Please fill all the fields.',
    })
    return true
  }

  // a regex to check if the domain is valid
  const domainRegex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  )
  if (!domainRegex.test(githubDomain)) {
    messageInteraction({
      type: 'error',
      content: 'Please enter a valid domain.',
    })
    return true
  }

  // a regex to check if githubAppId is alphanumeric
  const regexAlphaNumeric = new RegExp('^[a-zA-Z0-9]*$')
  const regexInteger = new RegExp('^[0-9]*$')

  if (!regexInteger.test(githubAppId)) {
    messageInteraction({
      type: 'error',
      content: 'Your GitHub App ID is invalid. It should be an integer.',
    })
    return true
  }
  if (!regexAlphaNumeric.test(githubAppSecret) || githubAppSecret.length !== 40) {
    messageInteraction({
      type: 'error',
      content:
        'Your GitHub Secret is invalid. It should be a 40 character alphanumeric string.',
    })
    return true
  }

  if (githubPrivateKey.slice(0, 31) !== '-----BEGIN RSA PRIVATE KEY-----') {
    messageInteraction({
      type: 'error',
      content:
        'Your GitHub Private Key is invalid. It should start with -----BEGIN RSA PRIVATE KEY-----',
    })
    return true
  }

  if (githubPrivateKey.slice(-29) !== '-----END RSA PRIVATE KEY-----') {
    messageInteraction({
      type: 'error',
      content:
        'Your GitHub Private Key is invalid. It should end with -----END RSA PRIVATE KEY-----',
    })
    return true
  }
  return false
}
