import { StopOutlined } from '@ant-design/icons'
import { Avatar, Button, Select, Tag, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { UserAvatar } from '../../pages/OpenChannels/userAvatar'
import { Organization } from '../../reducers/organizationReducer'
import { AxoloDeactivateIcon, AxoloArchiveIcon } from '../Settings/setting-helper'
import { findTeamNameFromId } from '../TeamChannels/helpers'
import {
  classActionColumn,
  classChannelColumn,
  classCreatorColumn,
  classTeamColumn,
} from './teamChannelTableHelper'

const { Option } = Select

export const TeamChannelTableData = ({
  handleSelectTeamsOnExistingChannel,
  newChannelState,
  validateTeams,
  onDelete,
  channels,
  personal,
}) => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { teams = [] } = organization

  const TeamTags = ({ channel }) => {
    let disable
    const raw = channel
    const teamSelect = []
    const preselected = channel?.teams?.map((id) => {
      const teamName = findTeamNameFromId({ id, teams })
      return teamName
    })

    if (!personal || preselected.includes('All Axolo onboarded users')) {
      const teamNames = channel?.teams?.map((id) => (
        <Tag key={id} className="font-medium" color="blue">
          {findTeamNameFromId({ id, teams })}
        </Tag>
      ))
      return teamNames
    }

    for (let i = 0; i < teams.length; i++) {
      const team = teams[i]
      teamSelect.push(
        <Option key={team.id} value={team.name} id={team.id}>
          {team.name}
        </Option>,
      )
    }

    return (
      <div className="flex">
        <Select
          mode="multiple"
          allowClear
          disabled={disable}
          style={{ width: '100%', minWidth: '250px', borderColor: '#0049ff' }}
          placeholder="Please select a team"
          defaultValue={preselected}
          labelInValue
          onChange={(e) => handleSelectTeamsOnExistingChannel(e, raw)}
          tagRender={(props) => {
            const { label, closable, onClose } = props
            return (
              <Tag
                color="blue"
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3, display: 'flex', alignItems: 'center' }}
              >
                {label}
              </Tag>
            )
          }}
        >
          {teamSelect}
        </Select>
        <Button
          className="ml-3"
          onClick={validateTeams}
          disabled={!newChannelState || newChannelState?.id !== raw?.id}
        >
          Validate
        </Button>
      </div>
    )
  }

  const RepositoriesTag = ({ channel }) => {
    if (!(channel?.repositories?.length > 0)) {
      return <Tag color="green">All repositories</Tag>
    }
    return (
      <div className="mr-2 border p-1">
        {channel?.repositories?.map((repo) => (
          <Tag key={repo} className="font-medium" color="green">
            {repo}
          </Tag>
        ))}
      </div>
    )
  }

  const CellAction = ({ channel }) => {
    if (!personal)
      return (
        <>
          <Tooltip title="You can't take actions on channels settings that are not yours.">
            <Button
              icon={<StopOutlined />}
              shape="circle"
              style={{ color: 'grey' }}
              className="h-8 w-8"
            />
          </Tooltip>
        </>
      )
    if (!channel.desactivate) {
      return (
        <>
          <Tooltip placement="topRight" title="Archive this channel">
            <div
              className="cursor-pointer"
              onClick={() => onDelete({ channelTitle: channel.title })}
            >
              <AxoloArchiveIcon size={8} color={'red'} />
            </div>
          </Tooltip>
          <br></br>
          <Tooltip
            placement="topRight"
            title="Disable Axolo on this channel"
            className="ml-3"
          >
            <div
              className="cursor-pointer"
              onClick={() => onDelete({ channelTitle: channel.title, desactivate: true })}
            >
              <AxoloDeactivateIcon color={'#faad14'} size={8} />
            </div>
          </Tooltip>
        </>
      )
    } else {
      return (
        <>
          <Tooltip title="This channel has been disabled, upgrade to business to reactivate it.">
            <Button
              icon={<StopOutlined />}
              shape="circle"
              style={{ color: 'grey' }}
              className="h-8 w-8"
            />
          </Tooltip>
        </>
      )
    }
  }

  if (channels === null || channels === undefined || channels?.length === 0) {
    return null
  }

  return channels?.map((channel) => (
    <tr key={channel?.id}>
      <td className={classChannelColumn}>{channel?.title}</td>

      {organization?.provider === 'github' && (
        <td className="">
          Channel is based
          {!channel.option || channel.option === 'on team' ? (
            <Tag color="blue" className="!ml-1">
              on team
            </Tag>
          ) : (
            <Tag color="green" className="!ml-1">
              {channel.option}
            </Tag>
          )}
        </td>
      )}
      <td className={classTeamColumn}>
        <RepositoriesTag channel={channel} />
        <TeamTags channel={channel} personal={personal} />
      </td>
      <td className={classCreatorColumn}>
        <div className="flex items-center">
          <UserAvatar record={channel?.creator} />
          <p className="mb-0 pl-2 text-gray-800">{channel?.creator?.providerLogin}</p>
        </div>
      </td>
      <td className={classActionColumn}>
        <span href="#" className="text-indigo-600 hover:text-indigo-900">
          <div className="flex justify-center ">
            <CellAction channel={channel} />
          </div>
        </span>
      </td>
    </tr>
  ))
}
