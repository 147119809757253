import { stripeKey } from '../../assets/stripeKeys'
import { loadStripe } from '@stripe/stripe-js'
const { URLBACK } = require('../../assets/urls')

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeKey)

export const InitCheckoutSession = async ({ priceId, quantity, axios }) => {
  const init = await axios.post(`${URLBACK}create-checkout-session`, {
    priceId,
    quantity,
  })
  const stripe = await stripePromise
  stripe.redirectToCheckout({ sessionId: init.data.sessionId })
  window.analytics.track('InitCheckoutSession', { priceId, quantity })
}

export const RedirectToPortal = async ({ axios }) => {
  const resp = await axios.get(`${URLBACK}stripe/customerPortal`)
  window.location.href = resp.data.url
  window.analytics.track('redirectToPortal')
}
