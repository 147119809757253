import { useState } from 'react'
import OnboardingSteps from '../../components/OnboardingSteps'
import { useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { isEqual } from 'lodash'
import { Button, Typography } from 'antd'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'
import { goGitlabApp } from '../../components/Helpers'
import { useHistory, useLocation } from 'react-router'

export default function SelfHostedGitlabOnboarding() {
  const location = useLocation()
  const history = useHistory()
  const user = useSelector(User.selectors.selectUser, isEqual)
  const [gitlabDomain, setGitlabDomain] = useState(
    user?.gitlabCredential?.gitlabHost || '',
  )
  const [gitlabAppId, setGitlabAppId] = useState(
    user?.gitlabCredential?.gitlabAppId || '',
  )
  const [gitlabAppSecret, setGitlabAppSecret] = useState('')
  const [proxy, setProxy] = useState(false)

  async function installAxoloApp() {
    if (
      !(gitlabDomain.length > 0) ||
      !(gitlabAppId.length > 0) ||
      !(gitlabAppSecret.length > 0)
    ) {
      messageInteraction({
        type: 'error',
        content: 'Please fill all the fields.',
      })
      return
    }

    // a regex to check if the domain is valid
    const regex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    )
    if (!regex.test(gitlabDomain)) {
      messageInteraction({
        type: 'error',
        content: 'Please enter a valid domain.',
      })
      return
    }

    // a regex to check if gitlabAppId is alphanumeric
    const regexAlphaNumeric = new RegExp('^[a-zA-Z0-9-]*$')

    if (!regexAlphaNumeric.test(gitlabAppId) || gitlabAppId.length !== 64) {
      messageInteraction({
        type: 'error',
        content:
          'Your GitLab App ID is invalid. It should be a 64 character alphanumeric string.',
      })
      return
    }
    if (!regexAlphaNumeric.test(gitlabAppSecret)) {
      messageInteraction({
        type: 'error',
        content:
          'Your GitLab Secret is invalid. It should be a 64 character alphanumeric string.',
      })
      return
    }

    if (gitlabAppId === gitlabAppSecret) {
      messageInteraction({
        type: 'error',
        content: 'Your GitLab App ID and Secret should not be the same.',
      })
      return
    }

    let domain = gitlabDomain
    if (domain[domain.length - 1] === '/') {
      domain = domain.slice(0, -1)
    }
    const selfHostedParams = {
      gitlabDomain: domain,
      gitlabAppId,
      gitlabAppSecret,
      proxy,
    }
    goGitlabApp({
      userId: user.id,
      authorized: user.providerAuth,
      selfHostedParams,
    })
  }

  return (
    <MainDivOnboarding pathname={location.pathname}>
      <OnboardingSteps />
      <br></br>
      <div className="justify-center">
        <Typography.Title level={4} className="text-center">
          Self-hosted GitLab onboarding
        </Typography.Title>
        <div className="pt-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Axolo app creation
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Please{' '}
              <a
                href="https://axolo.co/docs/gettingstarted/installation-gitlab-self-hosted"
                target="_blank"
                rel="nofollow noreferrer"
                className="text-primary"
              >
                follow the instructions
              </a>{' '}
              and fill the required information to create the Axolo app on your GitLab
              instance.
            </p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium text-gray-700"
              >
                GitLab Domain Address
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="gitlab-domain-address"
                  id="gitlab-domain-address"
                  autoComplete="gitlab-domain-address"
                  className="block h-6  w-full rounded-md border border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder='e.g. "https://gitlab.apple.com"'
                  onChange={(event) => setGitlabDomain(event.target.value)}
                  value={gitlabDomain}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="gitlab-app-id"
                className="block text-sm font-medium text-gray-700"
              >
                GitLab App ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="gitlab-app-id"
                  id="gitlab-app-id"
                  autoComplete="given-name"
                  className="block h-6 w-full rounded-md border border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(event) => setGitlabAppId(event.target.value)}
                  value={gitlabAppId}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                GitLab App Secret
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="block h-6 w-full rounded-md border border-gray-300 pl-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(event) => setGitlabAppSecret(event.target.value)}
                />
              </div>
            </div>
            <div className="mt-4 sm:col-span-6">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-indigo-500"
                  // We now have DO for fixed ip addresses so no need for proxy
                  // onChange={(event) => setProxy(event.target.checked)}
                />
                <span className="ml-2 text-sm text-gray-700">
                  Check this box if your GitLab instance is behind a VPN and needs to
                  whitelist Axolo server IP addresses, these are the two addresses you
                  need to whitelist: <strong>64.23.230.50</strong> and{' '}
                  <strong>64.23.230.204</strong>.
                </span>
              </label>
            </div>
          </div>
          <div className="w-50 flex justify-center pt-10">
            <Button
              type="primary"
              className="w-40 !rounded-lg"
              onClick={() => installAxoloApp()}
            >
              Install Axolo
            </Button>
          </div>
        </div>
        <div
          className="flex justify-center pt-4 text-center"
          onClick={() => history.push('/home/onboarding-host/gitlab')}
        >
          <button className="pb-6 text-center italic underline">Go back</button>
        </div>
      </div>
    </MainDivOnboarding>
  )
}
