import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlayerState } from '../../reducers/playersReducer'
import DropZoneTeamManagementTableColumn from './dropZoneTeamManagementTableColumn'
import DropZoneTeamManagementTableContent from './dropZoneTeamManagementTableContent'
import { onboardEngineer } from '../TeamManagementTable/utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { useHistory } from 'react-router'

async function onboardAllEngineers({
  selectedEngineers,
  slackMembers,
  dispatch,
  axios,
  history,
  organization,
}) {
  const hide = messageInteraction({
    type: 'loading',
    content: `Onboarding ${selectedEngineers.length} engineers...`,
    duration: 0,
  })
  for (let i = 0; i < selectedEngineers.length; i += 1) {
    const engineer = selectedEngineers[i]
    try {
      if (engineer.communicationToolId?.length > 0) continue
      const slackInfo = slackMembers?.find(
        (member) => member.profile.email === engineer.gitEmail,
      )
      await onboardEngineer({
        engineer,
        dispatch,
        axios,
        thisSlackMember: slackInfo,
        organization,
      })
      if ((i + 1) % 10 === 0) {
        messageInteraction({
          type: 'success',
          content: `${i + 1} engineers onboarded. Still processing...`,
          duration: 2,
        })
      }
    } catch (e) {
      console.log('Error onboarding engineer: ', e)
      messageInteraction({
        error: 'error',
        content: `Error onboarding ${engineer.providerLogin}.`,
      })
    }
  }
  messageInteraction({
    type: 'success',
    content: `Batch import completed`,
    duration: 3,
  })
  history.push('/team/team-management')
  hide()
}

export default function DropZoneTeamManagementTable() {
  const [selectedTeamIds, setSelectedTeamIds] = useState([])
  const organization = useSelector(Organization.selectors.getOrganization)
  const { slackMembers, csvData } = organization
  const players = useSelector(PlayerState.selectors.getPlayers)
  const [visibleActiveEngineers, setVisibleActiveEngineers] = useState([])
  const [visibleInactiveEngineers, setVisibleInactiveEngineers] = useState([])
  const [selectedEngineers, setSelectedEngineers] = useState(
    visibleActiveEngineers.concat(visibleInactiveEngineers),
  )
  const [notFoundSlackEngineers, setNotFoundSlackEngineers] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const axios = useAxiosWithHeader()
  const [notFoundEmails, setNotFoundEmails] = useState([])
  const [foundEngineersActive, setFoundEngineersActive] = useState([])
  const [foundEngineersInactive, setFoundEngineersInactive] = useState([])

  useEffect(() => {
    const cleanEmails = csvData.filter(
      (email) => email !== null && email !== undefined && email !== '',
    )

    const foundEngineersInactive = []
    const foundEngineersActive = []
    const emailNotFound = []
    for (const email of cleanEmails) {
      const cleanEmail = email.trim()
      const foundPlayer = players.find((p) => p.gitEmail === cleanEmail)
      const foundSlack = slackMembers.find((s) => s.profile.email === cleanEmail)
      if (foundPlayer && foundSlack) {
        if (foundPlayer?.communicationToolId?.length > 0) {
          foundEngineersActive.push(foundPlayer)
        } else {
          foundEngineersInactive.push(foundPlayer)
        }
      } else {
        emailNotFound.push(cleanEmail)
      }
    }
    setNotFoundEmails(emailNotFound)
    setFoundEngineersActive(foundEngineersActive)
    setFoundEngineersInactive(foundEngineersInactive)
  }, [slackMembers, csvData])

  return (
    <div className="">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <DropZoneTeamManagementTableColumn
                  selectedTeamIds={selectedTeamIds}
                  setSelectedTeamIds={setSelectedTeamIds}
                />
                <DropZoneTeamManagementTableContent
                  visibleToOnboard={foundEngineersInactive}
                  alreadyOnboardedEngineers={foundEngineersActive}
                  setSelectedEngineers={setSelectedEngineers}
                  selectedEngineers={selectedEngineers}
                  notFoundSlackEngineers={notFoundSlackEngineers}
                  notFoundEmails={notFoundEmails}
                />
              </table>
            </div>
            <div className="flex flex-row justify-between">
              <button
                type="button"
                className="float-right mt-4 inline-flex items-center justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 sm:w-auto"
                onClick={() => dispatch(Organization.actions.setData({ csvData: '' }))}
              >
                Reset CSV import
              </button>
              {selectedEngineers.length > 0 ? (
                <button
                  type="button"
                  className="float-right mt-4 inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-600 sm:w-auto"
                  onClick={() =>
                    onboardAllEngineers({
                      selectedEngineers,
                      slackMembers,
                      dispatch,
                      axios,
                      history,
                      organization,
                    })
                  }
                >
                  Onboard {selectedEngineers.length} engineer
                  {selectedEngineers.length > 1 ? 's' : ''}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
