import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import TimeSelector from './TimeSelector'
import 'tailwindcss/tailwind.css' // Ensure Tailwind CSS is imported
import SlackMessages from './SlackMessages'
import Graph from './Graph'

const Stats = () => {
  const currentDate = new Date()
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

  const [startDate, setStartDate] = useState(startOfMonth)
  const [endDate, setEndDate] = useState(endOfMonth)

  return (
    <>
      <TimeSelector
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <div className="mx-auto w-full rounded-lg p-6 shadow-md">
        <Graph
          event="Create slack channel"
          title="Channel creation"
          label="Number of channels created"
          color="rgb(75, 192, 192)"
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className="mx-auto w-full rounded-lg p-6 shadow-md">
        <SlackMessages startDate={startDate} endDate={endDate} />
      </div>
      <div className="mx-auto w-full rounded-lg p-6 shadow-md">
        <Graph
          event="Send reminder"
          title="Daily PR reminder"
          label="Reminder"
          color="rgb(255, 105, 180)" // hotpink
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className="mx-auto w-full rounded-lg p-6 shadow-md">
        <Graph
          event="Snooze Pull request"
          title="Snooze PR reminder"
          label="Snooze"
          color="rgb(135, 206, 235)" // skyblue
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className="mx-auto w-full rounded-lg p-6 shadow-md">
        <Graph
          event={'PR approved with /lgtm in Slack'}
          title="PR approved with /lgtm in Slack"
          label="lgtm command"
          color="rgb(50, 205, 50)" // limegreen
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className="mx-auto w-full rounded-lg p-6 shadow-md">
        <Graph
          event="Auto Assigned Reviewers"
          title="Auto Assigned Reviewers"
          label="Auto Assigned Reviewer"
          color="rgb(218, 112, 214)" // orchid
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </>
  )
}

export default Stats
