import React from 'react'
import { useEffectOnce } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { User } from '../../reducers/userReducer'
import HomeFooter from '../FooterHomepage/footerHomePage'
import { Organization } from '../../reducers/organizationReducer'

export const RefreshPage = () => {
  const dispatch = useDispatch()
  const user = useSelector(User.selectors.selectUser)
  const history = useHistory()
  const organization = useSelector(Organization.selectors.getOrganization)
  useEffectOnce(() => {
    dispatch(User.actions.logout({}))
    history.push(
      `/auth/slack/callback?jwt=${user.jwt}&orgProviderLogin=${organization.providerLogin}`,
    )
  })

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-white">
      <div className="flex h-[88vh] flex-col justify-center py-12 sm:px-6 lg:px-8" />
      <HomeFooter />
    </div>
  )
}
