import moment from 'moment'

export function sortWithoutAddingEmptyValue({ data, temporality }) {
  const result = []
  for (let i = 0; i < data?.length; i += 1) {
    const dayCreated = data[i].providerCreatedAt.slice(0, 10)
    const index = result.findIndex((item) =>
      moment(item.date).isSame(dayCreated, temporality),
    )
    if (index >= 0) {
      result[index].total += 1
    } else result.push({ date: dayCreated, total: 1 })
  }
  return result.sort((a, b) =>
    moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD')),
  )
}
