import React, { useEffect, useState, useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { Organization } from '../../reducers/organizationReducer'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setIsOnboarded } from '../../reducers/playersReducer'
import OnboardingSteps from '../../components/OnboardingSteps'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'
import { useAxiosWithHeader } from '../../utils'
import TeamChannelTable from '../../components/EngineerTeamChannelContainer/teamChannelTable'
import { User } from '../../reducers/userReducer'
import { isEqual } from 'lodash'
import { fetchPullRequestsAndUpdateReducer } from '../../pages/OpenChannels/fetchPullRequest'
import { fetchSlackChannelsAndUpdateReducer } from '../../components/Settings/utils'
import FinishOnboardingProcess from './FinishOnboardingProcess'

export default function OnboardingSelectTeamChannel() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const history = useHistory()
  const dispatch = useDispatch()
  const [hasCreatedChannel, setHasCreatedChannel] = useState(false)
  const pull = organization.provider === 'github' ? 'pull' : 'merge'
  const axios = useAxiosWithHeader()
  const user = useSelector(User.selectors.selectUser, isEqual)
  const isMountedRef = useRef(true)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (organization?.setting?.channels?.length > 0) {
      setHasCreatedChannel(true)
    }
  }, [organization?.setting?.channels])

  const [loading, setLoading] = useState(false)
  useEffectOnce(() => {
    fetchSlackChannelsAndUpdateReducer({
      organization,
      user,
      axios,
      dispatch,
      setLoading,
    })
  }, [organization.id])

  useEffectOnce(() => {
    if (!(organization.pullRequest?.length > 0)) {
      fetchPullRequestsAndUpdateReducer({
        organization,
        user,
        axios,
        dispatch,
        Organization,
        isMountedRef,
        source: 'OnboardingSelectTeamChannel',
      })
    }
  })

  return (
    <MainDivOnboarding>
      <OnboardingSteps step={4} />
      <>
        <div className="align-item mt-10 flex flex-col justify-center">
          <h1 className="mt-5 mb-2 text-2xl">Create your team channel</h1>
          <p className="">
            Get <strong>notified of new {pull} requests</strong> in a dedicated Slack
            channel and get <strong>automatic updates</strong> to know about the status of
            your team {pull === 'pull' ? 'PR' : 'MR'}.
          </p>
          <div className="flex h-[424px] justify-center">
            <img
              src="/images/teamchannelnotif.png"
              alt="Axolo general notification for pull request status"
              className="flex max-w-[454px] justify-center"
            />
          </div>
        </div>
        <h1 className="mb-4 text-2xl">Choose an existing channel or create a new one</h1>
        <div className="mt-10">
          <TeamChannelTable
            loadingTeamChannels={false}
            isAdmin={true}
            onlyTable={true}
            preFillAxoloGeneral={true}
            setHasCreatedChannel={setHasCreatedChannel}
            onboarding={true}
          />
        </div>
        <div className="flex justify-end">
          <FinishOnboardingProcess
            hasCreatedChannel={hasCreatedChannel}
            dispatch={dispatch}
            organization={organization}
            setIsOnboarded={setIsOnboarded}
            axios={axios}
          />
        </div>
      </>
    </MainDivOnboarding>
  )
}
