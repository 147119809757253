const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const AxoloDaySelector = ({ handleClickOnDay, daySelected, standup }) => {
  const unSelectedCss =
    'inline-flex items-center justify-center w-6 h-6 p-2 font-medium bg-indigo-200 text-gray-800 rounded-full cursor-pointer hover:opacity-75'
  const selectedCss = '!bg-primary !text-white '
  return (
    <div className="flex justify-between mt-2 w-full max-w-[250px] uppercase ">
      <span
        className={classNames(unSelectedCss, daySelected.sunday && selectedCss)}
        onClick={() => handleClickOnDay({ standup, daySelected, day: 'sunday' })}
      >
        s
      </span>

      <span
        className={classNames(unSelectedCss, daySelected.monday && selectedCss)}
        onClick={() => handleClickOnDay({ standup, daySelected, day: 'monday' })}
      >
        m
      </span>
      <span
        className={classNames(unSelectedCss, daySelected.tuesday && selectedCss)}
        onClick={() => handleClickOnDay({ standup, daySelected, day: 'tuesday' })}
      >
        t
      </span>
      <span
        className={classNames(unSelectedCss, daySelected.wednesday && selectedCss)}
        onClick={() => handleClickOnDay({ standup, daySelected, day: 'wednesday' })}
      >
        w
      </span>
      <span
        className={classNames(unSelectedCss, daySelected.thursday && selectedCss)}
        onClick={() => handleClickOnDay({ standup, daySelected, day: 'thursday' })}
      >
        t
      </span>
      <span
        className={classNames(unSelectedCss, daySelected.friday && selectedCss)}
        onClick={() => handleClickOnDay({ standup, daySelected, day: 'friday' })}
      >
        f
      </span>
      <span
        className={classNames(unSelectedCss, daySelected.saturday && selectedCss)}
        onClick={() => handleClickOnDay({ standup, daySelected, day: 'saturday' })}
      >
        s
      </span>
    </div>
  )
}
