import { Select } from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Setting } from '../../../reducers/settingReducer'
import { Analytics } from '../../../reducers/analyticsReducer'
import SkeletonLoading from '../utils/skeletonLoader'

const { Option } = Select
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const RepositoryMultiSelect = () => {
  const setting = useSelector(Setting.selectors.getSetting)
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const { repositories } = analytics
  const { selectedRepositories } = setting
  const dispatch = useDispatch()
  const children = []
  const allRepoSelected = selectedRepositories?.length === repositories?.length

  function handleOnChange(newValue) {
    const index = selectedRepositories.findIndex((repo) => repo.id === newValue.id)
    let newSelect = _.cloneDeep(selectedRepositories)
    if (index >= 0) {
      newSelect.splice(index, 1)
    } else {
      newSelect.push(newValue)
    }
    const intersection = repositories.filter((repo) => newValue.includes(repo.name))
    dispatch(Setting.actions.setData({ selectedRepositories: intersection }))
    if (process.env.NODE_ENV === 'production') {
      window.analytics.track('Update repository selection', {
        numberOfRepo: intersection?.length,
      })
    }
  }

  function handleOnClickSelect() {
    if (allRepoSelected) {
      dispatch(Setting.actions.setData({ selectedRepositories: [] }))
      if (process.env.NODE_ENV === 'production') {
        window.analytics.track('Unselect all repositories', {
          numberOfRepositories: 0,
        })
      }
    } else {
      dispatch(Setting.actions.setData({ selectedRepositories: repositories }))
      if (process.env.NODE_ENV === 'production') {
        window.analytics.track('Select all repositories', {
          numberOfRepositories: repositories?.length,
        })
      }
    }
  }

  for (let i = 0; i < repositories?.length; i++) {
    children.push(<Option key={repositories[i].name}>{repositories[i]?.name}</Option>)
  }

  return (
    <>
      <p className="text-sm font-medium text-gray-500 pt-1 mb-1">Repositories</p>

      <div className="flex items-center place-content-between">
        {false ? (
          <div className="w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 ">
            <SkeletonLoading specificCss="" />
          </div>
        ) : (
          <Select
            mode="tags"
            placeholder="Please select your repositories"
            value={selectedRepositories?.map((repo) => repo?.name)}
            onChange={(value, options) => handleOnChange(value, options)}
            style={{ width: '100%' }}
            bordered={false}
            maxTagCount="responsive"
            className=" border border-gray-300 rounded-md shadow-sm pl-1 py-1"
          >
            {/* {organization.loading ? <SkeletonLoading class='' /> : { children }} */}
            {children}
          </Select>
        )}
        <button
          type="button"
          onClick={() => handleOnClickSelect()}
          className={classNames(
            allRepoSelected
              ? 'bg-redFlash hover:bg-ourRed '
              : 'bg-indigo-600 hover:bg-indigo-700 ',
            'ml-1 flex justify-center items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white w-24',
          )}
        >
          {allRepoSelected ? 'Unselect' : 'Select all'}
        </button>
      </div>
    </>
  )
}

export default RepositoryMultiSelect
