import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export default function LoaderIcon({
  size,
  title,
  spinning,
  className = 'flex flex-col',
}) {
  const icon = <LoadingOutlined style={{ fontSize: size }} spin />

  return (
    <div className={className}>
      <Spin indicator={icon} spinning={spinning} />
      <p className="mt-5 text-center text-slate-700">{title}</p>
    </div>
  )
}
