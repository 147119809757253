import moment from 'moment'
import { useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts'
import { areaColor } from '../utils/graph-classes'
import NoDataGraph from '../NoDataGraph/NoDataGraph'
import ChartError from '../ChartError/chartError'
import GraphLoading from '../GraphLoading/GraphLoading'

function minutesIntoDays(minutes) {
  return `${(minutes / (60 * 24)).toFixed(1)} days`
}

function customYAxisLabel(value) {
  if (value === 0) return ''
  return minutesIntoDays(value)
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg bg-white px-4 py-2 shadow-lg">
        <p className="">{moment(label, 'YYYY-MM-DD').format('MMMM Do YYYY')}</p>
        <p className="">Average cycle time: {minutesIntoDays(payload[0].payload.stat)}</p>
      </div>
    )
  }
  return null
}

export default function ContinuousCycleTimeGraph({ loading, error }) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  if (error) return <ChartError />
  if (loading) return <GraphLoading />

  const { continuousCycleTime } = analytics
  if (continuousCycleTime?.length === 0) return <NoDataGraph />
  return (
    <div className="flex justify-center p-2 ">
      <ResponsiveContainer height={300} width={'100%'}>
        <AreaChart data={continuousCycleTime}>
          <Area
            type="monotone"
            dataKey="stat"
            stroke={areaColor}
            fillOpacity={1}
            fill="url(#colorContinusCycleTime)"
          />
          <defs>
            <linearGradient id="colorContinusCycleTime" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={areaColor} stopOpacity={0.8} />
              <stop offset="95%" stopColor={areaColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            tickFormatter={(t) => {
              return moment(t, 'YYYY-MM-DD').format('MMM Do')
            }}
          />
          <YAxis tickFormatter={customYAxisLabel} />
          <Tooltip content={<CustomTooltip />} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
