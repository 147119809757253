import { Button, message } from 'antd'

export default function ButtonRequest({ axios, URL, text, adminName }) {
  const request = async () => {
    try {
      const res = await axios.get(URL)
      if (res.status === 200) message.success(`Message successfully sent to ${adminName}`)
    } catch (e) {
      message.error('An error occured, please try again or contact us.')
    }
  }

  return (
    <div className="">
      <Button
        type={'primary'}
        className="ml-4 block items-center rounded-md border border-transparent bg-primary px-4 py-2"
        size={'large'}
        style={{ borderRadius: 5, margin: 10, height: 50 }}
        onClick={() => request()}
      >
        {text}
      </Button>
    </div>
  )
}
