import messageInteraction from '../Analytics/utils/messageInteraction'
import { allText } from '../EngineerTeamChannelContainer/teamChannelTable'

export const isSlackAuth = ({ user, organization }) => {
  if (
    user.credential ||
    user?.credential?.teamId?.length > 0 ||
    user.slackAuth ||
    organization?.credentials?.find((credential) => credential?.tool === 'slack') !==
      undefined
  ) {
    return true
  }
  return false
}

export const findTeamNameFromId = ({ id, teams }) => {
  if (id === allText) {
    return allText
  }
  const team = teams.find((team) => team.id === id)
  return team?.name
}

export function handleErrorsInputTeamChannel({
  state,
  setting,
  optionSelection,
  repositoryOptions,
}) {
  const missingInformation = []
  if (!(state.title?.length > 0)) {
    missingInformation.push('channel title')
  }
  if (!(state.teams?.length > 0) && optionSelection === 'on team') {
    missingInformation.push('team')
  }
  if (!(repositoryOptions?.length > 0) && optionSelection === 'on repository') {
    missingInformation.push('repository')
  }

  if (missingInformation.length > 0) {
    const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })
    const missingText = formatter.format(missingInformation)
    messageInteraction({
      content: `Missing ${missingText} information.`,
      type: 'error',
    })
    return true
  }
  const allTitles = setting.channels?.map(({ title }) => title)
  if (state.title.length > 79) {
    messageInteraction({
      content: 'Channel title cannot be longer than 80 chars.',
      type: 'error',
    })
    return true
  }
  if (allTitles?.includes(state.title)) {
    messageInteraction({
      content:
        "You can't use the same channel twice. You should update the existing channel.",
      type: 'error',
    })
    return true
  }
  return false
}
