import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { useAxiosWithHeader } from '../../utils'
import { useEffectOnce } from 'react-use'

// Register the necessary components
Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const SlackMessages = ({ startDate, endDate }) => {
  const [reviewCommentData, setReviewCommentData] = useState([]) // State for review comments
  const [issueCommentData, setIssueCommentData] = useState([]) // State for issue comments
  const [grouping, setGrouping] = useState('day') // State for grouping
  const [loading, setLoading] = useState(true) // State for loading
  const axios = useAxiosWithHeader()

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const [isTableOpen, setIsTableOpen] = useState(false) // State to toggle table visibility

  useEffectOnce(() => {
    // Fetch data for review comments
    const type = 'track'
    const events = [
      'Create Github review comment from Slack thread',
      'Issue comment created from Slack',
    ]

    // Construct the query string
    const queryString = `type=${type}&event_in=${encodeURIComponent(events.join(','))}`

    axios
      .get(`/events?${queryString}`)
      .then((response) => {
        const reviewComments = response.data.filter(
          (event) => event.event === 'Create Github review comment from Slack thread',
        )
        const issueComments = response.data.filter(
          (event) => event.event === 'Issue comment created from Slack',
        )
        setReviewCommentData(reviewComments)
        setIssueCommentData(issueComments)
        setLoading(false) // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching event data:', error)
        setLoading(false) // Set loading to false even if there's an error
      })
  }, [axios])

  const generateDateRange = (start, end, key) => {
    const dates = []
    let currentDate = new Date(start)
    const today = new Date() // Get today's date

    while (currentDate <= end && currentDate <= today) {
      // Ensure currentDate does not exceed today
      let formattedDate
      if (key === 'day') {
        formattedDate = currentDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        }) // Use month + day format
        currentDate.setDate(currentDate.getDate() + 1)
      } else if (key === 'week') {
        const startOfWeek = new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay()),
        )
        formattedDate = startOfWeek.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })
        currentDate.setDate(currentDate.getDate() + 7)
      } else if (key === 'month') {
        formattedDate = `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1,
        ).padStart(2, '0')}`
        currentDate.setMonth(currentDate.getMonth() + 1)
      }
      dates.push(formattedDate)
    }

    return dates
  }

  const groupBy = (data, key) => {
    const allDates = generateDateRange(startDate, endDate, key)
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.createdAt)
      if (date < startDate || date > endDate) return acc // Filter by date range
      let formattedDate
      if (key === 'day') {
        formattedDate = date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })
      } else if (key === 'week') {
        const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()))
        formattedDate = startOfWeek.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })
      } else if (key === 'month') {
        formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          '0',
        )}`
      }
      if (!acc[formattedDate]) acc[formattedDate] = 0
      acc[formattedDate] += 1
      return acc
    }, {})

    // Ensure all dates are represented
    allDates.forEach((date) => {
      if (!groupedData[date]) groupedData[date] = 0
    })

    // Sort the dates
    const sortedGroupedData = Object.keys(groupedData)
      .sort((a, b) => new Date(a) - new Date(b))
      .reduce((acc, key) => {
        acc[key] = groupedData[key]
        return acc
      }, {})

    return sortedGroupedData
  }

  const groupedReviewCommentData = groupBy(reviewCommentData, grouping)
  const groupedIssueCommentData = groupBy(issueCommentData, grouping)

  const labels = Object.keys(groupedReviewCommentData)
  const reviewCommentDataPoints = Object.values(groupedReviewCommentData)
  const issueCommentDataPoints = Object.values(groupedIssueCommentData)

  const data = {
    labels,
    datasets: [
      {
        label: 'Github Review Comments from Slack',
        data: reviewCommentDataPoints,
        borderColor: 'rgba(153, 102, 255, 0.6)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)', // Add background color for fill
        fill: true, // Enable fill
      },
      {
        label: 'Issue Comments from Slack',
        data: issueCommentDataPoints,
        borderColor: 'rgba(255, 159, 64, 0.6)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)', // Add background color for fill
        fill: true, // Enable fill
      },
    ],
  }

  // Calculate the max value for the y-axis considering the stacked data
  const maxDataPoint =
    Math.max(
      ...labels.map(
        (label, index) => reviewCommentDataPoints[index] + issueCommentDataPoints[index],
      ),
    ) + 1

  const options = {
    scales: {
      y: {
        stacked: true,
        min: 0,
        max: maxDataPoint, // Set dynamic max value
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        stacked: true, // Enable stacking
      },
    },
    interaction: {
      mode: 'nearest', // Show tooltip when hovering near the data points
      intersect: false, // Do not require an exact intersection with the data point
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
  }

  const allMessages = [
    ...reviewCommentData.map((comment) => ({ ...comment, type: 'Review Comment' })),
    ...issueCommentData.map((comment) => ({ ...comment, type: 'Issue Comment' })),
  ]

  // Filter messages within the date range
  const filteredMessages = allMessages.filter((message) => {
    const messageDate = new Date(message.createdAt)
    return messageDate >= startDate && messageDate <= endDate
  })

  const totalPages = Math.ceil(filteredMessages.length / itemsPerPage)

  const paginatedMessages = filteredMessages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  )

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold text-gray-900 ">Slack messages sent</h2>
      <div className="mb-4 flex justify-end">
        <select
          onChange={(e) => setGrouping(e.target.value)}
          value={grouping}
          className="rounded border border-gray-300 p-2"
        >
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
        </select>
      </div>
      {loading ? <div>Loading...</div> : <Line data={data} options={options} />}

      {/* Link-style toggle for table visibility */}
      <div className="mt-4 flex justify-end">
        <button
          onClick={() => setIsTableOpen(!isTableOpen)}
          className="flex items-center text-blue-500 hover:underline"
        >
          {isTableOpen ? 'Hide Messages' : 'Show Messages'}
          <span className="ml-2">
            {isTableOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M14.707 10.707a1 1 0 01-1.414 0L10 7.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </span>
        </button>
      </div>

      {/* Collapsible Table for displaying messages */}
      {isTableOpen && (
        <div className="mt-4">
          <table className="min-w-full table-fixed rounded-lg bg-white shadow-md">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-2" style={{ width: '160px' }}>
                  Date
                </th>
                <th className="py-2" style={{ width: '130px' }}>
                  Type
                </th>
                <th className="py-2">Message</th>
              </tr>
            </thead>
            <tbody>
              {paginatedMessages.map((comment, index) => (
                <tr key={index} className="h-24 border-b hover:bg-gray-100">
                  <td className="border px-2 py-2">
                    {new Date(comment.createdAt).toLocaleDateString('en-US', {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    })}
                  </td>
                  <td className="border px-2 py-2">{comment.type}</td>
                  <td
                    className="border px-2 py-2 text-left align-top"
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 5,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                      height: '100px',
                    }}
                    title={comment.properties.message}
                  >
                    {comment.properties.message}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="mt-2 flex justify-between">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="rounded bg-gray-300 px-4 py-2 disabled:opacity-50"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="rounded bg-gray-300 px-4 py-2 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SlackMessages
