import { Avatar } from 'antd'
import { useSelector } from 'react-redux'
import { UserAvatar } from '../../pages/OpenChannels/userAvatar'
import { Organization } from '../../reducers/organizationReducer'
import { classNames } from '../../utils'
import { classCell } from './helper-reminder-personalization'
import { ReminderAction } from './reminder-action'
import { ReminderPRStatus } from './reminders-helper-PR-status'

export default function AlreadyCreatedMessageTable({ isAdmin }) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization

  return setting?.personalReminders?.map((reminder, index) => {
    return (
      <tr key={index}>
        <td className={classNames(classCell, 'w-[575px] max-w-[575px]')}>
          {reminder?.message}
        </td>
        <td className={classNames(classCell, ' w-[200px] max-w-[200px]')}>
          <ReminderPRStatus reminder={reminder} indexReminder={index} isAdmin={isAdmin} />
        </td>
        <td className={classNames(classCell, 'w-[200px] max-w-[200px]')}>
          <div className="flex items-center">
            <UserAvatar record={reminder?.creator} />
            <p className="mb-0 pl-2 text-gray-800">
              {reminder?.creator?.providerLogin || 'Axolo'}
            </p>
          </div>
        </td>
        <td className={classNames(classCell, 'w-[167px] max-w-[167px]')}>
          <span href="#" className="text-indigo-600 hover:text-indigo-900">
            <div className="flex justify-center ">
              <ReminderAction
                reminder={reminder}
                isAdmin={isAdmin}
                indexReminder={index}
              />
            </div>
          </span>
        </td>
      </tr>
    )
  })
}
