import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Organization } from '../../reducers/organizationReducer'
import { navigation } from './roots'
import { classNames } from '../../utils'

export default function HeaderMenuDesktop({
  isOnboarded,
  activePage,
  setActivePage,
  setActiveSubPage,
  subNavigation,
}) {
  const history = useHistory()
  const organization = useSelector(Organization.selectors.getOrganization)
  const provider = organization?.provider || 'github'

  if (provider === 'github' && navigation) {
    navigation[1].name = 'Pull Requests'
  }
  return (
    <div className="flex items-center px-2 lg:px-0">
      <div className="flex-shrink-0">
        <img className="block h-14 w-14" src="/axolo_face_square.png" alt="Axolo logo" />
      </div>
      <div className="hidden lg:ml-10 lg:block">
        <div className="flex space-x-4">
          {isOnboarded
            ? navigation.map((item) => {
                if (organization.provider === 'gitlab' && item.name === 'Analytics') {
                  return null
                }
                return (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      activePage === item.route
                        ? 'bg-hoverPrimary text-white'
                        : 'text-white hover:bg-hoverPrimary ',
                      'rounded-md py-2 px-3 text-sm font-medium hover:text-gray-200',
                    )}
                    onClick={() => {
                      setActivePage(item.route)
                      setActiveSubPage(subNavigation[item.route][0].route)
                      history.push(`/${item.route}/${subNavigation[item.route][0].route}`)
                    }}
                    aria-current={activePage === item.route ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}
