import StaticBanner from '../StaticBanner/staticBanner'
import GraphBanner from '../GraphBanner'
import MergedPRGraphContainer from '../MergedPRGraph/MergedPRContainer'
import DeploymentContainer from '../DeploymentGraph/DeploymentContainer'
import ContinuousCycleTimeContainer from '../ContinuousCycleTime/continuousCycleTimeContainer'
import PullRequestLengthContainer from '../PullRequestLengthGraph/PullRequestLengthContainer'
import CycleTimeStackedContainer from '../CycleTimeStacked/CycleTimeStackedContainer'
import ReviewTimeStackedContainer from '../ReviewTimeStacked/ReviewTimeStackedContainer'

export default function Dashboard({temporality}) {
    return (
        <>
        <StaticBanner />
        <GraphBanner>
          <MergedPRGraphContainer
            title={'Merged pull requests'}
            subTitle={`Number of merged pull requests per ${temporality}`}
            documentationLink='https://axolo.co/docs/analytics/graph-definition#merged-pull-requests'
            tableGraphLink='/analytics/merged-pull-requests'
          />
          <DeploymentContainer
            title='Deployments'
            subTitle={`Number of deployments per ${temporality}`}
            tableGraphLink='/analytics/deployments'
          />
          <ContinuousCycleTimeContainer
            title='Cycle time evolution'
            subTitle={`Evolution of your cycle time per ${temporality} (in minutes)`}
            documentationLink='https://axolo.co/docs/analytics/graph-definition#cycle-time-evolution'
            tableGraphLink='/analytics/cycle-time-evolution'
          />
          <PullRequestLengthContainer
            title='Pull request length'
            subTitle={`Evolution of the size of your PR per ${temporality}`}
            documentationLink='https://axolo.co/docs/analytics/graph-definition#pull-request-length'
            tableGraphLink='/analytics/pull-requests-length'
          />
          <CycleTimeStackedContainer
            title='Cycle time'
            subTitle='Repartition of your cycle time'
            documentationLink='https://axolo.co/docs/analytics/glossary#development-time'
            tableGraphLink='/analytics/cycle-time'
          />
          <ReviewTimeStackedContainer
            title='Review time'
            subTitle='Composition of your review time'
            documentationLink='https://axolo.co/docs/analytics/glossary#review-time'
            tableGraphLink='/analytics/review-time'
          />
        </GraphBanner>
        </>
    )
}