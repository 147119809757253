import React, { useState } from 'react'
import { Select, Tooltip, Button, Avatar } from 'antd'
import { cloneDeep, set, sortBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import CreatableSelect from 'react-select/creatable'
import { Engineer } from '../../reducers/engineerReducer'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { handleErrorsInputTeamChannel } from './helpers'
import {
  classActionColumn,
  classChannelColumn,
  classCreatorColumn,
  classOptionTeamChannel,
  classTeamColumn,
} from '../EngineerTeamChannelContainer/teamChannelTableHelper'
import RefreshTeamButton from '../utils/refreshTeamsButton'
import RefreshConversationsButton from '../utils/refreshConversationsButton'
import { useEffectOnce } from 'react-use'
import { PlusCircleIcon } from '@heroicons/react/outline'
import { UserAvatar } from '../../pages/OpenChannels/userAvatar'

const slugify = require('slugify')

const initialState = {
  title: '',
  titleStatus: '',
  existingChannel: false,
  teams: [],
  teamStatus: '',
}

export default function TeamChannelInputTable({
  setLoading,
  loading,
  children,
  preFillAxoloGeneral,
  allText,
  setHasCreatedChannel,
  onboarding,
}) {
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const organization = useSelector(Organization.selectors.getOrganization)
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const { setting, repositories } = organization
  const [state, setState] = useState(initialState)
  const [channelsState, setChannelsState] = useState()
  const [teamState, setTeamState] = useState()
  const [repositoryOptions, setRepositoryOptions] = useState([])

  const settingOptions = ['on team', 'on repository']
  const [optionSelection, setOptionSelection] = useState(settingOptions[0])
  const [allRepositories, setAllRepositories] = useState(
    repositories?.map((repo) => repo.name),
  )

  useEffectOnce(() => {
    async function fetchRepo() {
      const { data } = await axios.get(
        `/repositories/find-repo-for-team-channel/${organization.id}`,
      )
      const options = data.map((repo) => repo.name)
      setAllRepositories(options)
      dispatch(Organization.actions.setData({ repositories: data }))
    }

    if (organization.provider === 'github' && !(repositories?.length > 0)) {
      fetchRepo()
      // it means we already have the repositories because we fetched them before a reload of the page
    } else if (organization.provider === 'github') {
      const options = repositories.map((repo) => repo.name)
      setAllRepositories(options)
    }
  })

  const shouldDisableInput = () => {
    const { plan } = organization
    const { channels } = setting
    if (plan === 'trial' || plan === 'business' || plan === 'enterprise') {
      return false
    }
    if (!channels || channels?.length === 0) {
      return false
    }
    const channelEnabled = channels.reduce((channel) => !channel.desactivate)
    if (plan !== 'trial' && plan !== 'business' && channelEnabled) {
      return true
    }
    return false
  }

  const handleTagChange = (values) => {
    setTeamState(values)

    // Check if "all axolo onboarded users" is among the selected values
    const isAllAxoloUserSelected = values.some(
      (value) => value.key === 'All Axolo onboarded users',
    )

    if (isAllAxoloUserSelected) {
      // If "all axolo onboarded users" is selected, keep only that selection
      setTeamState([
        { key: 'All Axolo onboarded users', label: 'All Axolo onboarded users' },
      ])
      return setState({ ...state, teams: ['All Axolo onboarded users'], teamStatus: '' })
    }

    // Proceed as before if "all axolo onboarded users" is not selected
    if (values.length === 0) {
      return setState({ ...state, teams: [], teamStatus: 'warning' })
    }
    const ids = values.map((value) => value.key)
    setState({ ...state, teams: ids, teamStatus: '' })
  }

  const onSave = async () => {
    const isError = handleErrorsInputTeamChannel({
      state,
      setting,
      settingOptions,
      optionSelection,
      repositoryOptions,
    })
    if (isError) return

    setLoading(true)
    const hide = messageInteraction({
      content: 'Creating your channel, setting the topic, and inviting your team..',
      type: 'loading',
      duration: 0,
    })
    const { title, teams, id, existingChannel } = state
    try {
      let newSettings = cloneDeep(setting)
      const creator = {
        avatarUrl: engineer.avatarUrl,
        providerLogin: engineer.providerLogin,
        id: engineer.id,
        htmlUrl: engineer.htmlUrl,
      }
      const teamsToInvite = teams
      const channel = {
        title,
        teams: teamsToInvite,
        id,
        existingChannel,
        creator,
        option: optionSelection,
        repositories: repositoryOptions,
      }
      if (newSettings.channels) {
        newSettings.channels.push(channel)
      } else {
        newSettings.channels = [channel]
      }
      if (setHasCreatedChannel) {
        setHasCreatedChannel(true)
      }
      const updatedSettingsResp = await axios.put(`/settings/${setting._id}`, {
        channels: newSettings.channels,
        updatingTeamChannel: true,
        newChannel: channel,
      })
      setLoading(false)
      if (hide) hide()
      if (updatedSettingsResp.status === 200) {
        if (
          typeof updatedSettingsResp.data === 'string' &&
          updatedSettingsResp.data.includes('Error')
        ) {
          messageInteraction({
            content: updatedSettingsResp.data,
            type: 'error',
          })
        } else {
          messageInteraction({
            content: 'Your channel has been created.',
            type: 'success',
          })
          dispatch(Organization.actions.setData({ setting: updatedSettingsResp.data }))
          setState(initialState)
          // clearing input values for channel and teams
          setChannelsState(null)
          setTeamState(undefined)
          setRepositoryOptions([])
        }
      }
    } catch (error) {
      if (hide) hide()
      console.log('Error creating a team channel', error)
      setLoading(false)
      if (error?.response?.status === 402) {
        messageInteraction({
          content: 'This feature is for Business plan only.',
          type: 'error',
        })
      } else {
        console.log("Couldn't create your channel", error)
        messageInteraction({
          content: "Couldn't create your channel. See logs.",
          type: 'error',
        })
      }
    }
  }

  const channelsOptions = sortBy(
    organization?.slackChannels?.map((c) => {
      const obj = {
        value: c.title,
        label: c.title,
        id: c.communicationToolId,
      }
      return obj
    }),
    'value',
  )
  useEffectOnce(() => {
    if (preFillAxoloGeneral) {
      const defaultChannelValue = {
        value: '_axolo',
        label: '_axolo',
        id: '_axolo',
      }
      const defaultTeamValue = [allText]
      setChannelsState(defaultChannelValue)
      setTeamState(defaultTeamValue)
      setState({
        ...state,
        title: defaultChannelValue.value,
        existingChannel: false,
        teams: defaultTeamValue,
      })
    }
  })

  const CreateIcon = () => {
    if (onboarding && !disableInput && !(setting?.channels?.length > 0)) {
      return (
        <button
          type="button"
          className="rounded bg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-hoverPrimary"
          onClick={() => !disableInput && onSave()}
        >
          Create my first team channel
        </button>
      )
    }
    return (
      <PlusCircleIcon
        className={`h-8 w-8 ${
          disableInput ? 'gray-400' : 'text-primary'
        } cursor-pointer hover:text-hoverPrimary`}
        aria-hidden="true"
        style={{ pointerEvents: 'auto' }}
        onClick={() => !disableInput && onSave()}
      />
    )
  }

  const handleTitleChange = (newValue) => {
    if (newValue?.label?.length > 0 && newValue?.__isNew__) {
      let { label } = newValue
      label = slugify(label, { replacement: '_', trim: false })
      label = label?.replace(/[^\w\s-]/g, '_').toLowerCase()
      newValue.label = label
      newValue.value = label
    }
    setChannelsState(newValue)

    if (!(newValue?.label?.length > 0)) return
    const { value = '', id = '' } = newValue
    if (newValue?.id?.length > 0) {
      setState({ ...state, title: value, id, existingChannel: true })
    } else if (newValue) {
      setState({ ...state, title: value, existingChannel: false })
    } else {
      setState({ ...state, title: '', titleStatus: '' })
    }
  }
  const formatLabel = (inputValue) => {
    return `Create new channel "${inputValue}"`
  }

  const disableInput = shouldDisableInput() || loading

  // limit to 10 channels showing to avoid performance issues with Enterprise
  const maxChannelsToShow = 10
  const [filteredOptions, setFilteredOptions] = useState(
    channelsOptions.slice(0, maxChannelsToShow),
  )
  const filterAndLimitOptions = (inputValue) => {
    return channelsOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, maxChannelsToShow)
  }
  // end of limiting code for channels

  function changeChannelOption(value) {
    setOptionSelection(value)
    if (value === 'on team') {
      setRepositoryOptions([])
    } else {
      setTeamState([])
    }
  }

  return (
    <tr key="newTeamChannelLine">
      <td className={classChannelColumn}>
        <div className="flex">
          <CreatableSelect
            createOptionPosition="first"
            isClearable
            onInputChange={(newValue) => {
              let inputValue = slugify(newValue, { replacement: '_', trim: false })
              inputValue = inputValue?.replace(/[^\w\s-]/g, '_').toLowerCase()
              setFilteredOptions(filterAndLimitOptions(inputValue))
              // if there is a text in the input, we save it as an option
              // but if we select an existing channel, the event will trigger without anything in it
              // so it will be the onChange props which will select the option
              if (inputValue?.length > 0) {
                handleTitleChange({
                  label: inputValue,
                  value: inputValue,
                  __isNew__: true,
                })
              }
            }}
            onChange={handleTitleChange}
            openMenuOnClick={false}
            options={filteredOptions}
            formatCreateLabel={formatLabel}
            value={channelsState}
            placeholder="Start typing..."
            className="w-[240px]"
          />
          <RefreshConversationsButton />
        </div>
      </td>
      {organization?.provider === 'github' && (
        <td className={classOptionTeamChannel}>
          Channel is based
          <Select
            value={optionSelection}
            onChange={(value) => changeChannelOption(value)}
            options={settingOptions.map((option) => ({ value: option, label: option }))}
            style={{ width: '130px', paddingLeft: '5px' }}
          />
        </td>
      )}
      <td className={classTeamColumn}>
        {organization?.provider === 'github' && (
          <Select
            value={optionSelection === 'on team' ? 'all repositories' : repositoryOptions}
            mode="multiple"
            onChange={(value) => setRepositoryOptions(value)}
            options={allRepositories.map((option) => ({
              value: option,
              label: option,
            }))}
            style={{ width: '40%' }}
            placeholder="Select a repository"
            disabled={optionSelection === 'on team'}
            className=" !pt-[3px]"
          />
        )}

        <Select
          status={state.teamStatus}
          mode="multiple"
          allowClear
          style={{ width: '45%' }}
          placeholder="Select your teams"
          labelInValue
          value={teamState}
          onChange={(e) => handleTagChange(e)}
          className="!ml-2 !pt-[3px]"
        >
          {children}
        </Select>
        <RefreshTeamButton />
      </td>
      <td className={classCreatorColumn}>
        <div className="flex items-center">
          <UserAvatar record={engineer} />
          <p className="mb-0 pl-2 text-gray-700">{engineer?.providerLogin}</p>
        </div>
      </td>

      <td className={classActionColumn}>
        <span href="#">
          <div className="flex  justify-center">
            {disableInput && organization?.plan !== 'trial' ? (
              <Tooltip
                title="Upgrade to the business plan to access this functionality"
                placement="top"
                trigger={['hover']}
              >
                <div>
                  <PlusCircleIcon
                    className={`h-8 w-8 ${
                      disableInput ? 'gray-400' : 'text-primary'
                    } cursor-pointer hover:text-hoverPrimary`}
                    aria-hidden="true"
                    style={{ pointerEvents: 'auto' }}
                    onClick={() => !disableInput && onSave()}
                  />
                </div>
              </Tooltip>
            ) : (
              <CreateIcon />
            )}
          </div>
        </span>
      </td>
    </tr>
  )
}
