import { useState, useEffect } from 'react'
import { createStandardQuery } from '../utils/query'
import { useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import { Setting } from '../../../reducers/settingReducer'
import _ from 'lodash'
import moment from 'moment'
import './style.css'
import SkeletonLoading from '../utils/skeletonLoader'
import messageInteraction from '../utils/messageInteraction'
import TagGrowth from '../utils/tagGrowth'
import FeedbackButton from '../utils/feedbackButton'
import { URLBACK_VELOFRAME } from '../../../assets/urls'
import { useAxiosWithHeader } from '../../../utils'
import { message } from 'antd'

async function findMergeRate({ axios, setting, organizationId, setStats }) {
  try {
    const res = await axios.get(
      `${URLBACK_VELOFRAME}api/pull-requests/findMergeRate?${createStandardQuery({
        setting,
        organizationId,
      })}`,
    )
    setStats((prevState) => ({
      ...prevState,
      mergeRate: {
        ...prevState.mergeRate,
        stat: res.data.mergeRate,
        pastStat: res.data.previousMergeRate,
        type: 'percentage',
        loading: false,
        name: 'Merge Rate',
      },
    }))
  } catch (e) {
    messageInteraction({
      content: 'Error fetching merge rate. Please refresh or contact support.',
      type: 'error',
    })
    console.log('Error fetching merge rate: ', e)
  }
}

async function findAverageReviewsPerPullRequest({
  axios,
  setting,
  organizationId,
  setStats,
}) {
  try {
    const res = await axios.get(
      `${URLBACK_VELOFRAME}api/reviews/averageReviewPerPullRequest?${createStandardQuery({
        setting,
        organizationId,
      })}`,
    )
    setStats((prevState) => ({
      ...prevState,
      averageReviewPerPr: {
        ...prevState.averageReviewPerPr,
        stat: res.data.averageReviewPerPr,
        pastStat: res.data.pastAverageReviewPerPr,
        type: 'number',
        loading: false,
        name: 'Average Reviews per Pull Request',
      },
    }))
  } catch (e) {
    messageInteraction({
      content:
        'Error fetching average review per pull request. Please refresh or contact support.',
      type: 'error',
    })
    console.log('Error fetching average review per pull request: ', e)
  }
}

async function findMergedPullRequest({ axios, setting, organizationId, setStats }) {
  try {
    const res = await axios.get(
      `${URLBACK_VELOFRAME}api/pull-requests/findAverageMergedPullRequests?${createStandardQuery(
        { setting, organizationId },
      )}`,
    )
    setStats((prevState) => ({
      ...prevState,
      averageMergedPr: {
        ...prevState.averageMergedPr,
        stat: res.data.averageMergedPr,
        pastStat: res.data.pastAverageMergedPr,
        type: 'number',
        loading: false,
        name: 'Average Merged Pull Requests per day',
      },
    }))
  } catch (e) {
    messageInteraction({
      content: 'Error fetching merged pull requests. Please refresh or contact support.',
      type: 'error',
    })
    console.log('Error fetching merged pull requests: ', e)
  }
}

export default function StaticBanner() {
  const organization = useSelector(Analytics.selectors.getAnalytics)
  const axios = useAxiosWithHeader('analytics')
  const setting = useSelector(Setting.selectors.getSetting)
  const [stats, setStats] = useState({
    averageMergedPr: {
      name: 'Average Merged Pull Requests per day',
      stat: 0,
      pastStat: 0,
      loading: true,
      type: 'number',
    },
    mergeRate: {
      name: 'Merge Rate',
      stat: 0,
      pastStat: 0,
      loading: true,
      type: 'percentage',
    },
    averageReviewPerPr: {
      name: 'Average Reviews per Pull Request',
      stat: 0,
      pastStat: 0,
      loading: true,
      type: 'number',
    },
  })

  useEffect(() => {
    const organizationId = organization.id
    function setAllToLoading() {
      setStats((prevState) => ({
        ...prevState,
        mergeRate: {
          loading: true,
          name: 'Merge Rate',
        },
        averageReviewPerPr: {
          loading: true,
          name: 'Average Merged Pull Requests per day',
        },
        averageMergedPr: {
          loading: true,
          name: 'Average Merged Pull Requests per day',
        },
      }))
    }
    setAllToLoading()
    ;(async () => {
      if (!setting.loadingInitialQuery) {
        await Promise.all([
          findMergeRate({ axios, setting, organizationId, setStats }),
          findAverageReviewsPerPullRequest({ axios, setting, organizationId, setStats }),
          findMergedPullRequest({ axios, setting, organizationId, setStats }),
        ])
      }
    })()
  }, [
    setting.startDate,
    setting.endDate,
    setting.selectedRepositories,
    setting.selectedTeams,
  ])

  const startDate = moment(setting.startDate, 'DD-MM-YYYY').format('MMMM Do YYYY')
  const endDate = moment(setting.endDate, 'DD-MM-YYYY').format('MMMM Do YYYY')
  return (
    <div className="">
      <div className="flex justify-between place-items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          From {startDate} to {endDate}
        </h3>
        <FeedbackButton text="Tell us what you'd like to see next!" />
      </div>
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x lg:h-28">
        {Object.keys(stats).map((element, index) => {
          const item = stats[element]
          const stat = _.round(item.stat, 2) || 0
          const pastStat = _.round(item.pastStat, 2) || 0
          const change = (stat - pastStat) / pastStat
          const changeType = change > 0 ? 'increase' : 'decrease'
          const mainStat =
            item.type === 'percentage' ? `${(stat * 100).toFixed()}%` : stat
          const secondaryStat =
            item.type === 'percentage' ? `${(pastStat * 100).toFixed()}%` : pastStat
          return (
            <div key={index} className="px-4 py-5 sm:p-6 flex flex-col  justify-between">
              <dt className="text-base font-normal text-gray-900">{item.name}</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                {item.loading ? (
                  <SkeletonLoading specificCss="!w-12" />
                ) : (
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {mainStat}
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      from {secondaryStat}
                    </span>
                  </div>
                )}
                <TagGrowth changeType={changeType} change={change} objective="increase" />
              </dd>
            </div>
          )
        })}
      </dl>
    </div>
  )
}
