import moment from 'moment'
import { useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts'
import sortUponProviderCreatedAt from '../utils/function/sortDataUponProviderCreatedAt'
import { areaColor } from '../utils/graph-classes'
import NoDataGraph from '../NoDataGraph/NoDataGraph'
import GraphLoading from '../GraphLoading/GraphLoading'
import ChartError from '../ChartError/chartError'
import { Setting } from '../../../reducers/settingReducer'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white rounded-lg shadow-lg px-4 py-2">
        <p className="">{moment(label, 'YYYY-MM-DD').format('MMMM Do YYYY')}</p>
        <p className="">Deployments: {payload[0].payload.total}</p>
      </div>
    )
  }
  return null
}

export default function DeploymentGraph({ loading, error }) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const setting = useSelector(Setting.selectors.getSetting)
  const { startDate, endDate } = setting
  if (error) return <ChartError />
  if (loading) return <GraphLoading />
  const { deployments, temporality } = analytics

  const data = sortUponProviderCreatedAt({
    data: deployments,
    temporality,
    startDate,
    endDate,
  })
  if (data.length === 0) return <NoDataGraph />

  return (
    <div className="flex p-2 justify-center ">
      <ResponsiveContainer height={300} width={'100%'}>
        <AreaChart data={data}>
          <Area
            type="monotone"
            dataKey="total"
            stroke={areaColor}
            fillOpacity={1}
            fill="url(#colorDeploymentGraph)" // label={<CustomLabel />}
          />
          <defs>
            <linearGradient id="colorDeploymentGraph" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={areaColor} stopOpacity={0.8} />
              <stop offset="95%" stopColor={areaColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            tickFormatter={(t) => {
              return moment(t, 'YYYY-MM-DD').format('MMM Do')
            }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
