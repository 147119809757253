import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const TimeSelector = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const [customRange, setCustomRange] = useState(false)

  const setDateRange = (range) => {
    const now = new Date()
    let start, end

    switch (range) {
      case 'today':
        start = end = now
        break
      case 'yesterday':
        start = end = new Date(now.setDate(now.getDate() - 1))
        break
      case 'last7days':
        start = new Date(now.setDate(now.getDate() - 7))
        end = new Date()
        break
      case 'last30days':
        start = new Date(now.setDate(now.getDate() - 30))
        end = new Date()
        break
      case 'last3months':
        start = new Date(now.setMonth(now.getMonth() - 3))
        end = new Date()
        break
      case 'last6months':
        start = new Date(now.setMonth(now.getMonth() - 6))
        end = new Date()
        break
      case 'last12months':
        start = new Date(now.setMonth(now.getMonth() - 12))
        end = new Date()
        break
      default:
        start = new Date(now.getFullYear(), now.getMonth(), 1)
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0)
    }

    setStartDate(start)
    setEndDate(end)
    setCustomRange(false)
  }

  return (
    <div>
      <div className="mb-4 flex justify-between">
        <div className="flex space-x-2">
          <button
            onClick={() => setCustomRange(true)}
            className="rounded border px-4 py-2"
          >
            Custom
          </button>
          <button
            onClick={() => setDateRange('today')}
            className="rounded border px-4 py-2"
          >
            Today
          </button>
          <button
            onClick={() => setDateRange('yesterday')}
            className="rounded border px-4 py-2"
          >
            Yesterday
          </button>
          <button
            onClick={() => setDateRange('last7days')}
            className="rounded border px-4 py-2"
          >
            7D
          </button>
          <button
            onClick={() => setDateRange('last30days')}
            className="rounded border px-4 py-2"
          >
            30D
          </button>
          <button
            onClick={() => setDateRange('last3months')}
            className="rounded border px-4 py-2"
          >
            3M
          </button>
          <button
            onClick={() => setDateRange('last6months')}
            className="rounded border px-4 py-2"
          >
            6M
          </button>
          <button
            onClick={() => setDateRange('last12months')}
            className="rounded border px-4 py-2"
          >
            12M
          </button>
        </div>
      </div>
      {customRange && (
        <div className="mb-4 flex justify-between">
          <div className="mr-2 flex w-1/2 flex-col">
            <label className="mb-2 font-bold">Start Date:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="w-full rounded border border-gray-300 p-2"
            />
          </div>
          <div className="ml-2 flex w-1/2 flex-col">
            <label className="mb-2 font-bold">End Date:</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className="w-full rounded border border-gray-300 p-2"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default TimeSelector
