import React, { useRef, useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { useState } from 'react'
import { URLBACK } from '../../assets/urls'
import { useAxiosWithHeader } from '../../utils'
import ButtonRequest from '../PlayerNotAdmin/buttonRequest'
import ReminderSetup from '../../components/ReminderSetup'
import { columnsEngineer } from './../EngineerStandup/columnsEngineer'
import { Table } from 'antd'
import { colors } from '../../components/Colors'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { Engineer } from '../../reducers/engineerReducer'
import { fetchPullRequestsAndUpdateReducer } from '../OpenChannels/fetchPullRequest'
import { User } from '../../reducers/userReducer'

export default function EngineerGeneral() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const user = useSelector(User.selectors.selectUser)
  const axios = useAxiosWithHeader()
  const [adminUsername, setAdminUsername] = useState('')
  const [adminId, setAdminId] = useState('')
  const [engineerId, setEngineerId] = useState('')
  const [loading, setLoading] = useState(true)
  const [engineer, setEngineer] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  const isMountedRef = useRef(true)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const setDataForRedirection = async () => {
    const res = await axios.get(`${URLBACK}organizations/queryDataForRedirection`)
    setAdminUsername(res.data.admin.username)
    setAdminId(res.data.admin.id)
    setEngineerId(res.data.user.id)
    setLoading(false)
  }

  const engineerMe = async (orgId) => {
    axios
      .get(`${URLBACK}engineer/me/${orgId}`)
      .then((engineerResp) => {
        setEngineer(engineerResp.data)
        setLoading(false)
        dispatch(
          Organization.actions.setData({
            ...engineerResp.data.organization,
          }),
        )
        dispatch(Engineer.actions.setData(engineerResp.data))
        // This means an engineer signed in but is not onboarded
        if (!engineerResp?.data || engineerResp?.data?.providerLogin?.length === 0) {
          history.push(`/onboarding/selfonboard`)
        }
      })
      .catch((error) => {
        console.log('error getting engineer info', error)
        setEngineer(error)
      })
  }

  useEffectOnce(() => {
    if (organization.loading === false) {
      setDataForRedirection().then()
      engineerMe(organization.id).then()
    }
  })

  useEffectOnce(() => {
    if (organization?.providerLogin?.length === 0) {
      dispatch(Organization.actions.setData({ ...user?.organizations?.[0] }))
    }
    if (!(organization.pullRequest?.length > 0)) {
      fetchPullRequestsAndUpdateReducer({
        organization,
        user,
        axios,
        dispatch,
        Organization,
        isMountedRef,
        source: 'EngineerGeneral',
      })
    }
  })

  const teamColor = {}
  engineer?.teams?.forEach((team) => {
    // To define the color of a team I'm taking the char code of the first letter of their team name.
    let colorNumber = Math.abs(team.name[0].toLowerCase().charCodeAt(0) - 97)
    if (colorNumber > colors.length) {
      colorNumber = colorNumber % colors.length
    }
    teamColor[team.name] = colors[colorNumber]
  })

  let columns = columnsEngineer({ teamColor, engineer })
  columns = columns.filter((column) =>
    engineer.provider === 'github'
      ? column.title !== 'GitLab Auth'
      : column.title !== 'GitHub Auth',
  )
  return (
    <div className="">
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">My Axolo Profile</h1>
        <Table
          columns={columns}
          dataSource={[engineer]}
          pagination={false}
          rowKey="id"
          loading={loading}
        />
      </div>
      <br></br>
      <ReminderSetup isAdmin={false} />
      {organization.isEnterprise ? null : (
        <>
          {' '}
          <h1 className=" text-xl font-semibold text-gray-900">General settings</h1>
          <div className="flex justify-between">
            <p>Become an admin on Axolo to edit the settings.</p>
            <ButtonRequest
              axios={axios}
              URL={`${URLBACK}organizations/request-admin-right?admin=${adminId}&engineer=${engineerId}`}
              text={`Request ${adminUsername?.split('_')[0]} admin access`}
              adminName={adminUsername?.split('_')[0]}
            />
          </div>
        </>
      )}
    </div>
  )
}
