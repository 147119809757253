import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Twemoji } from 'react-emoji-render'
import axios from 'axios'
import { URLBACK } from '../../assets/urls'
import { CheckIcon } from '@heroicons/react/solid'
import { useEffectOnce } from 'react-use'
import { Button, Avatar, Image, Spin, Input } from 'antd'
import { RandomAxoloAvatar } from '../../components/RandomAxoloAvatar'
import { UserOutlined } from '@ant-design/icons'
import HomeFooter from '../FooterHomepage/footerHomePage'
import messageInteraction from './../../components/Analytics/utils/messageInteraction'
import { UserAvatar } from '../OpenChannels/userAvatar'

const GitLabPersonalToken = ({
  isAuthed,
  setisAuthed,
  token,
  enterGitLabToken,
  setToken,
}) => {
  return (
    <div>
      {isAuthed ? (
        <div>
          <Button
            onClick={() => setisAuthed(false)}
            className="!inline-flex items-center rounded-md border border-transparent bg-gray-400 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            GitLab Personal Token up to date
          </Button>
          <p>Click the button to update your token</p>
        </div>
      ) : (
        <div>
          <p>
            Fill in your{' '}
            <a
              href="https://gitlab.com/-/profile/personal_access_tokens"
              target="_blank"
              rel="noreferrer"
            >
              personal GitLab access
            </a>{' '}
            <p>
              token below (scopes <strong>api and user_read</strong> are required).
            </p>
            <br></br>
            <p>Tip: Set no expiry date or in at least a year.</p>
          </p>
          <Input.Password
            value={token}
            onChange={(e) => {
              setToken(e.target.value)
            }}
            onPressEnter={() => enterGitLabToken()}
            placeholder="glpat-c1eHzS-Jcyg8qMBJoTmX"
          ></Input.Password>
          <Button
            onClick={() => enterGitLabToken()}
            className="!hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500 mt-5 !inline-flex items-center !rounded-md !border !border-transparent !bg-primary px-6 py-3 !text-base !font-medium !text-white !shadow-sm"
          >
            Validate
          </Button>
        </div>
      )}
    </div>
  )
}

const GitlabAuth = ({ authentication }) => {
  let { state } = useParams()
  const [token, setToken] = useState('')
  const [isAuthed, setisAuthed] = useState(authentication.gitlabAuth)
  const showPersonalToken = authentication.gitlabpersonaltoken

  const enterGitLabToken = async () => {
    if (token.length < 20) {
      return messageInteraction({
        type: 'error',
        content: 'Token should be at least 20 chars',
      })
    }
    const hide = messageInteraction({
      content: 'Testing your token...',
      type: 'loading',
      duration: 0,
    })
    try {
      const res = await axios.post(
        `${URLBACK}engineer/savePersonalToken`,
        {
          engineerId: state,
          token,
        },
        {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
          },
        },
      )

      if (res.status === 200) {
        messageInteraction({ type: 'success', content: 'Token saved' })
        setisAuthed(true)
      } else {
        throw new Error('Error saving token', res)
      }
    } catch (error) {
      messageInteraction({
        type: 'error',
        content:
          'Error validating your token, make sure the api and read_user scopes are ticked, try again or contact support.',
      })
    }
    hide()
  }

  return (
    <div className="">
      <UserAvatar record={authentication} size={120} />
      <p className="">{authentication.providerLogin}</p>
      {showPersonalToken ? (
        <GitLabPersonalToken
          isAuthed={isAuthed}
          setisAuthed={setisAuthed}
          authentication={authentication}
          token={token}
          enterGitLabToken={enterGitLabToken}
          setToken={setToken}
        />
      ) : authentication.gitlabAuth ? (
        <Button
          disabled={true}
          className="!inline-flex items-center rounded-md border border-transparent bg-gray-400 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          GitLab oAuth successful
        </Button>
      ) : (
        <a href={authentication.gitlabUrl}>
          <Button className="!hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500 !inline-flex items-center !rounded-md !border !border-transparent !bg-primary px-6 py-3 !text-base !font-medium !text-white !shadow-sm">
            Sign in with GitLab
          </Button>
        </a>
      )}
    </div>
  )
}

const GithubAuth = ({ authentication }) => {
  return (
    <div className="">
      <UserAvatar record={authentication} size={120} />
      <p className="">{authentication.providerLogin}</p>

      {authentication.githubAuth ? (
        <Button
          disabled={true}
          className="!inline-flex items-center rounded-md border border-transparent bg-gray-400 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          GitHub oAuth successful
        </Button>
      ) : (
        <a href={authentication.githubUrl}>
          <Button className="!hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500 !inline-flex items-center !rounded-md !border !border-transparent !bg-primary px-6 py-3 !text-base !font-medium !text-white !shadow-sm">
            Sign in with GitHub
          </Button>
        </a>
      )}
    </div>
  )
}

const SlackAuth = ({ authentication }) => {
  const src = authentication?.slackAvatar
    ? authentication?.slackAvatar
    : 'https://pbs.twimg.com/media/EWAJB4WUcAAje8s.png'
  return (
    <div className="">
      <Avatar
        size={120}
        icon={<UserOutlined />}
        src={<Image src={src} preview={false} />}
      />
      <p className="">{authentication.slackName || authentication.userName}</p>

      {authentication.slackAuth ? (
        <Button
          disabled={true}
          className="!inline-flex items-center rounded-md border border-transparent bg-gray-400 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          Slack oAuth successful
        </Button>
      ) : (
        <a href={authentication.slackUrl}>
          <Button className="!hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500 !inline-flex !items-center !rounded-md !border !border-transparent !bg-primary !px-6 !py-3 !text-base !font-medium !text-white !shadow-sm">
            Sign in with Slack
          </Button>
        </a>
      )}
    </div>
  )
}

export default function AuthSuccess() {
  let { state } = useParams()
  const [authentication, setAuthentication] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffectOnce(async () => {
    try {
      const resAuthentication = await axios.get(
        `${URLBACK}player/isEngineerAuthed/${state}`,
      )
      setAuthentication(resAuthentication.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(true)
      console.log('error:', e)
    }
  })
  let authedMessage = 'Please sign in with GitHub & Slack'
  let fullyAuthed = false
  if (
    authentication.slackAuth &&
    (authentication.githubAuth || authentication.gitlabAuth)
  ) {
    authedMessage = "Thanks! You're all set"
    fullyAuthed = true
  } else if (authentication.slackAuth) authedMessage = 'Please sign with GitHub'
  else if (authentication.githubAuth) authedMessage = 'Please sign in with Slack'
  const { provider } = authentication
  if (provider === 'gitlab') {
    authedMessage = authedMessage.replace('GitHub', 'GitLab')
  }

  if (error)
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center text-center ">
        <img
          className="top-10 mb-10 h-10 w-auto sm:h-20"
          src="/logo_axolo.png"
          alt="Logo Axolo"
        />
        <div className="max-w-[800px] rounded-xl bg-textWhite">
          <div className="my-12 mx-10">
            <p className=" text-lg">Oups... Unknown error.</p>
            <p className=" text-lg">Refresh or contact support.</p>
            <div className="flex justify-center">
              <RandomAxoloAvatar />
            </div>
          </div>
        </div>
      </div>
    )
  if (loading)
    return (
      <div className=" h-screen bg-white">
        <div className="mb-[20px] flex h-[88vh] flex-col justify-center bg-white py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
            <img className="mx-auto h-12 w-auto" src="/logo_axolo.png" alt="Logo Axolo" />
            <div className="">
              <h2 className="text-ourBlack mt-6 text-center text-3xl font-bold">
                Loading
              </h2>
              <div className="mt-24 flex justify-center">
                <Spin spinning />
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    )
  return (
    <div className=" h-screen bg-white">
      <div className="mb-[20px] flex h-[88vh] flex-col items-center justify-center py-12 sm:px-6 lg:px-8">
        <img
          className="mx-auto mb-8 h-12 w-auto"
          src="/logo_axolo.png"
          alt="Logo Axolo"
        />
        <div className="max-w-[800px] rounded-xl bg-textWhite text-center ">
          <div className="my-12 inline-grid justify-items-center">
            <p className=" text-lg">{authedMessage}</p>
            {fullyAuthed ? <Twemoji text="🎉" className="pb-10 text-5xl" /> : null}
            <div className="mx-12 mt-12 grid grid-cols-2 gap-12 rounded-xl">
              {provider === 'gitlab' ? (
                <GitlabAuth authentication={authentication} />
              ) : (
                <GithubAuth authentication={authentication} />
              )}
              <SlackAuth authentication={authentication} />
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  )
}
