import { Organization } from '../reducers/organizationReducer'
import { setInitialPlayers } from '../reducers/playersReducer'

export default async function getOrg({ orgId, dispatch, axios }) {
  const [info, playersInfo] = await Promise.all([
    axios.get(`/organizations/p/onboarding/${orgId}`),
    axios.get(`/player/get-players-onboarding/${orgId}`),
  ])

  const { org, setting, paymentPlan } = info.data
  if (info?.data?.org?.id?.length > 0) {
    dispatch(
      Organization.actions.setData({
        ...org,
        setting,
        paymentPlan,
      }),
    )
  }
  if (playersInfo.status === 200 && playersInfo.data.length > 0) {
    dispatch(setInitialPlayers({ players: playersInfo.data }))
    dispatch(
      Organization.actions.setData({
        players: playersInfo.data,
      }),
    )
  }
}
