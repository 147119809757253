import { Spin } from 'antd'
import React from 'react'

import { Twemoji } from 'react-emoji-render'
import { useLocation } from 'react-router'
import HomeFooter from '../FooterHomepage/footerHomePage'

export const AuthError = () => {
  const location = useLocation()
  if (location.search.includes('reason')) {
  }

  let errorText = 'Unknown error. Please try again or contact support.'
  if (location.search.includes('reason=differentEmails')) {
    errorText =
      "GitHub account's email address is different from the one used to sign up on Slack, or your GitHub email address is not verified."
  }
  console.log('location.search', location.search)
  if (location.search.includes('message=Engineer%20mismatch')) {
    errorText =
      "Your Slack account's is different from the one you've been onboarded with, contact your admin or support to resolve this issue."
  }

  return (
    <div className=" h-screen bg-white">
      <div className="mb-[20px] flex h-[88vh] flex-col justify-center bg-white py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
          <img className="mx-auto h-12 w-auto" src="/logo_axolo.png" alt="Logo Axolo" />
          <div className="">
            <h2 className="text-ourBlack mt-6 text-center text-3xl font-bold">
              Error during the OAuth process
            </h2>
            <div className="mx-8 mt-24 flex justify-center  text-center lg:mx-20">
              <p className="">{errorText}</p>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  )
}
