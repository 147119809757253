import { fetchPullRequestsAndUpdateReducer } from './fetchPullRequest'

// Functions for the search query that avoids calling the API too often
// Everytime the user types

export function debounce(func, wait) {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const fetchWithSearchQuery = async ({
  value,
  setSearchLoading,
  organization,
  user,
  axios,
  dispatch,
  Organization,
  isMountedRef,
}) => {
  setSearchLoading(true)
  await fetchPullRequestsAndUpdateReducer({
    organization,
    user,
    axios,
    Organization,
    dispatch,
    isMountedRef,
    searchQuery: value,
    source: 'debounce',
  })
  setSearchLoading(false)
}

export const debouncedFetch = debounce(fetchWithSearchQuery, 500)
