import { useDispatch, useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import { Setting } from '../../../reducers/settingReducer'
import PullRequestLengthGraph from '.'
import messageInteraction from '../utils/messageInteraction'
import { useState, useEffect } from 'react'
import { useAxiosWithHeader } from '../../../utils'
import { URLBACK_VELOFRAME } from '../../../assets/urls'
import { createStandardQuery } from '../utils/query'

export default function PullRequestLengthContainer({ fields = 'providerId,additions,deletions,providerCreatedAt', pullRequestRelations }) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const axios = useAxiosWithHeader('analytics')

  const setting = useSelector(Setting.selectors.getSetting)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const organizationId = analytics.id
    setLoading(true)
    async function getPullRequestLength({ axios, setting, organizationId, dispatch }) {
      try {
        if (!setting.loadingInitialQuery) {
          const res = await axios.get(
            `${URLBACK_VELOFRAME}api/pull-requests/fetchPullRequestLength?fields=${fields}&pullRequestRelations=${pullRequestRelations}&${createStandardQuery(
              { setting, organizationId },
            )}`,
          )
          dispatch(Analytics.actions.setData({ pullRequestLength: res.data }))
        }
      } catch (e) {
        messageInteraction({
          content: 'Error in get pull request length. Please refresh or contact support.',
          type: 'error',
        })
        setError(true)
        console.log('Error get pull request length: ', e)
      }
      setLoading(false)
    }

    getPullRequestLength({ axios, setting, organizationId, dispatch })
  }, [
    setting.startDate,
    setting.endDate,
    setting.selectedRepositories,
    setting.selectedTeams,
  ])

  return <PullRequestLengthGraph loading={loading} error={error} />
}
