import { useEffect, useState } from 'react'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Engineer } from '../../reducers/engineerReducer'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { LoadingTeamChannelData, MasterTableContainer } from './teamChannelTableHelper'
import { cloneDeep, sortBy, partition } from 'lodash'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { Popover, Select } from 'antd'
import TeamChannelInputTable from '../TeamChannels/inputTable'
import { TeamChannelTableData } from './channelData'
import { InfoCircleTwoTone } from '@ant-design/icons'
import PlayersNotInTeamChannels from '../TeamChannels/PlayersNotInTeamChannels'

export const allText = 'All Axolo onboarded users'

const { Option } = Select

export default function TeamChannelTable({
  loadingTeamChannels,
  isAdmin = false,
  onlyTable = false,
  preFillAxoloGeneral = false,
  setHasCreatedChannel,
  onboarding = false,
}) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { teams = [], setting } = organization
  const { channels = [] } = setting
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [newChannelState, setNewChannelState] = useState(undefined)
  const pull = organization.provider === 'github' ? 'pull' : 'merge'

  let myChannels, teamChannelCreatedByOthers
  if (isAdmin) {
    myChannels = channels
    teamChannelCreatedByOthers = []
  } else {
    ;[myChannels, teamChannelCreatedByOthers] = partition(channels, (el) => {
      return el.creator?.providerLogin === engineer.providerLogin
    })
  }

  const [teamChannelCreatedByMe, setTeamChannelCreatedByMe] = useState(myChannels)

  useEffect(() => {
    if (isAdmin) {
      setTeamChannelCreatedByMe(channels)
    } else {
      setTeamChannelCreatedByMe(
        channels?.filter((channel) => channel?.creator?.id === engineer?.id),
      )
    }
  }, [setting.channels])

  const onDelete = async ({ channelTitle, desactivate = false }) => {
    const hide = message.loading('loading...')
    try {
      let newSettings = cloneDeep(setting)
      newSettings.channels = newSettings.channels.filter(
        (channel) => channel.title !== channelTitle,
      )
      const updatedSettingsResp = await axios.put(`/settings/${setting._id}`, {
        channels: newSettings.channels,
        updatingTeamChannel: true,
        desactivate,
      })
      hide()
      if (updatedSettingsResp.status === 200) {
        if (
          typeof updatedSettingsResp.data === 'string' &&
          updatedSettingsResp.data.includes('Error')
        ) {
          hide()
          messageInteraction({
            content: updatedSettingsResp.data,
            type: 'error',
          })
        } else {
          hide()
          const message = `Your channel has been ${
            desactivate ? 'disabled' : 'archived'
          }.`
          messageInteraction({
            content: message,
            type: 'warning',
          })
          dispatch(
            Organization.actions.setData({
              ...organization,
              setting: updatedSettingsResp.data,
            }),
          )
        }
      }
    } catch (error) {
      hide()
      console.log('Could not archive channel, error:', error)
      messageInteraction({
        content: "Couldn't archive your channel, try again or contact support",
        type: 'error',
      })
    }
  }

  const handleSelectTeamsOnExistingChannel = async (teams, channel) => {
    const newChannel = { ...channel }
    newChannel.teams = teams.map((team) => team.key)
    setNewChannelState(newChannel)
    const newChannels = cloneDeep(teamChannelCreatedByMe)
    const index = newChannels.findIndex((chan) => chan.id === newChannel.id)
    newChannels[index].teams = teams.map((team) => team.key)
    setTeamChannelCreatedByMe(newChannels)
  }

  const validateTeams = async () => {
    const hide = messageInteraction({
      content: '',
      type: 'loading',
      duration: 0,
    })

    if (!newChannelState) {
      hide()
      return
    }
    const newChannel = newChannelState
    try {
      const updatedSettingsResp = await axios.put(`/settings/${setting._id}`, {
        channel: newChannel,
        updatingTeamsInSpecificChannel: true,
      })
      if (updatedSettingsResp.status === 200) {
        if (
          typeof updatedSettingsResp.data === 'string' &&
          updatedSettingsResp.data.includes('Error')
        ) {
          messageInteraction({
            content: updatedSettingsResp.data,
            type: 'error',
          })
        } else {
          messageInteraction({
            content: 'Your teams have been updated.',
            type: 'success',
          })
          setNewChannelState(undefined)
          dispatch(Organization.actions.setData({ setting: updatedSettingsResp.data }))
        }
      }
      if (updatedSettingsResp.status === 204) {
        messageInteraction({
          content: 'Need at least one team to update your channel.',
          type: 'warning',
        })
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
      if (error?.response?.status === 402) {
        messageInteraction({
          content: 'This feature is for Business plan only.',
          type: 'error',
        })
      } else {
        console.log("Couldn't modify teams", error)
        messageInteraction({
          content: "Couldn't modify teams, try again or contact support.",
          type: 'error',
        })
      }
      hide()
    }
    hide()
  }

  const children = []
  // creates the 'All Axolo onboarded users' option
  children.push(
    <Option key={allText} value={allText} id={allText}>
      {allText}
    </Option>,
  )

  const teamsOrdered = sortBy(teams, function (t) {
    return t?.name?.toLowerCase()
  })

  for (let i = 0; i < teamsOrdered.length; i++) {
    const team = teamsOrdered[i]
    if (team?.players?.length > 0) {
      children.push(
        <Option key={team.id} value={team.name} id={team.id}>
          {team.name}
        </Option>,
      )
    }
  }

  const TeamChannelHeader = ({ isMyChannels }) => {
    if (onlyTable) return null
    return (
      <>
        <h1 className="mt-2 text-xl font-semibold text-gray-900">
          {isMyChannels ? 'Your team channels' : "Other's team channels"}
        </h1>
        {isMyChannels && (
          <div className="">
            <div className="flex overflow-visible">
              <p>
                Team channels notify their team members whenever someone creates a new{' '}
                {pull} request.
                <span className="pl-3"></span>
              </p>
              <Popover
                content={
                  <div className="max-w-[650px]">
                    <p>
                      Axolo will send new {pull} request notifications in team channels
                      and update its content upon different actions such as a reviewer
                      added its review, or the {pull} request status changed (draft,
                      awaiting review, work in progress, mergeable, ...)
                    </p>
                    <div className="flex justify-center">
                      <img
                        src="/images/teamchannelnotif.png"
                        alt="Axolo general notification for pull request status"
                        className="max-w-[450px]"
                      />
                    </div>
                  </div>
                }
              >
                <InfoCircleTwoTone twoToneColor="#0049ff" />
              </Popover>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <div className=" flex flex-col overflow-visible">
      <div className="-my-2 overflow-visible  ">
        <div className="inline-block min-w-full overflow-visible pb-2 align-middle ">
          {/* loading table */}
          {loadingTeamChannels && (
            <>
              <TeamChannelHeader isMyChannels={true} />
              <MasterTableContainer provider={organization?.provider}>
                <LoadingTeamChannelData />
              </MasterTableContainer>
            </>
          )}
          {/* Personal team channels or every team channel if admin */}
          {!loadingTeamChannels && (
            <>
              <TeamChannelHeader isMyChannels={true} />
              <MasterTableContainer provider={organization?.provider}>
                <TeamChannelInputTable
                  setLoading={setLoading}
                  loading={loading}
                  children={children}
                  old={false}
                  preFillAxoloGeneral={preFillAxoloGeneral}
                  allText={allText}
                  setHasCreatedChannel={setHasCreatedChannel}
                  onboarding={onboarding}
                />
                <TeamChannelTableData
                  handleSelectTeamsOnExistingChannel={handleSelectTeamsOnExistingChannel}
                  validateTeams={validateTeams}
                  newChannelState={newChannelState}
                  onDelete={onDelete}
                  channels={teamChannelCreatedByMe}
                  personal={true}
                />
              </MasterTableContainer>
              {isAdmin && <PlayersNotInTeamChannels />}
            </>
          )}
          <br className="pt-8" />
          {/* Other team channels */}
          {teamChannelCreatedByOthers?.length > 0 && (
            <>
              <TeamChannelHeader isMyChannels={false} />
              <MasterTableContainer provider={organization?.provider}>
                <TeamChannelTableData
                  channels={teamChannelCreatedByOthers}
                  personal={false}
                />
              </MasterTableContainer>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
