import React from 'react'

import { useParams } from 'react-router-dom'

export default function GithubInstalled() {
  let { user } = useParams()

  return (
    <div className="h-screen w-screen flex justify-center items-center flex-col">
      <img
        className="absolute top-10 h-10 w-auto sm:h-20"
        src="/logo_axolo.png"
        alt="Logo Axolo"
      />
      <h1 className="px-10 pb-10 text-3xl text-textWhite text-center">
        🎉 Congratulations {user}, you've successfully accepted to install Axolo on your
        Github
      </h1>
      <p className="text-textWhite font-medium px-10 text-center pb-5">
        The requester is being notified to finish Axolo's onboarding.
      </p>
    </div>
  )
}
