import { Avatar, Tooltip } from 'antd'
import { UserAvatar } from '../../pages/OpenChannels/userAvatar'

export default function GitHubUsers({ githubUsers }) {
  return (
    <Avatar.Group maxCount={3}>
      {githubUsers?.map((engineer) => (
        <Tooltip title={engineer?.providerLogin}>
          <UserAvatar record={engineer} />
        </Tooltip>
      ))}
    </Avatar.Group>
  )
}
