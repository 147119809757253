import HomeFooter from '../../pages/FooterHomepage/footerHomePage'
import React, { useState } from 'react'
import { LogOutButton } from '../logoutButton'
import { useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { createAxios } from '../../utils'
import { URLBACK } from '../../assets/urls'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { Redirect } from 'react-router-dom'

export default function WrongWorkSpace({
  workSpaceUrl,
  workspaceName,
  previousWorkspace,
}) {
  const [showMessage, setShowMessage] = useState(false)
  const user = useSelector(User.selectors.selectUser)
  const axios = createAxios(user.jwt)
  const { authedUser, tempCredential } = user
  const [redirectTo, setRedirectTo] = useState(null)
  const [explanation, setExplanation] = useState(false)

  const handleRedirect = () => {
    setRedirectTo({
      pathname: '/',
      state: { fromWrongWorkspace: true },
    })
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  const switchCredential = async () => {
    try {
      const response = await axios.post(`${URLBACK}credentials/switch`, {
        authedUser: authedUser,
        tempCredential: tempCredential,
      })
      if (response.status === 200) {
        messageInteraction({
          content: 'Successfully switched workespace!',
          type: 'success',
        })
        handleRedirect('/')
      } else {
        messageInteraction({
          content:
            'An error occurred while switching credentials. Please contact support.',
          type: 'error',
        })
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setExplanation(true)
        messageInteraction({
          content:
            'Please refer to our help article on how to delete your organization and recreate one.',
          type: 'info',
        })
      } else {
        messageInteraction({
          content:
            'An error occurred while switching credentials. Please contact support.',
          type: 'error',
        })
      }
      console.error('Error switching credential:', error)
    }
  }

  const handleYesClick = () => {
    setShowMessage(true)
  }
  return (
    <div className="h-screen bg-white">
      <div className="flex h-[88vh] flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
          <img className="mx-auto h-12 w-auto" src="/logo_axolo.png" alt="Logo Axolo" />
          <div className="flex flex-col items-center ">
            <h2 className="text-ourBlack mt-6 text-center text-xl font-bold">
              You're attempting to log in to a different workspace:
            </h2>
            <div className="mt-4 flex flex-row items-center space-y-2">
              <img
                className="mx-auto mr-2 h-10 w-10 rounded-sm object-cover"
                src={workSpaceUrl}
                alt="Workspace Logo"
              />
              <div className="text-2xl font-semibold">{workspaceName}</div>
              {/* Add any additional metadata here */}
            </div>
            <br></br>
            <h2 className="text-ourBlack mt-6 text-center text-xl font-bold">
              {showMessage
                ? `Sign in with the correct workspace`
                : `Do you wish to switch to ${workspaceName} workspace, or stay with 
              ${previousWorkspace}?`}
            </h2>
            <div className="flex flex-row space-x-4">
              {showMessage ? (
                <div className="mt-4 flex flex-col items-center">
                  <div className="mt-2 text-center text-lg">
                    After logging out, make sure to select the right workspace like in the
                    image below
                  </div>
                  <LogOutButton />
                  <br></br>
                  <img
                    src="/images/workspace.jpg"
                    alt="How to change workspace"
                    className="h-[275px] w-[408px]"
                  />
                  <br></br>
                  <br></br>
                  <button
                    className="text-gray-500 underline hover:text-gray-700"
                    onClick={() => setShowMessage(false)}
                  >
                    Go Back
                  </button>
                </div>
              ) : (
                <div className="flex flex-row space-x-4">
                  <button
                    onClick={() => {
                      handleYesClick()
                      setExplanation(false)
                    }}
                    type="button"
                    className="rounded-md border-none bg-primary px-3 py-1 text-textWhite hover:bg-indigo-700"
                  >
                    Stay with {previousWorkspace}
                  </button>
                  <button
                    onClick={() => switchCredential()}
                    // () => {
                    //   window.$crisp.push(['do', 'chat:open'])
                    //   window.$crisp.push([
                    //     'do',
                    //     'message:send',
                    //     [
                    //       'text',
                    //       `Hi! please help me switch workspace on my user account from ${previousWorkspace} to ${workspaceName}`,
                    //     ],
                    //   ])
                    // }
                    type="button"
                    className="rounded-md border-none bg-primary px-3 py-1 text-textWhite hover:bg-indigo-700"
                  >
                    Switch to the new workspace
                  </button>
                </div>
              )}
            </div>
            {explanation && (
              <div className="mt-4 flex flex-col items-center">
                <div className="mt-2 bg-yellow-100 p-4 text-center text-lg">
                  <strong>⚠️ Attention:</strong> It seems your user is linked to an
                  organization, if you would like to change workspace you need to
                  uninstall axolo and reinstall it completely on the new workspace, here
                  is how you can do it:{' '}
                  <a
                    href="https://help.axolo.co/en/article/how-to-delete-all-my-data-on-axolo-1tg8xq9/"
                    className="text-blue-500 underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    How to delete my organization on Axolo
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  )
}
