function handleClick() {
  if (process.env.NODE_ENV === 'production') {
    window.analytics.track('Click on feedback button')
  }
  window.$crisp.push(['do', 'chat:open'])
}

export default function FeedbackButton({ text }) {
  return (
    <button
      type="button"
      className="inline-flex items-center px-4 py-1 border border-transparent text-base rounded-lg shadow-sm text-white bg-primary hover:bg-[#4648B5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      onClick={() => handleClick()}
    >
      {text}
    </button>
  )
}
