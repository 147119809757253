import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { updateUser } from '../../reducers/userReducer'
import axios from 'axios'
import { useEffectOnce } from 'react-use'
import { URLBACK } from '../../assets/urls'
import { useDispatch } from 'react-redux'
import LoadingPage from '../Loading'
import { LogOutButton } from '../../components/logoutButton'

export default function RedirectionAutoReviewRequestsAfterGitlab() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [error, setError] = useState(null)

  useEffectOnce(() => {
    async function updateUserForAutoAssignReviewer() {
      try {
        const query = new URLSearchParams(location.search)
        const userId = query.get('state')
        const res = await axios.get(
          `${URLBACK}gitlab/get-user-for-auto-assign-reviewer/${userId}`,
        )
        const { user, jwt } = res.data
        dispatch(updateUser({ user: user, jwt }))
        history.push('/home/onboarding/auto-assign-reviewer-post-gitlab')
      } catch (err) {
        console.log(err)
        console.error('Error fetching user for auto-assign reviewer:', err)
        if (err.status === 400) {
          setError(
            'It seems you already have an Axolo account. Please login to Axolo from app.axolo.co and enable the Auto Assign Reviewer feature from there.',
          )
        } else {
          setError(`${err.message}`) // Set error message
        }
      }
    }

    updateUserForAutoAssignReviewer()
  })

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'white',
          color: 'black',
          flexDirection: 'column',
        }}
      >
        <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>
          Error
        </h1>
        <p style={{ fontSize: '18px' }}>
          An error occurred while processing your request. Please try again or contact
          support.
        </p>
        <p style={{ fontSize: '18px' }}>{error}</p>

        <LogOutButton to={`/redirection-auto-reviewer-onboarding`}></LogOutButton>
      </div>
    )
  }

  // Render the LoadingPage component if there's no error
  return <LoadingPage />
}
