import { useDispatch, useSelector } from 'react-redux'
import { Engineer } from '../../reducers/engineerReducer'
import messageInteraction from '../Analytics/utils/messageInteraction'
import {
  allPRStatus,
  PRStatusBgColor,
  PRStatusTextColor,
} from './helper-reminder-personalization'

import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Organization } from '../../reducers/organizationReducer'
import { cloneDeep } from 'lodash'
import { globalUpdateCompanySetting, useAxiosWithHeader } from '../../utils'

export function handleErrorReviewersTag() {
  messageInteraction({
    content:
      "The 'reviewers' text variable is only available for the 'reviewable' status",
    duration: 5,
    type: 'info',
  })
}

async function handleNewPRStatusAdded({
  value,
  index,
  dispatch,
  setting,
  axios,
  options,
  setOptions,
  message,
}) {
  if (message.includes('[reviewers]') && value?.[0] !== 'reviewable') {
    handleErrorReviewersTag()
    return
  }
  const newReminders = cloneDeep(setting.personalReminders)
  newReminders[index].status.push(value?.[0])
  await globalUpdateCompanySetting({
    key: 'personalReminders',
    value: newReminders,
    setting,
    axios,
    dispatch,
  })
  setOptions(options.filter((option) => option !== value?.[0]))
}

function handleNewPRStatusInputLine({
  statusAlreadySelect,
  value,
  options,
  setNewStatus,
  setOptions,
  message,
}) {
  if (message.includes('[reviewers]') && value?.[0] !== 'reviewable') {
    handleErrorReviewersTag()
    return
  }
  setNewStatus([...statusAlreadySelect, value?.[0]])
  setOptions(options.filter((option) => option !== value?.[0]))
}

function LabelListBox({
  statusAlreadySelect,
  index,
  axios,
  isInputLine,
  setNewStatus,
  message,
  options,
  setOptions,
}) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const [selected, setSelected] = useState([])
  const dispatch = useDispatch()

  return (
    <div className="">
      <Listbox
        value={selected}
        onChange={(value) =>
          isInputLine
            ? handleNewPRStatusInputLine({
                statusAlreadySelect,
                setNewStatus,
                value,
                options,
                setOptions,
                message,
              })
            : handleNewPRStatusAdded({
                value,
                index,
                dispatch,
                setting,
                axios,
                options,
                setOptions,
                message,
              })
        }
        multiple
      >
        <div className="flex ">
          <Listbox.Button className="relative h-[31px] w-[120px] cursor-default rounded-lg border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-36 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options?.map?.((status, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-indigo-100 text-primary' : 'text-gray-900'
                    }`
                  }
                  value={status}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {status}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

const StatusLabel = ({
  status,
  indexStatus,
  reminder,
  handlePRStatusClick,
  engineer,
  isInputLine = false,
  isAdmin,
}) => {
  const bgColor = PRStatusBgColor[status]
  const textColor = PRStatusTextColor[status]
  return (
    <span
      className={`inline-flex items-center rounded-full py-0.5 pl-2.5 pr-1 text-sm font-medium ${bgColor}  ${textColor}`}
    >
      {status}
      {/* add button if it is the input line or one reminder created by the user */}
      {isInputLine || reminder?.creator?.id === engineer?.id || isAdmin ? (
        <button
          type="button"
          onClick={() => handlePRStatusClick(indexStatus)}
          className={`ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full  focus:text-white focus:outline-none`}
        >
          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      ) : (
        <span className="mr-1.5" />
      )}
    </span>
  )
}

export const ReminderPRStatus = ({ reminder, indexReminder, isAdmin }) => {
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const dispatch = useDispatch()
  const [options, setOptions] = useState(
    allPRStatus.filter((status) => !reminder?.status?.includes(status)),
  )

  async function handlePRStatusClick(indexStatus) {
    if (reminder.status?.length <= 1) {
      messageInteraction({
        content: 'You need at least one PR status per reminder.',
        type: 'error',
      })
      return
    }
    let newReminders = cloneDeep(setting.personalReminders)
    const deletedStatus = newReminders?.[indexReminder]?.status?.splice(indexStatus, 1)
    await globalUpdateCompanySetting({
      key: 'personalReminders',
      value: newReminders,
      setting,
      axios,
      dispatch,
    })
    const newOptions = cloneDeep(options)
    newOptions.push(deletedStatus[0])
    setOptions(newOptions)
    dispatch(
      Organization.actions.setData({
        setting: { ...setting, personalReminders: newReminders },
      }),
    )
  }

  return (
    <div className="flex flex-wrap  items-center gap-1">
      {reminder.status.map((status, index) => {
        return (
          <div className="" key={index}>
            <StatusLabel
              status={status}
              indexStatus={index}
              engineer={engineer}
              handlePRStatusClick={handlePRStatusClick}
              reminder={reminder}
              isAdmin={isAdmin}
            />
          </div>
        )
      })}
      {/* we can only add new status if we don't already have all of them and if we are the creator of the reminder */}
      {((reminder.status?.length < allPRStatus.length &&
        reminder.creator?.id === engineer.id) ||
        isAdmin) &&
        // we add this only if the reminder does not have all status selected
        reminder?.status?.length < allPRStatus.length && (
          <LabelListBox
            statusAlreadySelect={reminder?.status}
            index={indexReminder}
            axios={axios}
            message={reminder?.message}
            options={options}
            setOptions={setOptions}
          />
        )}
    </div>
  )
}

export const InputNewReminderStatus = ({
  newStatus = [],
  setNewStatus,
  newMessage,
  options,
  setOptions,
}) => {
  function handlePRStatusClickOnInputLine(indexStatus) {
    const updatedStatus = cloneDeep(newStatus)
    const deletedStatus = updatedStatus.splice(indexStatus, 1)
    setNewStatus(updatedStatus)
    const newOptions = cloneDeep(options)
    newOptions.push(deletedStatus[0])
    setOptions(newOptions)
  }

  return (
    <div className="flex flex-wrap  items-center gap-1">
      {newStatus?.map?.((status, index) => {
        return (
          <div className="" key={index}>
            <StatusLabel
              status={status}
              indexStatus={index}
              handlePRStatusClick={handlePRStatusClickOnInputLine}
              setNewStatus={setNewStatus}
            />
          </div>
        )
      })}

      {/* we add this only if the user did not already select every status */}
      {newStatus?.length < allPRStatus.length && (
        <LabelListBox
          statusAlreadySelect={newStatus}
          isInputLine={true}
          setNewStatus={setNewStatus}
          message={newMessage}
          options={options}
          setOptions={setOptions}
        />
      )}
    </div>
  )
}
