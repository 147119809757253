import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { useHistory } from 'react-router'
import { User } from '../../reducers/userReducer'
import { useSelector } from 'react-redux'
import { URLBACK } from '../../assets/urls'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'

export default function ModalAskedAdminAccess({ isOpen, setIsOpen, axios }) {
  const history = useHistory()
  const user = useSelector(User.selectors.selectUser)

  const request = async () => {
    try {
      const res = await axios.get(
        `${URLBACK}organizations/request-admin-right?organizationId=${user.organizations[0].id}&engineer=${user.id}`,
      )
      const adminName = res.data.split(':')[1]
      if (res.status === 200)
        messageInteraction({
          content: `Message successfully sent to ${adminName}`,
          type: 'success',
        })
    } catch (e) {
      messageInteraction({
        content: `An error occured, please try again or contact us.`,
        type: 'error',
      })
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-yellow-600"
                      aria-hidden="true"
                    />
                  </div>

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      You do not have admin access
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Request you admins to grant you admin privileges to have access to
                        the billing section. Once you've ascended to the rank of admin,
                        you'll need to log out and log back in to complete the process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => {
                      request()
                    }}
                  >
                    Ask my admins
                  </button>
                </div>
                <div
                  className="flex justify-center"
                  onClick={() => history.push('/engineer/settings/general')}
                >
                  <button className="pt-3 text-center italic underline">Ignore</button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
