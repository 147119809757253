import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />

export default function StackedBarLoading() {
  return (
    <div className="flex h-[160px] items-center justify-center">
      <Spin className="mt-4 mb-14" indicator={antIcon} />
    </div>
  )
}
