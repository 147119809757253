import { StopOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Engineer } from '../../reducers/engineerReducer'
import { Organization } from '../../reducers/organizationReducer'
import { globalUpdateCompanySetting, useAxiosWithHeader } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { AxoloTrashIcon } from '../Settings/setting-helper'

export const ReminderAction = ({ reminder, indexReminder, isAdmin }) => {
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()

  async function deleteReminder() {
    const deletedReminder = setting.personalReminders?.[indexReminder]
    const reminderStatus = deletedReminder?.status
    const statusOccurence = {
      wip: 0,
      draft: 0,
      reviewable: 0,
      mergeable: 0,
    }
    for (const reminder of setting?.personalReminders) {
      for (const status of reminder?.status) {
        statusOccurence[status] += 1
      }
    }
    for (const status of reminderStatus) {
      if (statusOccurence?.[status] <= 1) {
        messageInteraction({
          content: `You need at least one reminder of the ${status} status. `,
          type: 'info',
        })
        return
      }
    }
    let newReminders = cloneDeep(setting.personalReminders)
    newReminders?.splice(indexReminder, 1)

    await globalUpdateCompanySetting({
      key: 'personalReminders',
      value: newReminders,
      setting,
      axios,
      dispatch,
    })

    return
  }
  if (!(reminder?.creator?.id === engineer.id || isAdmin)) {
    return (
      <>
        <Tooltip title="You can't take actions on reminders that are not yours.">
          <Button icon={<StopOutlined />} shape="circle" style={{ color: 'grey' }} />
        </Tooltip>
      </>
    )
  } else {
    return (
      <Button
        icon={<AxoloTrashIcon />}
        shape="circle"
        type="danger"
        onClick={() => deleteReminder()}
      />
    )
  }
}
