export const TeamChannelTableColumn = ({ provider = 'github' }) => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
        >
          Channel
        </th>
        {provider === 'github' && (
          <th
            scope="col"
            className="!w-[100px] py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
          >
            Option
          </th>
        )}
        <th
          scope="col"
          className="w-[500px] px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          {provider === 'github' ? 'Repo or Teams' : 'Projects'}
        </th>
        <th
          scope="col"
          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          Creator
        </th>

        <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Action</span>
        </th>
      </tr>
    </thead>
  )
}
