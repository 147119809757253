import React, { useState } from 'react'
import { Modal, Button, Form, Input, Spin, message } from 'antd'
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useAxiosWithHeader } from '../../utils'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { useDispatch, useSelector } from 'react-redux'

const CancelSubscriptionModal = () => {
  const organization = useSelector(Organization.selectors.getOrganization)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  const [isCanceled, setIsCanceled] = useState(false)
  const [feedback, setFeedback] = useState('')

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setStep(1)
    setIsLoading(false)
  }

  const handleKeepSubscription = () => {
    // Define the action for keeping the subscription
    // For example, just close the modal
    setIsModalVisible(false)
    setStep(1)
  }

  const isFeedbackValid = () => {
    return feedback.trim().length > 0 // Check if feedback is not just whitespace
  }

  const submitFeedback = async (values) => {
    if (!isFeedbackValid()) {
      message.error('Please provide feedback before cancelling.')
      return
    }

    setIsLoading(true)
    try {
      setStep(2)
      try {
        await axios.post(`${URLBACK}cancelSubscription/${organization.id}`, {
          feedback: values.feedback,
        })
      } catch (error) {
        console.log('error', error)
      }
      dispatch(
        Organization.actions.setData({
          paymentPlan: 'starter',
          hasCanceled: true,
          stripeStatus: 'cancelled',
        }),
      )
      setIsLoading(false)
      setIsCanceled(true)
    } catch (error) {
      console.error('Error cancelling subscription:', error)
      message.error('Failed to cancel subscription. Please try again or contact support.')
      setIsLoading(false)
      setIsModalVisible(false)
    }
  }

  const renderStepContent = () => {
    if (step === 1) {
      return (
        <Form onFinish={submitFeedback}>
          <h2>Why do you want to stop your subscription?</h2>
          <Input.TextArea
            rows={4}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Example: I'm cancelling because I found a better alternative with more features."
          />
          <div className="mt-5 flex justify-end">
            <Button
              onClick={handleKeepSubscription}
              style={{
                backgroundColor: '#10B981', // Tailwind green-500
                color: 'white',
                borderColor: '#10B981',
                boxShadow: 'none',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#059669' // Tailwind green-600
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#10B981' // Tailwind green-500
              }}
              className="mr-2"
            >
              Keep my subscription
            </Button>
            <Button
              type="danger"
              danger
              onClick={submitFeedback}
              disabled={!isFeedbackValid()} // Enable button only if feedback is valid
            >
              Cancel my account
            </Button>
          </div>
        </Form>
      )
    } else if (step === 2) {
      return (
        <Spin size="large" spinning={isLoading} indicator={LoadingOutlined}>
          <div className="text-center">
            <CheckCircleOutlined className="m-4 text-6xl text-green-500" />
            <p>Your subscription has been stopped and you will not be billed further.</p>
          </div>
        </Spin>
      )
    }
  }
  if (isCanceled) {
    return <></>
  }

  return (
    <>
      <button
        onClick={showModal}
        className="mt-2 ml-2 w-fit rounded bg-red-500 py-2 px-4 text-white hover:bg-red-600"
      >
        Cancel my subscription
      </button>
      <Modal
        title={step === 1 ? 'Your feedback matters' : 'Processing Cancellation'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        afterClose={() => setStep(1)}
      >
        {renderStepContent()}
      </Modal>
    </>
  )
}

export default CancelSubscriptionModal
