import { useDispatch, useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import { Setting } from '../../../reducers/settingReducer'
import CycleTimeStackedChart from '.'
import messageInteraction from '../utils/messageInteraction'
import { useState, useEffect } from 'react'
import { useAxiosWithHeader } from '../../../utils'
import { URLBACK_VELOFRAME } from '../../../assets/urls'
import { createStandardQuery } from '../utils/query'

export default function CycleTimeStackedContainer({pullRequestRelations = 'events,review_requests,pull_request_reviews', marginTime }) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const axios = useAxiosWithHeader('analytics')
  const setting = useSelector(Setting.selectors.getSetting)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const organizationId = analytics.id
    setLoading(true)
    async function getCycleTime({ axios, setting, organizationId, dispatch }) {
      try {
        if (!setting.loadingInitialQuery) {
          const res = await axios.get(
            `${URLBACK_VELOFRAME}api/pull-requests/findCycleTime?pullRequestRelations=${pullRequestRelations}&${createStandardQuery({
              setting,
              organizationId,
            })}`,
          )
          dispatch(Analytics.actions.setData({ cycleTime: res.data }))
        }
      } catch (e) {
        setError(true)
        messageInteraction({
          content: 'Error in get cycle time. Please refresh or contact support.',
          type: 'error',
        })
        console.log('Error get cycle time: ', e)
      }
      setLoading(false)
    }
    getCycleTime({ axios, setting, organizationId, dispatch })
  }, [
    setting.startDate,
    setting.endDate,
    setting.selectedRepositories,
    setting.selectedTeams,
  ])

  return <CycleTimeStackedChart loading={loading} error={error} marginTime={marginTime} />
}
