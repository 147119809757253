import { useSelector } from 'react-redux'
import DropZone from '../../components/DropZone'
import DropZoneTeamManagementTable from '../../components/DropZone/dropZoneTeam'
import { AppContent } from '../../components/utils/utils'
import { Organization } from '../../reducers/organizationReducer'

export default function BatchImport() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { csvData } = organization

  return (
    <div>
      <div className="">
        <AppContent>
          <h1 className="mt-2 text-xl font-semibold text-gray-900">Batch importation</h1>
          <p>Onboard your engineers by importing a CSV</p>
          {csvData?.length > 0 ? (
            <DropZoneTeamManagementTable csvData={csvData} /> //
          ) : (
            <DropZone />
          )}
        </AppContent>
      </div>
    </div>
  )
}
