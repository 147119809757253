import SkeletonLoading from '../Analytics/utils/skeletonLoader'

const teamBadgeCss =
  'h-[75px] max-w-20 wrap flex-wrap items-center px-3 py-4 text-sm text-gray-500 hidden xl:flex'

export const LoadingTeamManagementTable = () => {
  return (
    <tbody>
      {[...Array(3)].map((_, index) => (
        <tr key={index} className="h-[75px]">
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            <SkeletonLoading specificCss="!w-[11vw] !bg-sky-300" />
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
            <SkeletonLoading specificCss="!w-[11vw] !bg-sky-300" />
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <SkeletonLoading specificCss="!w-[15vw] !bg-sky-300" />
          </td>
          <td className={teamBadgeCss}>
            <SkeletonLoading specificCss="!w-[15vw] !bg-sky-300" />
          </td>
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
            <SkeletonLoading specificCss="!w-[6vw] !bg-sky-300" />
          </td>
        </tr>
      ))}
    </tbody>
  )
}
