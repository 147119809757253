import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'

export default function DropZoneTeamManagementTableColumn() {
  const organization = useSelector(Organization.selectors.getOrganization)

  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
        >
          {organization.provider === 'github' ? 'GitHub' : 'GitLab'}
        </th>
        <th
          scope="col"
          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          Slack
        </th>
        <th
          scope="col"
          className="flex items-center justify-between  px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          Team
        </th>
        <th
          scope="col"
          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
        >
          <span className=""></span>
        </th>
      </tr>
    </thead>
  )
}
