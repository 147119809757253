import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { User } from '../../reducers/userReducer'
import { handleLogout } from './handleLogout'
import { useAxiosWithHeader } from '../../utils'

export const LogOutButton = ({ to = '/' }) => {
  const dispatch = useDispatch()
  const jwt = useSelector(User.selectors.selectJWT)
  const axios = useAxiosWithHeader()

  return (
    <Link onClick={() => handleLogout(jwt, dispatch, axios)} to={to}>
      <button
        to={`/`}
        type="primary"
        className="rounded-md border-none bg-primary px-3 py-1 text-textWhite hover:bg-indigo-700 hover:text-textWhite"
      >
        Log out
      </button>
    </Link>
  )
}
