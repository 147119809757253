import moment from 'moment'
import { PlayerState } from '../reducers/playersReducer'
import { Organization } from '../reducers/organizationReducer'
import { setIsSelectMembersReady } from '../reducers/organizationReducer'
import { message } from 'antd'

export async function listAllSlackUsers({ axios, dispatch }) {
  dispatch(Organization.actions.setSlackMembersCalled(true))
  const result = await axios.get(`/organizations/p/getSlackUsersFromUser`)
  const { status, data } = result
  if (status === 200 && data.length > 0) {
    dispatch(Organization.actions.setSlackMembers(data))
  }
  dispatch(Organization.actions.setSlackMembersCalled(false))
}

export default async function getOrgAndEngineers({
  invocation = 1,
  organization,
  slackAuth,
  dispatch,
  user,
  axios,
  setIsOnboarded,
  requestRefresh = false,
}) {
  if (user.loadingSwitchOrg && organization.providerLogin === undefined) {
    return
  }
  // if (organization.errorInstallation?.length > 0) {
  // history.push('/installation/error')
  //  should we prevent this function if there is an error?
  // }
  if (
    // Adding a setting check because otherwise cannot load home properly
    organization.setting &&
    organization.setting !== '' &&
    !requestRefresh &&
    organization?.players?.length > 0 &&
    organization?.lastUpdated &&
    moment(organization.lastUpdated).isAfter(moment().subtract(10, 'minutes')) &&
    !moment(organization.createdAt).isAfter(moment().subtract(1, 'hours'))
  ) {
    dispatch(setIsSelectMembersReady(true))
    return
  }

  if (user.organizations[0]?.id === undefined && organization?.id === undefined) {
    dispatch(setIsOnboarded(false))
    dispatch(setIsSelectMembersReady(true))
    return
  }

  dispatch(setIsSelectMembersReady(false))
  const { organizations } = user
  const lastOrg = organizations[organizations.length - 1]
  // Order is important here. We either take the organization that is being given as a parameter (in case of a switch from a header)
  // or we take the last one in case of login OR adding a new org
  const orgId = organization?.id || lastOrg.id
  const [info, playersInfo] = await Promise.all([
    axios.get(`/organizations/p/onboarding/${orgId}`),
    axios.get(`/player/get-players-onboarding/${orgId}`),
  ])

  const { org, setting, paymentPlan } = info.data
  if (info?.data?.org?.id?.length > 0) {
    dispatch(
      Organization.actions.setData({
        ...org,
        setting,
        paymentPlan,
      }),
    )
  }
  if (!moment(organization.createdAt).isAfter(moment().subtract(4, 'days'))) {
    try {
      // This route is to update team channels from Slack
      const channelsFromSlackUpdated = await axios.get(
        `/settings/retrieveTeamChannels/${setting.id}`,
      )
      if (channelsFromSlackUpdated.status === 200) {
        dispatch(Organization.actions.setData({ setting: channelsFromSlackUpdated.data }))
      }
    } catch (e) {
      console.log('Error in retrieve team channels', e)
    }
  }

  // Sometimes the server takes a a few seconds to get the developers list from github
  // So this parts will call this functions again every 1.5 seconds maximum 10 times
  if (playersInfo.status === 200 && playersInfo.data.length > 0) {
    const players = playersInfo.data
    dispatch(setIsSelectMembersReady(true))
    dispatch(PlayerState.actions.setInitialPlayers({ players: players }))
    dispatch(
      Organization.actions.setData({
        players: players,
      }),
    )
    const activePlayers = players?.filter((p) => p.communicationToolId?.length > 0)
    if (activePlayers?.length > 0) {
      dispatch(setIsOnboarded(true))
    } else {
      dispatch(setIsOnboarded(false))
    }
  } else {
    if (invocation < 10) {
      invocation += 1
      setTimeout(
        () =>
          getOrgAndEngineers({
            invocation,
            organization,
            slackAuth,
            dispatch,
            user,
            axios,
          }),
        1500,
      )
    } else {
      // we think slackAuth is not useful. It seems the only way we could enter this if is from a GitHub marketplace onboarding
      if (slackAuth) {
        message.error(
          "Couldn't find any engineers in your organization, reload this page or contact support",
        )
      }

      dispatch(setIsSelectMembersReady(true))
      dispatch(PlayerState.actions.setInitialPlayers({ players: org.players }))
    }
  }
}
