import { Avatar, Select, Tag, Tooltip } from 'antd'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { URLBACK } from '../../assets/urls'
import { setPlayer } from '../../reducers/playersReducer'
import { statusColors, classNames } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { ExclamationIcon } from '@heroicons/react/solid'
import { UserAvatar } from '../../pages/OpenChannels/userAvatar'
import { listAllSlackUsers } from '../../api/getOrgAndEngineers'
import { useState } from 'react'

const { Option } = Select

export const LongTextWrapper = ({ ...props }) => {
  const text = props.children
  if (!text || text?.length < 20) {
    return text
  }
  return <Tooltip title={text}>{text.substring(0, 20)}...</Tooltip>
}

export const GitHubProfile = ({ eng }) => {
  return (
    <div className="flex items-center">
      <UserAvatar record={eng} />
      <div className="ml-4">
        <div className="!font-medium text-gray-900">
          <LongTextWrapper>{eng?.providerLogin}</LongTextWrapper>
        </div>
        <div className="!font-normal text-gray-500">
          <LongTextWrapper>{eng?.email}</LongTextWrapper>
        </div>
      </div>
    </div>
  )
}

export const SlackProfile = ({ profileSlack }) => {
  return (
    <div className="flex items-center">
      <Avatar src={profileSlack?.image_192}></Avatar>
      {/* <p className="mb-0 pl-2 text-gray-800">{name}</p>{' '} */}
      <div className="ml-4 ">
        <div className="font-medium text-gray-900">
          <LongTextWrapper>{profileSlack?.real_name}</LongTextWrapper>
        </div>
        <div className="text-gray-500">
          <LongTextWrapper>{profileSlack?.email}</LongTextWrapper>
        </div>
      </div>
    </div>
  )
}

export async function handleClickRequestAuth({ eng, axios, players, dispatch }) {
  const hide = messageInteraction({
    type: 'loading',
    content: `Asking ${eng.providerLogin} to complete onboarding.`,
    duration: 0,
  })
  try {
    await axios.get(`${URLBACK}player/notifSignInWithSlack?playerId=${eng.id}`)
    const index = players.indexOf(eng)
    const newData = cloneDeep(players)
    newData[index].requestToAuthenticateSentAt = moment()
    dispatch(setPlayer(newData[index]))
    messageInteraction({
      type: 'success',
      content: `Asked ${eng.providerLogin} to complete onboarding.`,
      duration: 1,
    })
    hide()
  } catch (e) {
    hide()
    messageInteraction({
      type: 'error',
      content: `Unknown error. Please try again or contact support.`,
      duration: 1,
    })
  }
}

export const StatusBadge = ({ eng }) => {
  const status = eng.communicationToolId?.length > 0 ? 'active' : 'inactive'
  return (
    <span
      className={classNames(
        'mr-2 rounded  px-2.5 py-0.5 text-xs font-medium ',
        `bg-${statusColors?.[status]?.bg} text-${statusColors?.[status]?.text}`,
      )}
    >
      {status}
    </span>
  )
}

export const TeamBadge = ({ eng, teamColors }) => {
  const { provider, teams } = eng
  const res = []
  const maxTeam = teams.length > 3 ? 3 : teams.length
  for (let i = 0; i < maxTeam; i++) {
    res.push(
      <a
        href={`${
          provider === 'github'
            ? `${teams[i].htmlUrl}/members`
            : `${teams[i].htmlUrl}/-/group_members`
        }`}
        target="_blank"
        rel="noopener noreferrer"
        key={teams[i].slug} // Use slug as the key
      >
        <Tag style={{ marginBottom: '5px' }} color={teamColors[teams[i].name]}>
          {teams[i].name.length > 33 ? teams[i].name.slice(0, 30) + '...' : teams[i].name}
        </Tag>
      </a>,
    )
  }
  if (teams.length > 3) {
    const test = cloneDeep(teams)
    test.splice(0, 3)
    res.push(
      <Tooltip title={test.map((team) => team.name).join(', ')} key="additional-teams">
        <Tag className="!h-fit">+{teams.length - 3}</Tag>
      </Tooltip>,
    )
  }
  return res
}

export const MissingAuthBadge = ({ eng, axios, players, dispatch }) => {
  const { provider } = eng
  const providerAuthKey = `${eng.provider}Authed`
  let missingAuth = []
  const requestSentRecently = moment(eng?.requestToAuthenticateSentAt).isAfter(
    moment().subtract(1, 'days'),
  )
  if (eng.slackAuthed && eng[providerAuthKey]) return null
  if (!eng.slackAuthed) missingAuth.push('Slack')
  if (!eng[providerAuthKey]) missingAuth.push(provider === 'github' ? 'GitHub' : 'GitLab')
  if (missingAuth.length > 0)
    return (
      <WrapperForMissingSignIn eng={eng} requestSentRecently={requestSentRecently}>
        <span
          className={classNames(
            'mr-2 rounded  px-2.5 py-0.5 text-xs font-medium hover:cursor-pointer ',
            `bg-red-100 text-red-800`,
          )}
          onClick={() =>
            requestSentRecently
              ? null
              : handleClickRequestAuth({ eng, axios, players, dispatch })
          }
        >
          Missing {missingAuth.join(' and ')} sign-in
        </span>
      </WrapperForMissingSignIn>
    )
  const { slackUpToDate } = eng
  if (!slackUpToDate.isUpToDate)
    return (
      <span
        className={classNames(
          'mr-2 rounded  px-2.5 py-0.5 text-xs font-medium ',
          `bg-yellow-100 text-yellow-800`,
        )}
      >
        Slack auth is outdated
      </span>
    )
}

export const SlackHandleTeamManagement = ({
  slackMembers,
  eng,
  onSlackHandleChange,
  slackMemberFiltered,
  foundMemberWithSameEmail,
  canOnboardEngineerIfDifferentEmail,
  axios,
  dispatch,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false)

  const handleDropdownOpen = () => {
    if (!dropdownOpened) {
      setDropdownOpened(true)
    }
  }
  if (foundMemberWithSameEmail) {
    const newEng = cloneDeep(eng)
    newEng.slackInfo = foundMemberWithSameEmail
    return <SlackProfile profileSlack={foundMemberWithSameEmail?.profile} />
  }

  if (canOnboardEngineerIfDifferentEmail === false) {
    return (
      <div className="flex justify-center">
        <Tooltip
          title={
            "Your organization settings require that the user's public email matches the one in Slack. Contact us to update your user emails."
          }
        >
          <ExclamationIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
        </Tooltip>
      </div>
    )
  }

  return (
    <Select
      showSearch
      key={eng.id}
      style={{ width: 280 }}
      placeholder="Select"
      optionFilterProp="children"
      allowClear
      onFocus={handleDropdownOpen}
      onChange={(value, option) =>
        onSlackHandleChange({ value, player: eng, slackMembers })
      }
      notFoundContent={
        <button
          className={classNames(
            ' block w-full rounded-md border border-primary bg-primary py-2 text-center text-sm font-semibold text-white hover:bg-blue-700 hover:text-white',
          )}
          onClick={() => {
            messageInteraction({
              content: 'Updating your Slack members...',
              type: 'loading',
            })
            if (slackMembers?.length <= 1000) {
              messageInteraction({
                content: 'Please refresh the page later. It might take up to 5 minutes.',
                type: 'info',
              })
            }
            if (slackMembers?.length > 1000 && slackMembers?.length <= 5000) {
              messageInteraction({
                content:
                  'Please refresh the page later. It might take from 5 to 10 minutes.',
                type: 'info',
              })
            } else if (slackMembers?.length > 5000) {
              messageInteraction({
                content:
                  'Please refresh the page later. It might take more than 10 minutes.',
                type: 'info',
              })
            }
            listAllSlackUsers({ axios, dispatch })
          }}
        >
          Missing someone? <br />
          Sync your Slack members
        </button>
      }
    >
      {dropdownOpened &&
        slackMemberFiltered?.map((member, index) => {
          return (
            // Member.communicationToolId happens when we fetch slack list from api.axolo.co rather than slack directly
            <Option
              key={member.id}
              value={
                member?.communicationToolId?.length > 0
                  ? member.communicationToolId
                  : member.id
              }
            >
              <Avatar
                size={30}
                src={
                  member?.profile?.image_24 ||
                  member?.image_24 ||
                  'https://axolo.s3.eu-west-3.amazonaws.com/analytics/media-app/default-avatar.png'
                }
                className="!mr-2"
              />
              {member.real_name?.length > 0
                ? member.real_name
                : member.name || 'Missing name from Slack info'}
            </Option>
          )
        })}
    </Select>
  )
}

export const WrapperForMissingSignIn = ({ children, ...props }) => {
  const { eng, requestSentRecently } = props
  return (
    <Tooltip
      placement="top"
      title={
        requestSentRecently
          ? `Request already sent today.`
          : `Ask ${eng.providerLogin} to complete its onboarding.`
      }
    >
      {children}
    </Tooltip>
  )
}

export async function onboardEngineer({
  engineer,
  dispatch,
  axios,
  thisSlackMember,
  organization,
}) {
  const updatedPlayer = cloneDeep(engineer)
  updatedPlayer.communicationToolId =
    thisSlackMember.communicationToolId || thisSlackMember.id
  updatedPlayer.userName = thisSlackMember.name
  updatedPlayer.slackInfo = thisSlackMember
  dispatch(setPlayer(updatedPlayer))
  await axios.put(`${URLBACK}players/${engineer.id}`, {
    playing: true,
    communicationToolId: thisSlackMember.communicationToolId || thisSlackMember.id,
    userName: thisSlackMember.name,
    slackInfo: thisSlackMember,
    orgId: organization.id,
  })
}
