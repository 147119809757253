import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { RandomAxoloAvatar } from '../../components/RandomAxoloAvatar'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router'
import { User } from '../../reducers/userReducer'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { handleLogout } from './../../components/logoutButton/handleLogout'
import { useAxiosWithHeader } from '../../utils'

export const Logout = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState(false)
  const jwt = useSelector(User.selectors.selectJWT)
  const axios = useAxiosWithHeader()

  useEffectOnce(() => {
    handleLogout(jwt, dispatch, axios)
    history.push('/')
    setRedirect(true)
  })

  if (redirect) {
    return <Redirect to={'/'} />
  }
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <RandomAxoloAvatar></RandomAxoloAvatar>
      <h1 className="pb-10 text-xl text-textWhite">
        You should be logged out automatically, or click on this button:
      </h1>
      <Link onClick={() => handleLogout(jwt, dispatch, axios)} to={`/`}>
        <Button
          type="primary"
          className="border-none bg-primary text-textWhite hover:bg-indigo-700 hover:text-textWhite"
        >
          Go to home page
        </Button>
      </Link>
    </div>
  )
}
