import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { User } from '../../reducers/userReducer'
import { classNames } from '../../utils'

export default function SubHeaderMenuDesktop({
  isOnboarded,
  activePage,
  activeSubPage,
  setActiveSubPage,
  subNavigation,
}) {
  const user = useSelector(User.selectors.selectUser)
  const history = useHistory()
  return (
    <header className="py-4">
      <div className="mx-auto max-w-[1500px] px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-3 items-center gap-8">
          <div className="col-span-2">
            <nav className="flex space-x-4">
              {isOnboarded
                ? subNavigation[activePage]?.map((item) => {
                    if (!user.superAdmin && item.name === 'Batch import') return null
                    return (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          activeSubPage === item.route ? 'text-white' : 'text-gray-300 ',
                          'rounded-md px-3 py-2  text-sm font-medium',
                        )}
                        aria-current={activeSubPage === item.route ? 'page' : undefined}
                        onClick={() => {
                          const newSubRoute = subNavigation[activePage].find(
                            (element) => element.route === item.route,
                          ).route
                          setActiveSubPage(newSubRoute)
                          history.push(`/${activePage}/${newSubRoute}`)
                        }}
                      >
                        {item.name}
                      </a>
                    )
                  })
                : null}
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}
