import React, { useState } from 'react'
import { Avatar, message, Select, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { isEqual } from 'lodash'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import OnboardingSteps from '../../components/OnboardingSteps'
import LoaderIcon from '../../components/LoaderIcon'
import getOrgAndEngineers, { listAllSlackUsers } from '../../api/getOrgAndEngineers'
import { setIsOnboarded } from '../../reducers/playersReducer'
import { useEffectOnce } from 'react-use'
import { Redirect } from 'react-router'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import updateUserFunction from '../GitLabGroups/updateUser'

const { Option } = Select

export default function GitHubOnboarding() {
  const axios = useAxiosWithHeader()
  const user = useSelector(User.selectors.selectUser, isEqual)
  const organization = useSelector(Organization.selectors.getOrganization, isEqual)
  const { slackMembers } = organization
  const dispatch = useDispatch()
  const [values, setValues] = useState([])
  const [redirect, setRedirect] = useState(false)
  const [confirmed, setConfirmed] = useState(
    user?.channelOnboardingAdmin?.length > 0 ? true : false,
  )
  const [channel, setChannel] = useState({})
  async function onChange(value, option) {
    setValues(value)
  }

  useEffectOnce(() => {
    updateUserFunction({ axios, user, dispatch, User })
    hasGitHubBeenAccepted()
    if (user.channelOnboardingAdmin) {
      setChannel({ id: user.channelOnboardingAdmin })
    }
  })

  async function hasGitHubBeenAccepted(invocation = 0) {
    invocation += 1
    try {
      const resp = await axios.get(`/users/me`)
      if (slackMembers.length === 0 && organization.slackMembersCalled === false) {
        listAllSlackUsers({ axios, dispatch })
      }
      const { data: user } = resp
      if (user.requestGithubApp) {
        setTimeout(() => hasGitHubBeenAccepted(invocation), 3000)
      } else if (user.requestGithubApp === false) {
        await updateUserFunction({ axios, user, dispatch, User })
        await setTimeout(
          () =>
            getOrgAndEngineers({
              organization,
              dispatch,
              user,
              axios,
              setIsOnboarded,
              requestRefresh: true,
            }),
          10000,
        )
        setRedirect(true)
      }
    } catch (error) {
      console.log('hasGitHubBeenAccepted, error', error)
      messageInteraction({
        content:
          'Error getting your information, try refreshing the page or contact support.',
        type: 'error',
      })
    }
  }

  async function onConfirm() {
    const hide = message.loading()
    try {
      const resp = await axios.post('/organizations/p/createConvForAdmins', values)
      setChannel(resp.data)
      setConfirmed(true)
    } catch (error) {
      message.error('error', error)
    }
    hide()
  }

  if (redirect) {
    return <Redirect to={'/home/onboarding/members'} />
  }

  if (user?.githubCredential?.githubHost?.length > 0) {
    return (
      <MainDivOnboarding>
        <OnboardingSteps />
        <div className="mt-20 flex flex-col justify-center text-center">
          <Typography.Title level={4}>
            Axolo doesn't support Admin request on GitHub enterprise.
          </Typography.Title>
          <p>
            Please cancel your request from GitHub and ask your admin to either <br></br>
            <br></br>1) make you and admin or <br></br>2) install Axolo on your behalf.
          </p>
          <br></br>
          <p>Contact support below for more information</p>
        </div>
      </MainDivOnboarding>
    )
  }

  return (
    <MainDivOnboarding>
      <OnboardingSteps />
      <div className="mt-20 flex flex-col justify-center text-center">
        <Typography.Title level={4}>
          You've just requested to install GitHub on your organization.{' '}
        </Typography.Title>
        <Typography.Title level={5}>
          We are waiting for an approval from the admin.
        </Typography.Title>
        <p>(This page will automatically update when an admin accepts Axolo).</p>
      </div>
      <div className="flex flex-col justify-center">
        <LoaderIcon size={46} />
      </div>
      <div className="mt-10 flex flex-col justify-center text-center">
        <Typography.Title level={5}>
          In the meantime create a conversation with the administrator(s) <br></br>who are
          responsible for installing Axolo on GitHub:
        </Typography.Title>
        <div>
          {confirmed ? (
            <p className="text-green-600">
              A channel has been created, open Slack to join the conversation!
              <a
                target={'_blank'}
                href={`https://slack.com/app_redirect?channel=${channel.id}&team=${user.credential.teamId}`}
                rel="noreferrer"
              >
                <button className="m-2 bg-green-700 px-2 py-1 text-textWhite hover:bg-green-600 hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50">
                  Open Slack
                </button>
              </a>
            </p>
          ) : (
            <>
              <Select
                mode="multiple"
                style={{ width: '300px', marginRight: '5px' }}
                placeholder="Choose your GitHub administrator(s)"
                onChange={(value, option) => onChange(value, option)}
                optionLabelProp="label"
                labelInValue
              >
                {slackMembers.map((member) => (
                  <Option key={member.id} value={member.id} label={member.real_name}>
                    <Avatar
                      size={30}
                      src={member?.profile?.image_24 || member?.image_24}
                      className="!mr-2"
                    />
                    {member?.real_name}{' '}
                    {member?.real_name?.length === 0 ? member.name : ''}
                  </Option>
                ))}
              </Select>
              <button
                className="m-2 bg-primary px-2 py-1 text-textWhite hover:bg-hoverPrimary hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50"
                onClick={() => onConfirm()}
              >
                Confirm
              </button>
            </>
          )}
        </div>
      </div>
    </MainDivOnboarding>
  )
}
