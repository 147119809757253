import React, { useState, useEffect, useRef } from 'react'
import { Input, Avatar, Table, message, Tooltip } from 'antd'
import moment from 'moment'
import _, { cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { User } from '../../reducers/userReducer'
import { useEffectOnce } from 'react-use'
import { FilterTwoTone } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { ChannelInformation } from '../../components/ChannelLink'
import { classNames } from '../../utils'
import RefreshPRButton from '../../components/utils/refreshPRButton'
import { PlusCircleIcon } from '@heroicons/react/outline'
import LoaderIcon from '../../components/LoaderIcon'
import {
  fetchPullRequestsAndUpdateReducer,
  fetchRepositoriesAndUpdateReducer,
} from './fetchPullRequest'
import { UserAvatar } from './userAvatar'
import { debouncedFetch } from './debounce'

const RECORD_STYLES = {
  Archived: {
    className: 'bg-blue-100 text-blue-800',
  },
  Active: {
    className: 'bg-green-100 text-green-800',
  },
  'No channel': {
    className: 'bg-yellow-100 text-yellow-800',
  },
}
const getRecordStyle = (record) => RECORD_STYLES[record] || {}

const Search = Input.Search

function createRepoArrayFromPullRequests({ pullRequests, repositories }) {
  const uniqueRepositories = new Map()

  // Add repositories from pullRequests
  pullRequests?.forEach((pr) => {
    if (!uniqueRepositories.has(pr.repository._id)) {
      uniqueRepositories.set(pr.repository._id, pr.repository)
    }
  })

  // Add repositories from the repositories array
  repositories?.forEach((repo) => {
    if (!uniqueRepositories.has(repo._id)) {
      uniqueRepositories.set(repo._id, repo)
    }
  })

  return Array.from(uniqueRepositories.values())
}

function createCreatorArrayFromPullRequests({ pullRequests }) {
  const creators = []
  for (let i = 0; i < pullRequests?.length; i += 1) {
    creators.push(pullRequests[i].creator)
  }
  return _.uniqBy(creators, 'id')
}

function ButtonWithLoader(
  CreateChannelsAction,
  id,
  creatorIsNotOnboarded,
  disabledButtons,
  setDisabledButtons,
  creator,
) {
  const pull = creator?.provider === 'gitlab' ? 'merge' : 'pull'
  async function openSlackChannel() {
    setDisabledButtons({ ...disabledButtons, [id]: true })
    const isItCreated = await CreateChannelsAction(id)

    console.log('isItCreated', isItCreated)

    if (isItCreated) {
      message.success('Your channel has been created.')
    }
    setDisabledButtons({ ...disabledButtons, [id]: false })
  }

  const isDisabled = disabledButtons[id]

  if (creatorIsNotOnboarded) {
    return (
      <Tooltip title={"Can't create a channel if the creator is not onboarded on Axolo"}>
        <PlusCircleIcon
          className="h-8 w-8 text-gray-400"
          aria-hidden="true"
          style={{ pointerEvents: 'auto', opacity: 0.5 }}
        />
      </Tooltip>
    )
  }
  // if isDisabled this means its loading
  if (isDisabled) {
    return <LoaderIcon className="flex" title={''} spinning={isDisabled} />
  }

  return (
    <Tooltip title={`Create a channel for this ${pull} request`}>
      <PlusCircleIcon
        className={`h-8 w-8 ${
          isDisabled ? 'text-gray-400' : 'text-primary hover:text-hoverPrimary'
        } cursor-pointer`}
        aria-hidden="true"
        onClick={isDisabled ? null : () => openSlackChannel()}
      />
    </Tooltip>
  )
}

const CreateChannels = ({
  data,
  setData,
  pullRequests = [],
  CreateChannelsAction,
  organization,
  disabledButtons,
  setDisabledButtons,
  axios,
  dispatch,
  isMountedRef,
}) => {
  const [searchText, setSearchText] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const user = useSelector(User.selectors.selectUser)
  const repositories = createRepoArrayFromPullRequests({
    pullRequests: organization.pullRequests,
    repositories: organization.repositories,
  })
  const repoFilter = repositories?.map((repo) => ({
    text: repo?.name,
    value: repo?.name,
  }))
  const creators = createCreatorArrayFromPullRequests({ pullRequests })
  const creatorFilter = creators?.map((creator) => ({
    text: creator?.providerLogin,
    value: creator?.providerLogin,
  }))
  const Pull = organization.provider === 'gitlab' ? 'Merge' : 'Pull'
  const { setting } = organization

  const columns = [
    {
      width: 200,
      title: () => (
        <Tooltip placement="top" title="Click to sort upon the creation date">
          <p className="!mb-0 px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
            {Pull} request title
          </p>
        </Tooltip>
      ),
      dataIndex: 'title',
      key: 'id',
      sorter: (a, b) => new Date(a.providerCreatedAt) - new Date(b.providerCreatedAt),
      render: (record, raw) => {
        return (
          <a
            href={raw.url}
            target="_blank"
            rel="noopener noreferrer"
            className="  text-black hover:text-hoverPrimary"
          >
            {raw.title}
            <br />
            <span className="italic">
              {moment(raw?.providerCreatedAt).format('MMM d, YYYY')}
            </span>
          </a>
        )
      },
    },
    {
      width: 50,
      title: () => (
        <p className="!mb-0 -mr-2 px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
          Creator
        </p>
      ),
      dataIndex: 'creator',
      filters: creatorFilter,
      filterSearch: true,
      onFilter: (item, record) => {
        return record?.creator?.providerLogin === item
      },
      render: (record) => <UserAvatar record={record} />,
    },
    {
      width: 100,
      title: () => (
        <p className="!mb-0 px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
          Assignees
        </p>
      ),
      dataIndex: 'assignees',
      render: (records) => {
        return (
          <Avatar.Group>
            {records?.map((record, i) => {
              return <UserAvatar record={record} key={`${record.id}+${i}`} />
            })}
          </Avatar.Group>
        )
      },
    },
    {
      width: 150,
      title: () => (
        <p className="!mb-0 px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
          Reviewers
        </p>
      ),
      dataIndex: 'requestedReviewers',
      render: (records) => {
        return (
          <Avatar.Group>
            {records?.map((record, i) => {
              return <UserAvatar record={record} key={`${record.id}+${i}`} />
            })}
          </Avatar.Group>
        )
      },
    },
    {
      width: 200,
      title: () => (
        <p className="!mb-0 -mr-2 px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
          Repository
        </p>
      ),
      dataIndex: 'repository',
      key: 'id',
      render: (record) => {
        return record?.fullName
      },
      filters: repoFilter,
      filterSearch: true,
      filterIcon: (filtered) =>
        filtered ? (
          <FilterTwoTone twoToneColor="#0049ff" style={{ fontSize: '16px' }} />
        ) : (
          <FilterTwoTone twoToneColor="#718096" style={{ fontSize: '16px' }} />
        ),
      onFilter: (repository, record) => {
        return record.repository.name === repository
      },
    },

    {
      width: 80,
      title: () => (
        <p className="!mb-0  px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
          Channel Status
        </p>
      ),
      key: 'id',
      dataIndex: 'channelStatus',
      filters: [
        { value: 'Active', text: 'Active' },
        { value: 'Archived', text: 'Archived' },
        { value: 'No channel', text: 'No channel' },
      ],
      filterSearch: true,
      onFilter: (item, record) => {
        return record?.channelStatus === item
      },
      sorter: (a, b) => a?.channelStatus?.localeCompare(b?.channelStatus),
      render: (record, raw) => {
        const { className } = getRecordStyle(record)
        const pull = organization.provider === 'github' ? 'pull' : 'merge'
        return (
          <Tooltip
            placement="top"
            title={
              record === 'Active'
                ? `Active means the ${pull} request is opened and has an open Slack channel.`
                : record === 'Archived'
                ? `Archived means the ${pull} request is opened but the Slack channel is archived.`
                : `No channel means that the ${pull} request is opened but there is no Slack channel.`
            }
          >
            <span
              className={classNames(
                'mr-2 mt-1 rounded px-2.5 py-0.5 text-xs font-medium',
                className,
              )}
            >
              {record || 'None'}
            </span>
          </Tooltip>
        )
      },
    },
    {
      width: 80,
      title: () => (
        <p className="!mb-0 px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
          Action
        </p>
      ),
      // dataIndex: 'id',
      key: 'id',
      render: (item, record) => {
        const isOnboardedCreator = item?.creator?.playing
        if (record?.slackChannelId) {
          const channel = {
            channelId: record?.slackChannelId,
            channelName: record?.slackChannelName,
          }
          return (
            <span className="flex">
              <ChannelInformation
                archived={item.archived}
                channel={channel}
                slackCred={
                  organization?.credentials?.find((c) => c?.tool === 'slack') ||
                  user.credential
                }
              />
            </span>
          )
        } else {
          return ButtonWithLoader(
            CreateChannelsAction,
            record.id,
            !isOnboardedCreator,
            disabledButtons,
            setDisabledButtons,
            item?.creator,
          )
        }
      },
    },
  ]

  // onSearch function
  const onSearch = (e) => {
    const value = e && e.target ? e.target.value : e
    debouncedFetch({
      value,
      setSearchLoading,
      organization,
      user,
      axios,
      Organization,
      dispatch,
      isMountedRef,
    })
  }
  // SEARCH FUNCTION END
  const pull = organization.provider === 'gitlab' ? 'merge' : 'pull'
  return (
    <div className="">
      <p className="pb-2">
        {' '}
        {/* a améliorer!!!! */}
        You can open a channel for each {pull} request that do not have an existing one.
      </p>
      <div className="mb-4 flex">
        <Search
          placeholder={`Search your ${pull} requests`}
          size="large"
          loading={searchLoading}
          allowClear
          onChange={(e) => {
            setSearchText(e.target.value)
            onSearch(e)
          }}
          value={searchText}
          onSearch={(e) => {
            setSearchText(e)
            onSearch(e)
          }}
          className="max-w-[400px]"
        />
        <div className="flex">
          <RefreshPRButton />
          {setting.onlyRefreshPrOnDemand ? (
            <p className="mb-0 ml-1 self-center text-xs">
              Last pull request refresh:{' '}
              {moment(organization.lastHomePullRequestRefresh).format(
                'MMMM Do YYYY, h:mm A',
              )}{' '}
              UTC
            </p>
          ) : null}
        </div>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        rowKey={(record) => record.id}
        className="!rounded-xl border border-gray-100 px-1 !shadow-lg"
      />
    </div>
  )
}

export default function OpenChannels({ finishButton }) {
  const axios = useAxiosWithHeader()
  const history = useHistory()
  const user = useSelector(User.selectors.selectUser)
  const organization = useSelector(Organization.selectors.getOrganization)
  let { pullRequests = [] } = organization
  const [data, setData] = useState(pullRequests)
  const [disabledButtons, setDisabledButtons] = useState({})
  const isMountedRef = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    // This is to be able to know which button should be activated or not.
    // We need to be high up in the components.
    // The disabledButtons objects will look like {id: true/false}.
    // If the id of the PR is in the object and set to true, it means that the button should be disabled.
    if (pullRequests.length > 0) {
      const disabledButtonsReduced = pullRequests.reduce((acc, pr) => {
        const isOnboardedCreator = pr?.creator?.playing
        acc[pr.id] = !isOnboardedCreator
        return acc
      }, {})
      setDisabledButtons({ ...disabledButtonsReduced })
    }
  }, [pullRequests])

  useEffectOnce(() => {
    fetchRepositoriesAndUpdateReducer({
      organization,
      user,
      axios,
      dispatch,
      Organization,
      isMountedRef,
      updateRepos: false,
    })

    if (!(organization.pullRequest?.length > 0)) {
      fetchPullRequestsAndUpdateReducer({
        organization,
        user,
        axios,
        dispatch,
        Organization,
        isMountedRef,
        source: 'OpenChannels',
      })
    }
  })

  useEffect(() => {
    setData(pullRequests)
  }, [pullRequests])

  async function CreateChannelsAction(id) {
    const prToCreate = [id]
    // const hide = message.loading('Creating channel... It can take up to a minute.', 0)
    try {
      const resp = await axios.post(
        `/organizations/channels/${organization.id}?source=open-channels`,
        prToCreate,
      )

      console.log('resp', resp.data)

      if (resp.data === 'restricted_action')
        message.error(
          "Slack 'restriced_action' detected. We sent you a dm in Slack to continue.",
          5,
        )
      else if (resp.data === 'Error: No onboarded members') {
        message.error(
          'No onboarded members are part of this pull request, make sure at least one member of this PR is onboarded',
          5,
        )
      } else if (resp.data === 'Unauthorized: max channels limit reached') {
        message.error(
          "You've hit your monthly channel limit on the Free plan. Upgrade for unlimited PR channels.",
          5,
        )
      } else if (resp.data) {
        const allPrs = cloneDeep(pullRequests)
        const indexToUpdate = allPrs.findIndex((pr) => pr.id === id)
        allPrs[indexToUpdate] = resp.data
        dispatch(
          Organization.actions.setData({
            pullRequests: allPrs,
            initialPullRequests: allPrs,
          }),
        )
        return true
      } else {
        // hide()
        message.error('Error creating your channels, please try again or contact support')
        return false
      }
      // hide()
    } catch (error) {
      // hide()
      message.error('Error creating your channels, please try again or contact support')
      return false
    }
  }

  const tableData = data.map((pr) => {
    let channelStatus

    if (pr.slackChannelId) {
      channelStatus = pr.archived ? 'Archived' : 'Active'
    } else {
      channelStatus = 'No channel'
    }

    return { ...pr, channelStatus }
  })

  return (
    <>
      <div className="mb-4 block items-end lg:flex">
        <h1 className="mt-2 text-xl font-semibold text-gray-900">Channels</h1>
        <h3 className="!mb-0 self-center font-normal text-gray-400 lg:ml-8">
          Manually open {organization.provider === 'github' ? 'Pull' : 'Merge'} Request
          Channels in Slack
        </h3>
      </div>

      <CreateChannels
        data={tableData}
        setData={setData}
        pullRequests={organization.pullRequests}
        CreateChannelsAction={CreateChannelsAction}
        organization={organization}
        disabledButtons={disabledButtons}
        setDisabledButtons={setDisabledButtons}
        axios={axios}
        dispatch={dispatch}
        isMountedRef={isMountedRef}
      />
      {finishButton && (
        <div className="flex justify-end">
          <button
            className="m-1 rounded-sm bg-primary px-2 py-1 text-textWhite hover:bg-hoverPrimary hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50"
            onClick={() => history.push('/settings/pull-request-channel')}
          >
            Finish & access my settings
          </button>
        </div>
      )}
      {/* <div className="mt-12 flex justify-between items-center"></div> */}
    </>
  )
}
