export async function checkIfShouldSendMessage({ loader, setLoader, axios, history }) {
  let count = 0
  while (loader.loading && count < 100) {
    try {
      const response = await axios.get(`/organizations/p/fetchOrgaForApp`)
      const organization = response.data
      console.log(
        'Should send message at the end of onboarding:',
        organization.shouldSendMessageEndOnboarding,
      )
      if (organization.shouldSendMessageEndOnboarding) {
        setLoader({ loading: false, text: '' })
        // if we should send message, and the onboarding is not completed, then we redirect to
        // the waiting page
        if (!organization.savingChannelsOnboardingCompleted)
          history.push('/onboarding/waiting-git-installation')
        // else we stop the while loop
        break
      }
      count += 1
      await new Promise((resolve) => setTimeout(resolve, 2000))
    } catch (error) {
      console.error('Error checking if message should be sent:', error)
    }
  }
}
