export const URLBACK = process.env.REACT_APP_URLBACK

export const URLBACK_VELOFRAME =
  process.env.NODE_ENV === 'production'
    ? 'https://api.veloframe.com/'
    : 'http://localhost:1331/'

export const URLFRONT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_URLFRONT
    : 'http://localhost:3000/'

export const GITHUB_APP_INSTALL_URL = process.env.REACT_APP_GITHUB_APP_INSTALL_URL

export const GITHUB_APP_REDIRECT_URI =
  process.env.NODE_ENV === 'production'
    ? 'https://app.axolo.co/auth/github/callback'
    : 'http://localhost:3000/auth/github/callback'
