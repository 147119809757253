import moment from 'moment'
import { cloneDeep } from 'lodash'
import { URLBACK } from '../../assets/urls'
import { setInitialPlayers } from '../../reducers/playersReducer'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { Tooltip } from 'antd'

async function askEveryoneToCompleteOnboardingFunction({
  engineers,
  axios,
  organization,
  dispatch,
}) {
  const hide = messageInteraction({
    type: 'loading',
    content: `Asking everyone to complete onboarding.`,
    duration: 0,
  })
  try {
    await axios.get(`${URLBACK}player/notifSignInWithSlack?orgId=${organization.id}`)
    const newData = cloneDeep(engineers)
    for (let i = 0; i < engineers.length; i++) {
      newData[i].requestToAuthenticateSentAt = moment()
    }
    messageInteraction({
      type: 'success',
      content: `Asked everyone to complete onboarding successfully.`,
      duration: 3,
    })
    dispatch(setInitialPlayers({ players: newData }))
  } catch (e) {
    messageInteraction({
      type: 'error',
      content: `Unknown error. Please try again or contact support.`,
      duration: 3,
    })
  }
  hide()
}
export const AskEveryoneToCompleteOnboardingButton = ({
  visibleActiveEngineers,
  axios,
  organization,
  dispatch,
  pathName,
}) => {
  const missingAuthEnginers = visibleActiveEngineers?.filter(
    (eng) =>
      !eng.slackAuthed ||
      (eng.provider === 'github' && !eng.githubAuthed) ||
      (eng.provider === 'gitlab' && !eng.gitlabAuthed),
  )
  const shouldShow = !pathName?.includes('onboarding') && missingAuthEnginers?.length > 0
  if (!shouldShow) return null
  const shouldEnableButton = missingAuthEnginers.find(
    (eng) =>
      !eng.requestToAuthenticateSentAt ||
      moment(eng.requestToAuthenticateSentAt).isBefore(moment().subtract(1, 'days')),
  )
  if (shouldEnableButton) {
    return (
      <Tooltip placement="top" title={`Ask everyone to complete its onboarding now.`}>
        <button
          type="button"
          onClick={() =>
            askEveryoneToCompleteOnboardingFunction({
              engineers: visibleActiveEngineers,
              axios,
              organization,
              dispatch,
            })
          }
          className="ml-2 rounded bg-blue-100  px-2.5 py-0.5  text-xs font-medium text-blue-800 shadow-xl hover:cursor-pointer"
        >
          Ask everyone to complete its onboarding
        </button>
      </Tooltip>
    )
  } else {
    return null
    // not showing the button in the column title if already requested
    //  (
    //   <button
    //     type="button"
    //     className="mr-2 rounded  bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 hover:cursor-pointer"
    //   >
    //     Request already sent today
    //   </button>
    // )
  }
}
