import { businessPricingIds } from '../../../assets/stripeKeys'
import { InitCheckoutSession } from '../../Stripe/utils'

export default function EndOfAnalyticsTrialModal({ axios, activePlayers }) {
  return (
    <div className="">
      <div className="fixed top-28 left-0 h-[150vh] w-[100vw] z-20 bg-gradient-to-b from-transparent via-gray-300 to-gray-500"></div>
      <div className="absolute m-auto inset-0 max-h-[350px] max-w-[750px] text-center shadow-xl rounded-lg bg-white z-30 mx-8 md:mx-auto">
        <div className="flex h-[-webkit-fill-available] justify-center">
          <div className="flex flex-col justify-center p-12 ">
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              Your Analytics trial has come to an end
            </h2>
            <p className="mt-2 text-center text-sm text-grey-600">
              If you are interested in Axolo analytics, please switch to the our business
              plan.
            </p>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex items-center justify-center">
              <button
                type="button"
                onClick={() =>
                  InitCheckoutSession({
                    priceId: businessPricingIds.monthly,
                    quantity: activePlayers,
                    axios,
                  })
                }
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                Switch to Axolo business
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
