import { useDispatch, useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import { Setting } from '../../../reducers/settingReducer'
import { createStandardQuery } from '../utils/query'
import { useAxiosWithHeader } from '../../../utils'
import { URLBACK_VELOFRAME } from '../../../assets/urls'
import DeploymentGraph from '.'
import messageInteraction from '../utils/messageInteraction'
import { useState, useEffect } from 'react'
import { addEmptyDates } from '../utils/function/addEmptyDates'

export default function DeploymentContainer({
  fields = 'productionEnvironment,environment,providerCreatedAt,providerId',
  pullRequestRelations,
}) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  const axios = useAxiosWithHeader('analytics')
  const setting = useSelector(Setting.selectors.getSetting)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const organizationId = analytics.id
    setLoading(true)
    async function getDeployments({ axios, setting, organizationId, dispatch }) {
      try {
        if (!setting.loadingInitialQuery) {
          const res = await axios.get(
            `${URLBACK_VELOFRAME}api/deployment/findDeployments?fields=${fields}&pullRequestRelations=${pullRequestRelations}&${createStandardQuery(
              { setting, organizationId },
            )}`,
          )
          // const data = addEmptyDates({ data: res.data, startDate: setting.startDate, endDate: setting.endDate })
          dispatch(Analytics.actions.setData({ deployments: res.data }))
        }
      } catch (e) {
        setError(true)
        messageInteraction({
          content: 'Error in get deployments. Please refresh or contact support.',
          type: 'error',
        })
        console.log('Error get deployments: ', e)
      }
      setLoading(false)
    }
    getDeployments({ axios, setting, organizationId, dispatch })
  }, [
    setting.startDate,
    setting.endDate,
    setting.selectedRepositories,
    setting.selectedTeams,
  ])

  return <DeploymentGraph loading={loading} error={error} />
}
