import { User } from './userReducer'
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

let initialState = {
  loading: true,
  error: false,
  players: [],
  repositories: [],
  comments: [],
  pullRequests: [],
  slackChannels: [],
  slackMembers: [],
  slackMembersCalled: false,
  isManageMemberModalVisible: false,
  isSelectMembersReady: false,
  setting: { reminders: false },
  timeZone: null,
  lastUpdated: null,
  csvImport: '',
  initialPullRequests: [],
}

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setTimeZone: (state, action) => {
      return {
        ...state,
        timeZone: action.payload,
      }
    },
    addUser: (state, action) => {
      const newUsersArray = [...state.users, action.payload]
      return {
        ...state,
        users: newUsersArray,
      }
    },
    deleteUser: (state, action) => {
      const updatedUsersArray = state.users.filter(
        (user) => user.id !== action.payload.id,
      )
      return {
        ...state,
        users: updatedUsersArray,
      }
    },
    setSlackChannels: (state, action) => {
      return {
        ...state,
        slackChannels: action.payload,
      }
    },
    setSlackMembers: (state, action) => {
      return {
        ...state,
        slackMembers: action.payload,
      }
    },
    setSlackMembersCalled: (state, action) => {
      return {
        ...state,
        slackMembersCalled: action.payload,
      }
    },
    setData: (state, action) => {
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
        lastUpdated: moment().format(),
      }
    },
    setPullRequestToArchived: (state, action) => {
      const { channelId } = action.payload
      const pullRequests = state.pullRequests.map((pr) => {
        if (pr.slackChannelId === channelId) {
          return { ...pr, archived: true }
        }
        return pr
      })
      return {
        ...state,
        ...action.payload,
        pullRequests,
        error: false,
        loading: false,
      }
    },

    setError: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    setLoading: (state) => ({ ...state, loading: true }),
    unsetLoading: (state) => ({ ...state, loading: false }),
    setIsManageMemberModalVisible: (state, action) => ({
      ...state,
      isManageMemberModalVisible: action.payload,
    }),
    setIsSelectMembersReady: (state, action) => ({
      ...state,
      isSelectMembersReady: action.payload,
    }),
    setPlayer: (state, action) => {
      const newPlayers = [...state.players]
      const player = action.payload
      const index = newPlayers.findIndex((p) => p.id === player.id)
      newPlayers[index] = player
      return {
        ...state,
        players: newPlayers,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(User.actions.logout, (_) => initialState)
      .addCase(User.actions.changesOrg, (_) => initialState)
  },
})

export const { setPlayer } = organizationSlice.actions

export const {
  setIsManageMemberModalVisible,
  setIsSelectMembersReady,
  addUser,
  deleteUser,
} = organizationSlice.actions

const getOrganization = (state) => state.organization
const getSlackMembers = (state) => state.organization.slackMembers

export const Organization = {
  actions: organizationSlice.actions,
  selectors: { getOrganization, getSlackMembers },
}
