import { InfoCircleTwoTone, UserOutlined } from '@ant-design/icons'
import { Select, Avatar, Button, Popover } from 'antd'
import moment from 'moment'
import { URLBACK } from '../../assets/urls'
import { deleteStandup } from '../../reducers/standupReducer'
import messageInteraction from '../Analytics/utils/messageInteraction'
import SkeletonLoading from '../Analytics/utils/skeletonLoader'
import { AxoloTrashIcon } from '../Settings/setting-helper'
import { AxoloDaySelector } from '../utils/day-selector'
import { PlusCircleIcon } from '@heroicons/react/outline'
import { globalUpdateCompanySetting } from '../../utils'

export const LoadingStandupData = () => {
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      <tr key="newStandup">
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <SkeletonLoading specificCss="!bg-sky-300" />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
          <div className="flex items-center">
            <Avatar
              icon={<UserOutlined />}
              className="!mr-2 !flex !items-center !justify-center"
            ></Avatar>
            {/* <p className="pl-2 mb-0 text-gray-800">{engineer?.providerLogin}</p> */}
            <SkeletonLoading specificCss="!w-20 !bg-sky-300 " />
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {' '}
          <SkeletonLoading specificCss="!bg-sky-300" />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {' '}
          <SkeletonLoading specificCss="!bg-sky-300" />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {' '}
          <SkeletonLoading specificCss="!bg-sky-300" />
        </td>
      </tr>
    </tbody>
  )
}
export const StandupTimeInformation = ({
  standup,
  handleClickOnDay,
  timeZoneOffset,
  timeZone,
  personalStandup,
}) => {
  const time = moment(standup.timeUtc, 'HH:mm:ss')
    .add(timeZoneOffset, 's')
    .format('h:mm A')
  const StandupTime = () => {
    return (
      <div className="">
        {time} {timeZone}
      </div>
    )
  }
  return (
    <div className="">
      <StandupTime />
      <AxoloDaySelector
        handleClickOnDay={handleClickOnDay}
        daySelected={standup}
        standup={standup}
        personalStandup={personalStandup}
      />
    </div>
  )
}
export const StandupTitle = ({ personalStandup, provider }) => {
  let title = 'My Stand-ups'
  let subTitle = 'A list of all the stand-ups you have created.'
  if (!personalStandup) {
    title = "Other's Stand-ups"
    subTitle = "The rest of your organization's stand-ups"
  }
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
        <div className="mt-2  flex items-center">
          <p className="text-sm text-gray-700">{subTitle}</p>
          <Popover
            showArrow={false}
            placement="right"
            content={
              <div className="max-w-[650px]">
                <p>
                  Anyone within your team can ask Axolo to send a{' '}
                  {provider === 'github' ? 'pull' : 'merge'} request recap anytime on
                  specific days. This feature helps make it easier to run standups, and to
                  remind who is working on which {provider === 'github' ? 'PR' : 'MR'} .
                </p>
                <div className="flex justify-center">
                  <img
                    src="/images/doc/stand-up.png"
                    alt="Axolo PR summary for stand-ups"
                    width={1992}
                    height={1100}
                  />
                </div>
              </div>
            }
          >
            <InfoCircleTwoTone className="-mt-4 ml-2" twoToneColor="#0049ff" />
          </Popover>
        </div>
      </div>
    </div>
  )
}

export const AddStandupButton = ({ addNewStandup, disabled }) => {
  return (
    <div className="flex justify-center">
      <PlusCircleIcon
        className={`h-8 w-8 ${
          disabled ? 'text-gray-400' : 'text-primary hover:text-hoverPrimary'
        } cursor-pointer`}
        aria-hidden="true"
        onClick={disabled ? null : () => addNewStandup()}
      />
    </div>
  )
}

export const DeletedStandupButton = ({
  standupId,
  creatorId,
  engineerId,
  axios,
  myStandups,
  dispatch,
}) => {
  async function handleDeleteStandup() {
    const hide = messageInteraction({
      content: 'Loading...',
      type: 'loading',
      duration: 0,
    })
    try {
      const res = await axios.get(
        `${URLBACK}settings/deleteOneStandup?standupId=${standupId}&creatorId=${creatorId}&userId=${engineerId}`,
      )
      hide()
      if (res.status === 200) {
        dispatch(deleteStandup({ id: standupId }))
      } else {
        throw new Error(`Status is not 200, ${res.message}`)
      }
    } catch (e) {
      hide()
      messageInteraction({
        content: 'Error deleting one standup.',
        type: 'error',
      })
      console.log('error in handleDeleteStandup:', e)
    }
  }

  return (
    <div onClick={() => handleDeleteStandup({})}>
      <AxoloTrashIcon color={'red'} />
    </div>
  )
}

export const StandupPersonalizationTitle = () => {
  let title = 'Personalize your stand-up notifications'
  let subTitle = 'Select which status you wish to receive. More information about status '
  return (
    <div className="mt-10 sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
        <div className="mt-2  flex items-center">
          <p className="text-sm text-gray-700">
            {subTitle}
            <a
              href="https://axolo.co/docs/makethebest/understanding-pr-status"
              target="_blank"
              rel="noreferrer"
            >
              in our documentation.
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export const PersonalizationStatusStandup = ({ setting, axios, dispatch }) => {
  const { standupNotifPersonalization } = setting

  async function updateStandupPersonalization({ statusKey, value }) {
    const status = {
      ...standupNotifPersonalization,
      [statusKey]: value,
    }

    await globalUpdateCompanySetting({
      key: 'standupNotifPersonalization',
      value: status,
      setting,
      axios,
      dispatch,
    })
  }
  return (
    <fieldset>
      <div className="space-y-5">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="reviewable"
              aria-describedby="reviewable-description"
              name="reviewable"
              type="checkbox"
              checked={standupNotifPersonalization?.reviewable}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onClick={() =>
                updateStandupPersonalization({
                  statusKey: 'reviewable',
                  value: !standupNotifPersonalization?.reviewable,
                })
              }
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="reviewable" className="font-medium text-gray-900">
              Awaiting review
            </label>
            <p id="reviewable-description" className="!mb-0 text-gray-500">
              Send PR that awaiting a review.
            </p>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="mergeable"
              aria-describedby="mergeable-description"
              name="mergeable"
              type="checkbox"
              checked={standupNotifPersonalization?.mergeable}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onClick={() =>
                updateStandupPersonalization({
                  statusKey: 'mergeable',
                  value: !standupNotifPersonalization?.mergeable,
                })
              }
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="mergeable" className="font-medium text-gray-900">
              Awaiting merge
            </label>
            <p id="mergeable-description" className="!mb-0 text-gray-500">
              Send PR that have been approved and are mergeable.
            </p>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="draft"
              aria-describedby="draft-description"
              name="draft"
              type="checkbox"
              checked={standupNotifPersonalization?.draft}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onClick={() =>
                updateStandupPersonalization({
                  statusKey: 'draft',
                  value: !standupNotifPersonalization?.draft,
                })
              }
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="draft" className="font-medium text-gray-900">
              Draft
            </label>
            <p id="draft-description" className="!mb-0 text-gray-500">
              Send PR that are still considered draft.
            </p>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="wip"
              aria-describedby="wip-description"
              name="wip"
              type="checkbox"
              checked={standupNotifPersonalization?.wip}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onClick={() =>
                updateStandupPersonalization({
                  statusKey: 'wip',
                  value: !standupNotifPersonalization?.wip,
                })
              }
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="wip" className="font-medium text-gray-900">
              Work-in-progress
            </label>
            <p id="wip-description" className="!mb-0 text-gray-500">
              Send PR that are still in work-in-progress.{' '}
            </p>
          </div>
        </div>
      </div>
    </fieldset>
  )
}
