import { useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'

export default function CompanyGeneral() {
  const analytics = useSelector(Analytics.selectors.getAnalytics)

  return (
    <div className="bg-white shadow sm:rounded-lg ">
      <div className="p-5">
        <dl className="grid grid-cols-3 gap-x-4 gap-y-8">
          <div className="w-32 rounded-lg">
            <img src={analytics.avatarUrl} alt="avatar" className="rounded-lg" />
          </div>

          <div className="col-span-2 flex flex-col justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {analytics.providerLogin}
            </h3>
            <div className="">
              <div className="grid grid-cols-2">
                <div className="">
                  <dt className="text-sm font-medium text-gray-500 pt-1">Repositories</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {analytics.repositories?.length}
                  </dd>
                </div>
                <div className="">
                  <dt className="text-sm font-medium text-gray-500 pt-1">Teams</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {analytics.teams?.length}
                  </dd>
                </div>
              </div>
              <div className="">
                <dt className="text-sm font-medium text-gray-500 pt-1">About</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {analytics.description
                    ? analytics.description
                    : "Your GitHub organization description should be here. Looks like it's empty..."}
                </dd>
              </div>
            </div>
          </div>
        </dl>
      </div>
    </div>
  )
}
