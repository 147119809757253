import React, { useState } from 'react'
import axiosFromModule from 'axios'

import { Avatar, Select } from 'antd'
import { useAxiosWithHeader } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { updateUser, User } from '../../reducers/userReducer'
import { listAllSlackUsers } from '../../api/getOrgAndEngineers'
import { Organization } from '../../reducers/organizationReducer'
import { isEqual } from 'lodash'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'

const { Option } = Select

export const GitLabAdmin = ({ autoReviewRequestOnboarding }) => {
  let axios = useAxiosWithHeader()
  if (autoReviewRequestOnboarding) {
    axios = axiosFromModule
  }

  const dispatch = useDispatch()
  const [channel, setChannel] = useState({})
  const [values, setValues] = useState([])

  const user = useSelector(User.selectors.selectUser, isEqual)

  const [confirmed, setConfirmed] = useState(
    user?.channelOnboardingAdmin?.length > 0 ? true : false,
  )
  const [show, setShow] = useState(false)

  const organization = useSelector(Organization.selectors.getOrganization, isEqual)
  const { slackMembers } = organization

  async function onChange(value, option) {
    setValues(value)
  }

  async function onConfirm() {
    const hide = messageInteraction({ content: 'loading', type: 'loading' })
    try {
      const resp = await axios.post(
        '/organizations/p/createConvForAdmins?provider=gitlab',
        values,
      )
      setChannel(resp.data)
      setConfirmed(true)
    } catch (error) {
      messageInteraction({
        type: 'error',
        content: 'Error:' + error,
      })
    }
    hide()
  }

  useEffectOnce(() => {
    updateUser({ axios, user, dispatch, User })
    if (!autoReviewRequestOnboarding && (slackMembers?.length === 0 || !slackMembers)) {
      listAllSlackUsers({ axios, dispatch })
    }
    if (user.channelOnboardingAdmin) {
      setChannel({ id: user.channelOnboardingAdmin })
    }
  })

  return (
    <div>
      {!autoReviewRequestOnboarding && (
        <p className="mb-0 flex justify-center pt-12">
          Don't see your group?{' '}
          <span
            className="cursor-pointer pl-1 text-primary"
            onClick={() => setShow(!show)}
          >
            Ask your admin on Slack.
          </span>
        </p>
      )}

      <div className="mt-4 flex items-center justify-center">
        {show &&
          (confirmed ? (
            <p className="text-green-600">
              A channel has been created, open Slack to join the conversation!
              <a
                target={'_blank'}
                href={`https://slack.com/app_redirect?channel=${channel.id}&team=${user.credential.teamId}`}
                rel="noreferrer"
              >
                <button className="m-2 bg-green-700 px-2 py-1 text-textWhite hover:bg-green-600 hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50">
                  Open Slack
                </button>
              </a>
            </p>
          ) : (
            <>
              <Select
                mode="multiple"
                style={{ width: '300px', marginRight: '5px' }}
                placeholder="Choose your GitLab administrator(s)"
                onChange={(value, option) => onChange(value, option)}
                optionLabelProp="label"
                labelInValue
              >
                {slackMembers.map((member) => (
                  <Option key={member.id} value={member.id} label={member.real_name}>
                    <Avatar
                      size={30}
                      src={member?.profile?.image_24 || member?.image_24}
                      className="!mr-2"
                    />
                    {member.real_name}{' '}
                    {member?.real_name?.length === 0 ? member.name : ''}
                  </Option>
                ))}
              </Select>
              <button
                className="m-2 bg-primary px-2 py-1 text-textWhite hover:bg-hoverPrimary hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50"
                onClick={() => onConfirm()}
              >
                Ask my admin to install Axolo
              </button>
            </>
          ))}
      </div>
    </div>
  )
}
