import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import OnboardingSteps from '../../components/OnboardingSteps'
import { Typography } from 'antd'
import LoaderIcon from '../../components/LoaderIcon'

export default function WaitingGitInstallation() {
  const history = useHistory()
  const organization = useSelector(Organization.selectors.getOrganization)
  const axios = useAxiosWithHeader()

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await axios.get(`/organizations/p/fetchOrgaForApp`)
        // if savingChannelsOnboardingCompleted is true, then we refresh the application data to
        // be able to onboard the developers
        if (response.data.savingChannelsOnboardingCompleted) {
          history.push('/home/refresh')
        }
      } catch (error) {
        console.error('Error checking if message should be sent:', error)
      }
    }, 2000)

    return () => clearInterval(interval)
  }, [organization, history])

  return (
    <MainDivOnboarding>
      <OnboardingSteps />
<div className="mt-16 flex flex-col items-center justify-center gap-4">
  <LoaderIcon title={''} spinning={true} size={42} />

  <Typography.Title level={4}>Hang tight, we're importing your data...</Typography.Title>
  <p className="">
    Axolo is still working on importing your data from GitLab. Once the process is finished, we’ll notify you through Slack and email. Feel free to leave this page open; it will refresh automatically, or you can close it and return later.
  </p>
</div>
    </MainDivOnboarding>
  )
}
