import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { Organization } from '../../reducers/organizationReducer'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PlayerState } from '../../reducers/playersReducer'
import OnboardingSteps from '../../components/OnboardingSteps'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import { URLBACK } from '../../assets/urls'
import { Engineer } from '../../reducers/engineerReducer'
import { useAxiosWithHeader } from '../../utils'
import ModalErrorInstallation from '../../components/ModalErrorInstallation'
import { User } from '../../reducers/userReducer'
import TeamManagementTable from '../../components/TeamManagementTable'
import NextButtonWithModal from './notifyModal'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'

// we let the onboarding continue if there are 3 people or less in GitHub/GitLab or Slack
// or the admin invited at least 2 devs
function canContinueOnboarding({ players, user }) {
  const { activeSlackUsers } = user
  if (players?.length <= 3) return true
  if (activeSlackUsers <= 3) return true
  const activePlayers = players.filter((player) => player.communicationToolId)
  if (activePlayers?.length < 2) return false
  return true
}

export default function OnboardingInviteMembers() {
  const players = useSelector(PlayerState.selectors.getPlayers)
  const organization = useSelector(Organization.selectors.getOrganization)
  const user = useSelector(User.selectors.selectUser)
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const history = useHistory()
  const dispatch = useDispatch()
  const axios = useAxiosWithHeader()

  const canContinue = canContinueOnboarding({ players, user })

  const handleNotifyConfirmation = async (notify) => {
    try {
      if (notify) {
        axios.post(`${URLBACK}engineer/sendWelcomeMsgToEngineers/${organization.id}`)
        console.log('Notifying team members')
        messageInteraction({
          type: 'success',
          content: `Successfully notified your team members!`,
          duration: 3,
        })
      }
      history.push('/home/onboarding/team-channel')
    } catch (error) {
      console.error(error)

      // Report the error to Sentry
      Sentry.captureException(error)

      // Notify the user to contact support
      messageInteraction({
        type: 'error',
        content: 'Error notifying your team. Please contact support.',
      })
    }
  }

  useEffect(() => {
    if (!engineer?.id) {
      axios
        .get(`${URLBACK}engineer/me/${organization.id}`)
        .then((engineerResp) => {
          dispatch(
            Organization.actions.setData({
              timeZone: engineerResp.timeZone,
              timeZoneOffset: engineerResp.timeZoneOffset,
            }),
          )
          dispatch(Engineer.actions.setData(engineerResp.data))
        })
        .catch((error) => {
          console.log('error getting engineer info', error)
        })
    }
  }, [players])

  return (
    <MainDivOnboarding>
      {organization?.errorInstallation?.length > 0 ? (
        <ModalErrorInstallation dispatch={dispatch} />
      ) : null}
      <OnboardingSteps step={3} />
      <>
        <br></br>
        <TeamManagementTable />
        <NextButtonWithModal
          canContinue={canContinue}
          onNotifyConfirm={handleNotifyConfirmation}
        />
      </>
    </MainDivOnboarding>
  )
}
