import { Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { URLBACK } from '../../assets/urls'
import OnboardingSteps from '../../components/OnboardingSteps'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import { User } from '../../reducers/userReducer'

const allsteps = [
  { id: 'Step 1', name: 'Install on Git platform', href: '#', status: 'complete' },
  { id: 'Step 2', name: 'Install Slack', href: '#', status: 'current' },
  { id: 'Step 3', name: 'Onboard your team', href: '#', status: 'upcoming' },
]

export default function SlackOnboarding() {
  const user = useSelector(User.selectors.selectUser)
  return (
    <MainDivOnboarding>
      <OnboardingSteps steps={allsteps} />
      <div className="mt-10 flex flex-col justify-center text-center">
        <Typography.Title level={4}>Add Slack to your workspace</Typography.Title>
      </div>
      <div className="flex justify-center justify-self-center text-center">
        <a
          href={'#'}
          onClick={() => window.open(`${URLBACK}identify/slack?id=${user.id}`, '_self')}
          style={{
            alignItems: 'center',
            color: '#000',
            backgroundColor: '#531853',
            border: '1px solid #531853',
            borderRadius: '4px',
            display: 'inline-flex',
            fontFamily: 'Lato, sans-serif',
            fontSize: '18px',
            fontWeight: 600,
            height: '56px',
            justifyContent: 'center',
            textDecoration: 'none',
            width: '276px',
            text: 'white',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: '24px', width: '24px', marginRight: '12px' }}
            viewBox="0 0 122.8 122.8"
          >
            <path
              d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
              fill="#e01e5a"
            />
            <path
              d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
              fill="#36c5f0"
            />
            <path
              d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
              fill="#2eb67d"
            />
            <path
              d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
              fill="#ecb22e"
            />
          </svg>
          <p className="!m-0 text-white"> Add to Slack</p>
        </a>
      </div>
    </MainDivOnboarding>
  )
}
