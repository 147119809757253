import { useHistory } from 'react-router'
import BackButton from '../../Buttons/backButton'

export default function TableContainer({ title, description, children }) {
  const history = useHistory()
  return (
    <>
      <BackButton callback={() => history.push('/analytics/dashboard')}></BackButton>
      <div className="sm:flex sm:items-center">
        <div className="mt-5 px-4 sm:px-6 lg:px-8 sm:flex-auto">
          <h3 className="font-medium text-lg text-gray-500 mb-0">{title}</h3>
          <h4 className="text-sm font-normal text-gray-900">{description}</h4>
        </div>
      </div>
      {children}
    </>
  )
}
