export const userMenu = () => [
  // { name: orgName, href: '#' },
  // { name: 'Billing', route: 'billing' },
  // { name: 'Users', route: 'users' },
  { name: 'Refresh application data' },
  { name: 'Log out' },
]

export const navigation = [
  { name: 'Team', route: 'team' },
  { name: 'Merge Requests', route: 'open' },
  { name: 'Settings', route: 'settings' },
  // commented on 4 oct 2022
  // { name: 'Analytics', route: 'analytics' },
]

export const subNavigation = {
  team: [
    { name: 'Team management', route: 'team-management' },
    { name: 'Batch import', route: 'batch-import' },
    { name: 'Administrators', route: 'admins' },
  ],
  open: [{ name: 'Open Slack channels', route: 'channels' }],
  settings: [
    { name: 'General', route: 'pull-request-channel' },
    { name: 'Team channel', route: 'team-channel' },
    { name: 'Stand-up', route: 'standups' },
    { name: 'Integrations', route: 'integration' },
    { name: 'Billing', route: 'billing' },
  ],
  // commented on 4 oct 2022
  // analytics: [{ name: 'Dashboard', route: 'dashboard' }],
}
