import React from 'react'
import { Layout } from 'antd'
import Header from '../Header/header'
import HomeFooter from '../../pages/FooterHomepage/footerHomePage'
import { useIsAdminUser, useIsEngineer } from '../../utils'
import HeaderEngineer from '../HeaderEngineer/headerEngineer'
import TopBannerBillingRedirection from '../topBanner'
// import { User } from '../../reducers/userReducer'
// import { Organization } from '../../reducers/organizationReducer'
// import { useEffectOnce } from 'react-use'

export default function AppLayout({ children, items }) {
  const isEngineer = useIsEngineer()
  const isAdminUser = useIsAdminUser()

  // Every time the user updates it updates the whole app and can end up in a loop.
  // This shouldn't be use this way, need to either not update the user or tell React
  // to not update

  // const user = useSelector(User.selectors.selectUser)
  // const organization = useSelector(Organization.selectors.getOrganization)

  // useEffectOnce(() => {
  //   if (process.env.NODE_ENV === 'production') {
  //     window.analytics.track('App Page Rendering', {
  //       userId: user.id,
  //       page: location?.pathname,
  //       plan: organization?.plan,
  //       organizationId: organization?.id,
  //       numberOfEngineers: organization?.players?.length,
  //     })
  //   }
  // })

  const AxoloBody = () => {
    return (
      <div className="mx-auto max-w-[1500px] px-4 pb-12 sm:px-6 lg:px-8">
        <div className="min-h-[500px] rounded-lg bg-white px-10 py-6 shadow">
          <Layout.Content className="site-layout-background" style={{}}>
            {children}
          </Layout.Content>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="flex min-h-full w-full flex-col">
        <TopBannerBillingRedirection />
        {/* Either engineer user or admin user */}
        {isEngineer && <HeaderEngineer />}
        {isAdminUser && <Header />}
        <main className="-mt-32">
          <AxoloBody />
          <HomeFooter />
        </main>
      </div>
    </div>
  )
}
