import { Switch, message } from 'antd'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'

export default function ChannelDeploymentOption({ organization, setting, user }) {
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()

  async function toggleDeploymentChannel() {
    const hide = message.loading('Loading...', 0)
    try {
      const res = await axios.get(
        `/organizations/updateSettings/${organization.id}?setting=deploymentChannel`,
      )
      hide()
      if (res.data.substring(0, 27) === 'No engineer with Slack Auth') {
        message.error(
          'Unable to unarchive _axolo_deployments channel because no engineer has signed in with Slack.',
          5,
        )
        return
      }
      if (res?.data?.slice(-17) === 'channel_not_found') {
        message.error(
          "Error from the Slack API: channel_not_found. It's possible the channel was private and Axolo couldn't unarchive it. Please unarchive the channel, invite Axolo in it and then enable the setting. Contact support if needed, we're nearby!",
          10,
        )
        return
      }
      if (res?.data?.includes('restricted_action')) {
        message.error(
          "Slack 'restriced_action' detected. We sent you a dm in Slack to continue.",
          10,
        )
        return
      }

      if (res.data.includes('Error')) {
        message.error(
          'Error with the _axolo_deployments channel. Please try again in a few seconds or contact support.',
          5,
        )
        return
      }
      if (setting.deploymentChannel)
        message.warning('_axolo_deployments channel was archived', 5)
      else
        message.success(
          '_axolo_deployments channel was opened in your Slack workspace',
          5,
        )
      let newSettings = cloneDeep(setting)
      newSettings.deploymentChannel = !newSettings.deploymentChannel
      dispatch(Organization.actions.setData({ setting: newSettings }))
    } catch (e) {
      console.log('Error:', e)
      hide()
      message.error(
        'Your Slack settings do not enable Axolo to handle channels in your workspace. Contact support if you need more information.',
        10,
      )
      return
    }
  }

  return (
    <div className="flex justify-between">
      <p className="">
        Open a dedicated _axolo_deployments channel for deployment notifications.
      </p>
      <Switch
        checked={setting?.deploymentChannel}
        onClick={() => toggleDeploymentChannel(organization)}
      />
    </div>
  )
}
