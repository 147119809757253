import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import { colors } from '../Colors'
import { GitHubProfile, SlackProfile, TeamBadge } from '../TeamManagementTable/utils'

export default function DropZoneTeamManagementTableContent({
  visibleToOnboard,
  alreadyOnboardedEngineers,
  setSelectedEngineers,
  selectedEngineers,
  notFoundEmails,
}) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const { slackMembers } = organization
  let { teams } = organization
  const teamColors = {}
  if (!Array.isArray(teams)) {
    teams = []
  }
  teams?.forEach((team) => {
    // To define the color of a team I'm taking the char code of the first letter of their team name.
    let colorNumber = Math.abs(team.name[0].toLowerCase().charCodeAt(0) - 97)
    if (colorNumber > colors.length) {
      colorNumber = colorNumber % colors.length
    }
    teamColors[team.name] = colors[colorNumber]
  })
  const teamBadgeCss = 'max-w-20 wrap flex flex-wrap px-3 py-4 text-sm text-gray-500'

  const ActionDropZoneSection = ({ eng, selectedEngineers, setSelectedEngineers }) => {
    const checked =
      !(eng?.communicationToolId?.length > 0) &&
      selectedEngineers.map((e) => e.providerLogin).includes(eng.providerLogin)

    const disabled = eng?.communicationToolId?.length > 0
    return (
      <div className="flex flex-col items-center justify-end ">
        <div className="flex h-6 items-center">
          <input
            id="eng-batch-import"
            aria-describedby="eng-batch-import"
            name="eng-batch-import"
            type="checkbox"
            className="h-6 w-6 rounded border-gray-300 text-primary focus:ring-primary"
            checked={checked}
            disabled={disabled}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedEngineers([...selectedEngineers, eng])
              } else {
                setSelectedEngineers(
                  selectedEngineers.filter((selectedEng) => selectedEng.id !== eng.id),
                )
              }
            }}
          />
        </div>
        {disabled ? 'Already onboarded' : ''}
      </div>
    )
  }

  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      {alreadyOnboardedEngineers.map((eng) => {
        return (
          <tr key={eng?.id} id={eng?.id}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              <GitHubProfile eng={eng} />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
              <SlackProfile profileSlack={eng?.slackInfo?.profile} />
            </td>
            <td className={teamBadgeCss}>
              <TeamBadge eng={eng} teamColors={teamColors} />
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
              <ActionDropZoneSection
                eng={eng}
                selectedEngineers={selectedEngineers}
                setSelectedEngineers={setSelectedEngineers}
              />
            </td>
          </tr>
        )
      })}
      {visibleToOnboard?.map((eng) => {
        if (eng.isBot) {
          return null
        }
        return (
          <tr key={eng?.id} id={eng?.id}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              <GitHubProfile eng={eng} />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
              <SlackProfile
                profileSlack={
                  slackMembers?.find((m) => m?.profile?.email === eng.gitEmail)?.profile
                }
              />
            </td>
            <td className={teamBadgeCss}>
              <TeamBadge eng={eng} teamColors={teamColors} />
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
              <ActionDropZoneSection
                selectedEngineers={selectedEngineers}
                eng={eng}
                setSelectedEngineers={setSelectedEngineers}
              />
            </td>
          </tr>
        )
      })}
      {alreadyOnboardedEngineers.length === 0 && visibleToOnboard.length === 0 && (
        <tr>
          <td colSpan="4" className="py-4 text-center text-sm text-gray-500">
            No engineers found.
          </td>
        </tr>
      )}
      {notFoundEmails?.length > 0 ? (
        <tr key={'missing-emails'} id={'missing-emails'}>
          <td
            colSpan="4"
            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
          >
            Emails not found:{' '}
            <span className="font-normal">
              {new Intl.ListFormat('en-GB', {
                style: 'long',
                type: 'conjunction',
              }).format(notFoundEmails.map((email) => email))}
            </span>
          </td>
        </tr>
      ) : null}
    </tbody>
  )
}
