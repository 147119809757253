import { User } from './userReducer'
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

let initialState = {
  loading: true,
  error: false,
  onboardingCompleted: false,
  hasAccess: false,
  providerLogin: '',
  status: '',
  plan: '', // to do
  suspendedGithub: '',
  avatarUrl: '',
  mergedPullRequests: [],
  deployments: [],
  pullRequestLength: [],
  cycleTime: {
    stat: {
      totalCycleTime: 0,
      developmentTime: 0,
      reviewTime: 0,
    },
    pastStat: {
      totalCycleTime: 0,
      developmentTime: 0,
      reviewTime: 0,
    },
  },
  continuousCycleTime: [],
  reviewTime: {
    stat: {
      firstResponseTime: 0,
      reworkTime: 0,
      idleCompletionTime: 0,
      totalReviewTime: 0,
    },
    pastStat: {
      firstResponseTime: 0,
      reworkTime: 0,
      idleCompletionTime: 0,
      totalReviewTime: 0,
    },
  },
  repositories: [],
  teams: [],
  temporality: 'days',
}

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        ...action.payload,
        lastUpdated: moment().format(),
      }
    },
    setError: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    setLoading: (state) => ({ ...state, loading: true }),
    unsetLoading: (state) => ({ ...state, loading: false }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(User.actions.logout, (_) => initialState)
      .addCase(User.actions.changesOrg, (_) => initialState)
  },
})

export const { setError, setData } = analyticsSlice.actions

const getAnalytics = (state) => state.analytics

export const Analytics = {
  actions: analyticsSlice.actions,
  selectors: { getAnalytics },
}
