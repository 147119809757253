import { useSelector } from 'react-redux'
import { Setting } from '../../../reducers/settingReducer'
import moment from 'moment'
import { Organization } from '../../../reducers/organizationReducer'
import GitHubUsers from '../../GithubUsers'
import { tableClass, tdClass, thClass } from './tablesClasses'
import { classNames } from '../../../utils'
import { DateTime, Duration } from 'luxon'
import { Tooltip } from 'antd'
import { delayToolTip } from '../utils/const'
import { ExclamationIcon } from '@heroicons/react/outline'

const ReviewTimeData = ({ pr }) => {
  if (pr.reviewTime) {
    return (
      <span className={`text-[#22c55e]`}>
        {Duration.fromObject({ minutes: pr.reviewTime }).toFormat("hh'h'mm'm'")}
      </span>
    )
  } else if (pr.numberOfReviews === 0)
    return (
      <div className="flex flex-col max-w-fit">
        <div className="flex self-center">
          <ExclamationIcon className="h-6 w-6 text-orange-300" aria-hidden="true" />
        </div>
        no review
      </div>
    )
  return 'no data'
}
export default function TablePR({
  pullRequests,
  title,
  showReviewTime,
  showDevTime,
  showPrReviews,
  showReviewRequests,
  showResponseTime,
  showReworkTime,
  showCompletionTime,
  titleWidth = '350',
}) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const setting = useSelector(Setting.selectors.getSetting)
  const { startDate, endDate } = setting
  return (
    <div className="mt-5 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h3 className="font-medium text-lg text-gray-500 mb-0">{title}</h3>
          <h4 className="text-sm font-normal text-gray-900">
            A list of all the PR from{' '}
            {moment(startDate, 'DD-MM-YYYY').format('MMMM Do YYYY')} to{' '}
            {moment(endDate, 'DD-MM-YYYY').format('MMMM Do YYYY')}.
          </h4>
        </div>
      </div>
      <div className="mt-3 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className={tableClass}>
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className={classNames(thClass, `w-[${titleWidth}px]`)}
                    >
                      Title
                    </th>
                    <th scope="col" className={classNames(thClass, `w-[80px]`)}>
                      Creator
                    </th>
                    <th scope="col" className={classNames(thClass, `w-[130px]`)}>
                      Reviewers
                    </th>
                    <th scope="col" className={thClass}>
                      Repository
                    </th>
                    {showDevTime && (
                      <th scope="col" className={thClass}>
                        Dev time
                      </th>
                    )}
                    {showReviewTime && (
                      <th scope="col" className={thClass}>
                        Review Time
                      </th>
                    )}
                    {showResponseTime && (
                      <th scope="col" className={thClass}>
                        Pickup Time
                      </th>
                    )}
                    {showReworkTime && (
                      <th scope="col" className={thClass}>
                        Rework Time
                      </th>
                    )}
                    {showCompletionTime && (
                      <th scope="col" className={thClass}>
                        Completion Time
                      </th>
                    )}
                    {showCompletionTime && (
                      <th scope="col" className={thClass}>
                        Total Review Time
                      </th>
                    )}
                    {showReviewRequests && (
                      <th scope="col" className={thClass}>
                        # of review requests
                      </th>
                    )}
                    {showPrReviews && (
                      <th scope="col" className={thClass}>
                        # of PR reviews
                      </th>
                    )}
                    <th scope="col" className={classNames(thClass, 'w-[100px]')}>
                      Diff
                    </th>
                    <th scope="col" className={classNames(thClass, 'w-[160px]')}>
                      Created At / Merged At
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {pullRequests &&
                    pullRequests?.map((pr) => (
                      <tr key={pr.id}>
                        <td className={tdClass}>
                          <a
                            target={'_blank'}
                            rel="noreferrer"
                            href={`https://github.com/${organization.providerLogin}/${pr?.repository?.name}/pull/${pr?.number}`}
                            onClick={() => {
                              if (process.env.NODE_ENV === 'production') {
                                window.analytics.track('Clicked on PR Title', {
                                  repository: pr?.repository?.name,
                                  prTitle: pr.title,
                                })
                              }
                            }}
                          >
                            <Tooltip
                              placement="topLeft"
                              title={pr.title}
                              mouseEnterDelay={delayToolTip}
                            >
                              {pr.title || 'no data'}
                            </Tooltip>
                          </a>
                        </td>
                        <td className={tdClass}>
                          <GitHubUsers githubUsers={[pr?.creator]} />
                        </td>
                        <td
                          className={'py-4 pl-4 pr-3 text-sm font-medium text-gray-900'}
                        >
                          <GitHubUsers githubUsers={pr?.reviewers} />
                        </td>
                        <td className={tdClass}>
                          <Tooltip
                            mouseEnterDelay={delayToolTip}
                            title={pr?.repository?.name}
                          >
                            <a
                              target={'_blank'}
                              rel="noreferrer"
                              href={`https://github.com/${organization.providerLogin}/${pr?.repository?.name}`}
                              onClick={() => {
                                if (process.env.NODE_ENV === 'production') {
                                  window.analytics.track('Clicked on repository', {
                                    repository: pr?.repository?.name,
                                    prTitle: pr.title,
                                  })
                                }
                              }}
                            >
                              {pr?.repository?.name}
                            </a>
                          </Tooltip>
                        </td>
                        {showDevTime && (
                          <td className={tdClass}>
                            {pr.devTime ? (
                              <span className={`text-[#6366f1]`}>
                                {Duration.fromObject({ minutes: pr.devTime }).toFormat(
                                  "hh'h'mm'm'",
                                )}
                              </span>
                            ) : (
                              'no data'
                            )}
                          </td>
                        )}
                        {showReviewTime && (
                          <td className={tdClass}>
                            <ReviewTimeData pr={pr} />
                          </td>
                        )}
                        {showResponseTime && (
                          <td className={tdClass}>
                            <span className={`text-[#6366f1]`}>
                              {Duration.fromObject({ minutes: pr.responseTime }).toFormat(
                                "hh'h'mm'm'",
                              )}
                            </span>
                          </td>
                        )}
                        {showReworkTime && (
                          <td className={tdClass}>
                            <span className={`text-[#22c55e]`}>
                              {Duration.fromObject({ minutes: pr.reworkTime }).toFormat(
                                "hh'h'mm'm'",
                              )}
                            </span>
                          </td>
                        )}
                        {showCompletionTime && (
                          <td className={tdClass}>
                            <span className={`text-[#11165e]`}>
                              {Duration.fromObject({
                                minutes: pr.completionTime,
                              }).toFormat("hh'h'mm'm'")}
                            </span>
                          </td>
                        )}
                        {showCompletionTime && (
                          <td className={tdClass}>
                            <span className={'text-orange-500'}>
                              {Duration.fromObject({
                                minutes:
                                  pr.responseTime + pr.reworkTime + pr.completionTime,
                              }).toFormat("hh'h'mm'm'")}
                            </span>
                          </td>
                        )}
                        {showReviewRequests && (
                          <td className={tdClass}>{pr?.review_requests?.length}</td>
                        )}
                        {showPrReviews && (
                          <td className={tdClass}>{pr?.pull_request_reviews?.length}</td>
                        )}
                        <td className={tdClass}>
                          <span className={'text-green-500'}>+{pr.additions}</span>
                          <br></br>
                          <span className={'text-red-500'}>-{pr.deletions}</span>
                        </td>
                        <td className={classNames(tdClass, 'w-[100px]')}>
                          <br></br>
                          {DateTime.fromISO(pr.providerCreatedAt).toFormat(
                            'DD MMMM YYYY',
                          ) || 'no data'}
                          /<br></br>
                          {pr.mergedAt ? (
                            DateTime.fromISO(pr.mergedAt).toFormat('DD MMMM YYYY')
                          ) : (
                            <Tooltip
                              mouseEnterDelay={delayToolTip}
                              title={`Closed on ${DateTime.fromISO(pr.closedAt).toFormat(
                                'DD MMMM YYYY',
                              )}`}
                            >
                              Closed on{' '}
                              {DateTime.fromISO(pr.closedAt).toFormat('DD MMMM YYYY')}
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
