import { partition } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlayerState } from '../../reducers/playersReducer'
import TeamManagementTableColumn from './teamManagementTableColumn'
import TeamManagementTableContent from './teamManagementTableContent'
import RefreshTeamButton from '../utils/refreshTeamsButton'
import { Input } from 'antd'
import ReactPaginate from 'react-paginate'
import { LoadingTeamManagementTable } from './LoadingTeamManagementTable'
import { Organization } from '../../reducers/organizationReducer'
import { debounce } from '../../pages/OpenChannels/debounce'
import { AskEveryoneToCompleteOnboardingButton } from './askTeamOnboarding'
import { useAxiosWithHeader } from '../../utils'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const Search = Input.Search

const ITEMS_PER_PAGE = 50

export default function TeamManagementTable() {
  const dispatch = useDispatch()
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)
  const [selectedTeamIds, setSelectedTeamIds] = useState([])
  const players = useSelector(PlayerState.selectors.getPlayers)
  const [allVisibleEngineer, setAllVisibleEngineer] = useState(players)
  let nonDeletedEngnieers = players.filter((player) => !player.deleted)
  let [activeEngineers, inactiveEngineers] = partition(
    nonDeletedEngnieers,
    (eng) => eng?.communicationToolId?.length > 0,
  )

  const [activePlayers, setActivePlayers] = useState(activeEngineers)
  const [inactivePlayers, setInactivePlayers] = useState(inactiveEngineers)
  const [visibleActiveEngineers, setVisibleActiveEngineers] = useState([])
  const [visibleInactiveEngineers, setVisibleInactiveEngineers] = useState([])
  const [searchText, setSearchText] = useState('')
  const searchInputRef = useRef(null)
  const [loading, setLoading] = useState(false)

  // Pagination
  const [currentPage, setCurrentPage] = useState(0)
  const location = useLocation()

  const pathName = location?.pathname

  useEffect(() => {
    // Calculate the current items based on pagination
    const visibleActiveEngineers = activePlayers.slice(
      currentPage * ITEMS_PER_PAGE,
      (currentPage + 1) * ITEMS_PER_PAGE,
    )
    setVisibleActiveEngineers(visibleActiveEngineers)

    const visibleInactiveEngineers = inactivePlayers.slice(
      currentPage * ITEMS_PER_PAGE,
      (currentPage + 1) * ITEMS_PER_PAGE,
    )
    setVisibleInactiveEngineers(visibleInactiveEngineers)
  }, [currentPage, activePlayers, inactivePlayers])

  const handlePageClick = (event) => {
    const newPage = event.selected
    setCurrentPage(newPage)
  }

  // End Pagination

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  })

  useEffect(() => {
    let nonDeletedEngnieers = players.filter((player) => !player.deleted)
    setAllVisibleEngineer(nonDeletedEngnieers)
  }, [players])

  useEffect(() => {
    setActivePlayers(
      allVisibleEngineer.filter((player) => player?.communicationToolId?.length > 0),
    )
    setInactivePlayers(
      allVisibleEngineer.filter((player) => !(player?.communicationToolId?.length > 0)),
    )
  }, [allVisibleEngineer])

  useEffect(() => {
    if (!(nonDeletedEngnieers.length > 0) || !(organization.slackMembers?.length > 0)) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [nonDeletedEngnieers, organization.slackMembers])

  const handleReset = () => {
    setAllVisibleEngineer(players)
  }

  const onSearch = (searchText) => {
    if (typeof searchText !== 'string') {
      handleReset()
      return
    }

    const searchTextLower = searchText.toLowerCase()
    const isMatch = (record) => {
      return (
        record?.providerLogin?.toLowerCase().includes(searchTextLower) ||
        record?.email?.toLowerCase().includes(searchTextLower) ||
        record?.gitEmail?.toLowerCase().includes(searchTextLower)
      )
    }

    const filteredData = players.filter(isMatch)
    setAllVisibleEngineer(filteredData)
  }

  // const debouncedSearch = useCallback(
  //   debounce((value) => onSearch(value), 500),
  //   [],
  // )

  // useEffect(() => {
  //   if (players.length < 300 || searchText.length > 3) {
  //     debouncedSearch(searchText)
  //   }
  // }, [searchText, players, debouncedSearch])

  const FilterMenu = () => {
    return (
      <div className="table-operations mb-4 flex items-center">
        <Search
          size="large"
          allowClear
          enterButton={players.length > 150}
          onChange={(e) => {
            setSearchText(e.target.value)
            console.log('players.length', e.target.value, e.target.value.length)
            if (players.length < 150 || e.target.value.length !== 1) {
              onSearch(e.target.value)
            }
          }}
          placeholder="Search developers by login or email."
          value={searchText}
          onPressEnter={(e) => {
            setSearchText(e.target.value)
            onSearch(e.target.value)
            // debouncedSearch(searchText)
          }}
          // onSearch={() => {
          //   debouncedSearch(searchText)
          //   onSearch(e.target.value)
          // }}
          className="!w-[80vw] !max-w-[400px] lg:!w-[50vw]"
          ref={searchInputRef}
        />
        <RefreshTeamButton />
      </div>
    )
  }
  return (
    <div className="">
      <div className="">
        <div className="">
          <div className="mt-8 flex justify-between">
            <FilterMenu />
          </div>
        </div>
        <div className=" flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <TeamManagementTableColumn
                    selectedTeamIds={selectedTeamIds}
                    setSelectedTeamIds={setSelectedTeamIds}
                    visibleActiveEngineers={visibleActiveEngineers}
                    axios={axios}
                    org={organization}
                    dispatch={dispatch}
                    pathName={pathName}
                  />
                  {loading ? (
                    <LoadingTeamManagementTable />
                  ) : (
                    <TeamManagementTableContent
                      visibleActiveEngineers={visibleActiveEngineers}
                      visibleInactiveEngineers={visibleInactiveEngineers}
                    />
                  )}
                </table>
              </div>
              <br></br>
              <div className="flex items-center justify-center">
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  pageCount={Math.ceil(allVisibleEngineer.length / ITEMS_PER_PAGE)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'inline-flex items-center -space-x-px'}
                  pageLinkClassName={
                    'py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
                  }
                  previousLinkClassName={
                    'py-2 px-3 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
                  }
                  nextLinkClassName={
                    'py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
                  }
                  breakLinkClassName={
                    'py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300'
                  }
                  activeLinkClassName={
                    'py-2 px-3 leading-tight text-blue-600 bg-gray-200 border font-bold'
                  }
                  // Ensure to add proper conditions to these to prevent styling applied when disabled
                  disabledLinkClassName={
                    'py-2 px-3 leading-tight text-gray-400 bg-white border border-gray-300'
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
