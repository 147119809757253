import OnboardingSteps from '../../components/OnboardingSteps'
import { Typography } from 'antd'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import { useHistory } from 'react-router-dom'
import { sendAxoloSlack, useAxiosWithHeader } from '../../utils'
import { useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { useEffectOnce } from 'react-use'

export default function BitbucketOnboarding() {
  const history = useHistory()
  const user = useSelector(User.selectors.selectUser)
  const axios = useAxiosWithHeader()

  async function handleBitbucketEarlyAccesRequest() {
    window.$crisp.push(['do', 'chat:open'])
    window.$crisp.push([
      'do',
      'message:show',
      [
        'text',
        "Hi!\n\n Thank you for your interest in Axolo for Bitbucket early access. We'll get back to you as soon as possible. In the mean time you can tell us more about your organization, how big is your team who will be using Axolo, and what are your expectations.",
      ],
    ])
  }

  useEffectOnce(() => {
    sendAxoloSlack({
      userId: user?.id,
      type: 'onboarding-bitbucket',
      message: 'Clicked on Bitbucket onboarding',
      axios,
    })
  })

  return (
    <MainDivOnboarding>
      <OnboardingSteps />
      <br></br>
      <div className="pb-12 text-center">
        <Typography.Title level={4}>
          Bitbucket integration is still in development...
        </Typography.Title>
        <p className="mt-20">
          Would you like to be part of our Axolo for Bitbucket early-access?
        </p>
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          onClick={() => handleBitbucketEarlyAccesRequest()}
        >
          Request early-access
        </button>
        <div
          className="flex justify-center"
          onClick={() => history.push('/home/onboarding')}
        >
          <button className="pt-6 text-center italic underline">Go back</button>
        </div>
      </div>
    </MainDivOnboarding>
  )
}
