import SkeletonLoading from '../Analytics/utils/skeletonLoader'
import { TeamChannelTableColumn } from './teamChannelColumns'

export const classChannelColumn =
  'w-[275px] whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'

export const classTeamColumn =
  'w-[500px] items-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex flex-wrap gap-2'

export const classCreatorColumn =
  'w-[250px] whitespace-nowrap px-3 py-4 text-sm text-gray-500'

export const classActionColumn =
  'w-[167px] whitespace-nowrap px-3 py-4 text-sm text-gray-500 '

export const classOptionTeamChannel =
  '!w-[300px] whitespace-nowrap px-3 py-4 text-sm text-gray-500'

export const LoadingTeamChannelData = () => (
  <tr key="newStandup">
    <td className={classChannelColumn}>
      <SkeletonLoading specificCss="!w-[250px] !bg-sky-300" />
    </td>
    <td className={classTeamColumn}>
      <div className="flex items-center">
        <SkeletonLoading specificCss="!w-[375px] !bg-sky-300" />
      </div>
    </td>
    <td className={classCreatorColumn}>
      <div className="flex items-center">
        <SkeletonLoading specificCss="!w-[225px] !bg-sky-300 " />
      </div>
    </td>
    <td className={classActionColumn}></td>
  </tr>
)

export const MasterTableContainer = ({ children, provider }) => (
  <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
    <table className="min-w-full divide-y divide-gray-300 overflow-visible">
      <TeamChannelTableColumn provider={provider} />
      <tbody className="divide-y divide-gray-200 bg-white">{children}</tbody>
    </table>
  </div>
)
