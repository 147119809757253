import Avatar from 'antd/lib/avatar/avatar'
import { CheckIcon, ExclamationIcon } from '@heroicons/react/solid'
import { Button, Tag, Tooltip } from 'antd'
import { URLBACK } from '../../assets/urls'
import { UserAvatar } from '../OpenChannels/userAvatar'

export const columnsEngineer = ({ teamColor, engineer }) => [
  {
    title: '',
    dataIndex: 'avatarUrl',
    key: 'avatarUrl',
    render: (avatarUrl) => <UserAvatar record={engineer} />,
  },
  {
    title: 'Git Login',
    dataIndex: 'providerLogin',
    key: 'providerLogin',
  },
  {
    title: '',
    dataIndex: 'slackAvatar',
    key: 'slackAvatar',
    render: (slackAvatar) => <Avatar src={slackAvatar}></Avatar>,
  },
  {
    title: 'Slack name',
    dataIndex: 'slackUsername',
    key: 'slackUsername',
  },
  {
    title: 'Teams',
    dataIndex: 'teams',
    key: 'teams',
    render: (playerTeams = []) => (
      <>
        {playerTeams?.map((team) => {
          return (
            <Tag
              style={{ marginBottom: '5px' }}
              color={teamColor[team.name]}
              key={team.slug}
            >
              {team.name.length > 33 ? team.name.slice(0, 30) + '...' : team.name}
            </Tag>
          )
        })}
      </>
    ),
  },
  {
    title: 'GitHub Auth',
    dataIndex: 'githubAuth',
    key: 'githubAuth',
    render: (item, raw) => (
      <div className="flex justify-center">
        {raw.githubAuthed ? (
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
        ) : (
          <a
            href={`${URLBACK}player/connect/github/${engineer.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="!hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500 !inline-flex !items-center !rounded-md !border !border-transparent !bg-primary !px-6 !py-3 !text-base !font-medium !text-white !shadow-sm">
              Sign in with GitHub
            </Button>
          </a>
        )}
      </div>
    ),
  },
  {
    title: 'GitLab Auth',
    dataIndex: 'gitlabAuth',
    key: 'gitlabAuthed',
    render: (item, raw) => (
      <div className="flex justify-center">
        {raw.gitlabAuthed ? (
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
        ) : (
          <a
            href={`${URLBACK}player/connect/gitlab/${engineer.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="!hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500 !inline-flex !items-center !rounded-md !border !border-transparent !bg-primary !px-6 !py-3 !text-base !font-medium !text-white !shadow-sm">
              Sign in with GitLab
            </Button>
          </a>
        )}
      </div>
    ),
  },
  {
    title: 'Slack Auth',
    dataIndex: 'slackAuth',
    key: 'slackAuth',
    render: (item, raw) => {
      let icon = (
        <div className="flex justify-center">
          <a
            href={`${URLBACK}player/connect/slack/${engineer.id}/${engineer.slackTeam}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="!hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500 !inline-flex !items-center !rounded-md !border !border-transparent !bg-primary !px-6 !py-3 !text-base !font-medium !text-white !shadow-sm">
              Sign in with Slack
            </Button>
          </a>
        </div>
      )
      if (raw?.slackUpToDate?.notUptoDateScopes?.length > 0) {
        icon = (
          <Tooltip
            title={`Missing scopes ${raw?.slackUpToDate?.notUptoDateScopes.join(',')}`}
          >
            <div className="flex flex-col items-center justify-center">
              <ExclamationIcon
                className="h-5 w-5 flex-shrink-0 text-yellow-500"
                aria-hidden="true"
              />
              <a
                href={`${URLBACK}player/connect/slack/${engineer.id}/${engineer.slackTeam}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="!hover:bg-hoverPrimary !focus:outline-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-indigo-500 !inline-flex !items-center !rounded-md !border !border-transparent !bg-primary !px-6 !py-3 !text-base !font-medium !text-white !shadow-sm">
                  Update my Slack auth
                </Button>
              </a>
            </div>
          </Tooltip>
        )
      }
      return raw?.slackUpToDate?.isUpToDate ? (
        <div className="flex justify-center">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
        </div>
      ) : (
        icon
      )
    },
  },
]
