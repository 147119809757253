import moment from 'moment'
import _ from 'lodash'
import { sortWithoutAddingEmptyValue } from './sortWithoutAddingEmptyValue'

function sortAddingEmptyValue({ data, temporality, startDate, endDate }) {
  let workingData = _.cloneDeep(data)
  workingData.sort((a, b) =>
    moment(a.providerCreatedAt.slice(0, 10), 'YYYY-MM-DD').diff(
      moment(b.providerCreatedAt.slice(0, 10), 'YYYY-MM-DD'),
    ),
  )

  let actualDate = moment(startDate, 'DD-MM-YYYY')
  const result = []
  let i = 0
  // adding empty value before the start of the working data range. We want 0s if we don't have any data between startDate and the beginning of the data
  let stillBeforeFirstData = moment(actualDate).isBefore(
    moment(workingData[i]?.providerCreatedAt?.slice(0, 10), 'YYYY-MM-DD'),
  )

  while (stillBeforeFirstData) {
    result.push({ date: actualDate.format('YYYY-MM-DD'), total: 0 })
    actualDate.add(1, temporality)
    stillBeforeFirstData = moment(actualDate).isBefore(
      moment(workingData[i].providerCreatedAt.slice(0, 10), 'YYYY-MM-DD'),
    )
  }

  // we are now at the beginning of the working data. We need to add empty value if there is no data on actual date.
  // if there are data on actual date, we need to sum them up.
  let stillBeforeLastData = moment(actualDate).isSameOrBefore(
    moment(
      workingData[workingData.length - 1]?.providerCreatedAt?.slice(0, 10),
      'YYYY-MM-DD',
    ),
  )
  while (stillBeforeLastData && i < workingData.length) {
    const dayCreated = moment(workingData[i].providerCreatedAt.slice(0, 10), 'YYYY-MM-DD')

    // this an error while the app update the date. Should not be taken into account
    if (dayCreated.isBefore(actualDate)) return []

    // if the day we are working on is before our current working value, it means that
    // today we do not have any PR. So we add an empty day and push one day forward
    if (dayCreated.isAfter(actualDate)) {
      const alreadyThisDayInResult = result.findIndex((item) =>
        moment(item.date, 'YYYY-MM-DD').isSame(actualDate, temporality),
      )
      if (alreadyThisDayInResult < 0) {
        result.push({ date: actualDate.format('YYYY-MM-DD'), total: 0 })
      }
      actualDate.add(1, temporality)
      stillBeforeLastData = moment(actualDate).isSameOrBefore(
        moment(
          workingData[workingData.length - 1]?.providerCreatedAt?.slice(0, 10),
          'YYYY-MM-DD',
        ),
      )
    }
    // if the day we are working on is the same day as the PR
    // it either means we should create a new day with a total of 1
    // or we should add 1 to the total of the day if we find a value in the result array
    else if (dayCreated.isSame(actualDate)) {
      const index = result.findIndex((item) =>
        moment(item.date, 'YYYY-MM-DD').isSame(dayCreated, temporality),
      )
      if (index >= 0) {
        result[index].total += 1
      } else {
        result.push({ date: dayCreated.format('YYYY-MM-DD'), total: 1 })
      }
      i += 1
    }
  }
  actualDate.add(1, temporality)

  // adding empty value after the end of the working data range. We want 0s if we don't have any data between the end of the data and the endDate
  const end = moment(endDate, 'DD-MM-YYYY')
  let stillBeforeEndDate = moment(actualDate).isSameOrBefore(end)

  while (stillBeforeEndDate) {
    result.push({ date: actualDate.format('YYYY-MM-DD'), total: 0 })
    actualDate.add(1, temporality)
    stillBeforeEndDate = moment(actualDate).isSameOrBefore(end)
  }
  return result // to test
  return result.sort((a, b) =>
    moment(a.date, 'YYYY-MM-DD').diff(moment(b.date, 'YYYY-MM-DD')),
  )
}

export default function sortUponProviderCreatedAt({
  data,
  temporality,
  startDate,
  endDate,
  type = 'addingEmptyValue',
}) {
  if (type === 'addingEmptyValue') {
    return sortAddingEmptyValue({ data, temporality, startDate, endDate })
  } else {
    return sortWithoutAddingEmptyValue({ data, temporality })
  }
}
