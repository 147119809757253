import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import LoaderIcon from '../LoaderIcon'
import Papa from 'papaparse'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { useDispatch } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'

const Dropzone = () => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      if (acceptedFiles.length > 0) {
        setLoading(true)
        const file = acceptedFiles[0]

        // Use FileReader to read the file
        const reader = new FileReader()
        reader.onload = () => {
          // Split the file content by newline to get an array of email addresses
          const emailList = reader.result.split('\n')

          // Check if the list is empty or not
          if (emailList.length === 0 || (emailList.length === 1 && emailList[0] === '')) {
            messageInteraction({
              content: 'Your file seems empty.',
              type: 'error',
            })
            setLoading(false)
            return
          }

          // Dispatch the email list to your Redux store or handle it as needed
          dispatch(Organization.actions.setData({ csvData: emailList }))
          setLoading(false)
        }

        reader.readAsText(file)
      }
    },
    [dispatch],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'text/csv',
    multiple: false,
  })

  if (loading) {
    return (
      <div className="mx-auto flex h-64 w-64 items-center justify-center rounded-md border-4 border-dashed border-gray-200">
        <LoaderIcon size={64} title={"We're processing your file"} spinning={loading} />
      </div>
    )
  }
  return (
    <div
      {...getRootProps()}
      className="mx-auto flex h-64 w-64 items-center justify-center rounded-md border-4 border-dashed border-gray-200"
    >
      <input {...getInputProps()} className="" />

      {isDragActive ? (
        <p className="px-4 text-gray-600">Drop the file here ...</p>
      ) : (
        <p className="px-4 text-gray-600">
          Drag 'n' drop your csv file here, or click to select your file
        </p>
      )}
    </div>
  )
}

export default Dropzone
