import { Avatar, Button } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { classNames, globalUpdateCompanySetting, useAxiosWithHeader } from '../../utils'
import { allPRStatus, classCell } from './helper-reminder-personalization'
import { InputNewReminderStatus } from './reminders-helper-PR-status'
import { Engineer } from '../../reducers/engineerReducer'
import { PlusOutlined } from '@ant-design/icons'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { Organization } from '../../reducers/organizationReducer'
import { cloneDeep } from 'lodash'
import { ReminderVariableSelector } from './reminder-varialbe-selector'
import { UserAvatar } from '../../pages/OpenChannels/userAvatar'

function handleErrorsInputPersonalReminder({ newMessage, newStatus }) {
  const missingInformation = []
  if (!(newMessage?.length > 0)) {
    missingInformation.push('a message for your new reminder')
  }
  if (!(newStatus?.length > 0)) {
    missingInformation.push('at least one PR status')
  }

  if (missingInformation.length > 0) {
    const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })
    const missingText = formatter.format(missingInformation)
    messageInteraction({
      content: `Missing ${missingText}.`,
      type: 'error',
    })
    return true
  }

  return false
}
export const ReminderInputLine = ({ newMessage, setNewMessage }) => {
  const engineer = useSelector(Engineer.selectors.getEngineer)
  const [newStatus, setNewStatus] = useState([])
  const [loading, setLoading] = useState(false)
  const organization = useSelector(Organization.selectors.getOrganization)
  const { setting } = organization
  const axios = useAxiosWithHeader()
  const dispatch = useDispatch()
  // options for PR status
  const [options, setOptions] = useState(
    allPRStatus.filter((status) => !newStatus?.includes(status)),
  )

  async function onAddPersonalReminder() {
    setLoading(true)
    const isError = handleErrorsInputPersonalReminder({ newMessage, newStatus })
    if (isError) {
      setLoading(false)
      return
    }
    const allReminders = cloneDeep(setting.personalReminders)
    const newReminder = {
      message: `🔔 ${newMessage}`,
      creator: {
        avatarUrl: engineer.avatarUrl,
        providerLogin: engineer.providerLogin,
        id: engineer.id,
        htmlUrl: engineer.htmlUrl,
      },
      status: newStatus,
    }
    allReminders.unshift(newReminder)
    await globalUpdateCompanySetting({
      key: 'personalReminders',
      value: allReminders,
      setting,
      axios,
      dispatch,
    })
    try {
      await axios.post(
        `/settings/new-personal-reminder?orgId=${organization.id}&engineerId=${engineer.id}`,
        {
          newMessage,
          newStatus,
        },
      )
    } catch (e) {
      console.log('Error sending the new message to our server', e)
    }
    setLoading(false)
    setNewMessage('')
    setNewStatus([])
    setOptions(allPRStatus)
  }
  return (
    <tr key="newLine">
      <td className={classNames(classCell, 'h-40 w-[575px] max-w-[575px] pt-0')}>
        <div className="m-0 flex items-center rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
          <p className="mb-0">🔔</p>
          <input
            type="text"
            name="newMessagePersonalReminder"
            id="newMessagePersonalReminder"
            className="ml-1 block  h-[30px] w-full rounded-md focus:outline-0 sm:text-sm "
            placeholder="Type a new personal reminder..."
            onChange={(event) => setNewMessage(event.target.value)}
            value={newMessage}
          />
        </div>
        <ReminderVariableSelector
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          newStatus={newStatus}
        />
      </td>
      <td className={classNames(classCell, ' w-[200px] max-w-[200px] pt-0')}>
        <InputNewReminderStatus
          newStatus={newStatus}
          setNewStatus={setNewStatus}
          newMessage={newMessage}
          options={options}
          setOptions={setOptions}
        />
      </td>
      <td className={classNames(classCell, 'w-[200px] max-w-[200px] pt-0')}>
        <div className="flex items-center">
          <UserAvatar record={engineer} />
          <p className="mb-0 pl-2 text-gray-800">{engineer?.providerLogin}</p>
        </div>
      </td>
      <td className={classNames(classCell, 'w-[167px] max-w-[167px] pt-0')}>
        <span href="#" className="text-indigo-600 hover:text-indigo-900">
          <div className="flex justify-center ">
            <Button
              icon={<PlusOutlined />}
              disabled={loading}
              onClick={() => onAddPersonalReminder()}
              shape="circle"
              type="primary"
            />
          </div>
        </span>
      </td>
    </tr>
  )
}
