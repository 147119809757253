import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import RoleSelect from './roleSelect'
import AdminSlackSelect from './adminSlackSelect'
import { useAxiosWithHeader } from '../../utils'
import { URLBACK } from '../../assets/urls'
import { useSelector, useDispatch } from 'react-redux'
import { addUser, Organization } from '../../reducers/organizationReducer'
import { message } from 'antd'

export default function InviteAdminModal({ open, setOpen }) {
  const cancelButtonRef = useRef(null)
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)
  const { slackMembers } = organization
  const [newUser, setNewUser] = useState(slackMembers?.[0])
  const [type, setType] = useState({ id: 1, name: 'Admin' })
  const [disabled, setDisabled] = useState(false)
  const dispatch = useDispatch()

  async function handleInviteAdmin() {
    setDisabled(true)
    try {
      const res = await axios.get(
        `${URLBACK}organizations/manageAxoloAdmin/${organization.id}?userSlackId=${
          // In production we need .id, locally we need communicationToolId, why? I don't know
          newUser.communicationToolId || newUser.id
        }&type=${type.name}`,
      )
      if (res.status !== 200) {
        message.error('Error inviting an admin. Please try again or contact support')
        console.log('Error inviting an admin: ')
      } else {
        dispatch(addUser(res.data.user))
        message.success(`${newUser.real_name} was added as an admin.`)
        setOpen(false)
      }
    } catch (e) {
      message.error('Error inviting an admin. Please try again or contact support')
      console.log('Error inviting an admin: ', e)
    }
    setDisabled(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 divide-y-2 text-left">
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Add new administrator
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Grant administrator permissions in Axolo to a teammate
                        </p>
                      </div>
                    </div>
                    <div className="border-b-2 py-6">
                      <p className="text-sm text-gray-500">
                        Select the role and Slack profile of the new user
                      </p>
                      <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <AdminSlackSelect newUser={newUser} setNewUser={setNewUser} />
                        <RoleSelect type={type} setType={setType} />
                      </div>
                    </div>
                    <div />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    disabled={disabled}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-hoverPrimary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 sm:col-start-2 sm:text-sm"
                    onClick={() => handleInviteAdmin()}
                  >
                    Invite
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50  sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
