import { message } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { createBrowserHistory } from 'history'
import { User } from '../../reducers/userReducer'
import { Organization } from '../../reducers/organizationReducer'
import { setIsOnboarded, PlayerState } from '../../reducers/playersReducer'
import { AppContent } from '../../components/utils/utils'
import { useAxiosWithHeader, useQuery } from '../../utils'
import { useEffectOnce } from 'react-use'
import { Redirect } from 'react-router'
import getOrgAndEngineers from '../../api/getOrgAndEngineers'
import LoadingPage from '../Loading'
import { URLBACK } from '../../assets/urls'
import { Engineer } from '../../reducers/engineerReducer'
import {
  fetchPullRequestsAndUpdateReducer,
  fetchRepositoriesAndUpdateReducer,
} from '../OpenChannels/fetchPullRequest'
import ModalErrorInstallation from '../../components/ModalErrorInstallation'
import { isSlackAuth } from '../../components/TeamChannels/helpers'
import { handleLogout } from '../../components/logoutButton/handleLogout'
import { fetchSlackChannelsAndUpdateReducer } from '../../components/Settings/utils'

export function Home() {
  const history = createBrowserHistory()
  let prevPath = null

  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname
      window.analytics.page()
    }
  })
  const [redirect, setRedirect] = useState(false)
  const user = useSelector(User.selectors.selectUser, isEqual)
  const organization = useSelector(Organization.selectors.getOrganization, isEqual)
  const isOnboarded = useSelector(PlayerState.selectors.isOnboarded)
  const isMountedRef = useRef(true)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  // This is so that getOrgAndEngineers only gets called once at a time.
  let query = useQuery()
  const axios = useAxiosWithHeader()

  const dispatch = useDispatch()

  const slackAuth = isSlackAuth({ user, organization })

  const githubAuth = user.organizations[0]?.providerLogin?.length > 0

  // I don't understand this useEffectOnce, some comments would be nice! :)
  useEffectOnce(() => {
    ;(async () => {
      const isSetup = query.get('github')
      if (
        user &&
        !user.requestGithubApp &&
        isSetup &&
        organization.players?.length === 0
      ) {
        // need a time out so we can first get the user in due time
        await setTimeout(() => {
          getOrgAndEngineers({ organization, dispatch, user, axios, setIsOnboarded })
        }, 1000)
      }
      if (!organization.slackMembers) {
        getOrgAndEngineers({ organization, dispatch, user, axios, setIsOnboarded })
      }
    })()
  }, [axios, dispatch, query, user, getOrgAndEngineers])

  // I don't understand this useEffect, some comments would be nice! :)
  useEffect(() => {
    ;(async () => {
      const isSetup = query.get('slack')
      if (user && isSetup && !user.slackAuth) {
        try {
          const { data } = await axios.put('/users/' + user.id, {
            slackAuth: true,
          })
          dispatch(User.actions.updateUser(data))
          message.destroy()
          message.success('Slack successfully installed', 2.5)

          // TODO should call those two functions in other places
          // May be when someone loggs in, so it gets refreshed in case there are new members
          await getOrgAndEngineers({
            organization,
            dispatch,
            user,
            axios,
            setIsOnboarded,
          })
        } catch (e) {
          console.error({ e })
          message.error('Error, please try again or contact support')
        }
      }
    })()
  }, [axios, dispatch, query, user, getOrgAndEngineers])

  // act that user has connected his github
  useEffect(() => {
    ;(async () => {
      const isSetup = query.get('setup_action')
      if (user && isSetup && !user.providerAuth) {
        try {
          const { data } = await axios.put('/users/' + user.id, {
            providerAuth: true,
          })
          dispatch(User.actions.updateUser(data))
          if (data && data.providerAuth && data.organizations.length > 0) {
            message.success('GitHub app successfully installed')
          }
        } catch (e) {
          console.error({ e })
          message.error('Error, please try again or contact support')
        }
      }
    })()
  }, [axios, dispatch, query, user])

  // User logs in when he is already connected to slack
  // we update his org and slackmembers
  async function updateUser() {
    if (user?.jwt?.length < 5) {
      try {
        const resp = await axios.get('/users/' + user.id)
        if (resp?.data?.email?.length > 0) {
          dispatch(User.actions.updateUser(resp.data))
        }
      } catch (error) {
        console.log('updateUser error', error)
      }
    }
  }

  // this is the second const isSetup = query.get('slack'). do you know why? there seem to do almost the same thing
  // I think each of them handle a very specific use case.
  // The first one will update the slackAuth property to indicate slack
  // has been installed to the backend before calling getOrgAndEngineers
  // the second one call update getOrgAndEngineers to start onboarding directly
  useEffectOnce(() => {
    const isSetup = query.get('slack')
    if (!organization.id) {
      getOrgAndEngineers({
        invocation: 10,
        organization,
        dispatch,
        user,
        axios,
        setIsOnboarded,
      })
      updateUser()
      if (isSetup === 'error') {
        message.destroy()
        message.error(
          'It seems there was an error when installing Slack. Please try again or contact support',
        )
      }
    }
  }, [axios, dispatch, query, user, getOrgAndEngineers, updateUser])

  // if admin has providerAuth === true but no organization that means it did not have the right to install the app in its organization
  useEffect(() => {
    ;(async () => {
      const activePlayers = organization.players?.filter(
        (p) => p.communicationToolId?.length > 0,
      )

      // If User install from GitHub and we are waiting for him to add Slack it goes here.
      if (
        slackAuth === false &&
        organization?.sender?.length > 0 &&
        user.username === organization.sender &&
        activePlayers.length === 0 &&
        isOnboarded === null
      ) {
        dispatch(setIsOnboarded(false))
      } else if (activePlayers.length > 0 && !isOnboarded) {
        dispatch(setIsOnboarded(true))
      }
      // If user is GitHub Admin who just accepted to install GitHub then we redirect him
      if (
        organization?.requester?.length > 0 &&
        user.provider === 'github' &&
        user?.providerLogin !== organization?.requester
      ) {
        setRedirect(true)
        return
      }

      const isSetup = query.get('setup_action')
      message.destroy()
      if (
        user &&
        isSetup &&
        user.providerAuth &&
        user.organizations[0]?.providerLogin?.length <= 0 &&
        user.target_type === 'user'
      ) {
        message.error(
          'Axolo can only be installed on Organizations. Please install Axolo on an organization.',
        )
      } else if (
        user &&
        isSetup &&
        user.providerAuth &&
        user.organizations[0]?.providerLogin?.length <= 0
      ) {
        message.error(
          'It seems you do not have admin right on Github or that you tried to install Axolo on a personnal account, please install Axolo on your organization & come back!',
          10,
        )
      } else if (
        user &&
        isSetup &&
        user.providerAuth &&
        user.organizations[0]?.providerLogin?.length > 0
      )
        message.success('GitHub app successfully installed')
    })()
  }, [axios, dispatch, query, user])

  // this useEffectOnce update the Engineer state for admins
  useEffectOnce(() => {
    axios
      .get(`${URLBACK}engineer/me/${organization.id}`)
      .then((engineerResp) => {
        dispatch(Engineer.actions.setData(engineerResp.data))
      })
      .catch((error) => {
        console.log('error getting engineer info', error)
      })
  })

  const [loading, setLoading] = useState(false)
  // useEffectOnce to check if we have the Slack channels of the organization
  // this should only be useful during the first onboarding or first time and
  // old organization connects after this commit
  useEffect(() => {
    let active = true
    let attempts = 0
    const maxAttempts = 200

    // This is for the onboarding, we want to make sure we have PR
    const fetchPullRequestsRepeatedly = async () => {
      while (active && attempts < maxAttempts && organization.id) {
        attempts += 1
        const pullRequests = await fetchPullRequestsAndUpdateReducer({
          organization,
          user,
          axios,
          dispatch,
          Organization,
          isMountedRef,
          source: 'Home',
        })

        if (pullRequests.length > 0) {
          break
        }

        await new Promise((r) => setTimeout(r, 1000)) // Wait for 1 second before next attempt
      }
    }

    if (organization.id) {
      fetchSlackChannelsAndUpdateReducer({
        organization,
        user,
        axios,
        dispatch,
        setLoading,
      })

      fetchPullRequestsRepeatedly()
    }

    return () => {
      active = false
    }
    // if we add axios here, it will call twice the PR for this org
  }, [organization.id, dispatch, user, setLoading, organization])

  if (user?.providerName === 'autoAssignReviewer') {
    return <Redirect to={'/settings/auto-assign-reviewer'} />
  }

  if (redirect) {
    return <Redirect to={`/githubInstalled/${user.username}`} />
  }
  if (isOnboarded === false) {
    // We track reditus here
    try {
      window.gr('track', 'conversion', { email: user.email })
    } catch (error) {
      console.log('error tracking gr', error)
    }
    // Order matters
    if (!slackAuth && githubAuth) {
      // This means the admin accepted to install Axolo on GitHub
      if (
        organization?.requester?.length > 0 &&
        user.provider === 'github' &&
        user?.providerLogin !== organization?.requester
      ) {
        return <Redirect to={`/githubInstalled/${user.username}`} />
      }
      return <Redirect to={'/home/onboarding/slack'} />
    }
    if (slackAuth && githubAuth) {
      return <Redirect to={'/home/onboarding/members'} />
    }
    if (slackAuth && user.requestGithubApp) {
      return <Redirect to={'/home/onboarding/github'} />
    }
    if (slackAuth && !githubAuth) {
      return <Redirect to={'/home/onboarding'} />
    }
    return (
      <div>
        <AppContent className="mt-4 flex">
          <h1 className="pt-8 text-center text-xl">Something wrong happened</h1>
          <p className="text-center">
            If you are seeing this screen, try to log out and log in again or contact
            support.
          </p>
          <span
            className="flex justify-center pt-8"
            onClick={() => handleLogout(user.jwt, dispatch, axios)}
          >
            <button
              type="primary"
              className="rounded-lg border-none bg-primary px-4 py-1 text-textWhite hover:bg-indigo-700 hover:text-textWhite"
            >
              Logout
            </button>
          </span>
        </AppContent>
      </div>
    )
  }

  // This helps the users redirect to onboarding his members whenever
  // he logs in or refresh data during onboarding
  // when he has never onboarded anyone but has a Git organization
  const activePlayers = organization.players?.filter(
    (p) => p.communicationToolId?.length > 0,
  )

  if (
    isOnboarded === null &&
    organization?.players?.length > 0 &&
    activePlayers.length === 0 &&
    slackAuth
  ) {
    return <Redirect to={`/home/onboarding/members`} />
  }

  if (isOnboarded === null || !(organization?.players?.length > 0)) {
    return (
      <>
        {organization?.errorInstallation?.length > 0 ? (
          <ModalErrorInstallation
            provider={organization.provider === 'github' ? 'GitHub' : 'GitLab'}
            dispatch={dispatch}
          />
        ) : null}
        <LoadingPage
          footer={false}
          title={'Fetching your information. Can take up to 2 minutes.. '}
        />
      </>
    )
  } else {
    return <Redirect to={`/settings/pull-request-channel`} />
  }
}

export default Home
