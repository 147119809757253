export const stripeKey =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_51MPgnOClT7kSS65lcOwvz6p68FCUv6iomOKdWL5kAAkyHHBoa7XM08QCjRp9UpVbNtgy1Ta4rQQKYXmDU9TDtox8006Rm8QXAi'
    : 'pk_test_51MPgnOClT7kSS65lers5OfAQ7JBYrjVDBSjXV2AYPMxI4xTggNJ3y0sNMPeDKesFNI1LSXtFwUSIhPJYQZp2875j00HlDuqdbl'

export const professionalPricingIds = {
  monthly:
    process.env.NODE_ENV === 'production'
      ? 'price_1OoGVeClT7kSS65lU4HPS9Hu'
      : 'price_1OoKOHClT7kSS65l4lwOmylE',
  yearly:
    process.env.NODE_ENV === 'production'
      ? 'price_1OoGUeClT7kSS65lidjQNOVC'
      : 'price_1OoGNOClT7kSS65lR3i3oXsM',
}

export const businessPricingIds = {
  monthly:
    process.env.NODE_ENV === 'production'
      ? 'price_1MRt4hClT7kSS65lgL3NCMjP'
      : 'price_1KgmqDGYQFoJsqNvphY0xiwg',
  yearly:
    process.env.NODE_ENV === 'production'
      ? 'price_1MRt54ClT7kSS65ltYSH3msL'
      : 'price_1KgmqmGYQFoJsqNvWnwYvhZl',
}
