import { Organization } from '../reducers/organizationReducer'

export default async function getSlackMembers({ dispatch, axios }) {
  try {
    const info = await axios.get(`/organizations/p/getSlackUsers`)
    const slackMembers = info.data
    dispatch(Organization.actions.setData({ slackMembers }))
  } catch (error) {
    console.log('Error getting Slack Members', error)
  }
}
