import { RefreshIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { PlayerState } from '../../reducers/playersReducer'
import { useAxiosWithHeader } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import AxoloTooltip from './axoloTooltip'
import { listAllSlackUsers } from '../../api/getOrgAndEngineers'

export default function RefreshTeamButton() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const axios = useAxiosWithHeader()
  const provider = organization.provider === 'github' ? 'GitHub' : 'GitLab'
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  async function handleUpdateTeam() {
    if (loading) {
      messageInteraction({
        content: 'Please wait...',
        type: 'warning',
      })
      return
    }
    const hide = messageInteraction({
      content: 'Updating teams...',
      type: 'loading',
      duration: 0,
    })
    setLoading(true)
    try {
      // we automatically update Slack members as well for small organizations
      if (organization.slackMembers.length < 500) {
        listAllSlackUsers({ axios, dispatch })
      }
      const res = await axios.get(
        `${URLBACK}teams/update-players-and-teams/${organization.id}`,
      )
      hide()
      const {
        data: { teams, players },
      } = res
      dispatch(
        Organization.actions.setData({
          ...organization,
          teams,
          players,
        }),
      )
      dispatch(PlayerState.actions.setInitialPlayers({ players }))

      messageInteraction({
        content: 'Teams updated.',
        type: 'success',
      })
      setLoading(false)
    } catch (e) {
      hide()
      console.log('Error updating teams: ', e)
      if (e.response?.status === 401) {
        const github = organization.provider === 'github' ? 'GitHub' : 'GitLab'
        messageInteraction({
          content: `Error with your ${github} token, please re-authenticate your ${github} account through your Axolo home in Slack.`,
          type: 'error',
        })
        setLoading(false)
      } else {
        messageInteraction({
          content: 'Error updating teams.',
          type: 'error',
        })
        setLoading(false)
      }
    }
  }
  return (
    <AxoloTooltip title={`Click to sync your ${provider} teams`}>
      <div className="flex items-center hover:cursor-pointer">
        <RefreshIcon
          className="ml-2 h-4 w-4 text-gray-800"
          onClick={() => handleUpdateTeam()}
        />
      </div>
    </AxoloTooltip>
  )
}
