import React, { useState } from 'react'
import { Button, Input, message } from 'antd'
import { useAxiosWithHeader } from '../../utils'
import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'

const GATTokenInput = () => {
  const [gatToken, setGatToken] = useState('')
  const [loading, setLoading] = useState(false) // State to manage loading
  const axios = useAxiosWithHeader()
  const organization = useSelector(Organization.selectors.getOrganization)

  const handleGatTokenChange = (e) => {
    setGatToken(e.target.value)
  }

  const handleGatTokenSubmit = async () => {
    if (!gatToken.trim()) {
      message.error('GAT token cannot be empty') // Indicate error if token is empty
      return
    }
    
    setLoading(true) // Set loading to true when submitting
    try {
      await axios.post(`/organizations/gitlab-token/${organization.id}`, {
        token: gatToken,
      })
      message.success('GAT token updated successfully')
    } catch (error) {
      message.error(
        `Error updating GAT token: ${error.response?.data?.message || error.message}`,
      )
    } finally {
      setLoading(false) // Reset loading state after the request
    }
  }

  return (
    <div className="mb-2 mt-5 w-full">
      <h1 className="text-xl font-semibold text-gray-900">GitLab Access Token (GAT)</h1>
      <div className="">
        <p className="mb-4 flex items-center text-gray-600">
          Enter your GitLab Group Access Token (GAT) for better reliability.
          <a
            href="https://help.axolo.co/en/article/enable-access-tokens-for-gitlab-3fgxcl/"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1 text-blue-600 hover:text-blue-800"
          >
            Learn more about GitLab access tokens
          </a>
        </p>

        <div className={'flex w-1/2 items-center'}>
          <Input
            placeholder="Enter your GAT"
            value={gatToken}
            onChange={handleGatTokenChange}
            type="password"
            className="ml-2 w-32"
          />
          <Button
            type="primary"
            onClick={handleGatTokenSubmit}
            className="ml-2"
            loading={loading} // Show loader when loading
            disabled={loading} // Disable button when loading
          >
            {gatToken ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default GATTokenInput
