import { useSelector } from 'react-redux'
import { Analytics } from '../../../reducers/analyticsReducer'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
  LabelList,
} from 'recharts'
import TagGrowth from '../utils/tagGrowth'
import {
  radiusBarLeft,
  radiusBarRight,
  stackedColor1,
  stackedColor2,
} from '../utils/stackedBar-classes'
import NoDataGraph from '../NoDataGraph/NoDataGraph'
import SkeletonLoading from '../utils/skeletonLoader'
import StackedBarLoading from '../StackedBarLoading.js/stackedBarLoading'
import ChartError from '../ChartError/chartError'
import { classNames } from '../../../utils'

/*
 ** Variable constitution:
 **  stat = {
 **    devTime : int,
 **    reviewTime : int,
 **  }
 */

export default function CycleTimeStackedChart({ loading, error, marginTime }) {
  const analytics = useSelector(Analytics.selectors.getAnalytics)
  if (error) return <ChartError />
  const { cycleTime } = analytics
  let stat, pastStat
  if (cycleTime) {
    stat = cycleTime.stat
    pastStat = cycleTime.pastStat
  }

  if (!cycleTime || !stat) return <NoDataGraph />
  if (stat?.devTime < 0 || stat?.reviewTime < 0) return <NoDataGraph />
  const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props
    const value = props.value / stat?.totalCycleTime
    return (
      <Label
        {...rest}
        value={`${(value * 100).toFixed()}%`}
        fontSize="12"
        fill="#FFFFFF"
        fontWeight="Bold"
        fontFamily="Poppins"
      />
    )
  }

  const totalCycleTime =
    (stat?.totalCycleTime / 60).toFixed() +
    'h' +
    (stat?.totalCycleTime % 60).toFixed() +
    'm'
  const devTime =
    (stat?.developmentTime / 60).toFixed() +
    'h' +
    (stat?.developmentTime % 60).toFixed() +
    'm'
  const reviewTime =
    (stat?.reviewTime / 60).toFixed() + 'h' + (stat?.reviewTime % 60).toFixed() + 'm'

  const CycleTimeDescription = () => {
    return (
      <div className="grid grid-cols-2 gap-4 self-center ml-4 text-lg font-medium ">
        <div className="text-center">
          <h4 className=" text-gray-500">Development Time</h4>
          <div className="flex justify-center">
            {loading ? (
              <SkeletonLoading specificCss="!w-20" />
            ) : (
              <>
                <p className={`text-[${stackedColor1}] font-semibold mr-2`}>{devTime}</p>

                <TagGrowth
                  change={
                    (stat?.developmentTime - pastStat?.developmentTime) /
                      pastStat?.developmentTime || 0
                  }
                  changeType={
                    stat?.developmentTime - pastStat?.developmentTime > 0
                      ? 'increase'
                      : 'decrease' // < because we want to reduce it
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className="text-center">
          <h4 className=" text-gray-500">Review Time</h4>
          <div className="flex justify-center">
            {loading ? (
              <SkeletonLoading specificCss="!w-20" />
            ) : (
              <>
                <p className={`text-[${stackedColor2}] font-semibold mr-2`}>
                  {reviewTime}
                </p>
                <TagGrowth
                  change={
                    (stat?.reviewTime - pastStat?.reviewTime) / pastStat?.reviewTime || 0
                  }
                  changeType={
                    stat?.reviewTime - pastStat?.reviewTime > 0 ? 'increase' : 'decrease' // < because we want to reduce it
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  const CycleTimeBar = () => {
    if (loading) return <StackedBarLoading />
    return (
      <ResponsiveContainer height={150} width={'100%'}>
        <BarChart
          layout="vertical"
          data={[stat]}
          stackOffset="expand"
          maxBarSize={75}
          margin={{ top: 0, left: -30, right: 10, bottom: 0 }}
        >
          <XAxis hide type="number" />
          <YAxis type="category" dataKey="name" stroke="#FFFFFF" fontSize="12" />

          <Bar
            dataKey="developmentTime"
            fill={stackedColor1}
            stackId="a"
            radius={radiusBarLeft}
          >
            <LabelList
              dataKey="developmentTime"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
          <Bar
            dataKey="reviewTime"
            fill={stackedColor2}
            stackId="a"
            radius={radiusBarRight}
          >
            <LabelList
              dataKey="reviewTime"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }

  const MainCycleTimeData = () => {
    if (loading) return <SkeletonLoading specificCss="!w-20" />
    return (
      <>
        <p
          className={classNames(
            marginTime ? 'px-4 sm:px-6 lg:px-8' : 'ml-0',
            'text-lg text-gray-500 mr-4',
          )}
        >
          <span className="font-bold text-xl text-primary">{totalCycleTime}</span>
        </p>
        <TagGrowth
          change={stat?.totalCycleTime - pastStat?.totalCycleTime || 0}
          changeType={
            stat?.totalCycleTime - pastStat?.totalCycleTime > 0 ? 'increase' : 'decrease' // < because we want to reduce it
          }
        />
      </>
    )
  }

  return (
    <div>
      <div className="flex">
        <MainCycleTimeData marginTime />
      </div>
      <div className="py-2 pr-2 justify-center ">
        <CycleTimeBar />
        <CycleTimeDescription />
      </div>
    </div>
  )
}
