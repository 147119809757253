import { RefreshIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import _, { uniqBy } from 'lodash'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { useAxiosWithHeader } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import AxoloTooltip from './axoloTooltip'
import RepositorySelectorDropdown from './refresh-pr-selecting-repo'
import { PlayerState } from '../../reducers/playersReducer'

export default function RefreshPRButton() {
  const organization = useSelector(Organization.selectors.getOrganization)
  const players = useSelector(PlayerState.selectors.getPlayers)
  const { initialPullRequests } = organization
  const axios = useAxiosWithHeader()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const pull = organization.provider === 'gitlab' ? 'merge' : 'pull'

  let repositories = []
  if (initialPullRequests?.length > 0 || organization.repositories?.length > 0) {
    const initialRepos = initialPullRequests?.map((pr) => pr.repository) || []
    const orgRepos = organization.repositories || []
    repositories = uniqBy([...initialRepos, ...orgRepos], '_id')
  }

  // after the refresh upon a specific repo, a weird bug add a repo as an id and not an object
  repositories = repositories.filter((repo) => repo?._id)

  async function handleUpdateRepo() {
    if (loading) {
      messageInteraction({
        content: 'Please wait...',
        type: 'warning',
      })
      return
    }
    const hide = messageInteraction({
      content: `Updating repositories...`,
      type: 'loading',
      duration: 0,
    })
    setLoading(true)
    try {
      const res = await axios.get(
        `${URLBACK}organizations/repositories/${organization.id}`,
      )
      const { data: repositories } = res

      await dispatch(
        Organization.actions.setData({
          repositories,
        }),
      )
      hide()
      messageInteraction({
        content: `Repository updated successfully.`,
        type: 'success',
      })
      setLoading(false)
    } catch (e) {
      hide()
      console.log(`Error updating repository: `, e)
      messageInteraction({
        content: `Error updating repository.`,
        type: 'error',
      })
      setLoading(false)
    }
  }

  async function handleUpdateAllPRs() {
    if (loading) {
      messageInteraction({
        content: 'Please wait...',
        type: 'warning',
      })
      return
    }
    const hide = messageInteraction({
      content: `Updating all repositories & ${pull} requests...`,
      type: 'loading',
      duration: 0,
    })
    setLoading(true)
    try {
      const res = await axios.get(
        `${URLBACK}pull-requests/refreshOrganizationOpenPRsFromApp/${organization.id}`,
      )
      const { data: pullRequests } = res

      await dispatch(
        Organization.actions.setData({
          pullRequests,
          lastHomePullRequestRefresh: new Date(),
        }),
      )
      hide()
      messageInteraction({
        content: `${_.capitalize(pull)} requests updated.`,
        type: 'success',
      })
      setLoading(false)
    } catch (e) {
      hide()
      console.log(`Error updating ${pull} requests: `, e)
      messageInteraction({
        content: `Error updating ${pull} requests.`,
        type: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <AxoloTooltip title={`Click to sync your ${pull} requests`}>
      <div className="flex items-center hover:cursor-pointer">
        <RepositorySelectorDropdown
          repositories={repositories}
          handleUpdateAllPRs={handleUpdateAllPRs}
          handleUpdateRepo={handleUpdateRepo}
        />
        {/* {organization.provider === 'github' ? (
          
        ) : (
          <RefreshIcon
            className="ml-2 h-4 w-4 text-gray-800"
            onClick={() => handleUpdateAllPRs()}
          />
        )} */}
      </div>
    </AxoloTooltip>
  )
}
