import { Switch } from 'antd'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { globalUpdateCompanySetting } from '../../utils'

export const UpdateStatusActionAndDeployment = async (setting, status, type, axios) => {
  const newStatus = status.join(',')
  if (type === 'action') {
    try {
      await axios.put(`settings/${setting.id}`, {
        githubActionStatusNotifications: newStatus,
      })
    } catch (e) {
      console.log('Error, please contact support :', e)
    }
  } else if (type === 'deployment') {
    try {
      await axios.put(`settings/${setting.id}`, {
        githubDeploymentStatusNotifications: newStatus,
      })
    } catch (e) {
      console.log('Error, please contact support :', e)
    }
  }
}

export async function fetchSlackChannelsAndUpdateReducer({
  organization,
  user,
  axios,
  dispatch,
  setLoading,
}) {
  let i = 0
  let slackChannelsLength = 0
  while (
    organization.id &&
    !(organization.slackChannels?.length > 0) &&
    user?.id &&
    slackChannelsLength === 0 &&
    i < 200 &&
    user?.providerName !== 'autoAssignReviewer'
  ) {
    i += 1
    await new Promise((r) => setTimeout(r, 5000))
    if (process.env.NODE_ENV !== 'development') {
      try {
        const res = await axios.get(
          `${URLBACK}conversations/get-all-conversations?organization=${organization.id}&_limit=-1&userId=${user.id}`,
        )
        if (res.data?.length > 0) {
          dispatch(
            Organization.actions.setData({
              slackChannels: res.data,
            }),
          )
          slackChannelsLength = res.data.length
          setLoading(false)
        }
      } catch (error) {
        console.log('Error getting slack channels', error)
      }
    } else {
      setLoading(false) // Optionally set loading to false if in development
    }
  }
}

export const AutoAssignReviewerSetting = ({
  // default true for AutoAssignReviewerSetting
  orgIsPremium = true,
  setting,
  setIsModalPaywallVisible,
  axios,
  dispatch,
}) => (
  <div className="ml-10">
    <div className="relative" style={{ width: '100%' }}>
      <div className="flex justify-between">
        <p className="">
          Enable auto reviewer assignement for GitLab merge requests.
          <br />
          By default, Axolo will invite 2 reviewers per CODEOWNERS line. You can set
          Axolo's behavior by adding an integer at the end of each line. Learn more in{' '}
          <a
            href="https://axolo.co/docs/gitlab/auto-assignement-for-reviewers"
            target="_blank"
            rel="noreferrer"
          >
            our documentation
          </a>
          .
        </p>
        <Switch
          disabled={!orgIsPremium}
          checked={setting?.autoAssignReviewer}
          onClick={() => {
            if (orgIsPremium) {
              globalUpdateCompanySetting({
                key: 'autoAssignReviewer',
                value: !setting?.autoAssignReviewer,
                setting,
                axios,
                dispatch,
              })
            }
          }}
        />
      </div>
      {!orgIsPremium && (
        <div
          className="absolute bottom-0 left-0 right-0 top-0"
          onClick={() => {
            setIsModalPaywallVisible(true)
          }}
          style={{ cursor: 'pointer', backgroundColor: 'transparent' }}
        />
      )}
    </div>
    <div className=" mt-10 flex flex-col">
      <p className="text-center !text-xs">
        Some examples on how to write your CODEOWNERS file. <br />
        If you have not any CODEOWNERS file yet, please{' '}
        <a
          href="https://docs.gitlab.com/ee/user/project/codeowners/"
          target="_blank"
          rel="nofollow noreferrer"
        >
          {' '}
          follow the official documention
        </a>
        .
      </p>
      <div className="flex justify-center">
        <img
          src="/images/codeowner-screen.png"
          alt="codeowners screenshot"
          className="max-w-lg"
        />
      </div>
    </div>
  </div>
)
