import { User } from './userReducer'
import { createSlice, current } from '@reduxjs/toolkit'
import { partition } from 'lodash'

let initialState = {
  players: [],
  bots: [],
  isOnboarded: null,
}

export const playersSlice = createSlice({
  name: 'playerState',
  initialState,
  reducers: {
    setIsOnboarded: (state, action) => ({ ...state, isOnboarded: action.payload }),
    setInitialPlayers: (state, action) => {
      const { players } = action.payload
      const [engineers, bots] = partition(players, (player) => !player.isBot)
      return {
        ...state,
        players: engineers,
        bots: bots,
      }
    },
    setPlayer: (state, action) => {
      const currentState = current(state)
      const newPlayers = [...currentState.players]
      const player = action.payload
      const index = newPlayers.findIndex((p) => p.id === player.id)
      newPlayers[index] = player
      return {
        ...state,
        players: newPlayers,
      }
    },
    updateBots: (state, action) => {
      const bots = action.payload
      return {
        ...state,
        bots,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(User.actions.logout, (_) => initialState)
      .addCase(User.actions.changesOrg, (_) => initialState)
  },
})

export const { setPlayer, updateBots } = playersSlice.actions
export const { setInitialPlayers } = playersSlice.actions
export const { setIsOnboarded } = playersSlice.actions
export const { setRequestPlayerAuth } = playersSlice.actions

const getPlayers = (state) => state.playerState.players
const getBots = (state) => state.playerState.bots
const isOnboarded = (state) => state.playerState.isOnboarded
const requestPlayerAuth = (state) => state.playerState.requestPlayerAuth

export const PlayerState = {
  actions: playersSlice.actions,
  selectors: { getBots, getPlayers, isOnboarded, requestPlayerAuth },
}
