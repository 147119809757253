import { ExternalLinkIcon } from '@heroicons/react/outline'
import { Tooltip } from 'antd'
import { AxoloArchiveIcon } from '../Settings/setting-helper'
import { useAxiosWithHeader } from '../../utils'
import { useState } from 'react'
import LoaderIcon from '../LoaderIcon'
import { useDispatch } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'
import messageInteraction from '../Analytics/utils/messageInteraction'

export const ChannelInformation = ({ standup = false, channel, slackCred, archived }) => {
  const axios = useAxiosWithHeader()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  async function archiveChannel() {
    setLoading(true)
    try {
      const resp = await axios.get(`/slack/archiveChannels/${channel.channelId}`)
      if (resp?.status === 200) {
        dispatch(
          Organization.actions.setPullRequestToArchived({ channelId: channel.channelId }),
        )
      } else {
        throw Error('Error archiving channel')
      }
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      messageInteraction({ content: 'Error archiving channel', type: 'error' })
      setLoading(false)
    }
  }

  const SlackLink = () => (
    <a
      className="block overflow-hidden text-gray-800"
      target={'_blank'}
      rel="noreferrer nofollow"
      href={`https://slack.com/app_redirect?channel=${channel?.channelId}&team=${slackCred?.teamId}`}
    >
      <Tooltip
        title={`Open channel on Slack:\n #${channel?.channelName}`}
        className="flex"
      >
        <ExternalLinkIcon
          className={`mr-4 h-8 w-8 cursor-pointer text-primary`}
          aria-hidden="true"
        />
        {standup ? <p className={`mb-0`}>#{channel?.channelName}</p> : null}
      </Tooltip>
    </a>
  )

  const ArchivedTooltip = () => (
    <Tooltip title={`Channel is archived`}>
      <div className="flex items-center justify-center">
        {loading ? <LoaderIcon title={''} spinning={loading} /> : <></>}
      </div>
    </Tooltip>
  )

  const ArchiveButton = () => (
    <Tooltip title={`Archive channel`}>
      <div
        className="flex cursor-pointer items-center justify-center"
        onClick={() => archiveChannel(channel)}
      >
        {loading ? (
          <LoaderIcon title={''} spinning={loading} />
        ) : (
          <div>
            <AxoloArchiveIcon color="primary" size={8} />
          </div>
        )}
      </div>
    </Tooltip>
  )

  if (standup) {
    return (
      <div className="flex flex-row">
        <SlackLink />
      </div>
    )
  }

  return (
    <div className="flex flex-row">
      <SlackLink />
      {archived ? <ArchivedTooltip /> : <ArchiveButton />}
    </div>
  )
}
