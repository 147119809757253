export const MainDivOnboarding = ({ children, pathname = '' }) => {
  let helpText = 'Need help?'
  if (pathname?.includes('self-hosted')) {
    helpText = 'Need help or other technical requirements?'
  }
  return (
    <div className="flex min-h-[550px] flex-col justify-between sm:h-full sm:min-h-[50vh] ">
      {' '}
      <div className="">{children}</div>
      <p className="mb-0 flex justify-center pt-12">
        {helpText}
        <span
          className="cursor-pointer pl-1 text-primary"
          onClick={() => {
            window.$crisp.push(['do', 'chat:open'])
          }}
        >
          Chat with us.
        </span>
      </p>
    </div>
  )
}
