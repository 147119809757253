import React, { useState, useEffect } from 'react'
import { URLBACK } from '../../assets/urls'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useEffectOnce, useLocation } from 'react-use'
import { useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { setUser, updateUser } from '../../reducers/userReducer'
import HomeFooter from '../../pages/FooterHomepage/footerHomePage'
import { LogOutButton } from '../logoutButton'
import { createAxios } from '../../utils'
import WrongWorkSpace from './wrongWorkspace'
import getOrg from '../../api/getOrg'

export default function SlackAuth() {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [Error, setError] = useState(false)
  const [wrongWorkSpace, setWrongWorkspace] = useState(false)
  const [workspaceName, setworkspaceName] = useState('')
  const [previousWorkspace, setpreviousWorkspace] = useState('')
  const query = new URLSearchParams(location.search)
  let jwt = query.get('jwt')
  const orgProviderLogin = query.get('orgProviderLogin')

  useEffect(() => {
    // Ensure gtag is defined
    if (typeof gtag === 'function') {
      // Add Google Analytics event snippet
      // eslint-disable-next-line no-undef
      gtag('event', 'conversion', { send_to: 'AW-314380445/CxvyCP6h5OsYEJ2h9JUB' })
    }
  }, [])

  const loggingUser = async () => {
    let user
    let status
    try {
      if (jwt && jwt.length > 0) {
        const axios = createAxios(jwt)
        const url = `${URLBACK}axolouser/me`
        const resp = await axios.get(url)
        user = resp.data
        status = resp.status
      } else {
        const resp = await Axios.get(`${URLBACK}auth/slack/callback${location.search}`, {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
          },
        })
        status = resp.status
        jwt = resp.data.jwt
        user = resp.data.user
      }

      const isAdminUser = user?.role?.name === 'Authenticated'

      // TODO get organization from slack
      if (status !== 200) return

      dispatch(updateUser({ ...user, jwt, isAdminUser }))

      // This is used to log in the user with the first org we find
      let orgId = user?.organizations[0]?.id
      if (orgProviderLogin) {
        orgId = user?.organizations?.find(
          (org) => org?.providerLogin === orgProviderLogin,
        )?.id
      }
      if (orgId) {
        const axios = createAxios(jwt)
        try {
          await getOrg({ orgId, dispatch, axios })
        } catch (error) {
          console.log('error in getOrg', error)
        }
      }
      window.$crisp.push(['set', 'user:email', user.email])
      Object.keys(user).forEach((key) => {
        window.$crisp.push(['set', `user:${key}`, user[key]])
      })

      window.analytics.identify(user.email, {
        name: user.name,
        email: user.email,
      })
      // if (user.activeSlackUsers > 50000) {
      //   message.success("Thank you, book a call with us to finish onboarding");
      //   window.location.href = `https://calendly.com/arthur-coudouy/demo-axolo?email=${user.email}`
      // } else
      if (isAdminUser) {
        history.push('/home')
      } else if (user?.role?.type === 'engineer') {
        history.push(`/engineer/settings/general`)
      } else {
        history.push(`/onboarding/selfonboard`)
      }
    } catch (e) {
      console.log('e?.response?.data?.message', e?.response?.data?.message)
      if (
        e?.response?.data?.message &&
        e?.response?.data?.message[0]?.messages &&
        e?.response?.data?.message[0]?.messages[0]?.id ===
          'Auth.form.error.email.taken.username'
      ) {
        setWrongWorkspace(e?.response?.data?.message[0]?.messages[0]?.img)
        setworkspaceName(e?.response?.data?.message[0]?.messages[0]?.workspace)
        setpreviousWorkspace(
          e?.response?.data?.message[0]?.messages[0]?.previousWorkspace,
        )
        // If the user wants to switch workspace we'll need this
        const authedUser = e.response.data.message[0]?.messages[0].authed_user
        const credential = e.response.data.message[0]?.messages[0].credential
        const jwt = e.response.data.message[0]?.messages[0].jwt
        dispatch(setUser({ authedUser, tempCredential: credential, jwt }))

        const errorMessage =
          e?.response?.data?.message[0]?.messages[0]?.error ||
          'Same email different workspace.'
        setError(errorMessage)
        return
      }
      if (
        e?.response?.data?.message &&
        e?.response?.data?.message[0]?.messages &&
        e?.response?.data?.message[0]?.messages[0]?.id === 'Auth.advanced.couldntsignin'
      ) {
        setError(
          'Error: Could not sign you in, try "Add to Slack" instead or contact support',
        )
        return
      }
      console.error({ e })
      if (typeof e?.response?.data?.message === 'string') {
        setError(e?.response?.data?.message)
      } else {
        console.log(
          'Error in authslack',
          e?.response?.data?.message?.[0]?.messages?.[0]?.msg,
          e,
        )
        if (
          e?.response?.data?.message?.[0]?.messages?.[0]?.msg ===
          'User signed in and no credential found in DB.'
        ) {
          setError(
            'We have not found your credentials, please log out and then use "Add to Slack" instead.',
          )
        } else setError('Sorry, an unexpected error occured.')
      }
    }
  }

  useEffectOnce(() => {
    loggingUser().then()
  })

  const ErrorMessage = () => {
    return (
      <div className="flex flex-col items-center ">
        <h2 className="text-ourBlack mt-6 text-center text-3xl font-bold">
          There has been an error logging you in:
        </h2>
        <p className="mt-2 text-center text-sm text-ourGrey">{Error}</p>
        <p className="mt-2 text-center text-sm text-ourGrey">
          Contact support or try again.
        </p>
        <LogOutButton />
      </div>
    )
  }

  const LoadingMessage = () => {
    return (
      <div className="">
        <h2 className="text-ourBlack mt-6 text-center text-3xl font-bold">Loading</h2>
        <div className="mt-24 flex justify-center">
          <Spin spinning />
        </div>
      </div>
    )
  }

  if (workspaceName) {
    return (
      <WrongWorkSpace
        workSpaceUrl={wrongWorkSpace}
        workspaceName={workspaceName}
        previousWorkspace={previousWorkspace}
      />
    )
  }

  return (
    <div className=" h-screen bg-white">
      <div className="flex h-[88vh] flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-3xl">
          <img className="mx-auto h-12 w-auto" src="/logo_axolo.png" alt="Logo Axolo" />
          {Error ? <ErrorMessage /> : <LoadingMessage />}
        </div>
      </div>
      <HomeFooter />
    </div>
  )
}
