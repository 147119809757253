import { ArrowSmRightIcon } from '@heroicons/react/solid'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { Organization } from '../../reducers/organizationReducer'

export default function TopBannerBillingRedirection() {
  const history = useHistory()
  const organization = useSelector(Organization.selectors.getOrganization)

  const shouldShowBanner =
    organization && (organization.plan === 'blocked' || organization.plan === 'starter')
  if (!shouldShowBanner) return

  const blockedBanner = (
    <>
      <span className="inline">Are you looking for the</span>
      <span className="rainbow-button">
        <button
          onClick={() => history.push('/settings/billing')}
          className="inline-flex items-center font-bold text-white hover:text-primary"
        >
          Billing Section?
          <ArrowSmRightIcon className="animate-bounce-h ml-2 h-4 w-4" />
        </button>
      </span>
    </>
  )

  let starterBanner
  if (
    organization.channelCountSinceStartOfMonth >=
    organization.maxChannelsPerMonthForStarterPlan
  ) {
    starterBanner = (
      <>
        <span className="inline">
          You've hit your monthly channel limit on the Free plan.
        </span>
        <span className="rainbow-button">
          <button
            onClick={() => history.push('/settings/billing')}
            className="inline-flex items-center font-bold text-white hover:text-primary"
          >
            Upgrade for unlimited PR channels
            <ArrowSmRightIcon className="animate-bounce-h ml-2 h-4 w-4" />
          </button>
        </span>
      </>
    )
  } else {
    starterBanner = (
      <>
        <span className="inline">
          You've opened {organization.channelCountSinceStartOfMonth} PR channels out of
          your {organization.maxChannelsPerMonthForStarterPlan} monthly limit.
        </span>
        <span className="rainbow-button">
          <button
            onClick={() => history.push('/settings/billing')}
            className="inline-flex items-center font-bold text-white hover:text-primary"
          >
            Upgrade for unlimited PR channels
            <ArrowSmRightIcon className="animate-bounce-h ml-2 h-4 w-4" />
          </button>
        </span>
      </>
    )
  }

  return (
    <div className="relative bg-axoloBlack">
      <div className="mx-auto max-w-[1500px] py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center ">
          <p className="!mb-0 font-medium text-white">
            {organization.plan === 'blocked' ? blockedBanner : starterBanner}
          </p>
        </div>
      </div>
    </div>
  )
}
