export default async function updateUserFunction({ axios, user, dispatch, User }) {
  try {
    const resp = await axios.get('/users/' + user.id)
    if (
      (resp?.data?.email?.length > 0 && !user.email) ||
      resp.data.channelOnboardingAdmin !== user.channelOnboardingAdmin
    ) {
      dispatch(User.actions.updateUser(resp.data))
    }
  } catch (error) {
    console.log('updateUser error', error)
  }
}
