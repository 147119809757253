import { cloneDeep } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { URLBACK } from '../../assets/urls'
import { Organization } from '../../reducers/organizationReducer'
import { classNames, useAxiosWithHeader } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { PlayerState, setPlayer } from '../../reducers/playersReducer'
import { colors } from '../Colors'
import {
  GitHubProfile,
  handleClickRequestAuth,
  MissingAuthBadge,
  onboardEngineer,
  SlackHandleTeamManagement,
  SlackProfile,
  StatusBadge,
  TeamBadge,
  WrapperForMissingSignIn,
} from './utils'
import { MinusCircleIcon } from '@heroicons/react/solid'
import { Tooltip } from 'antd'
import { ExclamationIcon } from '@heroicons/react/outline'
import getSlackMembers from '../../api/getSlackMembers'

export default function TeamManagementTableContent({
  visibleActiveEngineers,
  visibleInactiveEngineers,
}) {
  const organization = useSelector(Organization.selectors.getOrganization)
  const players = useSelector(PlayerState.selectors.getPlayers)
  const { slackMembers, enterprise } = organization
  const canOnboardEngineerIfDifferentEmail =
    enterprise?.canOnboardEngineerIfDifferentEmail
  let { teams } = organization
  const teamColors = {}
  const dispatch = useDispatch()
  const axios = useAxiosWithHeader()

  useEffectOnce(() => {
    ;(async () => {
      if (!organization.slackMembers || organization?.slackMembers?.length === 0) {
        getSlackMembers({ dispatch, axios })
      }
    })()
  }, [axios, dispatch, getSlackMembers])

  if (!Array.isArray(teams)) {
    teams = []
  }
  teams?.forEach((team) => {
    // To define the color of a team I'm taking the char code of the first letter of their team name.
    let colorNumber = Math.abs(team.name[0].toLowerCase().charCodeAt(0) - 97)
    if (colorNumber > colors.length) {
      colorNumber = colorNumber % colors.length
    }
    teamColors[team.name] = colors[colorNumber]
  })
  const playersCommunicationToolIds = players?.map((player) => player.communicationToolId)

  const slackMemberFiltered = (slackMembers || [])
    .filter((member) => {
      const slackCommunicationToolId = member?.communicationToolId || member.id
      return (
        !playersCommunicationToolIds?.includes(slackCommunicationToolId) &&
        // we won't show a Slack profile which has a corresponding GitHub profile that is not onboarded but with an identical email.
        // these profiles will have a button "Onboard" instead of a "Slack handle selector"
        !visibleInactiveEngineers?.find((eng) => eng?.gitEmail === member?.profile?.email)
      )
    })
    ?.sort((a, b) => {
      const nameA = a?.real_name || a?.name
      const nameB = b?.real_name || b?.name
      return nameA?.localeCompare(nameB)
    })

  const teamBadgeCss = 'max-w-20 wrap flex flex-wrap px-3 py-4 text-sm text-gray-500'

  const ActionSection = ({ eng, offboardEngineer }) => {
    const { provider } = eng

    const requestSentRecently = moment(eng.requestToAuthenticateSentAt).isAfter(
      moment().subtract(1, 'days'),
    )
    return (
      <div className="flex justify-end ">
        {(!eng.slackAuthed || !eng[`${provider}Authed`]) && !requestSentRecently ? (
          <WrapperForMissingSignIn eng={eng} requestSentRecently={requestSentRecently}>
            <ExclamationIcon
              className={classNames(
                'mr-3 h-8 w-8 cursor-pointer text-primary hover:text-hoverPrimary',
                requestSentRecently && '!text-gray-400',
              )}
              onClick={() =>
                requestSentRecently
                  ? null
                  : handleClickRequestAuth({ eng, axios, players, dispatch })
              }
            />
          </WrapperForMissingSignIn>
        ) : null}
        <Tooltip placement="top" title={`Offboard ${eng.providerLogin}.`}>
          <MinusCircleIcon
            className="h-8 w-8 cursor-pointer text-primary hover:text-hoverPrimary"
            onClick={() => offboardEngineer({ eng, organization })}
          />
        </Tooltip>
      </div>
    )
  }

  async function offboardEngineer({ eng, organization }) {
    const hide = messageInteraction({
      type: 'loading',
      content: `Offboarding ${eng.providerLogin}...`,
      duration: 0,
    })
    try {
      const updatedPlayer = cloneDeep(eng)
      updatedPlayer.communicationToolId = ''
      updatedPlayer.userName = ''
      dispatch(setPlayer(updatedPlayer))
      await axios.put(`${URLBACK}players/${eng.id}`, {
        playing: true,
        communicationToolId: '',
        userName: '',
        orgId: organization.id,
      })
      hide()
      messageInteraction({
        type: 'success',
        content: `You have offboarded ${eng.providerLogin}.`,
        duration: 1,
      })
    } catch (error) {
      hide()
      // if error dispatch previous state
      dispatch(setPlayer(eng))
      console.error(`Error updating member ${eng.providerLogin}: ${error}`)
      messageInteraction({
        error: 'warning',
        content: `Error offboarding ${eng.providerLogin}`,
      })
    }
  }

  async function onSlackHandleChange({ value, player, slackMembers }) {
    const slackId = value || ''
    const hide = messageInteraction({
      type: 'loading',
      content: `Updating ${player.providerLogin}`,
      duration: 0,
    })
    try {
      const thisSlackMember = slackMembers.find(
        (member) => (member.communicationToolId || member.id) === slackId,
      )
      await onboardEngineer({
        engineer: player,
        dispatch,
        axios,
        thisSlackMember,
        organization,
      })
      hide()
      messageInteraction({
        type: 'success',
        content: `You have onboarded ${player.providerLogin}.`,
        duration: 1,
      })
    } catch (error) {
      hide()
      // if error dispatch previous state
      dispatch(setPlayer(player))
      console.error(`Error updating member ${player.providerLogin}: ${error}`)
      messageInteraction({
        error: 'warning',
        content: `Error onboarding ${player.providerLogin}.`,
      })
    }
  }
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      {/* active engineers */}
      {visibleActiveEngineers.map((eng) => {
        if (eng.isBot) {
          return null
        }
        return (
          <tr key={eng?.id} id={eng?.id}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              <GitHubProfile eng={eng} />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
              <SlackProfile profileSlack={eng?.slackInfo?.profile} />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <div className={teamBadgeCss}>
                <StatusBadge eng={eng} />
                <MissingAuthBadge
                  eng={eng}
                  axios={axios}
                  players={players}
                  dispatch={dispatch}
                />
              </div>
            </td>
            <td className={classNames(teamBadgeCss, 'hidden xl:flex')}>
              <TeamBadge eng={eng} teamColors={teamColors} />
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
              <ActionSection eng={eng} offboardEngineer={offboardEngineer} />
            </td>
          </tr>
        )
      })}
      {visibleInactiveEngineers.map((eng) => {
        const foundMemberWithSameEmail = slackMembers?.find(
          (member) => member.profile?.email === eng.gitEmail,
        )

        return (
          <tr key={eng?.id} id={eng?.id}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              <GitHubProfile eng={eng} />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
              <SlackHandleTeamManagement
                slackMembers={slackMembers}
                eng={eng}
                onSlackHandleChange={onSlackHandleChange}
                slackMemberFiltered={slackMemberFiltered}
                foundMemberWithSameEmail={foundMemberWithSameEmail}
                canOnboardEngineerIfDifferentEmail={canOnboardEngineerIfDifferentEmail}
                axios={axios}
                dispatch={dispatch}
              />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <StatusBadge eng={eng} />
            </td>
            <td className={classNames(teamBadgeCss, 'hidden xl:flex')}>
              <TeamBadge eng={eng} teamColors={teamColors} />
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
              {foundMemberWithSameEmail ? (
                <div className="flex h-full items-center">
                  <button
                    onClick={() =>
                      onSlackHandleChange({
                        value:
                          foundMemberWithSameEmail.communicationToolId ||
                          foundMemberWithSameEmail.id,
                        player: eng,
                        slackMembers,
                      })
                    }
                    className={classNames(
                      'block w-full rounded-md border  bg-green-700 p-2  text-center text-sm font-semibold text-green-100 hover:bg-green-800 hover:text-white',
                    )}
                  >
                    Onboard{' '}
                    {eng?.providerLogin.length > 10
                      ? eng?.providerLogin.slice(0, 10) + '...'
                      : eng?.providerLogin}
                  </button>
                </div>
              ) : null}
            </td>
          </tr>
        )
      })}
      {visibleInactiveEngineers.length === 0 && visibleActiveEngineers.length === 0 && (
        <tr>
          <td colSpan="5" className="py-4 text-center text-sm text-gray-500">
            No engineers found.
          </td>
        </tr>
      )}
    </tbody>
  )
}
